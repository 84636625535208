import { BusinessCenterRounded, CalendarMonth, DateRange, FolderCopyOutlined, SettingsRounded, Tune } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { useTimeTracking } from '../../Contexts/TimeTrackingContext/TimeTrackingProvider'
import { card } from '../../Interfaces/Card'
import ButtonControl from '../Buttons/ButtonControl/ButtonControl'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import Card from '../Card/Card'
import TimeOverviewChartDialog from '../Dialogs/TimeOverviewChartDialog/TimeOverviewChartDialog'
import TimeFilterDialog from '../Dialogs/TimeTrackingFilterDialog/TimeTrackingDialog'
import styles from './CurrentMonthCard.module.css'

function CurrentMonthCard({ width, height, order }: card) {

  const { currentMonth, currentWeek, getStatsOfPeriod } = useServices();
  const { duration, getWorktimeInPeriod } = useTimeTracking();

  const hoursInMonth = (duration?.inMonth ?? 0) / 60;
  const hoursInWeek = (duration?.inWeek ?? 0) / 60;

  const [mode, setMode] = useState<"month" | "week" | "custom">("week");

  const [timeFilter, setTimeFilter] = useState<{ start: Date, end: Date, open: boolean }>({ start: new Date(new Date(Date.now()).toDateString()), end: new Date(new Date(Date.now()).toDateString()), open: false })
  const [customFilteredDuration, setCustomFilteredDuration] = useState<{ serviceHrs: number, worktimeHrs: number }>({ serviceHrs: 0, worktimeHrs: 0 });

  const [hoursToDisplay, setHoursToDisplay] = useState<any>({ service: currentWeek, worktime: hoursInWeek })

  //Debug
  useEffect(() => {
    console.log("hours to display", hoursToDisplay)
  }, [hoursToDisplay])

  const handleSubmit = () => {
    getWorktimeInPeriod({ start: timeFilter.start, end: timeFilter.end })
      .then((res: any) => {
        try {
          setHoursToDisplay((old: any) => ({ ...old, service: (res.durationService / 60), worktime: (res.duration / 60) }))
        } catch (exception) {
          setHoursToDisplay((old: any) => ({ ...old, worktime: (res.duration / 60) }))
        }
      })
  }

  useEffect(() => {
    if (mode === "month") {
      setHoursToDisplay({ service: currentMonth, worktime: hoursInMonth });
    } else if (mode === "week") {
      setHoursToDisplay({ service: currentWeek, worktime: hoursInWeek })
    } else {
      getWorktimeInPeriod({ start: timeFilter.start, end: timeFilter.end })
        .then((res: any) => {
          try {
            const serviceTime = getStatsOfPeriod({ start: timeFilter.start, end: timeFilter.end })
            setHoursToDisplay((old: any) => ({ ...old, service: serviceTime, worktime: (res.duration / 60) }))
          } catch (exception) {

          }
        })


    }

    if (timeFilter.end.getTime && timeFilter.start.getTime && timeFilter.end.getTime() === timeFilter.start.getTime() && mode === "custom") {
      setTimeFilter((old: any) => ({ ...old, open: true }));
    }
  }, [mode, duration, currentMonth])

  const buttons = [
    {
      body: (active: boolean) => active ? <span className='centered' style={{ gap: "2px", fontSize: "small" }}><DateRange fontSize='small' />Woche</span> : <DateRange fontSize='small' />,
      value: "week"
    },
    {
      body: (active: boolean) => active ? <span className='centered' style={{ gap: "2px", fontSize: "small" }}><CalendarMonth fontSize='small' />Monat</span> : <CalendarMonth fontSize='small' />,
      value: "month"
    },
    {
      body: (active: boolean) => active ? <span onClick={() => { setTimeFilter((old: any) => ({ ...old, open: true })) }} className='centered' style={{ gap: "2px", fontSize: "small" }}><Tune fontSize='small' />Filter</span> : <Tune fontSize='small' />,
      value: "custom"
    }
  ]

  const [open, setOpen] = useState<boolean>(false);

  /**
  * Rounds the value for the milliseconds to hour correctly regarding the imprecision of a number calculation
  * @param {Number} num initial number with the imprecision
  * @return {Number} corrected number for hour and minute calculation
  */
  function correctRounding(num: number): number {
    //Checks if the number is the 59th minute: 0.9833 is the edge case
    if ((num % 1) > (59 / 60)) {
      //gets rid of the decimal places and adds 1 to the hour: 0.9833 -> 1.0
      num = Math.floor(num) + 1;
    }
    else {
      //if it is not the 59th minute, the number is ceiled with a decimal precision of 2: 1.0164... -> 1.02
      num = roundToTwoDecimalPlaces(num);
      //console.log(`CurrentMonthCard: ${num}`);
    }
    return num;
  }

  /**
  * Ceils the value of the given number with a precision of two decimal places: 1.45678 -> 1.46
  * @param {Number} num initial number with the imprecision
  * @return {Number} ceiled number with a precision of two decimal places
  */
  function roundToTwoDecimalPlaces(num: number) {
    let factor = Math.pow(10, 2); // 2 is the number of decimal places
    return Math.ceil(num * factor) / factor;
  }

  return (
    <Card
      style={{ height, width, order }}
      title="Zeit-Übersicht"
      disableBodyMargin
      disableScroll
      right={() => <ButtonControl setValue={(val) => setMode(val)} value={mode} buttons={buttons} />}
    >
      <div className='' style={{ height: "100%", width: "100%" }}>
        <div style={{ height: "80%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "space-around" }}>
          {/*
          {mode === "custom" &&
            <>
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>Start: {new Date(timeFilter.start ?? "").toLocaleDateString()}</span>
              <span style={{ fontSize: "20px", fontWeight: "bold" }}>End: {new Date(timeFilter.end ?? "").toLocaleDateString()}</span>
              <br />
            </>
          } 
          */}
          {/* Man kann auswählen in welcher Periode die Arbeitszeit angezeigt werden soll, entweder Monat, Woche oder Custom */}
          <div style={{ height: "100%" }}>
            <span className="label">
              Arbeitszeit:
            </span>
            <div style={{ width: "100%", height: "24%", backgroundColor: "#f5f5f5", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <span style={{ fontSize: "30px", fontWeight: "bolder" }}>

                {Math.floor(hoursToDisplay.worktime) + "h " + Math.floor((hoursToDisplay.worktime % 1) * 60) + "min"}
              </span>
            </div>
            <span className="label" style={{ marginTop: "2%" }}>
              Leistungszeit:
            </span>
            <div style={{ width: "100%", height: "24%", backgroundColor: "#f5f5f5", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <span style={{ fontSize: "30px", fontWeight: "bolder" }}>
                {
                  //Changed the calculation for this value. It should be correct for all edge cases.
                  Math.floor(correctRounding(hoursToDisplay.service)) + "h " + ((correctRounding(hoursToDisplay.service) % 1) * 60).toFixed(0) + "min"
                }
              </span>
            </div>


          </div>
        </div>
        <CTAButton title="Zeige Wochenübersicht" onClickHandler={() => { setOpen(true) }} secondary />
      </div>
      <TimeFilterDialog open={timeFilter.open} object={timeFilter} onClose={() => { setTimeFilter((old: any) => ({ ...old, open: false })) }} setObject={setTimeFilter} submitChange={handleSubmit}></TimeFilterDialog>

      <TimeOverviewChartDialog open={open} onClose={() => setOpen(false)} />
    </Card >
  )
}

export default CurrentMonthCard