import { Axios, AxiosResponse } from 'axios'
import React, { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { journal, journalContext, journalDTO } from '../../Interfaces/Journal'
import { ITaskDTO } from '../../Interfaces/Task'
import { useDocuments } from '../DocumentContext/DocumentContext'
import { useUser } from '../UserContext/UserContext'
import JournalReducer from './JournalReducer'
import { errorHandler } from '../../Connection/BaseConnection'
import SendPushNotification from '../PushNotifications/PushNotificiationComponent'
import { autoHideDurationDefault, autoHideDurationInfo } from '../../Global/Variables'

const JournalContext = createContext<journalContext>({
    entries: [],
    entriesWithFilter: [],
    journalFilter: { personIds: [], companyIds: [], textFilter: "" },
})

const JournalProvider = ({ children }: { children: ReactNode }) => {
    const connection = useAuthConnection();

    const { enqueueSnackbar, closeSnackbar } = useSnackBar();

    const [state, dispatch] = useReducer(JournalReducer, {
        entries: [],
        entriesWithFilter: [],
        journalFilter: { personIds: [], companyIds: [], textFilter: "" },
    })

    const [currPage, setCurrPage] = useState(0); // storing current page number
    const [prevPage, setPrevPage] = useState(-1); // storing prev page number
    const [userList, setUserList] = useState<any>([]); // storing list
    const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list
    const [wasLastFilteredList, setWasLastFilteredList] = useState(false); // setting a flag to know the last list
    const [loading, setLoading] = useState(false);
    const { addDocument } = useDocuments();

    function blobToBase64(blob: any) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    useEffect(() => {console.log("STATE.journalFilter", state)}, [state.journalFilter])

    const setJournalFilter = (updateFn: any) => {
        let journalFilter = updateFn(state.journalFilter);
        console.log("SetJournalFilter", journalFilter)


        dispatch({
            type: "SET_JOURNALFILTER",
            payload: journalFilter
        })
    }

    const receiveNewJournal = async (journal: journalDTO) => {
        let x = enqueueSnackbar(`${journal.authorName} hat einen neuen Journaleintrag gepostet`, { variant: "info", autoHideDuration: autoHideDurationInfo })

        /*let imageBase64 = null;
        for (let i = 0; (i < (journal.files?.length ?? 0)) && journal.files; i++) {
            if ((journal.files[i].type + "").toLowerCase().startsWith("image")) {
                imageBase64 = await blobToBase64(journal.files[i]);
                if ((imageBase64 + "").startsWith(""))
                    break;
            }
        }*/

        dispatch({
            type: "ADD_JOURNAL",
            payload: { ...journal }
        })

        SendPushNotification({ title: "Neuer Journaleintrag", message: `${journal.authorName} hat einen neuen Journaleintrag gepostet: ${journal.title}` })
    }

    const addJournal = async (journal: journalDTO) => {
        let x = enqueueSnackbar("Journaleintrag wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/journal", { ...journal, files: null })
            .then(async (res: AxiosResponse) => {
                /*journal.files?.forEach((file: any, index: number) => {
                    addDocument(file, "journalId", res.data.id, index);
                })*/

                let imageBase64 = null;
                for (let i = 0; (i < (journal.files?.length ?? 0)) && journal.files; i++) {
                    if ((journal.files[i].type + "").toLowerCase().startsWith("image")) {
                        imageBase64 = await blobToBase64(journal.files[i]);
                        if ((imageBase64 + "").startsWith(""))
                            break;
                    }
                }

                dispatch({
                    type: "ADD_JOURNAL",
                    payload: { ...res.data, imageBase64: imageBase64 }
                })

                closeSnackbar(x);
                enqueueSnackbar("Journaleintrag erfolgreich erstellt", { variant: "success" })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const updateJournal = (updatedJournal: journalDTO) => {
        let x = enqueueSnackbar("Journaleintrag wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.put('/journal', updatedJournal)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Journaleintrag erfolgreich gespeichert", { variant: "success" })

                dispatch({
                    type: "UPDATE_JOURNAL",
                    payload: updatedJournal
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const deleteJournal = (journal: journalDTO) => {
        let x = enqueueSnackbar("Journaleintrag wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.delete('/journal', { data: journal })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Journaleintrag erfolgreich entfernt", { variant: "success" })

                dispatch({
                    type: "DELETE_JOURNAL",
                    payload: journal
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    let intervall;

    const fetchJournal = async () => {
        let result;
        let fetchSize = 10;

        setCurrPage(1);
        setWasLastList(false);

        dispatch({
            type: "SET_JOURNAL",
            payload: []
        })

        try {
            result = await connection.get(`/journal/page=${0}&size=${10}?`)
        } catch (exception) {
            //result = fetchJournalPaginated({ currPage, fetchSize });
        } finally {
            setCurrPage((old: number) => (old + 1));
        }

        dispatch({
            type: "SET_JOURNAL",
            payload: result?.data ?? []
        })

        return result;
    }

    const fetchJournalPaginated: any = async () => {
        let result;
        let fetchSize = 10; //immer 10 Einträge, vgl. Pages...

        if (true) {//prevPage === currPage - 1) {
            try {
                setCurrPage((old: number) => (old + 1));
                result = await connection.get(`/journal/page=${currPage}&size=${fetchSize}?`);
            } catch (exception) {
                //result = fetchJournalPaginated({ currPage, fetchSize });
            } finally {
                if (result?.data.length < 10) {
                    setWasLastList(true)
                }
                setPrevPage((old: number) => (old + 1))
            }

            dispatch({
                type: "ADDRANGE_JOURNAL",
                payload: result?.data ?? []
            })
        }

        return result;
    }


    const clearJournalWithFilter = () => {
        dispatch({
            type: "SET_JOURNALWITHFILTER",
            payload: []
        })
    }
    const fetchJournalPaginatedWithFilter: any = async (page: number, size: number, keyword?: string, personIds?: Array<number>, companyIds?: Array<number>) => {
        if (page === 0) {
            dispatch({
                type: "SET_JOURNALWITHFILTER",
                payload: []
            })
        }

        let result;

        let params: URLSearchParams = new URLSearchParams();
        if (keyword && keyword.length > 0) {
            params.append('keyword', keyword)
        }

        personIds?.forEach((id: number) => {
            params.append('personIdItem', id.toString());
        })

        companyIds?.forEach((id: number) => {
            params.append('companyIdItem', id.toString());
        })

        if (page === 0 || !wasLastFilteredList) {
            try {
                result = await connection.get(`/journal/page=${page}&size=${size}`, {
                    params: params
                })
            } catch (exception) {
                result = fetchJournalPaginatedWithFilter({ page, size });
            }

            if (result?.data.length < 10) {
                setWasLastFilteredList(true)
            }

            if (page === 0) {
                dispatch({
                    type: "SET_JOURNALWITHFILTER",
                    payload: result?.data ?? []
                })
            } else {
                dispatch({
                    type: "ADDRANGE_JOURNALWITHFILTER",
                    payload: result?.data ?? []
                })
            }

            return result;
        }
        return []
    }

    return (
        <JournalContext.Provider
            value={{
                entries: state.entries,
                entriesWithFilter: state.entriesWithFilter,
                journalFilter: state.journalFilter,
                setJournalFilter,
                fetchJournal,
                receiveNewJournal,
                addJournal,
                updateJournal,
                deleteJournal,
                clearJournalWithFilter,
                wasLastList,
                fetchJournalPaginated,
                fetchJournalPaginatedWithFilter
            }}
        >
            {children}
        </JournalContext.Provider>
    )
}

export default JournalProvider


export const useJournal = () => useContext(JournalContext)