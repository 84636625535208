import { IService } from "../../Interfaces/Service"

export default function ServiceReducer(state: any, action: any) {
    switch (action.type) {
        case "SET_SERVICES":
            return {
                ...state,
                services: action.payload
            }
        case "ADD_SERVICE":
            return {
                ...state,
                services: [...[action.payload], ...state.services]
            }
        case "UPDATE_SERVICE":
            return {
                ...state,
                services: state.services.map((service: IService) => service.id == action.payload.id ? action.payload : service)
            }
        case "REMOVE_SERVICE":
            return {
                ...state,
                services: state.services.filter((service: IService) => service.id != action.payload.id)
            }
        case "SET_CURRENT_MONTH":
            return {
                ...state,
                currentMonth: action.payload.inMonth,
                currentWeek: action.payload.inWeek
            }
        case "SET_TASK_SERVICE_CATEGORIES":
            return {
                ...state,
                taskServiceCategories: action.payload
            }
        default:
            return {
                ...state
            }
    }
}