import { useEffect, useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import { IntervallFormModes } from '../../Components/Forms/EditCreateIntervall'
import IntervallCard from '../../Components/IntervallCards/IntervallCard'
import IntervallDataCard from '../../Components/IntervallCards/IntervallDataCard'
import { IIntervallDTO, defaultIntervall } from '../../Contexts/IntervallContext/IntervallProvider'

function Intervallleistungen() {

    const [clickedIntervall, setClickedIntervvall] = useState<IIntervallDTO>(defaultIntervall);
    const [formMode, setFormMode] = useState<IntervallFormModes>("createIntervall");

    //DEBUG
    useEffect(() => {
        console.log("clickedIntervall", clickedIntervall);
        console.log("intervallFormMode", formMode);
    }, [clickedIntervall, formMode])

    return (
        <>
            <CardColumn
                height='100%'
                width='50%'>
                <IntervallCard
                    height='100%'
                    width='100%'
                    setClickedIntervall={setClickedIntervvall}
                    setFormMode={setFormMode}
                />
            </CardColumn>
            <CardColumn
                height='100%'
                width='50%'>
                <IntervallDataCard
                    height='100%'
                    width='100%'
                    clickedIntervall={clickedIntervall}
                    setClickedIntervall={setClickedIntervvall}
                    formMode={formMode}
                    setFormMode={setFormMode}
                    mode={formMode === "editIntervall" ? "edit" : "create"}
                />
            </CardColumn>
        </>
    )
}

export default Intervallleistungen