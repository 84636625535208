import { Download } from '@mui/icons-material'
import { Button } from '@mui/material'
import { AxiosResponse } from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '../../Components/Card/Card'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CreateCompanyForm from '../../Components/Forms/CreateCompanyForm'
import CreatePersonForm from '../../Components/Forms/CreatePersonForm'
import PdfViewer from '../../Components/PDF/all-pages'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { IPerson, defaultCompany, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useProducts } from '../../Contexts/ProductContext/ProductProvider'
import { useUser } from '../../Contexts/UserContext/UserContext'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { IOrganization } from '../../Interfaces/Company'


const LaufzettelErstellen = () => {
  const { id } = useParams();

  const connection = useAuthConnection();
  const navigate = useNavigate();

  const { fetchProducts } = useProducts();

  const { bills, billPrefixes } = useBills();
  const { companies } = useContacts();
  const { user } = useUser();

  const [object, setObject] = useState<IOrganization>(defaultCompany);
  const [document, setDocument] = useState<any>(undefined);

  useEffect(() => {
    let tempCompany = companies.find(temp => temp.id === parseInt(id!));
    if (tempCompany) {
      setObject(tempCompany);
    }
  }, [id])

  useEffect(() => {
    if (object !== null && object?.id !== null && object?.id !== 0) {
      generatePdf();
    }
  }, [object])

  const generatePdf = () => {
    connection.post(`/organization/laufzettel`, { id: parseInt(id!) }).then((res: AxiosResponse) => {
      setDocument(res.data);
    });
  }
  return (
    <>
      <CardColumn
        width='50%'
        height='100%'
      >
        <Card title="Firma">
          {/* Infos zur Organisation */}
          <CreateCompanyForm
            object={object}
            setObject={setObject}
          />
        </Card>
      </CardColumn>
      <CardColumn
        width='50%'
        height='100%'
      >
        <Card fullHeightBody={true} style={{ backgroundColor: "#00000008", height: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <PdfViewer pdf={`data:${document ? document.fileEnding : ""};base64,${document?.base64}`} />
          </div>
          <div>
            <a download={`${document?.title ?? ""}`} href={`data:${document ? document.fileEnding : ""};base64,${document?.base64 ?? ""}`}>
              <Button variant='contained' style={{ position: "fixed", bottom: "6%", right: "2%" }} onClick={() => { }}>
                <Download />
              </Button>
            </a>
          </div>
        </Card>
      </CardColumn>
    </>
  )
}

export const LaufzettelErstellenPerson = () => {
  const { id } = useParams();

  const connection = useAuthConnection();
  const navigate = useNavigate();

  const { fetchProducts } = useProducts();

  const { bills, billPrefixes } = useBills();
  const { defaultPerson, persons } = useContacts();
  const { user } = useUser();

  const [object, setObject] = useState<IPerson | undefined>();
  const [document, setDocument] = useState<any>(undefined);

  useEffect(() => {
    let tempPerson = persons.find(temp => temp.id === parseInt(id!));
    console.log("tempPerson", tempPerson, "id", id)
    if (tempPerson) {
      setObject(tempPerson);
    }
  }, [id])

  useEffect(() => {
    generatePdf();
  }, [])

  const generatePdf = () => {
    connection.post(`/person/laufzettel`, { id: parseInt(id!) }).then((res: AxiosResponse) => {
      setDocument(res.data);
    });
  }
  return (
    <>
      <CardColumn
        width='50%'
        height='100%'
      >
        <Card title="Person">
          {/* Infos zur Organisation */}
          {object &&
            <CreatePersonForm
              object={object}
              //@ts-ignore
              setObject={setObject}
            />
          }
        </Card>
      </CardColumn>
      <CardColumn
        width='50%'
        height='100%'
      >
        <Card fullHeightBody={true} style={{ backgroundColor: "#00000008", height: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <PdfViewer pdf={`data:${document ? document.fileEnding : ""};base64,${document?.base64}`} />
          </div>
          <div>
            <a download={`${document?.title ?? ""}`} href={`data:${document ? document.fileEnding : ""};base64,${document?.base64 ?? ""}`}>
              <Button variant='contained' style={{ position: "fixed", bottom: "6%", right: "2%" }} onClick={() => { }}>
                <Download />
              </Button>
            </a>
          </div>
        </Card>
      </CardColumn>
    </>
  )
}

export default LaufzettelErstellen