export const EmployeeReducer = (state: any, action: any) => {
    switch(action.type) {
        case "SET_EMPLOYEES":
            return {
                ...state,
                employees: action.payload
            }
        default:
            return {
                ...state
            }
    }
}