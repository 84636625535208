import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";
import { TextModuleDocumentType, useGetDefaultTextModuleByIdQuery } from "../../../redux/apiSlices/TextModule";
import EditBillTextModuleCard from "./Cards/EditBillTextModule";
import EditOfferTextModuleCard from "./Cards/EditOfferTextModule";
import EditIntervallTextModuleCard from "./Cards/EditIntervallTextModule";
import EditReminderTextModuleCard from "./Cards/EditReminderTextModule";


export default function EditDefaultTextModule() {

    const { id } = useParams();
    const { data: textModule, isLoading } = useGetDefaultTextModuleByIdQuery(id ? parseInt(id) : 0, { refetchOnMountOrArgChange: 2 });

    const renderCard = (type: TextModuleDocumentType) => {
        console.log("received type: ", type, TextModuleDocumentType.BillSEPA);
        if (textModule) {
            switch (type) {
                case TextModuleDocumentType.Bill:
                    return <EditBillTextModuleCard textModule={textModule} />;
                case TextModuleDocumentType.BillSEPA:
                    return <EditBillTextModuleCard textModule={textModule} />;
                case TextModuleDocumentType.Offer:
                    return <EditOfferTextModuleCard textModule={textModule} />;
                case TextModuleDocumentType.Intervall:
                    return <EditIntervallTextModuleCard textModule={textModule} />
                case TextModuleDocumentType.IntervallSEPA:
                    return <EditIntervallTextModuleCard textModule={textModule} />
                case TextModuleDocumentType.Reminder:
                    return <EditReminderTextModuleCard />
                case TextModuleDocumentType.ReminderFinal:
                    return <EditReminderTextModuleCard />
                default:
                    return <div>{type}</div>
            }
        }
    }

    return (
        <div className="flex w-full gap-2">
            {isLoading ? <div className="flex w-full h-full justify-center items-center"><CircularProgress /></div> :

                (textModule &&
                    <div className="flex-1">
                        {renderCard(textModule.type)}
                    </div>
                )
            }
        </div>
    )
}