import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import { ClickAwayListener, Fade } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import useKeypress from '../../Hooks/useKeyPress'
import SearchableItem from './SearchableItem'
import itemStyles from './SearchableItem.module.css'
import styles from './SearchableSelect.module.css'


interface searchableSelect<T> {
  options: any[]
  placeholder?: string
  value: T | any
  setValue: (value: any) => void
  //TitleKey legt den Key des Objekts fest, der als Titel für die Auswahl dient -> Value des Keys darf kein Objekt sein 
  titleKey: string

  readOnly?: boolean

  //ValueKey muss die id des Obje
  valueKey: string
  width?: string
  multiple?: boolean
  error?: boolean
  backgroundWhite?: boolean
  disabled?: boolean
}


//! Funktioniert nur mit der ID eines Objekts
function SearchableSelect<T extends unknown>({ disabled, options, placeholder, value, setValue, titleKey, valueKey, width, multiple, error, readOnly = false, backgroundWhite = false }: searchableSelect<T>) {

  //*Bringt die übergebenen Auswahlmöglichkeiten in das Format, das für das Auswhlfeld notwendig ist.
  const transformOptions: any = (options: T[], valueKey: string, titleKey: string) => {
    return options?.map((option) => ({
      // @ts-ignore: Unreachable code error
      value: option[valueKey],
      // @ts-ignore: Unreachable code error
      title: option[titleKey],
      // @ts-ignore: Unreachable code error
      id: option.id
    }))
  }


  const [copiedOptions, setCopiedOptions] = useState(transformOptions(options, valueKey, titleKey))

  //*Falls sich die options ändern, so sollen diese im Feld aktualisiert werden
  useEffect(() => {
    setCopiedOptions(transformOptions(options, valueKey, titleKey))
  }, [options])

  const [searchValue, setSearchValue] = useState("")

  let number: number = 0;

  const [expanded, setExpanded] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  //*Funktion für das suchen der Elemente
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    // @ts-ignore: Unreachable code error
    setCopiedOptions(transformOptions(options, valueKey, titleKey).filter((option: T) => option.title.toLowerCase().match(e.target.value.toLowerCase())))
  }

  //*Funktion zum Zurücksetzen des InputFelds
  const handleDeleteSearchValue = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e?.preventDefault()
    setSearchValue("")
    setCopiedOptions(transformOptions(options, valueKey, titleKey))
    inputRef?.current?.focus()

    //@ts-ignore
    setValue(multiple ? [] : undefined)

    //setId(undefined)
  }


  //* Beim Klicken ausßerhalb des Felds
  const handleClickaway = () => {
    setExpanded(false)
    setArrowKeyHover(-1);
    //inputRef.current?.focus()
  }

  //* Beim Auswählen einer Auswahl
  const handleSelect = (id: string | number) => {
    //? Um die aktuellste Version der Ids zu bekommen
    let innerIds: number[] = []

    if (multiple) {
      if (!value) {
        setValue([id]);
      } else if (value.includes(id as number)) {
        innerIds = value.filter((val: any) => val !== id as number)

        setValue(value.filter((val: any) => val !== id as number))
      } else {
        innerIds = [...value, id as number]

        setValue([...value, id as number])
      }
    } else {
      setValue(id);
    }

    //setValue(value)
    //setId(id as number);
    //@ts-ignore
    //setValue(multiple ? innerIds : id)
    if (!multiple) {
      //@ts-ignore
      setSearchValue(options.find((option) => option[valueKey] == id)[titleKey] ?? "")
    } else {
      setSearchValue("");
      setCopiedOptions(transformOptions(options, valueKey, titleKey))
      //setSearchValue(innerIds.map((id) => options.find((option) => option[valueKey] == id)[titleKey]).join(", "))
      //setSearchValue(options.filter((option) => innerIds.includes(option[valueKey])).map((val) => val[titleKey]).join(", "))
    }

    /*if (multiple) {
      inputRef.current?.focus()
    }
    else {
      inputRef.current?.blur();
    }*/
    inputRef.current?.focus();
  }

  //* Beim Fokus des InputFelds
  const handleFocus = () => {
    setCopiedOptions(transformOptions(options, valueKey, titleKey))
    //Falls keine aktive Auswahl besteht, so soll der Wert im Input-Feld zurückgesetz werden
    if (!value) {
      setSearchValue("")
    }
    setExpanded(disabled ? false : true)
  }

  //*Beim Klick auf "Escape"
  useKeypress("Escape", () => {
    handleClickaway()
  })

  const [arrowKeyHover, setArrowKeyHover] = useState(-1);

  //simulates a tab keypress, is required in the searchable select as 2 tab keypresses are required to move to the next input field in the form
  function simulateTabKeypress() {
    const targetElement = inputRef.current;
    const event = new KeyboardEvent('keypress', {
      key: "Tab"
    })
    targetElement?.dispatchEvent(event);
  }

  //handle ArrowUp and ArrowDown key press, requires separate useEffect because of dependency on useState variable 'expanded'
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowUp" && expanded) {
        if (arrowKeyHover > 0) {
          setArrowKeyHover((prev) => {
            return prev - 1;
          })
        }

      }
      else if (e.key === "ArrowDown" && expanded) {
        if (arrowKeyHover < copiedOptions.length - 1) {
          setArrowKeyHover((prev) => {
            return prev + 1;
          })
        }

      }
      else if (e.key === "Enter" && expanded) {
        e.preventDefault();
        handleSelect(copiedOptions[arrowKeyHover].id);
        if (!multiple) {
          handleClickaway();
        }
      }
      else if (e.key === "Tab" && expanded) {
        handleClickaway();
        simulateTabKeypress();
      }
    }
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };

  }, [expanded, arrowKeyHover])

  useEffect(() => {

    setArrowKeyHover(-1);
    if (copiedOptions?.length === 1) {
      setArrowKeyHover(0);
    }

  }, [searchValue])

  /*useEffect(() => {
    if (!multiple) {
      setIds(value && value.length > 0 && typeof number === typeof value[0] ? value : [])
    }
  }, [value])*/

  const optionsRef = useRef(null);

  useEffect(() => {
    //Check if optionsRef is initialized
    if (optionsRef.current) {
      //Store list of elements
      const list: HTMLElement = optionsRef.current;

      //Wait for one render to make sure all elements are loaded

      //set the active element using class selector
      const activeElement: HTMLElement | null = list.querySelector(`.${itemStyles.containerArrowHover}`);
      //check if an element is active
      if (activeElement) {

        //Calculate top and bottom position of the active element
        const top = activeElement.offsetTop;
        const bottom = top + activeElement.offsetHeight;

        //Calculate the height of the next item
        const nextItem = activeElement.nextElementSibling as HTMLElement;
        const nextItemHeight = nextItem ? nextItem.offsetHeight : 0;

        //Calculate the current scroll position of the list
        const scrollTop = list.scrollTop;
        const scrollBottom = scrollTop + list.offsetHeight;

        //If the active element is above the visible area of the list, scroll up
        if (top < scrollTop) {
          list.scrollTo({ top: top })
        }
        //If the active element is below the visible area of the list, scroll down
        else if (bottom + nextItemHeight > scrollBottom) {
          list.scrollTo({ top: bottom - list.offsetHeight + nextItemHeight });
        }
        //If the active element is the last element, scroll to end of list
        else if (activeElement.nextElementSibling === null) {
          list.scrollTo({ top: list.offsetHeight });
        }
      }


    }
  }, [arrowKeyHover]);

  return (
    <ClickAwayListener
      onClickAway={() => handleClickaway()}
    >
      <div style={{ zIndex: expanded ? 1000 : 0, position: expanded ? "relative" : undefined, width: width ?? "100%", filter: expanded ? "drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.15))" : undefined, transition: "all 0.4s" }}>
        <div className={error ? getClassNames([styles.inputError, "centered"]) : getClassNames([styles.input, "centered"])} style={{ borderRadius: expanded ? "10px 10px 0 0" : "10px 10px 10px 10px", background: backgroundWhite ? "white" : "var(--light)" }}>
          {/* @ts-ignore: Unreachable code error */}
          <input
            ref={inputRef}
            value={expanded ? searchValue : multiple ? value?.map((id: any) => options?.find((option) => option[valueKey] == id)[titleKey]).join(", ") : (options?.find((option) => option.id == value)?.[titleKey] ?? "")}
            placeholder={placeholder}
            type="text"
            onFocus={() => handleFocus()}
            onChange={handleSearch}
            readOnly={readOnly || disabled}
            onKeyDown={(e) => { if (e.key === "Enter") e.preventDefault(); }}
          />
          {!disabled && (searchValue !== "" || value !== undefined) && (<button type='button' tabIndex={-1} className={styles.deleteButton} onClick={(e) => handleDeleteSearchValue(e)}>
            <CancelRoundedIcon tabIndex={-1} fontSize='inherit' />
          </button>)}
        </div>
        <Fade tabIndex={-1} in={expanded}>


          <div ref={optionsRef} tabIndex={-1} style={{ position: "fixed", padding: "10px", maxHeight: "200px", width: "100%", overflow: "scroll" }} className={getClassNames(["flex", "column", styles.dropdown])}>
            {/* @ts-ignore: Unreachable code error */}
            {expanded && copiedOptions?.length > 0 ? copiedOptions?.map((val, index) => <SearchableItem key={index} arrowKeyHovered={arrowKeyHover === index ? true : false} active={multiple ? (value ?? []).includes(val.id) : val.id === value} onClick={() => { handleSelect(val.id); if (!multiple) { handleClickaway(); } }} title={val.title} />) : <div style={{ padding: "10px", fontSize: "14px" }}>Keine Ergebnisse gefunden.</div>}
          </div>

        </Fade>

      </div>
    </ClickAwayListener>
  )
}

export default SearchableSelect