import React, { ReactNode, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useUser } from '../../Contexts/UserContext/UserContext'
import useRefreshToken from '../../Hooks/useRefreshToken'

function PersistLogin({children} : {children: ReactNode}) {
    const [loading, setLoading] = useState(true)
    const refresh = useRefreshToken()

    const {token} = useUser()


    useEffect(() => {
        const verify = async () => {
            try {
                await refresh()
            } catch (error) {
                console.log("ERROR: ", error)
            } finally {
                setLoading(false)
            }
        }

        verify()
    }, [])

  return (
    <>
    {
        loading ? children : children
    }
    </>
  )
}

export default PersistLogin