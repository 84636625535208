import React, { useEffect, useState } from "react";
import { useModals } from "../../../Contexts/DialogContext/DialogProvider";
import { IPause, IWorkTime, useTimeTracking } from "../../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import MoreOptionsButton from "../../Buttons/MoreOptionsButton/MoreOptionsButton";
import styles from './BillsCard.module.css'
import { IServiceDTO } from "../../../Interfaces/Service";
import { useUser } from "../../../Contexts/UserContext/UserContext";
import { useServices } from "../../../Contexts/ServiceContext/ServiceContext";
import dayjs from "dayjs";
import { getWorktimeDurationAsString, getClockedInDurationAsString, getTimeDifferenceInMinutes } from "../../../utils/WorktimeUtils";

interface props {
    workTime?: IWorkTime,
    pauses?: IPause[],
    n?: number,
    id: any
}

interface Document {
    id: number,
    title: string,
    description: string,
    fileEnding: string,
    fileId: string,
    base64: string,
    organizationId: number,
}

const WorkTimeRow = ({ workTime, pauses, n, id }: props) => {
    const [expanded, setExpanded] = useState(false);

    const dialogContext = useModals();
    dayjs.locale('de-DE');

    const worktimeContext = useTimeTracking();
    const { user } = useUser();
    const { defaultService } = useServices();

    const onPressEdit = () => {
        dialogContext.changeVisibility!("worktimeOverview", false);
        dialogContext.changeVisibility!("postscriptWorkPause", true, { props: { object: workTime, mode: "editwork" } });
    }

    const onPressDelete = () => {

        if (workTime) {
            dialogContext.changeVisibility!("yesNoDialog", true, { props: { object: { modalTitle: "Arbeitszeit löschen", submitFunction: () => worktimeContext.deleteWorktime!(workTime), cancelButtonText: "Abbrechen", confirmButtonText: "Löschen", modalText: "Möchten Sie diese Arbeitszeit wirklich löschen?" } } })
        }
    }

    const showPauses = () => {
        dialogContext.changeVisibility!("worktimeOverview", false);
        dialogContext.changeVisibility!("pausesOverview", true, { props: { object: workTime } });
    }

    const createService = () => {

        let service: IServiceDTO = defaultService!;
        service.employeeDatas[0] = {
            userId: user?.userId!,
            from: workTime?.start ? dayjs(workTime.start) : dayjs().subtract(1, 'hour'),
            to: workTime?.end ? dayjs(workTime.end) : dayjs(),
            hourlyRate: 80,
        }

        console.log("service passed", service);

        dialogContext.changeVisibility!("editCreateService", true, { props: { object: service, mode: "create" } })
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: 'Pausen anzeigen',
            function: showPauses
        },
        {
            text: 'Leistung zu Arbeitszeit erstellen',
            function: createService
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    let timeDifferenceMin = ((new Date(workTime?.end ? workTime.end + "" : new Date(new Date((new Date()).toString() + ""))).getTime() - new Date(dayjs(workTime?.start).toDate()).getTime()) / 1000 / 60);
    let timeDifferenceHours = ((timeDifferenceMin - (timeDifferenceMin % 60)) / 60);


    //getTimeDiffInHours(dayjs(workTime?.start), dayjs(workTime?.end));

    let tempTimeDifferencePauseMin = 0;
    let timeDifferencePauseHours = 0;

    const [timeDifferencePauseMin, setTimeDifferencePauseMin] = useState<number>(0);
    const [pauseActive, setPauseActive] = useState(false);

    useEffect(() => {
        tempTimeDifferencePauseMin = 0;
        if (pauses) {

            pauses.forEach((element: IPause) => {
                if (element.end) {
                    tempTimeDifferencePauseMin += getTimeDifferenceInMinutes(element.start, element.end);
                }
                else {
                    setPauseActive(true);
                }
            });
            timeDifferencePauseHours = (tempTimeDifferencePauseMin - (tempTimeDifferencePauseMin % 60)) / 60;
            setTimeDifferencePauseMin(tempTimeDifferencePauseMin);
        }
    }, [pauses])

    return (
        <div style={{ backgroundColor: n ? (n % 2 == 0 ? "white" : "#e9e9e9") : "white", width: "100%" }} key={id}>
            <div className={'flex column '.concat(styles.container)} style={{}}>
                <header className='flex centered-aligned'>
                    <div style={{ minWidth: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                        <div style={{ minWidth: "40%", display: "flex", justifyContent: "center" }}>
                            <span className='bold-big' style={{ whiteSpace: "nowrap" }}>{dayjs(workTime?.start).format('ddd DD.MM.YYYY HH:mm')}</span>
                        </div>
                        <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}></div>
                        <div style={{ minWidth: "40%", display: "flex", justifyContent: "center" }}>
                            <span className='bold-big' style={{}}>{workTime?.end ? new Date(workTime?.end.toString() + "").toLocaleDateString("de-DE", { weekday: "short", day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" }) : "Aktiv"}</span>
                        </div>
                    </div>
                    <div style={{ width: "10%" }}>
                        <MoreOptionsButton options={options}></MoreOptionsButton>
                    </div>
                </header>
                <section className='label' style={{ gap: "10px", marginTop: "5px", width: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            Eingestempelt
                        </span>
                    </div>
                    <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            Pause
                        </span>
                    </div>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            Arbeitszeit
                        </span>
                    </div>
                </section>
                <section className='label' style={{ gap: "10px", marginTop: "5px", width: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            {getClockedInDurationAsString(dayjs(workTime?.start), workTime?.end)}
                        </span>
                    </div>
                    <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            {!pauseActive ? (timeDifferencePauseMin < 60.01 ? timeDifferencePauseMin.toFixed(0) + " min" : (((timeDifferencePauseMin - (timeDifferencePauseMin % 60)) / 60).toFixed(0)) + " h " + (timeDifferencePauseMin % 60).toFixed(0) + " min") : "Aktiv"}
                        </span>
                    </div>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            {getWorktimeDurationAsString(dayjs(workTime?.start), workTime?.end, pauses)}
                        </span>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default WorkTimeRow;