import Card from "../../../Components/Card/Card"
import RemoveIcon from '@mui/icons-material/Remove';
import { useContacts } from "../../../Contexts/ContactsContext/ContactsProvider";
import OrganizationToTagRow from "./OrganizationToTagRow";
import { IOrganization } from "../../../Interfaces/Company";

interface IOrgsToTagCard {
    orgsToTag: IOrganization[]
    handleOrganizationSelect: (orgId: number) => void
    removeSelectedOrgsFromTag: () => void
    rowIsChecked: (id: number) => boolean
}

function OrgsToTagCard({ orgsToTag, handleOrganizationSelect, removeSelectedOrgsFromTag, rowIsChecked }: IOrgsToTagCard) {

    return (
        <Card
            title={"Organisationen zu Tag"}
            cta
            ctaTitle={"Entfernen"}
            buttonIcon={<RemoveIcon fontSize='small' />}
            ctaFunction={() => removeSelectedOrgsFromTag()}
        >
            {orgsToTag.length === 0 ? <>Keine Einträge zu ausgewähltem Tag</> : orgsToTag.map((organization, index) => {
                return (<OrganizationToTagRow organization={organization} index={index} handleSelect={handleOrganizationSelect} rowIsChecked={rowIsChecked}/>)
            })}
        </Card>
    )
}

export default OrgsToTagCard