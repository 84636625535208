import Page from '../../Navigation/Page'
import React, { useState } from "react";
import CardColumn from "../../Components/Card/CardColumn/CardColumn";
import CardRow from "../../Components/Card/CardRow/CardRow";

import UnderConstructionCard from "../../Components/UnderConstructionCard/UnderConstructionCard";

const SocialMedia = () => {

    return (
        <UnderConstructionCard
            height='100%'
            width='100%'
        />
    )
};

export default SocialMedia;
