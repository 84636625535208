import { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useTasks } from '../../../Contexts/TaskContext/TaskContext'
import { ITask, ITaskDTO } from '../../../Interfaces/Task'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateTaskForm from '../../Forms/CreateTaskForm'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import { useMobileView } from '../../../Hooks/useMobileView'

const CreateTaskDialog = ({ open, onClose, mode, object }: dialogWithProps<ITask | ITaskDTO>) => {

  const { defaultTask, addTask, updateTask } = useTasks()

  const { changeVisibility } = useModals()

  const [taskToSubmit, setTaskToSubmit] = useState<ITaskDTO>(object ?? defaultTask!)

  const handleSubmit = () => {
    if (validateInput(taskToSubmit)) {
      if (mode == "edit") {
        updateTask!(taskToSubmit)
      } else {
        addTask!(taskToSubmit)
      }
      onClose ? onClose() : changeVisibility!("createTask", false)
      setTaskToSubmit(defaultTask!)
    }
  }

  useEffect(() => { console.log(taskToSubmit) }, [taskToSubmit])

  useEffect(() => {
    setTaskToSubmit(object ?? defaultTask!)
  }, [object])

  //form error handling variables
  const [employeeError, setEmployeeError] = useState(false);
  const [customerError, setCustomerError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const mobile = useMobileView()

  function validateInput(task: ITaskDTO) {

    //check if an employee was asigned to task
    if (task?.userIds?.length === 0) {
      setEmployeeError(true);
    } else {
      setEmployeeError(false);
    }

    //check if task is assigned to a customer
    if (task.customerOrganizationId === null || task.customerOrganizationId === undefined || !task.customerOrganizationId) {
      setCustomerError(true);
    } else {
      setCustomerError(false);
    }

    if (task?.title?.length === 0) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }

    if (task?.userIds?.length === 0 || !task.customerOrganizationId || task.customerOrganizationId === null || task.customerOrganizationId === undefined || task?.title?.length === 0) {
      return false;
    }
    return true;
  }

  function resetErrorVariables() {
    setEmployeeError(false);
    setCustomerError(false);
    setTitleError(false);
  }

  return (
    <Modal
      title={mode == "edit" ? "Aufgabe bearbeiten" : 'Aufgabe erstellen'}
      open={open}
      onClose={onClose ? () => { resetErrorVariables(); onClose(); setTaskToSubmit(defaultTask!); } : () => {changeVisibility!("createTask", false); resetErrorVariables(); setTaskToSubmit(defaultTask!)}}
    >
      <CreateTaskForm
        object={taskToSubmit}
        setObject={setTaskToSubmit}
        errorVariables={{ employeeError, customerError, titleError }}
      />
      <CTAButton
        title='Speichern'
        onClickHandler={() => handleSubmit()}
      />
    </Modal>
  )
}

export default CreateTaskDialog