import { MoreVert } from "@mui/icons-material";
import { Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Tooltip } from "@mui/material";
import React from "react"

interface props {
    options?: any[],
    style?: any
}

const MoreOptionsButton = ({ options, style }: props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div style={style}>
                <Button
                    ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    style={{ color: "black" }}
                >
                    <MoreVert fontSize='small' />
                </Button>

                <Popper
                    style={{ zIndex: 100 }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }: any) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={open}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        {
                                            options?.map((option: any, index: number) => {
                                                if (option?.tooltipText && option.tooltipText.length > 0) {
                                                    return (
                                                        <Tooltip enterDelay={500} title={option.tooltipText} placement="left">
                                                            <MenuItem key={`more-options-menu-item-${index}`} onClick={() => { option.function(); setOpen(false); }}>
                                                                {option.text}
                                                            </MenuItem>
                                                        </Tooltip>
                                                    )

                                                }
                                                else {
                                                    return (
                                                        <MenuItem key={`more-options-menu-item-${index}`} onClick={() => { option.function(); setOpen(false); }}>
                                                            {option.text}
                                                        </MenuItem>
                                                    )
                                                }

                                            })
                                        }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
        </div>
    )
}

export default MoreOptionsButton;