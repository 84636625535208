import { NavLink, Outlet } from 'react-router-dom'
import SegmentedControl from '../../Components/SegmentedControl/SegmentedControl'
import { useMobileView } from '../../Hooks/useMobileView'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import SearchableSelect from '../../Components/InputField/SearchableSelect'
import dayjs from 'dayjs'
import { ReactNode } from 'react'

//TODO Sollte drauf angepasst werden, die Funktion in der Leiste anzuzeigen, die für die aktuelle Buchhaltungsseite relevant sind -> kann über den Router gemanaged werden -> Fabian oder wer Interesse hat
function AccountingWrapper({ children }: { children: ReactNode }) {

  const showMobile = useMobileView()

  const { companyData, setFiscalYear } = useCompanyData();

  const years = getYearsArrayFromDate(new Date("2022-04-01"))

  return (
    <div className='flex flex-col items-center w-[100%] h-[100%] gap-2 mt-[-8px]'>
      <div className='h-[5%] max-h-[40px] flex flex-row justify-between items-center box-border p-1 w-[20%] min-w-fit gap-2 max-md:w-[60%]'>
        <SearchableSelect
          options={years}
          setValue={(value) => setFiscalYear ? setFiscalYear(new Date(`${value}-01-01`)) : {}}
          titleKey='year'
          value={new Date(companyData.fiscalYear[0] ?? "").getFullYear()}
          valueKey='id'
          readOnly={true}
          backgroundWhite
        />
      </div>
      <div className='flex flex-row w-[100%] h-[95%] gap-4 max-md:flex-col'>
        {children}
      </div>
    </div>
  )
}

function getYearsArrayFromDate(startDate: Date) {
  const startYear = startDate.getFullYear();
  const currentYear = new Date().getFullYear();
  const years = [];

  let count = 0;
  for (let year = startYear; year <= currentYear; year++) {
    years.push({ id: year, year: year });
  }

  return years;
}


export default AccountingWrapper