import { Outlet } from "react-router-dom";
import Header from "./Header";
import Navigation from "./Navigation/Navigation";
import PageNew from "./Components/Page/PageNew";

export default function RootLayout() {
    return (
        <html lang="de">
            <body>
                <div className="flex h-screen w-screen max-md:flex-col">
                    <Navigation />
                    <div className="flex flex-col h-screen w-[86%] max-md:w-[100%]">
                        <Header />
                        <PageNew>
                            <Outlet /> {/* Renders Route Conent */}
                        </PageNew>
                    </div>
                </div>
            </body>
        </html>
    )
}