import { Card, CardContent, CardHeader, CardTitle } from "../../../UI/CardNew";

export default function EditReminderTextModuleCard() {
    return (
        <Card>
            <CardHeader>
                <CardTitle>Standardtext Mahnung bearbeiten</CardTitle>
            </CardHeader>
            <CardContent>
                Mahnungen werden demnächst implementiert!
            </CardContent>
        </Card>
    )
}