import { IHoliday, IPause, IWorkTime } from "./TimeTrackingProvider"

export const TimeTrackingReducer = (state: any, action: any) => {
    switch (action.type) {
        //TODO Anpassen auf tatsächlichen payload
        case "EDIT_WORKTIME":
            return {
                ...state,
                worktimes: state.worktimes.map((element: IWorkTime) => {
                    if (element.id === action.payload.id) {
                        return action.payload;
                    }
                    return element;
                }),
                worktimesOrganization: state.worktimesOrganization.map((element: IWorkTime) => {
                    if (element.id === action.payload.id) {
                        return action.payload;
                    }
                    return element;
                })
            }
        case "ADD_WORKTIME":
            return {
                ...state,
                worktimes: [action.payload, ...state.worktimes ?? []]
            }
        case "SET_WORKTIMES":
            return {
                ...state,
                worktimes: action.payload
            }
        case "SET_WORKTIMES_ORG":
            return {
                ...state,
                worktimesOrganization: action.payload
            }
        case "SET_STATUS":
            return {
                ...state,
                status: action.payload,
            }
        case "SET_BEGIN":
            return {
                ...state,
                begin: action.payload
            }
        case "SET_CURRENTWORKTIME":
            return {
                ...state,
                currentWorktime: action.payload
            }
        case "SET_PAUSES":
            return {
                ...state,
                pauses: action.payload
            }
        case "SET_PAUSES_ORG":
            return {
                ...state,
                pausesOrganization: action.payload
            }
        case "SET_HOLIDAYS":
            return {
                ...state,
                holidays: action.payload
            }
        case "SET_HOLIDAYS_ORG":
            return {
                ...state,
                organizationHolidays: action.payload
            }
        case "ADD_PAUSE":
            return {
                ...state,
                pauses: [action.payload, ...state.pauses ?? []]
            }
        case "EDIT_PAUSE":
            return {
                ...state,
                pauses: state.pauses.map((element: IPause) => {
                    if (element.id === action.payload.id) {
                        return action.payload;
                    }
                    return element;
                }),
                pausesOrganization: state.pausesOrganization.map((element: IPause) => {
                    if (element.id === action.payload.id) {
                        return action.payload;
                    }
                    return element;
                })
            }
        case "SET_DURATION":
            return {
                ...state,
                duration: action.payload
            }
        case "SET_DURATIONFORALL":
            return {
                ...state,
                durationForAll: action.payload
            }
        case "DELETE_WORKTIME":
            return {
                ...state,
                worktimes: state.worktimes.filter((element: IWorkTime) => (element.id !== action.payload.id))
            }
        case "DELETE_PAUSE":
            return {
                ...state,
                pauses: state.pauses.filter((element: IPause) => (element.id !== action.payload.id)),
                pausesOrganization: state.pausesOrganization.filter((element: IPause) => (element.id !== action.payload.id))
            }
        case "ADD_HOLIDAY_REQUEST":
            return {
                ...state,
                holidays: [...state.holidays, action.payload],
                organizationHolidays: [...state.organizationHolidays, action.payload]
            }
        case "PROCESS_HOLIDAY":
            return {
                ...state,
                holidays: state.holidays.map((holiday: IHoliday) => {
                    if (holiday.id === action.payload.id) {
                        return action.payload
                    }
                    return holiday
                }),
                organizationHolidays: state.organizationHolidays.map((holiday: IHoliday) => {
                    if (holiday.id === action.payload.id) {
                        return action.payload
                    }
                    return holiday
                })
            }
        default:
            return {
                ...state
            }
    }
}