import { Dispatch, SetStateAction, useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CardRow from '../../Components/Card/CardRow/CardRow'
import CompanyRegister from '../../Components/ContactCards/CompanyRegister'
import PersonCard from '../../Components/ContactCards/PersonCard'
import JournalCard from '../../Components/JournalCard/JournalCard'
import { PhoneCardModes } from '../../Components/PhoneCard/PhoneCard'
import SmsCard from '../../Components/SmsCard/SmsCard'
import { IPerson, IPersonDTO, defaultCompany, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useFetch } from '../../Hooks/useFetch'
import { IOrganization } from '../../Interfaces/Company'
import Page from '../../Navigation/Page'
import { IJournalFilter } from './EmailPage'


interface personComp {
    person: IPerson
    index: number
    setClickedPerson?: Dispatch<SetStateAction<IPerson>>
    setCalledPerson?: Dispatch<SetStateAction<IPersonDTO>>
    setPhoneJournalTitle?: Dispatch<SetStateAction<string>>
    setPhoneJournalEntry?: Dispatch<SetStateAction<string>>
    setPhoneJournalTime?: Dispatch<SetStateAction<Date>>
    setPhoneJournalDuration?: Dispatch<SetStateAction<string>>
}


const SmsPage = () => {


    useFetch(["firmen", "personen"])
    const { defaultPerson } = useContacts();
    const start = new Date();
    const end = new Date();
    const duration = 0;
    //const message = "";
    //hier gehört ein Filter her, der die Personen bei onClick auf die Personen d. Firma reduziert.
    //usestate-Variable - Hinweis von Nico. Usestate rendert alles neu, wo die Variable benutzt wird. (AHK: Danke Nico, werde ich angehen!)


    const [journalFilter, setJournalFilter] = useState<IJournalFilter>({ personIds: [], companyIds: [], textFilter: "" })
    const [calledPerson, setCalledPerson] = useState<any>(defaultPerson!);
    const [phoneJournalTitle, setPhoneJournalTitle] = useState<string>("Telefonjournal");
    const [phoneJournalEntry, setPhoneJournalEntry] = useState<string>("");
    const [phoneJournalTime, setPhoneJournalTime] = useState<Date>(start);
    const [phoneJournalDuration, setPhoneJournalDuration] = useState<number>(duration);

    const [clickedPerson, setClickedPerson] = useState<IPerson>(defaultPerson!);
    const [clickedCompany, setClickedCompany] = useState<IOrganization>(defaultCompany);
    const [phoneCardMode, setPhoneCardMode] = useState<PhoneCardModes>("undefined");

    const [message, setMessage] = useState<string>("Message");



    return (
        <>
            <CardColumn
                height='100%'
                width='66%'
            >

                <CardRow
                    height='100%'
                    width='100%'
                >
                    <CardColumn
                        height='100%'
                        width='100%'
                    >
                        <CompanyRegister
                            height='100%'
                            setClickedCompany={setClickedCompany}
                            journalFilter={journalFilter}
                            setJournalFilter={setJournalFilter}
                            setPhoneCardMode={setPhoneCardMode}
                        />
                    </CardColumn>
                    <CardColumn
                        height='100%'
                        width='100%'
                    >
                        <PersonCard
                            height='100%'
                            setClickedPerson={setClickedPerson}
                            journalFilter={journalFilter}
                            clickedCompany={clickedCompany}
                            setJournalFilter={setJournalFilter}
                            setPhoneCardMode={setPhoneCardMode}
                        />
                    </CardColumn>
                </CardRow>



            </CardColumn>

            <CardColumn
                height='100%'
                width='100%'
            >
                <CardRow
                    height='50%'
                    width='100%'
                >
                    <JournalCard
                        height='100%'
                        width='100%'
                    />
                </CardRow>
                <CardRow
                    height='50%'
                    width='100%'
                >
                    <SmsCard
                        height='100%'
                        width='100%'
                        clickedPerson={clickedPerson}
                        clickedCompany={clickedCompany}
                        phoneCardMode={phoneCardMode}
                        setCalledPerson={setCalledPerson}
                        calledPerson={calledPerson}
                        setPhoneJournalTitle={setPhoneJournalTitle}
                        phoneJournalTitle={phoneJournalTitle}
                        setPhoneJournalEntry={setPhoneJournalEntry}
                        phoneJournalEntry={phoneJournalEntry}
                        setPhoneJournalTime={setPhoneJournalTime}
                        phoneJournalTime={phoneJournalTime}
                        setPhoneJournalDuration={setPhoneJournalDuration}
                        phoneJournalDuration={phoneJournalDuration}
                        message={message}
                    />
                </CardRow>
            </CardColumn>
        </>
    )
}

export default SmsPage

