import React, { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import styles from './Expandable.module.css'
import { Collapse } from '@mui/material'

interface expandable {
    body: ReactNode,
    header: (expanded: boolean, setExpanded: Dispatch<SetStateAction<boolean>>) => ReactNode
    style?: React.CSSProperties
}

//TODO children -> Fabian
function Expandable({body, header, style} : expandable) {
    const [expanded, setExpanded] = useState(false)
  return (
    <div style={style}>
        <div>
            {header(expanded, setExpanded)}
        </div>
        <Collapse in={expanded}>
        {body}
        </Collapse>
    </div>
  )
}

export default Expandable