import { useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CurrentMonthCard from '../../Components/CurrentMonthCard/CurrentMonthCard'
import CreateServiceCard from '../../Components/FormCards/CreateServiceCard'
import ServiceCard from '../../Components/ServiceCard/ServiceCard'
import TravelCard from '../../Components/TravelCard/TravelCard'
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { useFetch } from '../../Hooks/useFetch'
import { useMobileView } from '../../Hooks/useMobileView'
import { IServiceDTO } from '../../Interfaces/Service'

export type serviceFormModes = "createService" | "createTravel" | "editService" | "editTravel"

function Services() {
  useFetch(["fahrten"])

  const isMobile = useMobileView();

  const { defaultService } = useServices();

  const [clickedService, setClickedService] = useState<IServiceDTO>(defaultService!);
  const [formMode, setFormMode] = useState<serviceFormModes>("createService");

  return (
    <>
      {!isMobile &&
        <>
          <CardColumn
            height='100%'
            width='25%'
          >
            <CurrentMonthCard
              height='33%'
              width='100%'
            />
            <TravelCard
              height='66%'
              cta
              ctaTitle='Fahrt erstellen'
            />
          </CardColumn>
          <CardColumn
            height='100%'
            width='25%'
          >
            <ServiceCard
              height='100%'
              cta
              ctaTitle='Leistung erstellen'
              setClickedService={setClickedService}
              setFormMode={setFormMode}
            />
          </CardColumn>
          <CardColumn
            height='100%'
            width='50%'
          >
            <CreateServiceCard
              clickedService={clickedService}
              setClickedService={setClickedService}
              formMode={formMode}
              setFormMode={setFormMode}
              height='100%'
              width='100%'
            />
          </CardColumn>
        </>}
      {isMobile &&
        <>
          <ServiceCard
            height='100%'
            cta
            ctaTitle='Leistung erstellen'
            setClickedService={setClickedService}
            setFormMode={setFormMode}
          />
          <TravelCard
            cta
            ctaTitle='Fahrt erstellen'
          />
          <CreateServiceCard
            clickedService={clickedService}
            setClickedService={setClickedService}
            formMode={formMode}
            setFormMode={setFormMode}
            height='100%'
            width='100%'
          />
          <CurrentMonthCard />
        </>
      }
    </>
  )
}

export default Services