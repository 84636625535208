import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { card } from '../../Interfaces/Card'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'

/* Icons */
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import Card from '../Card/Card'
import { useProducts } from '../../Contexts/ProductContext/ProductProvider'
import ProductRow from './IntervallRow'
import IntervallRow from './IntervallRow'
import { IIntervall, IIntervallDTO, ISubscriptionPosition, useIntervalls } from '../../Contexts/IntervallContext/IntervallProvider'
import Filter, { IFilterOption } from '../Filter/Filter'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { IntervallFormModes } from '../Forms/EditCreateIntervall'

interface IIntervallCard extends card {
  setClickedIntervall?: Dispatch<SetStateAction<IIntervallDTO>>
  setFormMode?: Dispatch<SetStateAction<IntervallFormModes>>
}

function IntervallCard({ width, height, order, setClickedIntervall, setFormMode }: IIntervallCard) {

  const { intervalls, fetchIntervalls } = useIntervalls();
  const { persons } = useContacts();
  const { companies } = useContacts();
  const [incomeIntervalls, setIncomeIntervalls] = useState<IIntervallDTO[]>([]);

  const incomeIntervallsOriginal = useMemo(() => getIncomeIntervalls(intervalls), [intervalls])

  function getIncomeIntervalls(intervalls: IIntervall[]) {
    const incomeIntervalls = intervalls.filter((intervall) => {
      let tempSum = 0
      intervall.subscriptionPositions?.forEach((subscriptionPosition: ISubscriptionPosition) => {
        tempSum += ((subscriptionPosition.netto * subscriptionPosition.quantity) -
          (subscriptionPosition.hasDiscount ? (subscriptionPosition.discountType! === false ? subscriptionPosition.netto! * subscriptionPosition.quantity! * (subscriptionPosition.discount! / 100) : subscriptionPosition.discount!) : 0))
      })
      if (tempSum >= 0) {
        return true
      }
      return false;
    })
    return incomeIntervalls;
  }

  useEffect(() => {
    fetchIntervalls();
  }, [])

  useEffect(() => {

    setIncomeIntervalls(intervalls.filter((intervall) => {
      let tempSum = 0
      intervall.subscriptionPositions?.forEach((subscriptionPosition: ISubscriptionPosition) => {
        tempSum += ((subscriptionPosition.netto * subscriptionPosition.quantity) -
          (subscriptionPosition.hasDiscount ? (subscriptionPosition.discountType! === false ? subscriptionPosition.netto! * subscriptionPosition.quantity! * (subscriptionPosition.discount! / 100) : subscriptionPosition.discount!) : 0))
      })
      if (tempSum >= 0) {
        return true
      }
      return false;
    }))
    console.log(intervalls);
  }, [intervalls])

  const filterOptions: IFilterOption[] = [
    { title: "Titel", valueKey: "title" },
    { title: "Beschreibung", valueKey: "description" },
    { title: "Organisation", valueKey: "customerOrganizationId", referenceList: companies, referenceListValueKey: "name" },
    { title: "Person", valueKey: "customerPersonId", referenceList: persons, referenceListValueKey: "completeName" }
  ]

  return (
    <Card
      title='Intervallleistungen'
      style={{ width, height, order }}
      fullHeightBody={true}
      right={() => <Filter<IIntervallDTO> dataToFilter={incomeIntervalls!} setDataToFilter={setIncomeIntervalls} filterOptions={filterOptions} originalData={incomeIntervallsOriginal} />}
    >
      {incomeIntervalls && incomeIntervalls.map((intervall: any) => {
        return (
          <IntervallRow key={intervall.id} intervall={intervall} setFormMode={setFormMode} setClickedIntervall={setClickedIntervall}/>
        )
      })}
    </Card>
  )
}

export default IntervallCard