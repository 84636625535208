import { AccountBalanceRounded, AssignmentTurnedIn, AttachFile, Call, MoreVertRounded, SatelliteAlt, Sms } from '@mui/icons-material';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import { Avatar, Button, CircularProgress, CircularProgressProps, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { useMemo, useRef, useState } from 'react';
import { authConnection } from '../../Connection/BaseConnection';
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { IDocument, useDocuments } from '../../Contexts/DocumentContext/DocumentContext';
import { useJournal } from '../../Contexts/JournalContext/JournalProvider';
import { useStaff } from '../../Contexts/StaffContext/StaffProvider';
import { useSnackBar } from '../../Hooks/useSnackBar';
import { journalWithAuthor } from '../../Interfaces/Journal';
import { stringToColour } from '../../Navigation/Navigation';
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton';
import Modal from '../Dialog/Dialog';
import { Delete, Download, FileDownload, FileUpload, ManageAccounts } from '@mui/icons-material';
import InputField from '../InputField/InputField';
import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import { saveAs } from 'file-saver';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import FileInput from '../FileInput/FileInput';
import ContextMenu from '../Menu/ContextMenu';
import styles from './JournalEntry.module.css';

function roundToNearestMinute(date = new Date()) {
  const minutes = 1;
  const ms = 1000 * 60 * minutes;

  // 👇️ replace Math.round with Math.ceil to always round UP
  return new Date(Math.round(date.getTime() / ms) * ms);
}



const JournalEntry = ({ journal }: { journal: journalWithAuthor }) => {
  const { deleteJournal } = useJournal()

  const { changeVisibility } = useModals()

  const { enqueueSnackbar } = useSnackBar()

  const tags = useMemo(() => Array.from(journal.tags?.split(",").map((item) => item.trim()) ?? []), [journal.tags])

  const { staff } = useStaff();
  const { persons } = useContacts();
  const { companies } = useContacts();

  const author = staff.find(temp => temp.id === journal.author);
  const person = persons.find(temp => temp.id === journal.personId)
  const company = companies.find(temp => temp.id === journal.customerOrganizationId)

  const downloadRef = useRef<HTMLAnchorElement>(null);

  const [showAttachmentModal, setShowAttachmentModal] = useState(false);

  const { downloadDocument } = useDocuments();

  const [downloading, setDownloading] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  const [progress, setProgress] = useState(0)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleDelete = async () => {
    try {
      changeVisibility!("yesNoDialog", true, { props: { object: { submitFunction: () => { deleteJournal!(journal) }, modalTitle: "Journaleintrag löschen", modalText: "Möchten Sie diesen Journaleintrag wirklich löschen?", confirmButtonText: "Löschen" } } })
    } catch (error) {
      enqueueSnackbar("Fehler beim Löschen.", { variant: "error" })
    }
  }

  const buttonSx = {
    ...(success && {
      bgcolor: "rgb(10,200,10)",
      '&:hover': {
        bgcolor: "rgb(10,250,10)",
      },
    }),
  };

  const downloadFile = (file: IDocument, url: string, onProgress: any) => {
    return authConnection({
      url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: onProgress,
    })
      .then((response) => {
        // Handle successful download
        // For example, create a download link and simulate a click
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.title ?? "Download");
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        // Handle error
      });
  };

  // Example usage
  const handleDownload = (file: IDocument) => {
    setDownloading(true);
    downloadFile(file, `https://api.ecockpit.online/document/download/${file.id}`, (event: any) => {
      const progress = Math.round((event.loaded / event.total) * 100);
      setProgress(progress);
    }).then(() => {
      setDownloading(false);
    });
  }

  const StyledBadge = styled(Badge)(({ theme }) => (author?.workingStatus !== 0 && {
    '& .MuiBadge-badge': {
      backgroundColor: author?.workingStatus === 1 ? "green" : (author?.workingStatus === 2 ? "orange" : "blue"),
      color: author?.workingStatus === 1 ? "green" : (author?.workingStatus === 2 ? "orange" : "blue"),
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        //animation: 'ripple 1.2s infinite ease-in-out',
        //border: '1px solid currentColor',
        content: '""',
      },
    },
    /*'@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },*/
  }));

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
  ) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div style={{ border: "1px solid rgba(0, 0, 0, 0.40)", borderRightWidth: "0px", borderLeftWidth: "0px", marginTop: "-1px", padding: "1%", position: 'relative' }}>
      <IconTooltipButton
        title='Optionen'
        arrow
        onClick={handleOptionsClick}
        style={{ position: 'absolute', top: '0%', left: '93%' }}
      >
        <MoreVertRounded fontSize='small'
        />
      </IconTooltipButton>
      <header className='flex column' style={{ marginBottom: "10px" }}>
        <div className='flex' style={{ gap: "10px" }}>
          {journal.author !== 8 && <StyledBadge
            style={{ zIndex: 0 }}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            {/*hier wird der Avatar für Meldungen gesetzt, die vom User kommen*/}
            <Avatar
              className={styles.avatar}
              src={`data:image/jpeg;base64,${author?.base64}`}
              sx={{ backgroundColor: stringToColour(journal.authorName ?? "") }}
              alt={journal.authorName ?? ""}
            />
          </StyledBadge>
          }
          {/*hier wird der Avatar für Systemmeldungen gesetzt.*/}
          {journal.author === 8 &&
            <Avatar
              className={styles.avatar}
              sx={{ backgroundColor: "var(--back)" }}
              alt={journal.authorName ?? ""}
            >
              {journal.title?.toLowerCase().startsWith("newsletter") && <SatelliteAlt fontSize='inherit' style={{ color: "var(--text)" }} />}
              {journal.title?.toLowerCase().startsWith("intervall") && <AccountBalanceRounded fontSize='inherit' style={{ color: "var(--text)" }} />}
              {journal.category === 4 && <AssignmentTurnedIn fontSize='inherit' style={{ color: "var(--text)" }} />}
              {journal.category === 5 && <Sms fontSize='inherit' style={{ color: "var(--text)" }} />}
            </Avatar>
          }
          <div className='flex column' style={{ justifyContent: "space-between", width: "100%", gap: "0px" }}>
            <span className='flex' style={{ fontSize: "16px", fontWeight: "500" }}>{journal.title}</span>
            <ContextMenu
              anchorEl={anchorEl}
              onClose={(index) => setAnchorEl(null)}
              options={[
                {
                  label: "Löschen",
                  onClick: (index) => handleDelete(),
                  selectable: false
                }
              ]}
            />
            {/*Hier werden die Unterüberschriften des Journals gesetzt und mit einem Icon ausgestattet */}
            <div className='flex centered-aligned' style={{ gap: "6px", fontSize: "14px", marginTop: '0px' }}>
              <div className='centered' style={{ gap: "2px" }}>
                {journal.category === 0 && <RssFeedIcon fontSize='inherit' />}
                {journal.category === 1 && <Call fontSize='inherit' />}
                {journal.category === 2 && <ManageAccounts fontSize='inherit' />}
                {journal.category === 4 && <ManageAccounts fontSize='inherit' />}
                {journal.category === 5 && <Sms fontSize='inherit' />}
                
                <span>
                  {journal.category === 0 && "Allgemein" + (company?.name ? (" | " + company?.name) : "") + (person?.firstname && person?.lastname ? (" | " + person.firstname + " " + person.lastname) : "")}
                  {journal.category === 1 && "Telefonat mit " + (company?.name ? (company.name.length > 20 ? company.name.substring(0, 18) + "... " : company.name + " ") : "") + (person?.firstname ? ("/ " + person.firstname.split(" ")[0] + " " + (person?.lastname ?? "")) : (person?.lastname ?? ""))}
                  {journal.category === 2 && "Administration" + (company?.name ? (" | " + company?.name) : "") + (person?.firstname && person?.lastname ? (" | " + person.firstname + " " + person.lastname) : "")}
                  {journal.category === 4 && "Administration" + (company?.name ? (" | " + company?.name) : "") + (person?.firstname && person?.lastname ? (" | " + person.firstname + " " + person.lastname) : "")}
                  {journal.category === 5 && "Folgender Text wurde erfolgreich versendet:"} {/*} gesendete SMS an " + (company?.name ? (company.name.length > 20 ? company.name.substring(0, 18) + "... " : company.name + " ") : "") + (person?.firstname ? ("" + person.firstname.split(" ")[0] + " " + (person?.lastname ?? "")) : (person?.lastname ?? ""))}*/}
                </span>
              </div>
            </div>
          </div>
        </div>

      </header>
      <section style={{ marginLeft: "50px", marginTop: "-8px", maxWidth: "93%" }}>
        {journal.description}
      </section>
      {journal.imageBase64 &&
        (<section style={{ marginLeft: "50px", marginTop: "15px", display: "flex", justifyContent: "start" }}>
          <img style={{ maxWidth: "93%", maxHeight: "93%", objectFit: "cover", borderRadius: "34px" }} src={journal.imageBase64.startsWith("data:image") ? journal.imageBase64 : `data:image/jpeg;base64,${journal?.imageBase64}`} /*TODO*/ />
        </section>)
      }
      {journal.documentIds && journal.documentIds.length > 0 &&
        <section style={{ marginLeft: "50px", marginTop: "15px", }}>
          <Button variant='outlined' onClick={() => { setShowAttachmentModal((old: boolean) => !old) }} style={{ borderColor: "gray", color: "gray" }}><AttachFile fontSize='small' />Anhänge</Button>
        </section>
      }
      {showAttachmentModal &&
        <Modal
          title={`Anhänge von ${journal.title}`}
          open={showAttachmentModal}
          onClose={() => { setShowAttachmentModal(false) }}
          maxWidth={"sm"}
        >
          <div className='form-row-label'>
            <span className="label">
              Anhänge:
            </span>
            {journal?.documents?.map((file: any, index: number) => {

              return (
                <>
                  <FileInput file={file} setFile={() => { }} index={index} key={index} isDownloadOnly existingFile={file}></FileInput>
                </>
              )
              /*return <div className='form-row-label' key={index}>
                <span className="label">
                  Dateiname:
                </span>
                <div style={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: "column" }}>
                  <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "80%" }}>
                      <InputField onChangeHandler={() => { }} value={file?.title} isReadOnly={true}></InputField>
                    </div>
                    <div style={{ display: "flex", maxWidth: "15%", justifyContent: "end" }}>
                      {!downloading && <Box sx={{ position: 'relative' }}>
                        <Fab
                          size="small"
                          aria-label="save"
                          sx={buttonSx}
                          onClick={() => { handleDownload(file) }}
                        >
                          {progress < 100 && progress > 0 ? (progress === 100 ? <span>{progress} %</span> : <CheckIcon fontSize='small' />) : <Download fontSize='small' />}
                        </Fab>
                      </Box>}
                      {downloading && (
                        <CircularProgressWithLabel
                          size={42}
                          value={progress}
                          sx={{
                            color: "rgb(10, 200, 10)"
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>*/
            })}
          </div>
        </Modal>
      }
      <div className='flex' style={{ gap: "6px", fontSize: "14px", width: "90%", marginLeft: "50px", marginTop: "10px" }}>
        <span style={{ color: author?.workingStatus === 1 ? "green" : (author?.workingStatus === 2 ? "orange" : "var(--blue)") }}>{journal.authorName ? "@" + journal.authorName : ""}</span>
        <span>|</span>
        <span>{roundToNearestMinute(new Date(journal.date ?? "")).toLocaleString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" })}</span>
      </div>
    </div >
    )
}

export default JournalEntry