import React, { useEffect, useRef, useState } from 'react'
import { IContactData, IPerson, IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import CardExpandable from '../Card/CardExpandable/CardExpandable'
import { form } from './CreateTaskForm'
import PersonContactDataDynamicForm, { generateId } from './PersonContactDataDynamicForm'
import Form from './FormUtils/Form'
import FormRow from './FormUtils/FormRow'
import uuid from 'react-uuid'
import { contactFormModes } from '../ContactCards/ContactFormCard'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { InputFieldErrorMessages } from '../InputField/InputFieldRefactored'
import ResetFormButton from '../Buttons/ResetFormButton/ResetFormButton'
import { Button, CircularProgress, LinearProgress } from '@mui/material'
import { Upload } from '@mui/icons-material'
import jsPDF from "jspdf";
import { createWorker } from "tesseract.js";
import { processInvoice } from '../OCRScanner/regexUtils'
import { IVisitenkarte, ScanVisitenkarteButton, processVisitenkarte } from '../OCRScanner/rergexqVisitenkarte'

import { Avatar } from '@mui/material'
import PersonProfilePictureDialog from '../Dialogs/ProfilePictureDialog/PersonProfilePictureDialog'

const CreatePersonForm = ({ object, setObject, mode, setFormMode, errorVariables }: form<IPersonDTO, contactFormModes>) => {

  const { companies, getProfilePicture } = useContacts()

  const { user } = useUser()

  const { tags } = useContacts();

  useEffect(() => {
    console.log("CreatePersonForm", object)
    fetchProfilePicture();
  }, [object])

  const [profilePicLoading, setProfilePicLoading] = useState(true);

  const fetchProfilePicture = async () => {
    try {

      if (object.profilePictureDocumentId == null || object.profilePictureBase64 != null) {
        return;
      }

      setProfilePicLoading(true);
      const base64 = await getProfilePicture!(object.id as number);
      setObject((old) => ({ ...old, profilePictureBase64: base64 }));

    }
    catch (error) {
      console.error('Error fetching profile picture', error);
    }
    finally {
      setProfilePicLoading(false);
    }
  }

  const setContactData = (newContactData: IContactData[]) => {
    const updatedContactData = [...object.contactData!];

    newContactData.forEach((newEntry) => {
      const index = updatedContactData.findIndex((data) => data.id === newEntry.id);
      if (index === -1) {
        updatedContactData.push(newEntry);
      } else {
        updatedContactData[index] = newEntry;
      }
    });

    const idsToRemove = updatedContactData.filter((data) => !newContactData.find((newEntry) => newEntry.id === data.id));
    idsToRemove.forEach((toRemove) => {
      const removeIndex = updatedContactData.findIndex((cd) => cd.id === toRemove.id);
      updatedContactData.splice(removeIndex, 1);
    });
    setObject({ ...object, contactData: updatedContactData });
  }

  const handleReset = () => {
    let person: IPerson = {
      id: generateId(),
      firstname: "",
      lastname: "",
      organizationId: user?.organizationId ?? 14,
      note: "",
      title: "",
      status: 1,
      birthdate: null,
      contactData: [{ id: generateId(), type: 1, value: "" }, { id: generateId(), type: 2, value: "" }, { id: generateId(), type: 3, value: "" },],
      tags: [],
      tagIds: [],
      identificationAdministration: "",
      identificationNumber: "",
      identificationType: ""
    }
    setObject(person);
    if (setFormMode) {
      setFormMode("person");
    }
  }

  const currentDate = new Date();


  const [ocrProgress, setOcrProgress] = useState(0);
  const [imageData, setImageData] = useState<string>("");
  const [ocrText, setOcrText] = useState<string>("");
  const { createCompany } = useContacts();

  const worker = createWorker({
    logger: (m: any) => {
      console.log(m);
      if (m.status == 'recognizing text') {
        const progress = Math.round(m.progress * 100);
        setOcrProgress(progress);
      }
    },
  });

  //This converts the png to a pdf so you can store it on your database
  const convertPngToPdf = (imageDataUri: any) => {
    try {
      const pdf = new jsPDF();
      pdf.addImage(imageDataUri, "PNG", 10, 10, 70, 100); // Adjust the position and dimensions as needed
      const pdfDataUri = pdf.output("datauristring");

      // Update the document state with the resulting PDF data
      //setDocument({ base64: pdfDataUri, fileEnding: "application/pdf" });

      // Optionally, you can save the PDF to a file or send it to your server here

      // Return a success message or perform any other actions as needed
      return "PNG image converted to PDF successfully!";
    } catch (error) {
      console.error("Error converting PNG to PDF:", error);
      // Handle errors here
    }
  };

  /*const convertImageToText = async (imageData: any) => {
    if (!imageData) {
      console.log("no image data", imageData)
    };

    //Uses the regexUtils file to get the properties
    const processedPerson = await processVisitenkarte(imageData, setPro);
    console.log(JSON.stringify(processedPerson));

    setObject((old) => ({ ...old, id: (old?.id ?? 0) - 1, firstname: processedPerson.firstname, lastname: processedPerson.lastname, contactData: [{ id: -1, type: 1, value: processedPerson.telephone[0] }, { id: -2, type: 2, value: processedPerson.email }, { id: -3, type: 3, value: processedPerson.website }] }))
    //setContactData([{ id: -1, type: 1, value: processedPerson.Telefonnummer[0] }, { id: -2, type: 2, value: processedPerson.EMail }, { id: -3, type: 3, value: processedPerson.EMail.split('@')[1] }])
    //setBillDTO(processedInvoice, text);
  };*/

  useEffect(() => {
    console.log("person dates", object.passing, object.birthdate, object.entryDate);
  }, [object])

  const inputRef = useRef<any>();

  useEffect(() => { console.log("Mode", mode) }, [mode])

  const [profilePictureOpen, setProfilePictureOpen] = useState(false);
  const [visitenkarte, setVisitenkarte] = useState<IVisitenkarte | undefined>(undefined);

  useEffect(() => {
    if (visitenkarte) {
      setObject((old) => ({ ...old, telefonnummer: visitenkarte.telephone, firstname: visitenkarte.firstname, lastname: visitenkarte.lastname, contactData: [{ id: -1, type: 1, value: visitenkarte.telephone ? visitenkarte.telephone[0] : "" }, { id: -2, type: 2, value: visitenkarte.email ?? "" }, { id: -3, type: 3, value: visitenkarte.email ? visitenkarte.email.split('@')[1] : "" }] }))
      //setContactData([{ id: -1, type: 1, value: visitenkarte.telephone ? visitenkarte.telephone[0] : "" }, { id: -2, type: 2, value: visitenkarte.email ?? "" }, { id: -3, type: 3, value: visitenkarte.email ? visitenkarte.email.split('@')[1] : "" }])
    }
  }, [visitenkarte])


  return (
    <Form
    >
      {mode?.toLowerCase() === "person" && ocrProgress < 98 &&
        <>
          <ScanVisitenkarteButton inputRef={inputRef} setImageData={setImageData} setVisitenkarte={setVisitenkarte} />
        </>
      }

      {/*</div>
        <div style={{ width: "25%", height: "100%" }}>
    <img style={{ height: "100px" }} src='https://isaca-gwdc.org/wp-content/uploads/2016/12/male-profile-image-placeholder-300x300.png'></img>*/}
      <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: "10px", alignItems: "center" }}>

        <div style={{ width: "80%", height: "100%", flexDirection: "column", alignItems: "end" }}>
          {<FormRow
            type="text"
            value={object.title}
            label="Titel"
            placeholder='Titel'
            onChange={(value) => setObject((old) => ({ ...old, title: value }))}
          />}
          {<FormRow
            type="select"
            value={object.salutation}
            label="Anrede"
            placeholder='Anrede'
            onChange={(value) => setObject((old) => ({ ...old, salutation: value }))}
            selectOptions={{
              options: [
                {
                  title: "Herr",
                  id: "Herr"
                },
                {
                  id: "Frau",
                  title: "Frau"
                }
              ],
              titleKey: "title",
              valueKey: "id"
            }}
          />}
        </div>
        <div style={{ height: 111, width: "15%", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 0px 1px 1px rgba(0,0,0,0.25)", margin: "0 2.5% 0 2.5%" }}>
          {profilePicLoading ?
            <CircularProgress size={60} />
            :
            <Avatar
              src={object?.profilePictureBase64 ? (`data:image/jpeg;base64,${object?.profilePictureBase64.includes("base64,") ? object.profilePictureBase64.split("base64,")[1] : object.profilePictureBase64}`) : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png`}
              alt={object.completeName}
              sx={{ width: "100%", height: 111, backgroundColor: "var(--elevated-background)", boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.75)", color: "grey" }}
              //style={{ position: "absolute", marginRight: 80, zIndex: 1 }}
              variant="rounded"
              onClick={() => { setProfilePictureOpen(true) }}
            ></Avatar>

          }
        </div>

      </div>
      <FormRow
        type='text'
        value={object.firstname}
        label="Vorname"
        placeholder='Vorname'
        onChange={(value) => setObject((old) => ({ ...old, firstname: value }))}
      />
      <FormRow
        type="text"
        value={object.lastname}
        label="Nachname"
        placeholder='Nachname'
        onChange={(value) => setObject((old) => ({ ...old, lastname: value }))}
        error={errorVariables?.lastNameError}
        errorMessage={InputFieldErrorMessages.NameRequired}
      />
      {<FormRow
        type="select"
        value={object.belongsToOrganizationId}
        label="Organisation"
        placeholder='Organisation'
        onChange={(value) => setObject((old) => ({ ...old, belongsToOrganizationId: value }))}
        selectOptions={{
          options: companies,
          titleKey: "name",
          valueKey: "id"
        }}
      />}
      <FormRow
        type='text'
        value={object.role ?? ""}
        label='Rolle'
        placeholder='CEO, Techniker, ...'
        onChange={(value) => setObject((old) => ({ ...old, role: value }))}
        maxLength={50}
      />
      <FormRow
        type="date"
        value={object.birthdate}
        label="Geburtsdatum"
        placeholder='Geburtsdatum'
        onChange={(value) => setObject((old) => ({ ...old, birthdate: value }))}
        dateOptions={{
          type: "Date"
        }}
      />

      <FormRow
        label="Status"
        type='select'
        value={object.status ?? 1}
        onChange={(val) => setObject((old) => ({ ...old, status: val }))}
        selectOptions={{
          options: [
            {
              id: 1,
              title: "Ok"
            },
            {
              id: 2,
              title: "Mäßig"
            },
            {
              id: 3,
              title: "Optimierungsbedarf"
            },
            {
              id: 4,
              title: "Verstorben"
            },
          ],
          titleKey: "title",
          valueKey: "id"
        }}
      />

      {/*<FormRow
        label="Tags"
        type='select'
        value={object.tagIds}
        onChange={(val) => setObject((old) => ({ ...old, tagIds: val }))}
        selectOptions={{
          titleKey: "title",
          valueKey: "id",
          options: tags,
          multiple: true
        }}
      />*/}

      <FormRow
        type="multiline"
        value={object.note}
        label="Notiz"
        placeholder='Notiz (max. 1500 Zeichen)'
        onChange={(value) => setObject((old) => ({ ...old, note: value }))}
        maxLength={1500}
      />
      <CardExpandable title='Adresse' style={{ marginTop: "-3px" }}>
        <FormRow
          type='text'
          label='Straße:'
          placeholder='Straße'
          value={object.address?.street ?? ""}
          onChange={(val) => setObject((prev: IPersonDTO) => {
            if (prev.address) {
              return { ...prev, address: { ...prev.address, street: val } }
            }
            return { ...prev, address: { id: 0, street: val } }
          })}
        />
        <FormRow
          type='text'
          label='Hausnummer:'
          placeholder='Hausnummer'
          value={object.address?.housenumber ?? ""}
          onChange={(val) => setObject((prev: IPersonDTO) => {
            if (prev.address) {
              return { ...prev, address: { ...prev.address, housenumber: val } }
            }
            return { ...prev, address: { id: 0, housenumber: val } }
          })}
        />
        <FormRow
          type='text'
          label='Postleitzahl:'
          placeholder='Postleitzahl'
          value={object.address?.postalcode ?? ""}
          onChange={(val) => setObject((prev: IPersonDTO) => {
            if (prev.address) {
              return { ...prev, address: { ...prev.address, postalcode: val } }
            }
            return { ...prev, address: { id: 0, postalcode: val } }
          })}
        />
        <FormRow
          type='text'
          label='Stadt:'
          placeholder='Stadt'
          value={object.address?.city ?? ""}
          onChange={(val) => setObject((prev: IPersonDTO) => {
            if (prev.address) {
              return { ...prev, address: { ...prev.address, city: val } }
            }
            return { ...prev, address: { id: 0, city: val } }
          })}
        />
      </CardExpandable>
      <CardExpandable title="Kontaktdaten" style={{ marginTop: "-0.9em" }}>
        <PersonContactDataDynamicForm
          mode={"fromPhone"}
          object={object}
          contactData={object.contactData}
          setContactData={setContactData}
        />
      </CardExpandable>
      <CardExpandable title='Bankdaten' style={{ marginTop: "-0.9em" }}>
        <FormRow
          label='Bank'
          placeholder='Bank'
          type='text'
          value={object?.bank?.bank ?? ""}
          onChange={(val) => setObject((old: any) => ({ ...old, bank: { ...old.bank, bank: val } }))}
        />
        <FormRow
          label='IBAN'
          placeholder='ATXX XXXX XXXX XXXX'
          type='text'
          value={object?.bank?.iban ?? ""}
          onChange={(val) => setObject((old: any) => ({ ...old, bank: { ...old.bank, iban: val } }))}
        />
        <FormRow
          label='BIC'
          placeholder='XXXXXXXX'
          type='text'
          value={object?.bank?.bic ?? ""}
          onChange={(val) => setObject((old: any) => ({ ...old, bank: { ...old.bank, bic: val } }))}
        />
      </CardExpandable>
      <CardExpandable title='Ausweis' style={{ marginTop: "-0.9em" }} >
        <FormRow
          type='text'
          label='Ausweisart'
          placeholder='Reisepass'
          value={object.identificationType ?? ""}
          onChange={(val) => setObject((old) => ({ ...old, identificationType: val }))}
        />
        <FormRow
          type='text'
          label='Ausweisnummer'
          placeholder='C01X0006H'
          value={object.identificationNumber ?? ""}
          onChange={(val) => setObject((old) => ({ ...old, identificationNumber: val }))}
        />
        <FormRow
          type='text'
          label='Austellende Behörde'
          placeholder='BH Klagenfurt Land'
          value={object.identificationAdministration ?? ""}
          onChange={(val) => setObject((old) => ({ ...old, identificationAdministration: val }))}
        />
      </CardExpandable>
      <CardExpandable title='Sonstiges' style={{ marginTop: "-0.9em" }} >
        <FormRow
          type="date"
          value={object.entryDate}
          label='Erfassungsdatum'
          placeholder='Erfassungsdatum'
          onChange={(value) => setObject((old) => ({ ...old, entryDate: value }))}
          dateOptions={{
            type: "Date"
          }}
        />
        <FormRow
          type="date"
          value={object.passing}
          label="Ableben"
          placeholder='Ableben'
          onChange={(value) => setObject((old) => ({ ...old, passing: value }))}
          dateOptions={{
            type: "Date"
          }}
        />
      </CardExpandable>

      <ResetFormButton
        title="Felder zurücksetzen"
        onClickHandler={() => handleReset()}
      />
      <PersonProfilePictureDialog open={profilePictureOpen} object={object} onClose={() => { setProfilePictureOpen(false) }} setObject={setObject} />

    </Form>

  )
}

export default CreatePersonForm