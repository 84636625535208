import React, { ReactNode, useContext, useReducer } from 'react'
import { createContext } from 'react'
import OfferReducer from './OfferReducer'

export interface IOffer {

}

export interface IOfferDTO {
    
}

interface IOfferContext {

}

const OfferContext = createContext<IOfferContext>({

})

const OfferProvider = ({children} : {children: ReactNode}) => {

    const [state, dispatch] = useReducer(OfferReducer, {

    })
    
  return (
    <OfferContext.Provider 
    value={{

    }}
    >
        {children}
    </OfferContext.Provider>
  )
}

export default OfferProvider

export const useOffer = () => useContext(OfferContext)