import { CircularProgress } from '@mui/material'
import { AxiosResponse } from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useBills } from '../../../Contexts/BillContext/BillProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IIntervallDTO, IIntervallPosition, useIntervalls } from '../../../Contexts/IntervallContext/IntervallProvider'
import { IProductDTO } from '../../../Contexts/ProductContext/ProductProvider'
import { useServices } from '../../../Contexts/ServiceContext/ServiceContext'
import useAuthConnection from '../../../Hooks/useAuthConnection'
import { IBillDTO, IBillingPosition, IBillListDTO } from '../../../Interfaces/Bill'
import { IService, IServiceDTO } from '../../../Interfaces/Service'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateBillingPosition from '../../Forms/CreateBillingPositionForm'
import CreateServiceForm from '../../Forms/CreateServiceForm'
import EditCreateExpanseIntervallPositionForm from '../../Forms/EditCreateExpanseIntervallPositionForm'
import { useCompanyData } from '../../../Contexts/CompanyDataContext/CompanyDataProvider'


export type dialog = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    onClose?: () => void
    object?: IIntervallPosition | undefined
    intervall: IIntervallDTO | undefined
    mode: "create" | "edit" | "createNewIntervall"
    setIntervall?: Dispatch<SetStateAction<IIntervallDTO>>
    index?: number
}

function EditCreateExpanseIntervallPositionDialog({ open, setOpen, onClose, object, intervall, setIntervall, mode, index }: dialog) {
    const { companyData } = useCompanyData();

    const [exists, setExists] = useState<boolean>(object !== undefined);

    const [product, setProduct] = useState<IProductDTO | undefined>(undefined);

    const [loading, setLoading] = useState<boolean>(false);

    //ToDo - ist hier noch etwas zu tun?
    const [intervallPositionToSubmit, setIntervallPositionToSubmit] = useState<IIntervallPosition>({
        subscriptionId: intervall?.id!,
        description: object?.description ? object?.description : "",
        id: object?.id ? object?.id : index,
        netto: object?.netto ? object?.netto : 0,
        organizationId: object?.organizationId ? object?.organizationId : 0,
        productId: object?.productId ? object?.productId : 0,
        quantity: object?.quantity ? object?.quantity : 1,
        tax: object?.tax ? object?.tax : (companyData.kleinunternehmer ? 0 : 20),
        title: object?.title ? object?.title : "",
        discount: object?.discount,
        discountType: object?.discountType,
        hasDiscount: object?.hasDiscount
    });

    useEffect(() => {
        setIntervallPositionToSubmit({
            subscriptionId: intervall?.id!,
            description: object?.description ? object?.description : "",
            id: object?.id ?? index,
            netto: object?.netto ? object?.netto : 0,
            organizationId: object?.organizationId ? object?.organizationId : 0,
            productId: object?.productId ? object?.productId : 0,
            quantity: object?.quantity ? object?.quantity : 1,
            tax: object?.tax ? object?.tax : 0,
            title: object?.title ? object?.title : "",
            discount: object?.discount,
            discountType: object?.discountType,
            hasDiscount: object?.hasDiscount
        })
    }, [open, intervall])

    const resetForm = () => {
        setIntervallPositionToSubmit({
            subscriptionId: 0,
            description: "",
            id: undefined,
            netto: 0,
            organizationId: undefined,
            productId: undefined,
            quantity: 0,
            tax: 0,
            title: "",
            discount: undefined,
            discountType: undefined,
            hasDiscount: undefined
        })
    }

    const connection = useAuthConnection();

    const { addIntervallPosition, updateIntervallPosition } = useIntervalls();

    const onSubmitEdit = async () => {
        setLoading(true);

        if (mode === "create") {
            addIntervallPosition({ ...intervallPositionToSubmit, subscriptionId: intervall?.id })
            if (setIntervall) {
                setIntervall((old: any) => ({ ...old, subscriptionPositions: [...old.subscriptionPositions ?? [], intervallPositionToSubmit] }))

            }
        } else if (mode === "createNewIntervall") {

            if (setIntervall) {
                if (exists) {
                    setIntervall((old: IIntervallDTO) => ({
                        ...old, subscriptionPositions: old.subscriptionPositions?.map((position) => {
                            if (position.id === intervallPositionToSubmit.id) {
                                return intervallPositionToSubmit
                            }
                            return position;
                        })
                    }))
                } else {
                    setIntervall((old: any) => ({ ...old, subscriptionPositions: [...old.subscriptionPositions ?? [], intervallPositionToSubmit] }))
                }
            }
        }
        else {
            await connection.put("/subscription/subscriptionposition", intervallPositionToSubmit)
        }

        setLoading(false);
        setOpen(false);
        resetForm();
    }

    return (
        <Modal
            open={open}
            title={object !== undefined ? 'Ausgabenposition bearbeiten' : 'Ausgabenposition erstellen'}
            onClose={onClose ? onClose : () => setOpen(false)}
        >
            <span>{}</span>
            <EditCreateExpanseIntervallPositionForm
                object={intervallPositionToSubmit}
                setObject={setIntervallPositionToSubmit}
                product={product}
                setProduct={setProduct}
            />
            <div style={{ marginBottom: "2%" }}></div>
            <CTAButton
                title={loading ? <CircularProgress /> : exists ? 'Speichern' : 'Erstellen'}
                onClickHandler={onSubmitEdit}
            />
        </Modal>
    )
}

export default EditCreateExpanseIntervallPositionDialog