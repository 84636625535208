import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

posthog.init('phc_e2h2tzHB8dN6UthgZZk96FqR2ZMi1W16zP8RuseFpuJ',
  {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well,
  }
)

root.render(
  <PostHogProvider
    client={posthog}
  >
    <App />
  </PostHogProvider>
);

if (process.env.NODE_ENV !== "development")
  console.log = () => { };

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
