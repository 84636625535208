import { AddRounded } from "@mui/icons-material";
import { Dispatch, SetStateAction, useState } from "react";
import { IDepartement, defaultDepartment, useDepartements } from "../../Contexts/DepartementContext/DepartementProvider";
import { useEmployees } from "../../Contexts/EmployeeContext/EmployeeProvider";
import { useSnackBar } from "../../Hooks/useSnackBar";
import { card } from "../../Interfaces/Card";
import Card from "../Card/Card";
import EditCreateDepartmentForm from "./EditCreateDepartmentForm";

export interface props {
    height?: string
    width?: string
    order?: string
}

export const validateDepartmentForm = (departement: IDepartement, setError: any) => {
    let valid = true;

    if ((departement.name?.length ?? 0) < 1) {
        setError((old: any) => ({ ...old, nameError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, nameError: false }))
    }


    return valid;
}

export type DepartmentFormModes = "createDep" | "editDep"

interface ICreateDepartmentCard extends card {
    clickedDepartment?: IDepartement
    setClickedDepartment?: Dispatch<SetStateAction<IDepartement>>
    formMode?: DepartmentFormModes
    setFormMode?: Dispatch<SetStateAction<DepartmentFormModes>>
}

export default function CreateDepartementCard({ width, height, order, clickedDepartment, setClickedDepartment, formMode, setFormMode }: ICreateDepartmentCard) {
    const [departement, setDepartement] = useState<IDepartement>(defaultDepartment);

    const { createDepartement, updateDepartement } = useDepartements();
    const { enqueueSnackbar } = useSnackBar()
    const { employees } = useEmployees()

    const handleSubmit = () => {
        switch (formMode) {
            case "createDep":
                if (validateDepartmentForm(departement, setError)) {
                    if (departement !== undefined && departement.name !== undefined && departement.name.length > 0) {
                        createDepartement(departement);
                        setDepartement(defaultDepartment);
                    }
                }
                break;
            case "editDep":
                if (clickedDepartment && validateDepartmentForm(clickedDepartment, setError)) {
                    if (clickedDepartment !== undefined && clickedDepartment.name !== undefined && clickedDepartment.name.length > 0) {
                        updateDepartement(clickedDepartment);
                        if (setFormMode && setClickedDepartment) {
                            setFormMode("createDep");
                            setClickedDepartment(defaultDepartment);
                        }

                    }
                }
                break;
            default: //createDep as default case
                if (validateDepartmentForm(departement, setError)) {
                    if (departement !== undefined && departement.name !== undefined && departement.name.length > 0) {
                        createDepartement(departement);
                        setDepartement(defaultDepartment);
                    }
                }
        }

    }

    const [error, setError] = useState<any>({});

    return (
        <Card
            title={formMode === "editDep" ? "Abteilung bearbeiten" : 'Abteilung erstellen'}
            style={{ width, height, order }}
            fullHeightBody={true}
            right={() => <div></div>}
            cta={true}
            ctaTitle={formMode === "editDep" ? "Speichern" : "Abteilung erstellen"}
            buttonIcon={<AddRounded fontSize='small' />}
            ctaFunction={() => { handleSubmit() }}
        >
            {formMode === "editDep" ?
                <EditCreateDepartmentForm
                    employees={employees}
                    setDepartment={setClickedDepartment!}
                    department={clickedDepartment!}
                    error={error}
                    formMode={formMode}
                    setFormMode={setFormMode}
                /> :
                <EditCreateDepartmentForm
                    employees={employees}
                    setDepartment={setDepartement}
                    department={departement}
                    error={error}
                />
            }

        </Card >
    )
}