import { useState } from "react";
import { IDocument } from "../../Contexts/DocumentContext/DocumentContext";
import { Collapse, Tooltip } from '@mui/material'
import MoreOptionsButton from '../../Components/Buttons/MoreOptionsButton/MoreOptionsButton'
import { ContentPaste, Download, ExpandLessRounded, ExpandMoreRounded, PersonOutline, SaveAlt, SnoozeOutlined, WorkHistoryOutlined, WorkOffOutlined, WorkOutline } from '@mui/icons-material'
import { formatBytes } from "../../utils/helper";

const DocumentHeaderRow = () => {

    const [expanded, setExpanded] = useState<boolean>(false);

    const options = [
        {
            text: "Bearbeiten",
            function: () => { }
        },
        {
            text: 'Pausen anzeigen',
            function: () => { }
        },
        {
            text: "Löschen",
            function: () => { }
        }
    ]


    return (
        <div style={{ borderWidth: "1px", borderColor: "#f2f2f2", borderStyle: "solid", borderRadius: "8px" }} key={/*id*/ 1}>
            <div  style={{ width: "100%", display: "flex", flexDirection: "row", padding: "1%", alignItems: "center", borderBottom: expanded ? "1px solid #e6e6e6" : "" }}>
                <div onClick={() => { }/*setExpanded(!expanded)*/} style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ width: "80%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/*expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />*/}
                        <Tooltip title="Mitarbeiter" placement="bottom">
                            <ContentPaste style={{ marginRight: "0.5vh" }} />
                        </Tooltip>
                        <span>
                            Dateiname
                        </span>
                    </div>
                    <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title="Datum" placement="bottom">
                            <SaveAlt />
                        </Tooltip>
                        <span>
                            Dateigröße
                        </span>
                    </div>
                </div>
                <div style={{ width: "10%", marginLeft: "auto", marginRight: "1vw" }}>
                </div>
            </div>
        </div >
    )
}

export default DocumentHeaderRow