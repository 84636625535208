import { AddRounded } from '@mui/icons-material'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { VariableSizeList } from 'react-window'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import Card from '../Card/Card'
import Company from '../Company/Company'
import Filter, { IFilterOption } from '../Filter/Filter'
import { PhoneCardModes } from '../PhoneCard/PhoneCard'
import { blankcard } from '../ServiceCard/ServiceCard'
import { contactFormModes } from './ContactFormCard'
import { IJournalFilter } from '../../Pages/Communication/EmailPage'

interface CompanyRegister extends blankcard {
  setClickedCompany?: Dispatch<SetStateAction<any>>
  journalFilter?: IJournalFilter
  setJournalFilter?: Dispatch<SetStateAction<IJournalFilter>>
  setFormMode?: Dispatch<SetStateAction<contactFormModes>>
  setPhoneCardMode?: Dispatch<SetStateAction<PhoneCardModes>>
}

const CompanyRegister = ({ width, height, order, setClickedCompany, journalFilter, setJournalFilter, cta, ctaTitle, setFormMode, setPhoneCardMode }: CompanyRegister) => {

  //companys werden aus "useContacts geholt"
  const { companies } = useContacts()
  const { changeVisibility } = useModals();

  const ctaFunction = () => {
    changeVisibility!("editCreateCompany", true, { props: { mode: "create" } })
  }

  const [filteredCompanies, setFilteredCompanies] = useState(companies);

  const [expandedElement, setExpandedElement] = useState<number | undefined>(undefined);

  const renderRow = ({ index, style }: any) => {
    const item = filteredCompanies[index];
    return <div style={{ ...style, backgroundColor: (index ?? 2) % 2 == 0 ? "transparent" : "var(--ultra-light)" }}>
      <Company expanded={expandedElement === index} 
      setExpandedElement={setExpandedElement} 
      setClickedCompany={setClickedCompany} 
      setJournalFilter={setJournalFilter} 
      journalFilter={journalFilter} 
      company={item} 
      setFormMode={setFormMode} 
      setPhoneCardMode={setPhoneCardMode} 
      index={index} />
    </div>;
  };

  const getItemSize = (index: number) => index === expandedElement ? 200 : 50;

  const VariableListRef = useRef<any>();

  //wäre gut, wenn neben der Firma eine Anzahl der zugehörigen Personen angezeigt wird
  const Personencount = 0;

  useEffect(() => {
    VariableListRef.current.resetAfterIndex(0)
  }, [expandedElement, filteredCompanies])

  const filterOptions: IFilterOption[] = [
    {title: "Name", valueKey: "name"},
    {title: "UID", valueKey: "uid"},
    {title: "Steuernummer", valueKey: "taxNumber"}
  ]

  
  return (
    //Die Firmen werden aufklappbar auf der Card dargestellt.
    <Card
      title='Organisationen'
      style={{ width: "100%", height, order }}
      cta={cta}
      ctaTitle={ctaTitle}
      ctaFunction={ctaFunction}
      bodyPadding={false}
      buttonIcon={<AddRounded fontSize= 'small' />} //unten auf der Card das Plus mit dem CTA "Organisation erstellen"
      right={() => <Filter 
        dataToFilter={filteredCompanies} 
      setDataToFilter={setFilteredCompanies} 
      filterOptions={filterOptions} 
      originalData={companies} 
      anchorOriginHorizontal='right' 
      anchorOriginVertical='bottom' 
      transformOriginVertical='top' 
      transformOriginalHorizontal='center'
      />
    }
    >
      <div style={{ width: "100%" }}>
        <VariableSizeList /*Sorgt dafür, dass nur die 30 Unternehmen auf einem Bildschirm angezeigt werden, nicht mehr! */
          height={1080}
          itemCount={filteredCompanies.length}
          itemSize={getItemSize}
          width="100%"
          ref={VariableListRef}



          style={{
            overflow: 'auto',
            scrollbarWidth: 'none', // Firefox-specific
            //'-ms-overflow-style': 'none', // IE/Edge-specific
            
          }}
        >
          
          {renderRow}
          
        </VariableSizeList>
       
      </div>
      
    </Card>
  )

}

export default CompanyRegister