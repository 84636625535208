import { Dispatch, SetStateAction, useState } from "react"
import { IHoliday, defaultHoliday, useTimeTracking } from "../../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import Modal from "../../Dialog/Dialog"
import CreateHolidayRequestForm from "../../Forms/HolidayRequest/CreateHolidayRequestForm"
import YesNoDialog from "../YesNoDialog/YesNoDialog"
import { AxiosResponse } from "axios"
import { useSnackBar } from "../../../Hooks/useSnackBar"
import useAuthConnection from "../../../Hooks/useAuthConnection"

interface ICreateHolidayRequestModal {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    mode?: string
    holiday?: IHoliday
}

function CreateHolidayRequestModal({ open, setOpen, mode, holiday }: ICreateHolidayRequestModal) {

    const [holidayToSubmit, setHolidayToSubmit] = useState<IHoliday>(defaultHoliday);
    const { enqueueSnackbar, closeSnackbar } = useSnackBar()
    const connection = useAuthConnection();
    const {createHolidayRequest} = useTimeTracking();

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handleSubmit = async () => {
        createHolidayRequest!(holidayToSubmit).then(() => {
            setOpen(false);
            setOpenConfirmModal(true);
        })
    }

    return (
        <>
            <YesNoDialog open={openConfirmModal} onClose={() => { setOpenConfirmModal(false) }} object={{ modalTitle: "Urlaubsantrag gestellt", modalText: "Der Urlaubsantrag wurde erfolgreich gestellt. Der von Ihnen zugewiesene Mitarbeiter hat eine Benachrichtung per Email über Ihren Antrag erhalten." }} />

            <Modal
                open={open}
                title="Urlaub beantragen"
                onClose={() => { setOpen(false) }}
            >

                <CreateHolidayRequestForm holiday={holidayToSubmit} setHoliday={setHolidayToSubmit} submitFunction={handleSubmit} />

            </Modal>
        </>
    )
}

export default CreateHolidayRequestModal