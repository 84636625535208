import { Dispatch, SetStateAction } from "react";
import { IHoliday } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { IAppointment } from "../../Interfaces/Appointment";
import { ITask } from "../../Interfaces/Task";
import Day from "./CalendarDay";
import { Dayjs } from "dayjs";

interface weekProps {
    week?: Dayjs[];
    selectedView?: string;
    showAppointmentInfoModal?: boolean;
    setShowAppointmentInfoModal?: Dispatch<SetStateAction<boolean>>;
    setClickedAppointment?: Dispatch<SetStateAction<IAppointment>>;
    showTaskInfoModal?: boolean;
    setShowTaskInfoModal?: Dispatch<SetStateAction<boolean>>;
    setClickedTask?: Dispatch<SetStateAction<ITask>>;
    showHolidayInfoModal?: boolean;
    setShowHolidayInfoModal?: Dispatch<SetStateAction<boolean>>;
    setClickedHoliday?: Dispatch<SetStateAction<IHoliday>>;
    privateCheckedCategories?: { [categoryId: number]: boolean };
    organizationCheckedCategories?: { [categoryId: number]: boolean };
}

export default function Week({
    week,
    selectedView,
    showAppointmentInfoModal,
    setShowAppointmentInfoModal,
    setClickedAppointment,
    showTaskInfoModal,
    setShowTaskInfoModal,
    setClickedTask,
    showHolidayInfoModal,
    setShowHolidayInfoModal,
    setClickedHoliday,
    privateCheckedCategories,
    organizationCheckedCategories,
}: weekProps) {
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gridAutoRows: "minmax(100px, auto)", // Ensure rows can expand to fit content
                height: "100%",
                width: "100%",
            }}
        >
            {week && week.map((day, idx: number) => (
                <Day
                    key={idx}
                    day={day}
                    rowIndex={0}
                    monthRows={1}
                    colIndex={idx}
                    selectedView={selectedView}
                    setShowAppointmentInfoModal={setShowAppointmentInfoModal}
                    setClickedAppointment={setClickedAppointment}
                    showTaskInfoModal={showTaskInfoModal}
                    setShowTaskInfoModal={setShowTaskInfoModal}
                    setClickedTask={setClickedTask}
                    setClickedHoliday={setClickedHoliday}
                    showHolidayInfoModal={showHolidayInfoModal}
                    setShowHolidayInfoModal={setShowHolidayInfoModal}
                    privateCheckedCategories={privateCheckedCategories}
                    organizationCheckedCategories={organizationCheckedCategories}
                />
            ))}
        </div>
    );
}
