export function calculateContrastColor(hex: string): string {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }

    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);

    // Calculate relative luminance
    const calculateRelativeLuminance = (channelValue: number) => {
        const sRGBChannelValue = channelValue / 255;
        return sRGBChannelValue <= 0.03928
            ? sRGBChannelValue / 12.92
            : Math.pow((sRGBChannelValue + 0.055) / 1.055, 2.4);
    };

    const relativeLuminance =
        0.2126 * calculateRelativeLuminance(r) +
        0.7152 * calculateRelativeLuminance(g) +
        0.0722 * calculateRelativeLuminance(b);

    // Calculate contrast ratio
    const contrastRatio = (relativeLuminance + 0.05) / 0.05;

    // Determine appropriate contrast color
    return contrastRatio >= 4.5 ? '#000000' : '#FFFFFF';
}