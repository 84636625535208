import { Dispatch, SetStateAction } from "react"
import { IDepartementDTO, defaultDepartment } from "../../Contexts/DepartementContext/DepartementProvider"
import { IEmployee } from "../../Contexts/EmployeeContext/EmployeeProvider"
import CTAButton from "../Buttons/CTAButton/CTAButton"
import FormRow from "../Forms/FormUtils/FormRow"
import InputField from "../InputField/InputField"
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored"
import { DepartmentFormModes } from "./CreateDepartementCard"

interface IEditCreateDepartmentForm {
    department: IDepartementDTO
    setDepartment: Dispatch<SetStateAction<IDepartementDTO>>
    employees: IEmployee[]
    error: any
    formMode?: DepartmentFormModes
    setFormMode?: Dispatch<SetStateAction<DepartmentFormModes>>
}


const EditCreateDepartmentForm = ({ department, setDepartment, employees, error, formMode, setFormMode}: IEditCreateDepartmentForm) => {
    const handleReset = () => {
        setDepartment(defaultDepartment);

        if(setFormMode) {
            setFormMode("createDep");
        }

    }

    return (
        <form className='form'>
            <div className='form-row-label'>
                <FormRow
                    label="Abteilungsname"
                    onChange={(val) => setDepartment((prev: IDepartementDTO | any) => ({ ...prev, name: val }))}
                    value={department?.name ?? ""}
                    type="text"
                    placeholder="Abteilungsname"
                    error={error?.nameError}
                    errorMessage={InputFieldErrorMessages.NameRequired}
                />
            </div>
            <div className='form-row-label'>
                <span className="label">
                    Beschreibung:
                </span>
                <InputField
                    type="string"
                    placeholder='Abteilungsbeschreibung'
                    value={department?.description  ?? ""}
                    multiline
                    onChangeHandler={(val) => setDepartment((prev: IDepartementDTO | any) => ({ ...prev, description: val }))}
                />
            </div>
            <div className='form-row-label'>
                <span className="label">
                    EMail:
                </span>
                <InputField
                    type="string"
                    placeholder='abteilung@ecockpit.at'
                    value={department?.eMail  ?? ""}
                    onChangeHandler={(val) => setDepartment((prev: IDepartementDTO | any) => ({ ...prev, eMail: val }))}
                />
            </div>
            <div>
                <FormRow
                    type='select'
                    label="Mitarbeiter*innen"
                    placeholder='Mitarbeiter*innen auswählen'
                    value={department?.userIds ?? []}
                    onChange={(val) => setDepartment((prev: any) => ({ ...prev, userIds: val }))}
                    selectOptions={{
                        //Legt den Titel-Key des aktiven Objekts fest -> Wert wird in das Feld eingefügt.
                        titleKey: "username",
                        //Legt den Wert-Key des aktiven Objekts fest -> Wert wird demnach an den onChange-Handler übergeben.
                        valueKey: "id",
                        options: employees,
                        multiple: true
                    }}
                />
            </div>
            <div style={{ marginBottom: "10px" }}></div>
            <CTAButton
                title={"Zurücksetzen"}
                onClickHandler={() => handleReset()}
                secondary={false}
            />
        </form>
    )
}

export default EditCreateDepartmentForm