import { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import Modal from '../../Dialog/Dialog'
import TravelForm from '../../Forms/CreateTravelForm'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import { defaultTravel, ITravel } from '../../../Interfaces/Travel'
import { useEditTravelMutation, usePostTravelMutation } from '../../../redux/apiSlices/Travel'
import { useSnackbar } from 'notistack'
import { useTravelMutationNotifications } from './TravelDialog.hooks'




function CreateTravelDialog({ open, onClose, object, mode }: dialogWithProps<ITravel>) {

  const { changeVisibility } = useModals();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  //* Management der Daten zum Submitten an die Datenbank
  const [travelToSubmit, setTravelToSubmit] = useState<ITravel>({
    ...(object ?? defaultTravel)
  })

  const [postTravel, { isSuccess: postTravelSuccess, isError: postTravelError, isLoading: postTravelIsLoading }] = usePostTravelMutation();
  const [editTravel, { isSuccess: editTravelSuccess, isError: editTravelError, isLoading: editTravelIsLoading }] = useEditTravelMutation();


  //Create Snackbars with success/error messages
  useTravelMutationNotifications(postTravelSuccess, postTravelError, editTravelSuccess, editTravelError);

  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (mode == "create") {
        await postTravel(travelToSubmit);

      } else {
        await editTravel(travelToSubmit as ITravel);
      }
      handleClose();
      changeVisibility!("editCreateTravel", false);
    }
    catch (error) {
    }
    finally {
      setLoading(false)
    }
  }

  const handleClose = async () => {
    setTravelToSubmit({ ...defaultTravel! });
    setLoading(false);
    if (onClose) {
      handleClose();
    }
    changeVisibility!("editCreateTravel", false);
  }

  useEffect(() => {
    setTravelToSubmit({ ...(object ?? defaultTravel) });
  }, [object])


  useEffect(() => {

  }, [postTravelSuccess, postTravelError])

  return (
    <Modal
      open={open}
      title={mode == "create" ? 'Fahrt erstellen' : "Fahrt bearbeiten"}
      onClose={handleClose}
    >
      {/* TravelForm als eigene Komp. */}
      <TravelForm
        object={travelToSubmit}
        setObject={setTravelToSubmit}
        mode={mode ?? "create"}
        submitFunction={handleSubmit}
        loading={postTravelIsLoading || editTravelIsLoading}
      />

    </Modal>
  )
}

export default CreateTravelDialog