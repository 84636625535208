import { AddRounded } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import dayjs from 'dayjs'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useState } from 'react'
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { card } from '../../Interfaces/Card'
import { IService, IServiceDTO } from '../../Interfaces/Service'
import { serviceFormModes } from '../../Pages/Services/Services'
import Card from '../Card/Card'
import CreateServiceForm from '../Forms/CreateServiceForm'

interface ICreateServiceCard extends card {
    clickedService?: IServiceDTO
    setClickedService?: Dispatch<SetStateAction<IServiceDTO>>
    formMode?: serviceFormModes
    setFormMode?: Dispatch<SetStateAction<serviceFormModes>>
}

const CreateServiceCard = ({ width, height, order, clickedService, setClickedService, formMode, setFormMode }: ICreateServiceCard) => {
    const { defaultService } = useServices()

    const [serviceToSubmit, setServiceToSubmit] = useState<IServiceDTO>({
        ...defaultService!
    })

    const { enqueueSnackbar } = useSnackbar()

    const { addService, updateService } = useServices()

    const [loading, setLoading] = useState(false)

    const resetServiceToSubmit = () => {
        setServiceToSubmit(defaultService!)
    }

    const handleAdd = async () => {
        try {
            setLoading(true);

            switch (formMode) {
                case "createService":
                    if (validateInput(serviceToSubmit)) {
                        await addService!(serviceToSubmit);
                        resetServiceToSubmit();
                    }
                    break;
                case "editService":
                    if (validateInput(clickedService!)) {
                        await updateService!(clickedService!);
                        setClickedService!(defaultService!);
                    }
                    break;
            }
        } catch (error) {
            console.log("Fehler beim erstellen", error);
            enqueueSnackbar("Fehler beim Erstellen.", { variant: "error" })
        } finally {
            setLoading(false)
        }


    }

    const getMode = (mode: serviceFormModes) => {
        switch (mode) {
            case "createService":
                return "Leistung erstellen"
            case "editService":
                return "Leistung bearbeiten"
            default:
                return "Leistung erstellen"
        }
    }

    //form error handling variables
    const [employeeError, setEmployeeError] = useState(false);
    const [customerError, setCustomerError] = useState(false);
    const [titleError, setTtitleError] = useState(false);
    const [dateStartError, setDateStartError] = useState(false);

    function validateInput(service: IServiceDTO | IService) {
        if (service.employeeDatas.length === 0) {
            setEmployeeError(true);
        } else {
            setEmployeeError(false);
        }

        if (!service.clientOrganizationId || service.clientOrganizationId <= 0) {
            setCustomerError(true);
        } else {
            setCustomerError(false);
        }

        if (service.title.length === 0) {
            setTtitleError(true);
        } else {
            setTtitleError(false);
        }

        const from = dayjs(service.employeeDatas[0].from).toDate();
        const to = dayjs(service.employeeDatas[0].to).toDate();

        if (from.getTime() > to.getTime()) {
            setDateStartError(true);
        } else {
            setDateStartError(false);
        }

        if (service.employeeDatas.length === 0 || !service.clientOrganizationId || service.clientOrganizationId <= 0 || service.title.length === 0 || from.getTime() > to.getTime()) {
            return false;
        }
        return true;
    }

    return (
        <Card
            title={getMode(formMode ?? "createService")}
            style={{ width, height, order }}
            cta
            ctaTitle={loading ? <CircularProgress size={14} />
                : formMode === "editService" || formMode === "editTravel"
                    ? "Speichern"
                    : "Erstellen"}
            buttonIcon={<AddRounded fontSize='small' />}
            ctaFunction={() => handleAdd()}
        >
            {formMode === "createService"
                ? (
                    <CreateServiceForm
                        setObject={setServiceToSubmit}
                        object={serviceToSubmit}
                        setFormMode={setFormMode}
                        errorVariables={{ employeeError, customerError, titleError, dateStartError }}
                    />
                )
                : formMode === "editService" ? (
                    <CreateServiceForm
                        setObject={setClickedService!}
                        object={clickedService!}
                        setFormMode={setFormMode}
                        errorVariables={{ employeeError, customerError, titleError, dateStartError }}
                    />
                )
                    : <></>}

        </Card>
    )
}

export default CreateServiceCard