import { useState } from "react"
import { IPause, IWorkTime, useTimeTracking } from "../../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import MoreOptionsButton from "../../Buttons/MoreOptionsButton/MoreOptionsButton"
import styles from "../PausesOverviewDialog/BillsCard.module.css"
import { useModals } from "../../../Contexts/DialogContext/DialogProvider"
import { StaffDTO, useStaff } from "../../../Contexts/StaffContext/StaffProvider"
import dayjs from "dayjs"

interface IPauseRowAdmin {
    pause: IPause
    pauses?: IPause[]
    workTime: IWorkTime
    n?: number
}



const PauseRowAdmin = ({ pause, pauses, workTime, n }: IPauseRowAdmin) => {

    const { changeVisibility } = useModals();
    const { staff } = useStaff();
    const { deletePause } = useTimeTracking();

    const now = dayjs();
    const pauseStart = dayjs(pause.start) ?? dayjs();
    const pauseEnd = dayjs(pause.end) ?? dayjs();

    let timeDifferenceMin = pauseEnd.diff(pauseStart, 'minute');
    let timeDifferenceHours = timeDifferenceMin / 60;

    let tempTimeDifferencePauseMin = 0;
    let timeDifferencePauseHours = 0;

    const [timeDifferencePauseMin, setTimeDifferencePauseMin] = useState<number>(0);

    const onPressEdit = () => {
        changeVisibility!("pausesOverviewAdmin", false)
        changeVisibility!("postscriptWorkPauseAdmin", true, { props: { object: { t: pause, staffMember: staff.find((tempStaff: StaffDTO) => (tempStaff.id === workTime?.userId)), worktimeToPause: workTime }, mode: "editpause" } })
    }

    function handleDeleteCancel() {
        changeVisibility!("pausesOverviewAdmin", true, { props: { object: workTime, mode: "admin" } });
    }

    //TODO: Fix deleting pause
    const onPressDelete = () => {
        changeVisibility!("pausesOverviewAdmin", false);
        changeVisibility!("yesNoDialog", true, { props: { object: { modalTitle: "Pause löschen", submitFunction: () => deletePause!(pause), cancelFunction: () => handleDeleteCancel(), cancelButtonText: "Abbrechen", confirmButtonText: "Löschen", modalText: "Möchten Sie diese Pause wirklich löschen?" } } });

    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    return (
        <div style={{ backgroundColor: n ? (n % 2 == 0 ? "white" : "#e9e9e9") : "white", width: "100%" }}>
            <div className={'flex column '.concat(styles.container)} style={{}}>
                <header className='flex centered-aligned'>
                    <div style={{ minWidth: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                        <div style={{ minWidth: "40%", display: "flex", justifyContent: "center" }}>
                            <span className='bold-big' style={{ whiteSpace: "nowrap" }}>{new Date(pause?.start?.toString() ?? "").toLocaleDateString("de-DE", { weekday: "short", day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" })}</span>
                        </div>
                        <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}></div>
                        <div style={{ minWidth: "40%", display: "flex", justifyContent: "center" }}>
                            <span className='bold-big' style={{}}>{pause?.end ? new Date(pause?.end?.toString() ?? "").toLocaleDateString("de-DE", { weekday: "short", day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit" }) : "Active"}</span>
                        </div>
                    </div>
                    <div style={{ width: "10%" }}>
                        <MoreOptionsButton options={options}></MoreOptionsButton>
                    </div>
                </header>
                <section className='label' style={{ gap: "10px", marginTop: "5px", width: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                        </span>
                    </div>
                    <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            Pause
                        </span>
                    </div>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                        </span>
                    </div>
                </section>
                <section className='label' style={{ gap: "10px", marginTop: "5px", width: "85%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                        </span>
                    </div>
                    <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                            {timeDifferenceMin < 60.01 ? timeDifferenceMin.toFixed(0) + " min" : (timeDifferenceHours.toFixed(0)) + " h " + (timeDifferenceMin % 60).toFixed(0) + " min"}
                        </span>
                    </div>
                    <div style={{ width: "40%", display: "flex", flexDirection: "row", justifyContent: "center", }}>
                        <span style={{ textOverflow: "ellipsis" }}>
                        </span>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default PauseRowAdmin