import { AddRounded } from "@mui/icons-material";
import { Switch, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { BillPrefix } from "../../Interfaces/Accounting";
import { IBillDTO, IBillingPosition } from "../../Interfaces/Bill";
import { IService } from "../../Interfaces/Service";
import { User } from "../../Interfaces/User";
import BillingPositionRow from "../BillingPosition/BillingPositionRow";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import Card from "../Card/Card";
import EditBillingPositionDialog from "../Dialogs/EditBillingPosition/EditBillingPositionDialog";
import FormRow from "../Forms/FormUtils/FormRow";
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored";
import MultiLineInputField from "../InputField/MultiLineInputField";
import { ITextModule, placeholderTextModule, TextModuleDocumentType, useGetDefaultTextModulesQuery } from "../../redux/apiSlices/TextModule";

export interface props {
    height?: string
    width?: string
    order?: string
    billId?: string | undefined
    billPrefixes?: Array<any> | undefined
    companies?: Array<any> | undefined
    user?: User | undefined
    object?: IBillDTO
    setObject: Dispatch<SetStateAction<any>>
    connection?: any
    isOffer?: boolean
    generatePdf: () => void
    type?: "Bill" | "Offer" | "Receipt"
    billingPositions?: Array<IBillingPosition>
    setBillingPositions?: Dispatch<SetStateAction<Array<IBillingPosition>>>
}

export const validateCreateBill = (setError: Dispatch<SetStateAction<any>>, billNumberLoading?: boolean, bill?: IBillDTO) => {
    let valid = true;

    if (bill?.prefix === undefined && bill?.isReceipt === false) {
        setError((old: any) => ({ ...old, prefixError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, prefixError: false }))
    }


    if (billNumberLoading) {
        setError((old: any) => ({ ...old, billNumberLoadingError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, billNumberLoadingError: false }))
    }

    if (bill?.billNumber === undefined && bill?.isReceipt === false) {
        setError((old: any) => ({ ...old, billNumberError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, billNumberError: false }))
    }

    /*if ((bill?.customerId === undefined || bill?.customerId < 1 || isNaN(bill?.customerId)) && (bill?.personId === undefined || bill?.personId < 1 || isNaN(bill?.personId))) {
        setError((old: any) => ({ ...old, customerError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, customerError: false }))
    }*/

    return valid;
}

// This function is used to render the BillDataCard component
export default function BillDataCard({ billingPositions, setBillingPositions, width, height, order, billId, billPrefixes, companies, user, object, setObject, connection, generatePdf, type }: props) {
    // useNavigate hook is used to navigate between different routes
    let navigate = useNavigate();

    const { data: defaultTextModules } = useGetDefaultTextModulesQuery();
    const [offerTextModule, setOfferTextModule] = useState<ITextModule>(defaultTextModules?.find(t => t.type === TextModuleDocumentType.Offer) ?? placeholderTextModule);
    const [billTextModule, setBillTextModule] = useState<ITextModule>(defaultTextModules?.find(t => t.type === TextModuleDocumentType.Bill) ?? placeholderTextModule);

    const isOffer = type === "Offer";
    const isReceipt = type === "Receipt";

    // useBills hook is used to add and update bills
    const { bills, addBill, updateBill } = useBills();

    const { persons } = useContacts();

    const [billNumberLoading, setBillnumberLoading] = useState(false);
    // updateBillnumber function is used to update the bill number
    const updateBillnumber = (val?: string) => {
        setBillnumberLoading(true);
        setObject((prev: any) => ({ ...prev, billNumber: "" }))
        connection.get(`/bill/NextBillNumberOfPrefix/${val ?? object?.prefix}`)
            .then((res: AxiosResponse) => {
                setObject((prev: any) => ({ ...prev, billNumber: res.data }));
                let prefixString = billPrefixes?.find(p => p.id === val).prefix;

                if (isOffer) {
                    navigate(`/rechnungswesen/angebote/bearbeiten/${prefixString}-${res.data}`, { replace: true });
                } else if (isReceipt) {
                    navigate(`/rechnungswesen/belege/bearbeiten/${prefixString}-${res.data}`, { replace: true })
                } else {
                    navigate(`/rechnungswesen/rechnungen/bearbeiten/${prefixString}-${res.data}`, { replace: true });
                }
                setBillnumberLoading(false);
            })
            .catch((error: any) => {
                setBillnumberLoading(false);
            });
    }

    // createBill function is used to create a new bill or update an existing bill
    const createBill = async () => {
        let result: any;

        if (validateCreateBill(setError, billNumberLoading, object)) {
            if (object) {
                if (billId === undefined) {
                    if (addBill) {
                        result = await addBill({ billingPositions: [], bill: { ...object, isOffer: isOffer, generatePDF: true, date: object.date ?? new Date(), isReceipt: false } });

                        if (isOffer) {
                            navigate(`/rechnungswesen/angebote/bearbeiten/${result.bill.billPrefix}-${result.bill.billNumber}`, { replace: true });
                        } else if (isReceipt) {
                            navigate(`/rechnungswesen/belege/bearbeiten/${result.bill.billPrefix}-${result.bill.billNumber}`, { replace: true })
                        } else {
                            navigate(`/rechnungswesen/rechnungen/bearbeiten/${result.bill.billPrefix}-${result.bill.billNumber}`, { replace: true });
                        }
                    }
                }
                else {
                    if (updateBill) {
                        result = await updateBill({ ...object, isOffer: isOffer, isReceipt: isReceipt, generatePDF: !isReceipt });
                    }
                }
            }
        }

        if (result !== null && result?.id !== null && result?.id !== 0 && !isReceipt && generatePdf) {
            generatePdf();
        }
    }

    const [filteredBillPrefixes, setFilteredBillPrefixes] = useState<Array<BillPrefix>>((billPrefixes ?? []).filter(prefix => prefix.isOffer === isOffer))


    const [error, setError] = useState<any>({})

    const [customerIsCompany, setCustomerIsCompany] = useState<boolean>((object?.personId === undefined || object.personId === null))

    const [open, setOpen] = useState<boolean>(false);
    const [openServices, setOpenServices] = useState<Array<IService>>([]);
    useEffect(() => {
        if (object !== null && object?.customerId !== null && object?.customerId! > 0) {
            getOpenServicesOfCustomerId();
        }
    }, [object?.customerId])
    const getOpenServicesOfCustomerId = () => {
        connection.get(`/service/OpenOfOrganization/${object?.customerId!}`)
            .then((res: AxiosResponse) => {
                setOpenServices(res.data);
            });
    }

    useEffect(() => {
        console.log("object", object);
    }, [object])

    return (
        <Card
            title={isOffer ? 'Angebotsdaten' : isReceipt ? 'Belegdaten' : 'Rechnungsdaten'}
            style={{ width, height, order }}
            right={() => <Typography>Gesamt: {object?.netto?.toFixed(2)} €</Typography>}
            cta={true}
            ctaTitle={isOffer ? ((!object?.isCreated) ? "Angebot speichern" : "Angebot wurde bereits erstellt keine Änderung möglich") : isReceipt ? "Beleg speichern" : "Rechnung speichern"}
            buttonIcon={<AddRounded fontSize='small' />}
            ctaFunction={createBill}
        >

            <form className='form'>
                {!isReceipt &&
                    <div className='form-row noselect'>
                        <span style={{ fontSize: "14px" }}>Firmenkunde</span>
                        <Switch color="secondary"
                            checked={!customerIsCompany}
                            onChange={(e, val) => {
                                setObject((old: any) => ({ ...old, personId: undefined, customerId: undefined }))
                                setCustomerIsCompany((oldVal) => (!oldVal))
                            }} />
                        <span style={{ fontSize: "14px" }}>Privatkunde</span>
                    </div>
                }
                <FormRow
                    label="Kunde"
                    placeholder="Kunde auswählen"
                    value={customerIsCompany ? object?.customerId : object?.personId}
                    onChange={(val) => {
                        if (customerIsCompany) {
                            setObject((prev: any) => ({ ...prev, customerId: val, personId: null }));
                        } else {
                            setObject((prev: any) => ({ ...prev, customerId: null, personId: val }));
                        }
                    }}
                    selectOptions={{
                        options: customerIsCompany ? (companies ?? []) : persons,
                        titleKey: customerIsCompany ? "name" : "completeName",
                        valueKey: "id"
                    }}
                    type="select"
                    error={error.customerError}
                    errorMessage={InputFieldErrorMessages.CustomerRequired}
                />
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <FormRow
                        disabled={!isReceipt && object?.isCreated}
                        label={isOffer ? "Angebotskreis" : isReceipt ? "Belegkreis" : "Rechnungskreis"}
                        placeholder={(isOffer ? "Angebotskreis" : isReceipt ? "Belegkreis" : "Rechnungskreis") + " auswählen"}
                        value={object?.prefix}
                        onChange={(val) => { setObject((prev: any) => ({ ...prev, prefix: val })); updateBillnumber(val); }}
                        selectOptions={{
                            options: filteredBillPrefixes,
                            titleKey: "prefix",
                            valueKey: "id"
                        }}
                        type="select"
                        error={error.prefixError}
                        errorMessage={InputFieldErrorMessages.BillPrefixRequired}
                    />
                    <FormRow
                        label={(isOffer ? "Angebots" : isReceipt ? "Belegs" : "Rechnungs") + "nummer"}
                        type="number"
                        readonly={billNumberLoading || (!isReceipt && object?.isCreated)}
                        placeholder={isOffer ? (billNumberLoading ? 'Nächste Angebotsnummer wird geladen' : 'Angebotsnummer eingeben') : (billNumberLoading ? 'Nächste Rechnungsnummer wird geladen' : 'Rechnungsnummer eingeben')}
                        onChange={(val) => { setObject((prev: any) => ({ ...prev, billNumber: parseInt(val) })) }}
                        value={object?.billNumber ?? ""}
                        error={error.billNumberError || error.billNumberLoadingError}
                        errorMessage={error.billNumberError ? InputFieldErrorMessages.BillNumberRequired : InputFieldErrorMessages.BillNumberLoadingError}
                    />
                </div>
                {/*<div className='form-row-label'>
                    <span className="label">
                        Buchhaltungskonto:
                    </span>
                    <InputField
                        type="number"
                        placeholder='Buchhaltungskonto eingeben'
                        value={object?.account ?? ""}
                        onChangeHandler={(val) => setObject((prev: any) => ({ ...prev, account: parseInt(val) }))}
                    />
                    </div>*/}
                {/* <div className='form-row-label'>
                    <span className="label">
                        Rechnungsdatum:
                    </span>
                    <DateInputField />
                </div> */}
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <FormRow
                        disabled={!isReceipt && object?.isCreated}
                        value={object?.date}
                        onChange={(val) => setObject((old: IBillDTO) => ({ ...old, date: new Date(val ?? "") }))}
                        type="date"
                        dateOptions={{
                            type: "Date"
                        }}
                        label={isOffer ? "Angebotsdatum" : "Rechnungsdatum"}
                    />
                    {!isOffer &&
                        <div className='form-row-label'>
                            <FormRow
                                label="Bezahldatum"
                                onChange={(val: any) => setObject((prev: IBillDTO) => ({ ...prev, paymentDate: val }))}
                                value={object?.paymentDate ?? ""}
                                type="date"
                                dateOptions={{
                                    disablePast: false,
                                    type: "Date"
                                }}
                            />
                        </div>
                    }
                </div>
                {!isOffer && !isReceipt &&
                    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                        <FormRow
                            value={object?.from}
                            onChange={(val) => setObject((old: IBillDTO) => ({ ...old, from: new Date(val ?? "") }))}
                            type="date"
                            dateOptions={{
                                type: "Date"
                            }}
                            label="Leistungsbeginn"
                            disabled={!isReceipt && object?.isCreated}
                        />
                        <FormRow
                            value={object?.to}
                            onChange={(val) => setObject((old: IBillDTO) => ({ ...old, to: new Date(val ?? "") }))}
                            type="date"
                            dateOptions={{
                                type: "Date"
                            }}
                            label="Leistungsende"
                            disabled={!isReceipt && object?.isCreated}
                        />
                    </div>
                }
                {/* <div className='form-row-label'>
                    <span className="label">
                        Leistungszeitraum:
                    </span>
                    <DateInputField />
                </div> */}
                {!isReceipt &&
                    <div className='form-row-label'>
                        <span className="label">
                            Text vor Gesamtübersicht:
                        </span>
                        <MultiLineInputField
                            value={!object || (!object?.isCreated && !billId) ? (isOffer ? offerTextModule.textStart! : billTextModule.textStart!) : (object?.textBefore ?? "")}
                            onChangeHandler={(val: string) => setObject((prev: any) => ({ ...prev, textBefore: val }))}
                            disabled={!isReceipt && object?.isCreated}
                        ></MultiLineInputField>
                    </div>
                }
                {!isReceipt &&
                    <div className='form-row-label'>
                        <span className="label">
                            Text nach Gesamtübersicht:
                        </span>
                        <MultiLineInputField
                            value={!object || (!object?.isCreated && !billId) ? (isOffer ? offerTextModule.textEnd : billTextModule.textEnd) ?? "" : (object?.textAfter ?? "")}
                            onChangeHandler={(val: string) => setObject((prev: any) => ({ ...prev, textAfter: val }))}
                            disabled={!isReceipt && object?.isCreated}
                        ></MultiLineInputField>
                    </div>
                }
            </form>
            <div className='form-row-label' style={{ marginTop: "1em", width: "100%" }}>
                <span className="label">
                    {isOffer ? "Angebotspositionen" : "Rechnungspositionen"}
                </span>
                <CTAButton disabled={!isReceipt && object?.isCreated} onClickHandler={() => { setOpen(true); }} title={isOffer ? "Angebotsposition hinzufügen" : "Rechnungsposition hinzufügen"} style={{ width: "40%" }}></CTAButton>
                {billingPositions &&
                    billingPositions.map((billingPosition: IBillingPosition, index: number) =>
                        <BillingPositionRow getOpenServicesOfCustomerId={getOpenServicesOfCustomerId} generatePdf={generatePdf} bill={object} billingPositions={billingPositions} backgroundColor={index % 2 === 1 ? "var(--ultra-light)" : undefined} billingPosition={billingPosition} setBillingPositions={setBillingPositions} key={index} />
                    )
                }
                {(billingPositions?.length ?? 0) === 0 &&
                    <span>Noch keine {(type === "Bill" ? "Rechnung" : (type === "Offer" ? "Angebot" : "Beleg")) + "spositionen"} erstellt</span>
                }
                <EditBillingPositionDialog isOffer={isOffer} generatePdf={generatePdf} bill={object} open={open} setOpen={setOpen} object={undefined} billingPositions={billingPositions!} setBillingPositions={setBillingPositions!} openServices={openServices} setOpenServices={setOpenServices}></EditBillingPositionDialog>
            </div>
        </Card >
    )
}