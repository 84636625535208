import { Dispatch, SetStateAction, useState } from 'react';
import Modal from '../../Components/Dialog/Dialog';
import CreateAppointmentCategoryForm from '../../Components/Forms/CreateAppointmentCategoryForm';
import { AppointmentCategoryVisibilityTypes } from '../../Contexts/TaskContext/TaskContext';
import { IAppointmentCategory, ICreateAppointmentCategory } from '../../Interfaces/Appointment';

interface modalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>,
    mode?: string
    appointmentCategory?: IAppointmentCategory,
    visibility?: AppointmentCategoryVisibilityTypes
}

const CreateAppointmentCategoryModal = ({ open, setOpen, mode, appointmentCategory, visibility }: modalProps) => {

    const [nameError, setNameError] = useState(false)
    const [visibilityError, setVisibilityError] = useState(false)

    function resetErrorVariables() {
        setNameError(false);
        setVisibilityError(false)
    }

    const validateInput = (appointmentCategory: ICreateAppointmentCategory | IAppointmentCategory) => {
        if (appointmentCategory?.name?.length === 0 || appointmentCategory?.name == null) {
            setNameError(true);
        } else {
            setNameError(false);
        }

        if (appointmentCategory?.visibility == null) {
            setVisibilityError(true)
        } else {
            setVisibilityError(false)
        }

        if (appointmentCategory?.name?.length === 0 || appointmentCategory?.name == null || appointmentCategory?.visibility == null) {
            return false;
        }

        return true;
    }
    return (
        <Modal open={open}
            title={mode === "edit" ? "Kategorie bearbeiten" : "Kategorie erstellen"}
            onClose={() => {
                setOpen(false)
                resetErrorVariables();
            }}
        >
            <CreateAppointmentCategoryForm onClose={() => {
                setOpen(false)
            }}
                errorVariables={{ nameError, visibilityError }}
                validateInput={validateInput}
                appointmentCategoryToEdit={appointmentCategory}
                mode={mode}
                visibility={visibility}
            />
        </Modal>
    )
}

export default CreateAppointmentCategoryModal;