import { Dispatch, SetStateAction } from "react";
import { INewsletterDesign, useContacts } from "../../../Contexts/ContactsContext/ContactsProvider";
import Modal from "../../Dialog/Dialog";
import FormRow from "../../Forms/FormUtils/FormRow";
import { useUser } from "../../../Contexts/UserContext/UserContext";
import { useStaff } from "../../../Contexts/StaffContext/StaffProvider";
import CTAButton from "../../Buttons/CTAButton/CTAButton";
import FileInput from "../../FileInput/FileInput";
import { Button } from "@mui/material";
import { AddBox } from "@mui/icons-material";

interface props {
    open: boolean
    object: any
    setObject: Dispatch<SetStateAction<any>>
    onClose: () => void
    onSubmit: () => void
}

const AttachmentsDialog = ({ open, onClose, onSubmit, object, setObject }: props) => {
    const { user } = useUser();
    const { staff } = useStaff();
    const { persons } = useContacts();

    return <>
        <Modal
            open={open}
            title="Anhänge"
            style={{ display: "flex", flexDirection: "column", gap: "0.75em" }}
            onClose={onClose}
        >
            <div className='form-row-label'>
                <span className="label">
                    {"Anhänge (optional)"}
                </span>
                {object?.files?.map((file: any, index: number) => {
                    return (<FileInput file={file} setFile={(newFile: any) => { setObject((old: any) => ({ ...old, files: old.files.map((file: any, i: number) => { if (i === index) { return newFile } return file }) })) }} key={index} setObject={setObject} index={index} ></FileInput>)
                })}
            </div>
            {((object?.documents && object.documents?.length && object?.files && object?.files?.length && object?.documents?.length === object?.files?.length) || (object?.files === undefined) || (object?.files?.length === 0)) &&
                <div style={{ width: "100%" }}>
                    <Button variant='outlined' component="label" style={{ marginBottom: "1vh", borderColor: "gray", color: "gray" }} onClick={() => { setObject((old: any) => ({ ...old, files: old.files ? [...old.files, {}] : [{}] })) }}>
                        <AddBox style={{ marginRight: "2px" }} />
                    </Button>
                </div>
            }
            <CTAButton title="Speichern" onClickHandler={onSubmit} />
        </Modal>
    </>
}

export default AttachmentsDialog;