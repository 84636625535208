import { TextareaAutosize } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './InputField.module.css'
import { debounce } from 'lodash';

function MultiLineInputField({ disabled, value, onChangeHandler, placeholder }: { disabled?: boolean, value: string, onChangeHandler: (value: string) => void, placeholder?: string }) {
  //Font does not match with other (normal) InputField

  //local state, to prevent rerenders in parent components
  const [localValue, setLocalValue] = useState(value);

  //memoize debouncedChange handler, to prevent recreation on every rerender, debounce to prevent rerenders in parent components
  const debouncedChangeHandler = useCallback(debounce((val: string) => onChangeHandler(val), 300), [onChangeHandler]);

  //handle local change and parent component changes
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(e.target.value)

    debouncedChangeHandler(e.target.value);
  }

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value])

  return (
    <TextareaAutosize
      className={styles.container}
      value={localValue}
      onChange={handleChange}
      placeholder={placeholder ?? ""}
      minRows={5}
      style={{ fontFamily: "sans-serif" }}
      disabled={disabled}
    />
  )
}

export default MultiLineInputField