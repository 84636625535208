import { CircularProgress } from '@mui/material'
import React, { Dispatch, useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IPerson, IPersonDTO, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import { IOrganization, IOrganizationDTO } from '../../../Interfaces/Company'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateCompanyForm from '../../Forms/CreateCompanyForm'
import Form from '../../Forms/FormUtils/Form'
import FormRow from '../../Forms/FormUtils/FormRow'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'



const AssignToCompanyDialog = ({open, onClose, object} : dialogWithProps<IPersonDTO | IPerson>) => {

    const {updatePerson, defaultPerson} = useContacts()
    const {companies} = useContacts()
    const {enqueueSnackbar} = useSnackBar()

    const {changeVisibility} = useModals()
    
    const handleClose = () => {
        setLoading(false)
        if (onClose) {
          handleClose()
        }
        changeVisibility!("assignToCompany", false)
    }



    useEffect(() => {
        setPersonToSubmit(object ?? defaultPerson!)
    }, [object])

    const [loading, setLoading] = useState(false)

    const [personToSubmit, setPersonToSubmit] = useState<IPersonDTO | IPerson>(object ?? {...defaultPerson!} )

    const handleSubmit = async () => {
        try {
            setLoading(true)
            await updatePerson!(personToSubmit)
            changeVisibility!("assignToCompany", false)
        } catch (error) {
            enqueueSnackbar("Fehler beim Bearbeiten.", {variant: "error"})
        } finally {
            setLoading(false)
        }
    }
    
  return (
    <Modal
    open={open}
    title="Organisation zuordnen"
    onClose={() => handleClose()}
    >
        <Form>
            <span className='label'>Person:</span>
            <span style={{padding: "10px 12px", borderRadius: "var(--borderRadius)", backgroundColor: "var(--light)", width: "fit-content"}}>{personToSubmit.title} {personToSubmit.lastname} {personToSubmit.firstname}</span>
            <FormRow
            label="Organisation"
            type='select'
            value={personToSubmit.belongsToOrganizationId ?? 14}
            onChange={(val) => setPersonToSubmit((old) => ({...old, belongsToOrganizationId: val}))}
            selectOptions={{
                options: companies,
                titleKey: "name",
                valueKey: "id"
            }}
            />
        </Form>
        <CTAButton
        style={{
            marginTop: "20px",
            zIndex: 1,
        }}
        title={loading ? <CircularProgress size={18} color='inherit'/> : "Speichern"}
        onClickHandler={() => handleSubmit()}
        disabled={loading}
        />
    </Modal>
  )
}

export default AssignToCompanyDialog