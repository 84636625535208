import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Button } from "@mui/material";
import dayjs from "dayjs";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTasks } from '../../Contexts/TaskContext/TaskContext';
import styles from "./Calendar.module.css";
import { getMonth } from "./CalendarUtils";

interface smallCalendarProps {
    selectedView?: string
    setWeekIndex?: Dispatch<SetStateAction<number>>
}

export default function SmallCalendar({ selectedView, setWeekIndex }: smallCalendarProps) {
    const [currentMonthIdx, setCurrentMonthIdx] = useState(dayjs().month());
    const [currentMonth, setCurrentMonth] = useState(getMonth())



    const { monthIndex, setSmallCalendarMonth, selectedDay, setSelectedDay } = useTasks();

    useEffect(() => {
        setCurrentMonthIdx(monthIndex);
    }, [monthIndex])

    useEffect(() => {
        setCurrentMonth(getMonth(currentMonthIdx));
    }, [currentMonthIdx])

    function handlePrevMonth() {
        setCurrentMonthIdx(currentMonthIdx - 1);
    }

    function handleNextMonth() {
        setCurrentMonthIdx(currentMonthIdx + 1)
    }

    function getDayClass(day: dayjs.Dayjs) {
        const format = "DD-MM-YY";
        const currentDay = dayjs().format(format);
        const givenDay = day.format(format)
        const clickedDay = selectedDay && selectedDay.format(format)

        let baseStyle;

        //if day is not part of currently displayed month make the text gray
        if (day.format('MM-YY') !== dayjs(new Date(dayjs().year(), currentMonthIdx)).format('MM-YY')) {
            baseStyle = {
                backgroundColor: 'white',
                color: 'lightgrey',
                border: '0px',
                cursor: 'pointer',
                borderRadius: '100%',
            }
        } else {
            baseStyle = {
                backgroundColor: 'white',
                color: 'black',
                border: '0px',
                cursor: 'pointer',
                borderRadius: '100%',
            }
        }

        //Change styling depending on which day in the small calendar was clicked
        if (currentDay === givenDay) {
            //Styling for the case when the current day gets selected
            if (currentDay === clickedDay) {
                return {
                    ...baseStyle,
                    backgroundColor: 'var(--brand)',
                    color: "white",
                    fontWeight: '750',
                }
            } else {
                //Base styling for current day
                return {
                    ...baseStyle,
                    backgroundColor: 'var(--brand)',
                    color: "white",
                }
            }
        } else if (givenDay === clickedDay) {
            //Styling when a day gets clicked in the calendar, which is not the current day
            return {
                ...baseStyle,
                backgroundColor: 'var(--brand-selected)',
                fontWeight: '700',
            }
        } else {
            //Base style for a non selected day in the small calendar
            return baseStyle
        }
    }

    return (
        <div style={{ marginTop: "1rem" }}>
            <header style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                <p style={{ color: 'var(--secondary)', fontWeight: '700' }}>
                    {dayjs(new Date(dayjs().year(), currentMonthIdx)).format("MMMM YYYY")}
                </p>
                <div>
                    <Button size="small" variant="text"
                        style={{ maxWidth: '2em', minWidth: '2em', maxHeight: '2em', minHeight: '2em', marginLeft: "0rem", marginRight: "0rem" }}
                        onClick={handlePrevMonth}>
                        <ChevronLeftRoundedIcon fontSize="small" style={{ color: 'var(--brand)' }} />
                    </Button>

                    <Button size="small" variant="text"
                        style={{ maxWidth: '2em', minWidth: '2em', maxHeight: '2em', minHeight: '2em', marginLeft: "0rem", marginRight: "auto" }}
                        onClick={handleNextMonth}>
                        <ChevronRightRoundedIcon fontSize="small" style={{ color: 'var(--brand)' }} />
                    </Button>
                </div>
            </header>

            <div className={styles.smallCalendar}>
                <span style={{ background: 'whitesmoke', paddingTop: "0.25rem", paddingBottom: "0.25rem", fontSize: "0.875rem", lineHeight: "1.25rem", textAlign: "center", borderRight: '1px solid lightgray' }}>
                    KW
                </span>
                {/*Display the first two letters of each day at the top of the small calendar in the sidebar */}
                {currentMonth[0].map((day, i) => (
                    <span key={i} style={{ paddingTop: "0.25rem", paddingBottom: "0.25rem", fontSize: "0.875rem", lineHeight: "1.25rem", textAlign: "center" }}>
                        {day.format('dd')}
                    </span>
                ))}

                {currentMonth.map((row, i) => (
                    <React.Fragment key={i}>
                        <span key={"displayedWeek" + i} style={{ background: 'whitesmoke', paddingTop: "0.25rem", paddingBottom: "0.25rem", fontSize: "0.875rem", lineHeight: "1.25rem", textAlign: "center", borderRight: '1px solid lightgray' }}>
                            {row[0].week()}
                        </span>
                        {row.map((day, idx) => (
                            <button key={idx} style={getDayClass(day)} onClick={() => {

                                setSmallCalendarMonth!(currentMonthIdx)

                                setWeekIndex!(day.week())


                                setSelectedDay!(day)
                            }}>
                                <span style={{ fontSize: "0.875rem", lineHeight: "1.75rem" }}>{day.format('D')}</span>
                            </button>
                        ))}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}