import { useEffect, useRef } from "react";

function useDragger(id: string, type: string): void {

    let modal: HTMLElement | null | undefined = null;
    const isClicked = useRef<boolean>(false)

    const coords = useRef<{
        startX: number,
        startY: number,
        lastX: number,
        lastY: number
    }
    >({
        startX: 0,
        startY: 0,
        lastX: 0,
        lastY: 0
    })

    //makes component draggable
    useEffect(() => {
        const target = document.getElementById(id)


        if (!target) throw new Error("Element with given id doesnt exist")
        modal = target.parentElement?.parentElement;

        if (type == 'AppointmentInfoModal') {
            modal = target.parentElement?.parentElement;
        } else {
            modal = target.parentElement
        }

        if (!modal) throw new Error('Modal doesnt exist')

        const container = target.closest('html');
        if (!container) throw new Error("Target element must have parent")

        coords.current.startX = modal.offsetLeft
        coords.current.startY = modal.offsetTop
        coords.current.lastX = modal.offsetLeft
        coords.current.lastY = modal.offsetTop

        const onMouseDown = (e: MouseEvent) => {
            isClicked.current = true;
            coords.current.startX = e.clientX
            coords.current.startY = e.clientY
        }

        const onMouseUp = (e: MouseEvent) => {
            isClicked.current = false;
            coords.current.lastX = modal!.offsetLeft
            coords.current.lastY = modal!.offsetTop
        }

        const onMouseMove = (e: MouseEvent) => {
            if (!isClicked.current) return;

            const nextX = e.clientX - coords.current.startX + coords.current.lastX
            const nextY = e.clientY - coords.current.startY + coords.current.lastY

            modal!.style.top = `${nextY}px`;
            modal!.style.left = `${nextX}px`;
        }

        target.addEventListener('mousedown', onMouseDown)
        target.addEventListener('mouseup', onMouseUp)
        container.addEventListener('mousemove', onMouseMove)
        container.addEventListener('mouseleave', onMouseUp)

        const cleanup = () => {
            target.removeEventListener('mousedown', onMouseDown)
            target.removeEventListener('mouseup', onMouseUp)
            container.removeEventListener('mousemove', onMouseMove)
            container.removeEventListener('mousemove', onMouseUp)
        }

        return cleanup;
    }, [id])
}

export default useDragger;