import { useLayoutEffect, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

type refTarget = HTMLElement | React.RefObject<HTMLElement> | null

interface useSize {
  target: refTarget
}

interface useSizeWithCallback extends useSize {
  callback: (target: ResizeObserverEntry) => void
}


export const useSizeWithCallback = ({target, callback} : useSizeWithCallback) => {
  const [size, setSize] = useState(null)

   useResizeObserver(target, (entry) => callback(entry))

}


/* export const useSize = ({target} : useSize) => {
  const [size, setSize] = useState<refTarget | undefined>(undefined)

  useLayoutEffect(() => {
    setSize(target?.current)
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
} */