import React, { ReactNode, useContext, useEffect, useReducer } from 'react'
import { createContext } from 'react'
import ContactsReducer from './ContactsReducer'
import { useUser } from '../UserContext/UserContext'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { generateId } from '../../Components/Forms/PersonContactDataDynamicForm'
import { AxiosError, AxiosResponse } from 'axios'
import { errorHandler } from '../../Connection/BaseConnection'
import { IBankInformation, IOrganization, IOrganizationDTO } from '../../Interfaces/Company'
import { IAddress } from '../../Interfaces/Address'
import { IDocument } from '../DocumentContext/DocumentContext'
import { autoHideDurationDefault } from '../../Global/Variables'
import { resourceLimits } from 'worker_threads'
import { Dayjs } from 'dayjs'

export interface ITag {
  id?: number
  title?: string
  description?: string
  createdAt?: Date
  organizationId?: number
}

export interface ITagDTO extends ITag {
  countPersons?: number
  countOrganizations?: number
}

export interface IContacts {

}

export interface IPerson extends IPersonDTO {
  id: number
}

export enum ContactDataType {
  phone = 1,
  mail = 2,
  website = 3
}

export interface IContactData {
  id: number,
  type: ContactDataType,
  value: string
}

export interface IContactDTO {
  type: ContactDataType,
  value: string,
  personId: number
}

export interface IContact extends IContactDTO {
  id: number

}

export interface IPersonDTO {
  id?: number
  firstname?: string
  lastname?: string
  completeName?: string
  salutation?: string
  title?: string
  birthdate?: string | null
  entryDate?: string | null
  passing?: Dayjs
  note?: string
  organizationId?: number
  status?: number
  belongsToOrganizationId?: number
  contactData?: Array<IContactData>
  tagIds?: Array<number>
  tags?: Array<ITag>
  tagsString?: string
  address?: IAddress
  bank?: IBankInformation
  role?: string
  identificationNumber?: string
  identificationType?: string
  identificationAdministration?: string
  documents?: Array<File>
  profilePictureBase64?: string
  profilePictureDocumentId?: number
}

export interface IContactsDTO {

}

export interface INewsletterDesign {
  id?: number
  organizationId?: number
  design?: string
  html?: string
  authorId?: number
  title?: string
  created?: Date
  lastEdited?: Date
  sent?: boolean
  files?: Array<File>
  documents?: Array<IDocument>
  documentIds?: Array<number>
}

export interface INewsletterQueueItem {
  id?: number
  newsletterSentId?: number
  newsletterDesignId?: number
  scheduledFor?: Date
}

export interface IScheduleNewsletterDesign extends INewsletterDesign {
  scheduledFor?: Date
  tagIds?: Array<number>
}

export interface INewsletterSent {
  id?: number
  organizationId?: number
  design?: string
  html?: string
  recipientCount?: number
  authorId?: number
  date?: Date
  newsletterDesignId?: number
}

interface IContactsContext {
  persons: IPerson[]
  defaultPerson: IPerson
  fetchPersons?: () => any
  addPerson?: (person: IPersonDTO) => Promise<void>
  updatePerson?: (person: IPersonDTO) => Promise<void>
  deletePerson?: (person: IPersonDTO) => Promise<void>
  defaultContact?: IContactDTO
  addContact?: (contactData: IContactDTO[]) => Promise<void>
  getPersonByID?: (id: number) => IPerson | undefined
  setProfilePicture?: (formData: FormData, id: number) => Promise<string>
  getProfilePicture?: (personId: number) => Promise<string>

  companies: IOrganization[]
  ownCompany?: IOrganization
  createCompany?: (company: IOrganizationDTO) => Promise<void>
  getCompanyByID?: (id: number) => IOrganization | undefined
  editCompany?: (company: IOrganization) => Promise<void>
  deleteCompany?: (company: IOrganization) => Promise<void>
  fetchCompanies?: () => Promise<void>

  tags: Array<ITagDTO>
  addTag?: (tag: ITag) => void
  editTag?: (tag: ITag) => void
  removeTag?: (tag: ITag) => void
  addTagToOrganizations?: (tag: ITag, organizations: Array<IOrganization>) => void
  addTagToOrganizationsViaId?: (tag: ITag, organizations: number[]) => void
  removeTagFromOrganizations?: (tag: ITag, organizations: Array<IOrganization>) => void
  removeTagFromOrganizationsViaId?: (tag: ITag, organizations: number[]) => void
  addTagToPersons?: (tag: ITag, persons: Array<IPerson>) => void
  addTagToPersonsViaId?: (tag: ITag, personIds: number[]) => void
  addAllPersonsFromOrganizationToTag?: (tag: ITag, organizations: number[]) => void
  removeTagFromPersons?: (tag: ITag, persons: Array<IPerson>) => void
  removeTagFromPersonsViaId?: (tag: ITag, persons: number[]) => void
  downloadTagCSV?: (tag: ITag) => void

  newsletterDrafts?: Array<INewsletterDesign>
  newsletterSent?: Array<INewsletterSent>
  newsletterQueue?: Array<INewsletterQueueItem>
  addNewsletterDraft?: (draft: INewsletterDesign) => Promise<any>
  updateNewsletterDraft?: (draft: INewsletterDesign) => Promise<void>
  sentNewsletter?: (draft: INewsletterDesign) => Promise<void>
  copyNewsletterDraft?: (scheduleDraft: INewsletterDesign) => Promise<any>
  scheduleNewsletter?: (scheduleDraft: IScheduleNewsletterDesign) => Promise<void>
}

const ContactsContext = createContext<IContactsContext>({
  persons: [],
  companies: [],
  tags: [],
  defaultPerson: {
    id: 0,
    firstname: "",
    lastname: "",
    organizationId: 14,
    note: "",
    title: "",
    status: 1,
    birthdate: null,
    contactData: [{ id: generateId(), type: 1, value: "" }, { id: generateId(), type: 2, value: "" }, { id: generateId(), type: 3, value: "" }],
    identificationAdministration: "",
    identificationNumber: "",
    identificationType: ""
  }
})

export const defaultCompany: IOrganization = {
  id: 0,
  name: "",
  court: "",
  uid: "",
  status: 1,
  telephone: "",
  eMail: "",
  slogan: "",
  website: "",
  taxNumber: "",
  kleinunternehmer: false,
  pauschaliert: false,
  logoRectangle: null,
  logoSquare: null,
  eCockpitOrganizationId: null,
  subscribedSince: null,
  address: undefined,
  bank: undefined
}

export const defaultTag: ITag = {
  title: "",
  description: ""
}

const ContactsProvider = ({ children }: { children: ReactNode }) => {

  const { user } = useUser()

  const connection = useAuthConnection()

  const { enqueueSnackbar, closeSnackbar } = useSnackBar()

  const defaultPerson: IPerson = {
    id: 0,
    firstname: "",
    lastname: "",
    organizationId: user?.organizationId ?? 14,
    note: "",
    title: "",
    status: 1,
    birthdate: null,
    contactData: [{ id: generateId(), type: 1, value: "" }, { id: generateId(), type: 2, value: "" }, { id: generateId(), type: 3, value: "" }],
    tags: [],
    tagIds: []
  }

  const defaultContact: IContactDTO = {
    type: 1,
    value: "",
    personId: user?.userId ?? 0
  }

  const [state, dispatch] = useReducer(ContactsReducer, {
    persons: [],
    contacts: [],
    companies: [],
    tags: [],
    newsletterDrafts: [],
    newsletterSent: [],
    newsletterQueue: []
  })

  let fetchingPersons = false;

  const fetchPersons = async () => {
    if (state.persons.length === 0 && fetchingPersons === false) {
      fetchingPersons = true;
      connection.get("/person/").then((res: AxiosResponse) => {
        let persons = res.data;

        persons = persons.map((person: IPersonDTO) => {
          person.tagsString = "";

          if (person.tags) {

            person.tags?.forEach((tag: ITag, index: number) => {
              person.tagsString += tag.title + ((index + 1) === person.tags?.length ? "" : ", ");
            })
          }


          return person
        })

        dispatch({
          type: "SET_PERSONS",
          payload: res.data
        })
      }).catch(() => {
        /*setTimeout(() => {
          fetchPersons();
        }, 2000)*/
      })
    }
  }

  const addPerson = async (person: IPersonDTO) => {
    let x = enqueueSnackbar("Kontakt wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

    if (!person.entryDate) {
      const currentDate = new Date();
      person.entryDate = currentDate.toISOString();
    }

    connection.post("/person/create", { person: person, personContactData: person.contactData })
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Kontakt erfolgreich erstellt", { variant: "success" })

        dispatch({
          type: "ADD_PERSON",
          payload: res.data
        })
      })
      .catch((error: any) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar)
      })
  }

  const setProfilePicture = async (formData: FormData, id: number) => {
    return new Promise<string>(function (resolve, reject) {
      let x = enqueueSnackbar("Profilbild wird hochgeladen", { variant: "default", autoHideDuration: 10000 })

      connection.post(`/person/profilepicture/${id}`, formData)
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Profilbild erfolgreich hochgeladen", { variant: "success" })

          let base64: string = res.data;

          dispatch({
            type: "ADD_PERSON_PROFILE_PICTURE",
            payload: { base64: base64, id: id }
          })
          resolve(base64);
        })
        .catch((error: any) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
          reject("error")
        })
    });
  }

  const getProfilePicture = async (personId: number): Promise<string> => {
    try {
      const response: AxiosResponse = await connection.get(`/person/profilePic/${personId}`);
      const base64: string = response.data;

      dispatch({
        type: "ADD_PERSON_PROFILE_PICTURE",
        payload: { base64: base64, id: personId }
      });

      return base64;
    } catch (error: any) {
      errorHandler(error, "", enqueueSnackbar, closeSnackbar);
      throw error; // Rethrow the error if needed
    }
  };

  const updatePerson = async (person: IPersonDTO) => {
    let x = enqueueSnackbar("Kontakt wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.put("/person/update", { person: person, personContactData: person.contactData })
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Kontakt erfolgreich bearbeitet", { variant: "success" })

        dispatch({
          type: "UPDATE_PERSON",
          payload: res.data
        })
      })
      .catch((error: any) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar)
      })
  }

  const deletePerson = async (person: IPersonDTO) => {
    let x = enqueueSnackbar("Kontakt wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.delete("/person/delete", { data: { ...person } })
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Kontakt erfolgreich erstellt", { variant: "success" })

        dispatch({
          type: "DELETE_PERSON",
          payload: person
        })
      })
      .catch((error: any) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar)
      })
  }


  const addContact = async (contactData: IContactDTO[]) => {
    const { data } = await connection.post("/contact", contactData);

    dispatch({
      type: "ADD_CONTACT",
      payload: data
    });

    //enqueueSnackbar("Kontaktdaten wurden erfolgreich hinzugefügt", { variant: "success" });
  }

  const getPersonByID = (id: number): IPerson | undefined => {
    return state.persons.find((person: IPerson) => person.id === id);
  }

  //Funktion zum Zusammenfügen der ID und den Unternehmensdaten
  const getCompanyByID = (id: number): IOrganization | undefined => {
    return state.companies.find((company: IOrganization) => company.id === id)
  }

  //Firma in den State einfügen
  const createCompany = async (company: IOrganizationDTO) => {
    let x = enqueueSnackbar("Organisation wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.post("/organization/createorganization", { ...company })
      .then((res: AxiosResponse) => {
        dispatch({
          type: "CREATE_COMPANY",
          payload: res.data
        })
        const { data } = res;


        if (res.status >= 200 && res.status < 300) {
          closeSnackbar(x);
          enqueueSnackbar("Firma erfolgreich erstellt.", { variant: "success" })
        } else {
          throw new AxiosError();
        }

        return data;
      })
      .catch((error: any) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);


          if (error.response.status !== 401) {
            enqueueSnackbar("Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.", { variant: "error" })
          } else {
            //Wenn Passwort oder Username falsch
            enqueueSnackbar("Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.", { variant: "warning" })
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js

          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        } else {
          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        }
      })
  }

  const editCompany = async (company: IOrganization) => {
    let x = enqueueSnackbar("Organisation wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.put("/organization/UpdateOrganization", { ...company })
      .then((res: AxiosResponse) => {
        const { data } = res;
        dispatch({
          type: "EDIT_COMPANY",
          payload: res.data
        })

        closeSnackbar(x);
        enqueueSnackbar("Firma erfolgreich bearbeitet.", { variant: "success" })

        return data;
      })
      .catch((error: any) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);


          if (error.response.status !== 401) {
            enqueueSnackbar("Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.", { variant: "error" })
          } else {
            //Wenn Passwort oder Username falsch
            enqueueSnackbar("Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.", { variant: "warning" })
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js

          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        } else {
          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        }
      })
  }

  const deleteCompany = async (company: IOrganization) => {
    let x = enqueueSnackbar("Organisation wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.delete(`/organization/deleteorganization`, { data: { ...company } })
      .then((res: AxiosResponse) => {
        dispatch({
          type: "DELETE_COMPANY",
          payload: company
        })
        const { data } = res;

        closeSnackbar(x);
        enqueueSnackbar("Firma erfolgreich entfernt.", { variant: "success" })

        return data;
      })
      .catch((error: any) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);


          if (error.response.status !== 401) {
            enqueueSnackbar("Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.", { variant: "error" })
          } else {
            //Wenn Passwort oder Username falsch
            enqueueSnackbar("Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.", { variant: "warning" })
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js

          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        } else {
          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        }
      })
  }

  //liefert nun alle Companies zurück
  const fetchCompanies = async () => {
    if (state.companies.length == 0) {

      const { data } = await connection.get("/organization/")
      let companies = data;

      dispatch({
        type: "SET_COMPANIES",
        payload: data
      })
    }
  }

  const fetchTags = () => {
    connection.get("/tags")
      .then((res: AxiosResponse) => {
        dispatch({
          type: "SET_TAGS",
          payload: res.data
        })
      })
  }

  const addTagToOrganizations = (tag: ITag, organizations: Array<IOrganization>) => {
    let x = enqueueSnackbar("Tags werden den Organisationen hinzugefügt", { autoHideDuration: autoHideDurationDefault })

    for (let i = 0; i < organizations.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString())
      //@ts-ignore
      organizations.slice(i, i + 50)?.forEach((organization: IOrganization) => {
        params.append("organizationId", organization.id!.toString());
      })

      connection.post("/tags/organizationhastag", null, {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich hinzugefügt", { variant: "success" })

          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countOrganizations: ((Tag.countOrganizations ?? 0) + res.data) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "ADD_TAG_TO_COMPANIES",
            payload: {
              companyIds: organizations.slice(i, i + 50).map((organization: IOrganization) => organization.id),
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const addTagToOrganizationsViaId = (tag: ITag, organizations: number[]) => {

    if (organizations.length === 0) {
      enqueueSnackbar("Wählen Sie Organisationen aus, die Sie dem Tag hinzufügen möchten", { variant: 'warning' });
      return;
    }

    let x = enqueueSnackbar("Tags werden den Organisationen hinzugefügt", { autoHideDuration: autoHideDurationDefault })

    for (let i = 0; i < organizations.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString())
      //@ts-ignore
      organizations.slice(i, i + 50)?.forEach((organizationId: number) => {
        params.append("organizationId", organizationId.toString());
      })

      connection.post("/tags/organizationhastag", null, {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich hinzugefügt", { variant: "success" })

          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countOrganizations: ((Tag.countOrganizations ?? 0) + res.data) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "ADD_TAG_TO_COMPANIES",
            payload: {
              companyIds: organizations.slice(i, i + 50),
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const addAllPersonsFromOrganizationToTag = (tag: ITag, organizations: number[]) => {
    console.log("submitted orgs :D", organizations);

    let x = enqueueSnackbar("Tags werden den Personen der ausgewählten Organisationen hinzugefügt", { autoHideDuration: autoHideDurationDefault })

    for (let i = 0; i < organizations.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString());

      organizations.slice(i, i + 50).forEach((orgId) => {
        params.append("organizationId", orgId.toString());
      })

      connection.post("/tags/AddAllPersonsFromOrganizationToTag", null, {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich hinzugefügt", { variant: "success" })

          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countPersons: ((Tag.countPersons ?? 0) + res.data.addedPersonCount) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "ADD_TAG_TO_PERSONS",
            payload: {
              personIds: res.data.addedTagToPersonIds,
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const removeTagFromOrganizations = (tag: ITag, organizations: Array<IOrganization>) => {

    let x = enqueueSnackbar("Tags werden von Organisationen entfernt", { autoHideDuration: autoHideDurationDefault })
    for (let i = 0; i < organizations.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString())

      organizations?.slice(i, i + 50).forEach((organization: IOrganization) => {
        params.append("organizationId", organization.id!.toString());
      })

      connection.delete("/tags/organizationhastag", {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich entfernt", { variant: "success" })

          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countOrganizations3333: ((Tag.countOrganizations ?? 0) - res.data) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "REMOVE_TAG_FROM_COMPANIES",
            payload: {
              companyIds: organizations.slice(i, i + 50).map((organization: IOrganization) => organization.id),
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const removeTagFromOrganizationsViaId = (tag: ITag, organizations: number[]) => {

    if (organizations.length === 0) {
      enqueueSnackbar("Wählen Sie Organisationen aus, die Sie vom Tag entfernen möchten", { variant: 'warning' });
      return;
    }

    let x = enqueueSnackbar("Tags werden von Organisationen entfernt", { autoHideDuration: autoHideDurationDefault })
    for (let i = 0; i < organizations.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString())

      organizations?.slice(i, i + 50).forEach((organizationId: number) => {
        params.append("organizationId", organizationId.toString());
      })

      connection.delete("/tags/organizationhastag", {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich entfernt", { variant: "success" })

          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countOrganizations: ((Tag.countOrganizations ?? 0) - res.data) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "REMOVE_TAG_FROM_COMPANIES",
            payload: {
              companyIds: organizations.slice(i, i + 50),
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const addTagToPersons = (tag: ITag, persons: Array<IPerson>) => {
    let x = enqueueSnackbar("Tags werden den Personen hinzugefügt", { autoHideDuration: autoHideDurationDefault })

    for (let i = 0; i < persons.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString())
      //@ts-ignore
      persons.slice(i, i + 50)?.forEach((person: IPerson) => {
        params.append("personId", person.id.toString());
      })

      connection.post("/tags/personhastag", null, {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich hinzugefügt", { variant: "success" })

          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countPersons: ((Tag.countPersons ?? 0) + res.data) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "ADD_TAG_TO_PERSONS",
            payload: {
              personIds: persons.slice(i, i + 50).map((person: IPerson) => person.id),
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const addTagToPersonsViaId = (tag: ITag, personIds: number[]) => {

    if (personIds.length === 0) {
      enqueueSnackbar("Wählen Sie Personen aus, die Sie dem Tag hinzufügen möchten", { variant: 'warning' });
      return;
    }

    let x = enqueueSnackbar("Tags werden den Personen hinzugefügt", { autoHideDuration: autoHideDurationDefault })

    for (let i = 0; i < personIds.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString())
      //@ts-ignore
      personIds.slice(i, i + 50)?.forEach((personId: number) => {
        params.append("personId", personId.toString());
      })

      connection.post("/tags/personhastag", null, {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich hinzugefügt", { variant: "success" })

          console.log("addPerson resdata", res.data)
          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countPersons: ((Tag.countPersons ?? 0) + res.data) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "ADD_TAG_TO_PERSONS",
            payload: {
              personIds: personIds.slice(i, i + 50),
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const removeTagFromPersonsViaId = (tag: ITag, persons: number[]) => {

    if (persons.length === 0) {
      enqueueSnackbar("Wählen Sie Personen aus, die Sie vom Tag entfernen möchten", { variant: 'warning' });
      return;
    }

    let x = enqueueSnackbar("Tags werden von Personen entfernt", { autoHideDuration: autoHideDurationDefault })

    for (let i = 0; i < persons.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString())

      //@ts-ignore
      persons?.slice(i, i + 50).forEach((personId: number) => {
        params.append("personId", personId.toString());
      })

      connection.delete("/tags/personhastag", {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich entfernt", { variant: "success" })

          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countPersons: ((Tag.countPersons ?? 0) - res.data) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "REMOVE_TAG_FROM_PERSONS",
            payload: {
              personIds: persons.slice(i, i + 50),
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const removeTagFromPersons = (tag: ITag, persons: Array<IPerson>) => {
    let x = enqueueSnackbar("Tags werden von Personen entfernt", { autoHideDuration: autoHideDurationDefault })

    for (let i = 0; i < persons.length; i += 50) {
      let params: URLSearchParams = new URLSearchParams();
      params.set("tagId", tag.id!.toString())

      //@ts-ignore
      persons?.slice(i, i + 50).forEach((person: IPerson) => {
        params.append("personId", person.id!.toString());
      })

      connection.delete("/tags/personhastag", {
        params: params
      })
        .then((res: AxiosResponse) => {
          closeSnackbar(x);
          enqueueSnackbar("Tags erfolgreich entfernt", { variant: "success" })

          dispatch({
            type: "SET_TAGS",
            payload: state.tags.map((Tag: ITagDTO) => {
              if (Tag.id === tag.id) {
                return { ...Tag, countPersons: ((Tag.countPersons ?? 0) - res.data) }
              }
              return Tag;
            })
          })

          dispatch({
            type: "REMOVE_TAG_FROM_PERSONS",
            payload: {
              personIds: persons.slice(i, i + 50).map((person: IPerson) => person.id),
              tagId: tag.id
            }
          })
        })
        .catch((error) => {
          errorHandler(error, x, enqueueSnackbar, closeSnackbar)
        })
    }
  }

  const addTag = (tag: ITag) => {
    let x = enqueueSnackbar("Tag wird erstellt", { autoHideDuration: autoHideDurationDefault })

    connection.post("/tags", tag)
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Tag erfolgreich erstellt", { variant: "success" })

        dispatch({
          type: "ADD_TAG",
          payload: res.data
        })

      })
      .catch((error) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar)
      })
  }

  const editTag = (tag: ITag) => {
    let x = enqueueSnackbar("Tag wird bearbeitet", { autoHideDuration: autoHideDurationDefault });

    connection.put("/tags", tag)
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Tag erfolgreich bearbeitet", { variant: "success" });

        dispatch({
          type: "EDIT_TAG",
          payload: res.data
        })
      })
      .catch((error) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar);
      })
  }

  const removeTag = (tag: ITag) => {
    let x = enqueueSnackbar("Tag wird entfernt", { autoHideDuration: autoHideDurationDefault })

    connection.delete("/tags", { params: { id: tag.id } })
      .then((res: AxiosResponse) => {
        closeSnackbar(x);
        enqueueSnackbar("Tag erfolgreich entfernt", { variant: "success" })

        dispatch({
          type: "REMOVE_TAG",
          payload: tag
        })

      })
      .catch((error) => {
        errorHandler(error, x, enqueueSnackbar, closeSnackbar)
      })
  }

  const downloadTagCSV = (tag: ITag) => {
    connection.get("/tags/csv", { params: { id: tag.id }, responseType: 'text' })
      .then((res: AxiosResponse) => {
        const blob = new Blob(['\uFEFF' + res.data], { type: 'text/csv;charset=utf-8' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const today = new Date();

        link.download = `${tag.title}_${today.getFullYear()}_${(today.getMonth() + 1).toString().padStart(2, '0')}_${today.getDay().toString().padStart(2, '0')}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
      })
  }

  useEffect(() => { console.log("state.newsletterDrafts", state.newsletterDrafts) }, [state.newsletterDrafts])

  const fetchNewsletterDrafts = async () => {
    const { data } = await connection.get("/newsletterdraft")
    let newsletterDrafts = data;

    console.log("Data", data);

    dispatch({
      type: "SET_NEWSLETTERDRAFTS",
      payload: newsletterDrafts
    })
  }

  const fetchNewsletterSents = async () => {
    const { data } = await connection.get("/newslettersent")
    let newsletterSents = data;

    dispatch({
      type: "SET_NEWSLETTERSENT",
      payload: newsletterSents
    })
  }

  const fetchNewsletterQueue = async () => {
    const { data } = await connection.get("/newsletterqueue")
    let newsletterQueue = data;

    dispatch({
      type: "SET_NEWSLETTERQUEUE",
      payload: newsletterQueue
    })
  }

  //Entwurft in den State einfügen
  const addNewsletterDraft = async (draft: INewsletterDesign) => {
    return new Promise(function (resolve, reject) {

      let x = enqueueSnackbar("Entwurf wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

      connection.post("/newsletterdraft", draft)
        .then((res: AxiosResponse) => {
          dispatch({
            type: "ADD_NEWSLETTERDRAFTS",
            payload: res.data
          })
          const { data } = res;


          if (res.status >= 200 && res.status < 300) {
            closeSnackbar(x);
            enqueueSnackbar("Entwurf erfolgreich erstellt.", { variant: "success" })
          } else {
            throw new AxiosError();
          }

          resolve(data);
        })
        .catch((error: any) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);


            if (error.response.status !== 401) {
              enqueueSnackbar("Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.", { variant: "error" })
            } else {
              //Wenn Passwort oder Username falsch
              enqueueSnackbar("Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.", { variant: "warning" })
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js

            enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
          } else {
            enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
          }
          reject(error)
        })
    })
  }

  //Entwurf kopieren
  const copyNewsletterDraft = async (draft: INewsletterDesign) => {
    return new Promise(function (resolve, reject) {
      let x = enqueueSnackbar("Newsletter wird kopiert", { variant: "default", autoHideDuration: autoHideDurationDefault })

      connection.post("/newsletterdraft/copy", draft)
        .then((res: AxiosResponse) => {
          dispatch({
            type: "ADD_NEWSLETTERDRAFTS",
            payload: res.data
          })
          const { data } = res;


          if (res.status >= 200 && res.status < 300) {
            closeSnackbar(x);
            enqueueSnackbar("Entwurf erfolgreich kopiert.", { variant: "success" })
          } else {
            throw new AxiosError();
          }

          resolve(data);
        })
        .catch((error: any) => {
          // Error
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);


            if (error.response.status !== 401) {
              enqueueSnackbar("Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.", { variant: "error" })
            } else {
              //Wenn Passwort oder Username falsch
              enqueueSnackbar("Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.", { variant: "warning" })
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js

            enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
          } else {
            enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
          }
          reject(error)
        })
    })
  }

  //Entwurft in den State einfügen
  const updateNewsletterDraft = async (draft: INewsletterDesign) => {
    let x = enqueueSnackbar("Entwurf wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.put("/newsletterdraft", draft)
      .then((res: AxiosResponse) => {
        dispatch({
          type: "UPDATE_NEWSLETTERDRAFTS",
          payload: res.data
        })
        const { data } = res;


        if (res.status >= 200 && res.status < 300) {
          closeSnackbar(x);
          enqueueSnackbar("Entwurf erfolgreich gespeichert.", { variant: "success" })
        } else {
          throw new AxiosError();
        }

        return data;
      })
      .catch((error: any) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);


          if (error.response.status !== 401) {
            enqueueSnackbar("Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.", { variant: "error" })
          } else {
            //Wenn Passwort oder Username falsch
            enqueueSnackbar("Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.", { variant: "warning" })
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js

          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        } else {
          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        }
      })
  }

  //Entwurft in den State einfügen
  const scheduleNewsletter = async (scheduleDraft: IScheduleNewsletterDesign) => {
    let x = enqueueSnackbar(`Newsletter wird für ${scheduleDraft.scheduledFor?.toLocaleString()} in die Warteschlange aufgenommen`, { variant: "default", autoHideDuration: autoHideDurationDefault })

    connection.post("/newsletterdraft/schedulenewsletter", scheduleDraft)
      .then((res: AxiosResponse) => {
        dispatch({
          type: "ADD_NEWSLETTERQUEUE",
          payload: res.data
        })
        const { data } = res;


        if (res.status >= 200 && res.status < 300) {
          closeSnackbar(x);
          enqueueSnackbar("Newsletter erfolgreich in Warteschlange aufgenommen.", { variant: "success" })
        } else {
          throw new AxiosError();
        }

        return data;
      })
      .catch((error: any) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);


          if (error.response.status !== 401) {
            enqueueSnackbar("Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.", { variant: "error" })
          } else {
            //Wenn Passwort oder Username falsch
            enqueueSnackbar("Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.", { variant: "warning" })
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the 
          // browser and an instance of
          // http.ClientRequest in node.js

          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        } else {
          enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
        }
      })
  }

  useEffect(() => {
    fetchPersons();
    fetchCompanies();
    fetchTags();
    fetchNewsletterDrafts();
    fetchNewsletterSents();
    fetchNewsletterQueue();

    console.log("FETCHCONTACTS")

    return;
  }, [])

  return (
    <ContactsContext.Provider
      value={{
        persons: state.persons,
        //ToDo: check why this gets called 4 times
        fetchPersons: () => { },
        addPerson,
        updatePerson,
        deletePerson,
        defaultPerson,
        addContact,
        defaultContact,
        getPersonByID,
        setProfilePicture,
        getProfilePicture,

        companies: state.companies,
        ownCompany: state.ownCompany,
        createCompany,
        getCompanyByID,
        editCompany,
        deleteCompany,
        fetchCompanies,

        tags: state.tags,
        addTag,
        editTag,
        removeTag,
        addTagToOrganizations,
        addTagToOrganizationsViaId,
        removeTagFromOrganizations,
        removeTagFromOrganizationsViaId,
        addTagToPersons,
        addTagToPersonsViaId,
        addAllPersonsFromOrganizationToTag,
        removeTagFromPersons,
        removeTagFromPersonsViaId,
        downloadTagCSV,

        newsletterSent: state.newsletterSent,
        newsletterDrafts: state.newsletterDrafts,
        newsletterQueue: state.newsletterQueue,
        addNewsletterDraft: addNewsletterDraft,
        updateNewsletterDraft: updateNewsletterDraft,
        scheduleNewsletter: scheduleNewsletter,
        copyNewsletterDraft: copyNewsletterDraft
      }}
    >
      {children}
    </ContactsContext.Provider>
  )
}

export default ContactsProvider

//export const useContacts = () => useContext(ContactsContext)
//export const useContacts = () => useContext(ContactsContext)
//export const useContacts = () => useContext(ContactsContext)
export const useContacts = () => useContext(ContactsContext)