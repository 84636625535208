import { Dialog, IconButton } from '@mui/material'
import React, { ReactNode } from 'react'
import styles from './Dialog.module.css'

//Icons
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import useKeypress from '../../Hooks/useKeyPress';

export interface BasicModalProps {
    open: boolean
    onClose?: () => void
    children?: ReactNode
    maxWidth?: "sm" | "md" | "lg"
}

export interface ModalWithTitleProps extends BasicModalProps {
    title: string
    style?: any
}

function Modal({ open, onClose, title, children, maxWidth, style }: ModalWithTitleProps) {
    useKeypress("Escape", () => onClose)

    return (
        <Dialog
            fullWidth
            open={open}
            scroll="body"
            maxWidth={maxWidth ?? "sm"}
            onClose={onClose}
            className={styles.container}
            classes={{
                paper: styles.container,
                paperScrollBody: styles.back,
                paperScrollPaper: styles.back,
            }}
            style={{}}
        >
            <header className={"flex stretched-justify centered-aligned".concat(" " + styles.header)} style={{ gap: "10px" }}>
                <span className='subheading'>{title}</span>
                <IconButton tabIndex={-1} style={{ color: "var(--text-contrast)" }} onClick={onClose ? () => onClose() : () => null}>
                    <CloseRoundedIcon tabIndex={-1} />
                </IconButton>
            </header>
            <div className={styles.body} style={style}>
                {children}
            </div>

        </Dialog>
    )
}

export default Modal