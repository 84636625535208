import { useSnackbar } from "notistack";
import { ITextModule, ITextModuleDTO, useAddTextModuleMutation, useEditDefaultTextModuleMutation } from "../apiSlices/TextModule";
import { RTKQueryErrorHandler } from "./utils";

export const useHandleAddTextModule = () => {

    const [addTextModule, { isLoading, isError, error, isSuccess }] = useAddTextModuleMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleAddTextModule = async (textModule: ITextModuleDTO) => {
        let x = enqueueSnackbar("Textbaustein wird erstellt", { variant: "default" });
        try {
            const result = await addTextModule(textModule).unwrap();
            //Success
            closeSnackbar(x);
            enqueueSnackbar("Textbaustein erfolgreich erstellt", { variant: "success" });
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" })
        }
    }

    return { handleAddTextModule, isLoading, isError, error, isSuccess };
}

export const useHandleEditDefaultTextModule = () => {

    const [editDefaultTextModule, { isLoading, isError, error, isSuccess }] = useEditDefaultTextModuleMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleEditDefaultTextModule = async (textModule: ITextModule) => {
        let x = enqueueSnackbar("Standardtext wird bearbeitet", { variant: "default" });

        try {
            const result = await editDefaultTextModule(textModule).unwrap();
            closeSnackbar(x);
            enqueueSnackbar("Standardtext erfolgreich bearbeitet", { variant: "success" });
        } catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" })
        }
    }

    return { handleEditDefaultTextModule, isLoading, isError, error, isSuccess };
}