import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { IDepartementDTO, useDepartements } from '../../../Contexts/DepartementContext/DepartementProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useEmployees } from '../../../Contexts/EmployeeContext/EmployeeProvider'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import { IService } from '../../../Interfaces/Service'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import EditCreateDepartmentForm from '../../DepartementCards/EditCreateDepartmentForm'
import Modal from '../../Dialog/Dialog'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import { validateDepartmentForm } from '../../DepartementCards/CreateDepartementCard'

function EditCreateDepartmentDialog({ open, onClose, object, mode }: dialogWithProps<IService>) {

  const { changeVisibility } = useModals()

  const { enqueueSnackbar } = useSnackBar()

  const [departement, setDepartement] = useState<IDepartementDTO | any>({ userIds: [] });

  const { createDepartement, updateDepartement } = useDepartements();
  const { employees } = useEmployees()


  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false)


  //TODO Implementieren -> Bernhard obzw. Nico
  //Funktion zum Handeln des Hinzufügens -> Wird ein Promise werden
  const handleSubmit = async () => {
    try {
      if (validateDepartmentForm(departement, setError)) {
        setLoading(true)
        if (mode == "create") {
          await createDepartement!(departement)
        } else {
          await updateDepartement!(departement)
        }
        if (onClose) {
          handleClose()
        }
        changeVisibility!("editCreateDepartment", false)
      }
    } catch (error) {
      enqueueSnackbar(`Fehler beim ${mode == "create" ? "Erstellen" : "Speichern"}.`, { variant: "error" })
    } finally {
      if (validateDepartmentForm(departement, setError)) {
        setLoading(false)
      }
    }

  }



  const handleClose = async () => {
    //setServiceToSubmit({ ...defaultService! })
    setLoading(false)
    if (onClose) {
      handleClose()
    }
    changeVisibility!("editCreateDepartment", false)
  }

  useEffect(() => {
    setDepartement(object)
  }, [object])

  const [error, setError] = useState<any>({});

  return (
    <Modal
      open={open}
      title={mode == "create" ? 'Abteilung erstellen' : "Abteilung bearbeiten"}
      onClose={handleClose}
    >
      {/* TravelForm als eigene Komp. */}
      <EditCreateDepartmentForm
        employees={employees}
        setDepartment={setDepartement}
        department={departement}
        error={error}
      />
      <div style={{ marginBottom: "10px" }}></div>
      <CTAButton
        title={loading ? <CircularProgress size={18} color='inherit' /> : mode == "create" ? "Erstellen" : "Speichern"}
        onClickHandler={() => handleSubmit()}
        disabled={loading}
      />
    </Modal>
  )
}

export default EditCreateDepartmentDialog