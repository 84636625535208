import { AddRounded, SearchRounded } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { IOrganization, IOrganizationDTO } from '../../Interfaces/Company'
import Card from '../Card/Card'
import Person from '../Person/Person'
import SearchBar from '../SearchBar/SearchBar'
import { blankcard } from '../ServiceCard/ServiceCard'
import { contactFormModes } from './ContactFormCard'
import { FixedSizeList, VariableSizeList } from 'react-window';
import { PhoneCardModes } from '../PhoneCard/PhoneCard'
import Filter, { IFilterOption } from '../Filter/Filter'
import { IJournalFilter } from '../../Pages/Communication/EmailPage'

interface PersonCard extends blankcard {
  setClickedPerson?: Dispatch<SetStateAction<any>>
  journalFilter?: IJournalFilter
  setJournalFilter?: Dispatch<SetStateAction<IJournalFilter>>
  setFormMode?: Dispatch<SetStateAction<contactFormModes>>
  setPhoneCardMode?: Dispatch<SetStateAction<PhoneCardModes>>
  clickedCompany?: IOrganization
}

const PersonCard = ({ width, height, order, setClickedPerson, journalFilter, setJournalFilter, cta, ctaTitle, setFormMode, clickedCompany, setPhoneCardMode }: PersonCard) => {
  const { persons } = useContacts();
  const { companies } = useContacts();

  const { changeVisibility } = useModals();

  const ctaFunction = () => {
    changeVisibility!("editCreatePerson", true, { props: { mode: "create" } })
  }

  const [filteredPersons, setFilteredPersons] = useState(persons);

  const renderRow = ({ index, style }: any) => {
    const item = filteredPersons[index];
    return <div style={{ ...style, width: "100%" }}><Person setPhoneCardMode={setPhoneCardMode} setFormMode={setFormMode} expanded={index === expandedElement} setExpandedElement={setExpandedElement} person={item} setClickedPerson={setClickedPerson} journalFilter={journalFilter} setJournalFilter={setJournalFilter} index={index} /></div>;
  };

  const [expandedElement, setExpandedElement] = useState<number | undefined>(undefined);

  const getItemSize = (index: number) =>
    index === expandedElement ?
      ((persons[index].contactData?.length !== undefined ? (persons[index].contactData?.length === 0 ? 3 : persons[index].contactData?.length! + 2) : 4)) * 50 :
      50;

  const VariableListRef = useRef<any>();

  useEffect(() => {
    VariableListRef.current.resetAfterIndex(0)
  }, [expandedElement, filteredPersons])

  let filterOptions: IFilterOption[] = [
    { title: "Name", valueKey: "completeName" },
    { title: "Notiz", valueKey: "note" },
    { title: "Organisation", valueKey: "belongsToOrganizationId", referenceList: companies, referenceListValueKey: "name", value: clickedCompany?.name },
    { title: "Tag", valueKey: "tagsString" },
  ]

  useEffect(() => { console.log("clickedCompany", clickedCompany) }, [clickedCompany])

  return (
    <Card
      title='Personen'
      style={{ width, height, order }}
      //right={({expanded, setExpanded}) => <Tooltip title="Leistungen suchen"><IconButton style={{backgroundColor: expanded ? "var(--brand)" : undefined, color: expanded ? "white" : undefined}} onClick={() => handleExpand(expanded, setExpanded)}><SearchRoundedIcon /></IconButton></Tooltip>}
      cta={cta}
      bodyPadding={false}
      ctaTitle={ctaTitle}
      ctaFunction={ctaFunction}
      buttonIcon={<AddRounded fontSize='small' />}
      right={() => <Filter dataToFilter={filteredPersons} setDataToFilter={setFilteredPersons} originalData={persons} filterOptions={filterOptions} anchorOriginHorizontal='right' anchorOriginVertical='bottom' transformOriginVertical='top' transformOriginalHorizontal='center' />}
    >
      <VariableSizeList
        height={850}
        itemCount={filteredPersons.length}
        itemSize={getItemSize}
        width="100%"
        ref={VariableListRef}
        style={{
          overflow: 'auto',
          scrollbarWidth: 'none', // Firefox-specific
          //'-ms-overflow-style': 'none', // IE/Edge-specific
        }}
      >
        {renderRow}
      </VariableSizeList>
    </Card>
  )
}

export default PersonCard