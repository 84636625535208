import React, { useEffect, useState } from 'react';
import styles from './OfferCard.module.css';

//Icons
import PendingActionsIcon from '@mui/icons-material/PendingActions';

import { AxiosResponse } from 'axios';
import { useUser } from '../../Contexts/UserContext/UserContext';
import { IBillListDTO } from '../../Interfaces/Bill';

import { Paid } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useBills } from '../../Contexts/BillContext/BillProvider';
import { IPerson, useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import useAuthConnection from '../../Hooks/useAuthConnection';
import { useMobileView } from '../../Hooks/useMobileView';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';


interface props {
    billDTO: IBillListDTO
}

interface Document {
    id: number,
    title: string,
    description: string,
    fileEnding: string,
    fileId: string,
    base64: string,
    organizationId: number,
}

const OfferRow = ({ billDTO }: props) => {
    let navigate = useNavigate();
    const connection = useAuthConnection();
    const isMobile = useMobileView();

    const billContext = useBills();

    const { changeVisibility } = useModals();

    const [expanded, setExpanded] = useState<boolean>(false);
    const { user } = useUser();

    let { bill } = billDTO;

    useEffect(() => {
        bill = billDTO.bill;
    }, [billDTO])

    const onPressEdit = () => {
        if (billDTO.bill.isReceipt === false) {
            navigate(`bearbeiten/${bill.billPrefix}-${bill.billNumber}`);
        } else {
            navigate(`/rechnungswesen/belege/bearbeiten/${bill.billPrefix}-${bill.billNumber}`, { replace: true })
        }
    }

    const onPressDelete = () => {
        changeVisibility!("yesNoDialog", true, { props: { object: { submitFunction: () => { billContext.deleteBill!(bill); connection.delete("/bill", { data: bill }).then((res: AxiosResponse) => { }) }, modalTitle: "Rechnung löschen", confirmButtonText: "Löschen", modalText: "Möchten Sie diese Rechnung wirklich löschen?" } } })
        /*billContext.deleteBill!(bill);
        connection.delete("/bill", { data: bill })
            .then((res: AxiosResponse) => {
            });*/
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const { persons } = useContacts();


    return (
        <>
            {(!isMobile && bill) &&
                <div className={'flex column '.concat(styles.container)} style={{ backgroundColor: "white" }}>
                    <header className='flex centered-aligned stretched-justify' style={{ paddingLeft: "1em", paddingRight: "1em" }}>
                        <div className='flex centered-aligned' style={{ gap: "6px", width: "100%", height: "2.5em", }}>
                            <div style={{ width: "3%", padding: "0.25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                                {!bill.isCreated &&
                                    <Tooltip title="Entwurf">
                                        <PendingActionsIcon style={{ color: "#5e5e5eee" }} />
                                    </Tooltip>
                                }
                                {bill.isCreated &&
                                    <Tooltip title="Angenommen">
                                        <Paid style={{ color: "#105a37" }} />
                                    </Tooltip>
                                }
                            </div>
                            <div style={{ width: "9%", padding: "0.25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                                <span style={{ fontSize: "1em", whiteSpace: 'nowrap', overflow: 'hidden', msTextOverflow: "ellipsis", textAlign: "center" }}>
                                    {!bill.isCreated ? "Entwurf" : "Angenommen"}
                                </span>
                            </div>
                            <div style={{ width: "7%", padding: "0.25em", display: "flex", flexDirection: "row", justifyContent: "start ", alignItems: "center" }}>
                                <span style={{ fontSize: "1em", whiteSpace: 'nowrap', overflow: 'hidden', msTextOverflow: "ellipsis", textAlign: "center" }}>
                                    {"Angebot"}
                                </span>
                            </div>
                            <div style={{ width: "9%", padding: "0.25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                                <span style={{ fontSize: "1em", whiteSpace: 'nowrap', overflow: 'hidden', msTextOverflow: "ellipsis" }}>{bill.billPrefix}-{bill.billNumber}</span>
                            </div>
                            <div style={{ width: "18%", padding: "0.25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                                <span style={{ fontSize: "1em", whiteSpace: 'nowrap', overflow: 'hidden', msTextOverflow: "ellipsis" }}>{bill.customerName ? (<span style={{ fontSize: "1em", whiteSpace: 'nowrap', overflow: 'hidden', msTextOverflow: "ellipsis" }}>{bill.customerName.substring(0, 25)}</span>) : persons.find((temp: IPerson) => temp.id === bill.personId) ? "Person: " + persons.find((temp: IPerson) => temp.id === bill.personId)?.completeName?.substring(0, 25) : "Kein Kunde zugewiesen" /* Hier sollte die zur ID gehrenden Firma stehen TODO: ergänzen, wenn der Firmenstate existiert */}</span>
                            </div>
                            <div style={{ width: "5%", padding: "0.25em", display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}>
                                <span style={{ width: "90%" }}>
                                    {new Date(bill?.date ?? "").toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                                </span>
                            </div>
                            <div style={{ width: "10%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{bill?.netto?.toFixed(2).replace('.', ',')} €</span>
                            </div>
                        </div>
                        <MoreOptionsButton options={options}></MoreOptionsButton>
                    </header>
                </div>
            }

            {(isMobile && bill) &&
                <div className='flex flex-row w-full border-b-2 p-2 items-center'>
                    <div className='flex flex-col w-[90%]'>
                        <div className='flex flex-row justify-between w-full'>
                            <span className=''>Kunde:</span>
                            <span className='max-w-[80%] text-ellipsis whitespace-nowrap overflow-hidden'>{bill.customerName}</span>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <span>Angebotsnr.:</span>
                            <span>{bill.billPrefix}-{bill.billNumber}</span>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <span>Datum:</span>
                            <span>{bill.date ? dayjs(bill.date).format("DD.MM.YYYY") : ""}</span>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <span>Status:</span>
                            <span>
                                {!bill.isCreated &&
                                    <Tooltip title="Entwurf">
                                        <PendingActionsIcon style={{ color: "#5e5e5eee" }} />
                                    </Tooltip>
                                }
                                {bill.isCreated &&
                                    <Tooltip title={bill.isPaid ? "Bezahlt" : "Offen"}>
                                        <Paid style={{ color: "#105a37" }} />
                                    </Tooltip>
                                }
                            </span>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <span>Betrag:</span>
                            <span>{bill?.netto?.toFixed(2).replace('.', ',')} €</span>
                        </div>
                    </div>
                    <div className='flex flex-col w-[10%] mr-3'>
                        <MoreOptionsButton options={options} />
                    </div>
                </div>

            }
        </>
    )
}

export default OfferRow;