import { Dispatch, SetStateAction, useState, useEffect } from 'react'
import { ITask } from '../../Interfaces/Task'
import Card from '../Card/Card'
import Task from '../Task/Task'
import TaskExpandable from '../Task/TaskExpandable'
//import callLink from './callLink'  //C:\Users\horstkandutsch\Source\eCockpit\eCockpit NEU\src\Hooks\Utilities\callLink.tsx
import { AddRounded, CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckRounded, RefreshRounded, MoreVertRounded, Call, CallEnd, CallMissed, Email, PhoneMissed, Sms, Cancel, CheckBox } from '@mui/icons-material'
import { Button, Checkbox, Chip, FormControlLabel, Tooltip } from '@mui/material'
import { TextField } from '@mui/material'
import styles from './TaskCard.module.css'
import ErrorDialog from '../Dialogs/ErrorDialog/ErrorDialog'
import { useDeliveryNote } from '../../Contexts/DeliveryNoteContext/DeliveryNoteContext'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'
import { card } from '../../Interfaces/Card'
import CreateTaskDialog from '../Dialogs/CreateTaskDialog/CreateTaskDialog'
import { blankcard } from '../ServiceCard/ServiceCard'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import ContextMenu from '../Menu/ContextMenu'
import { Grow } from '@mui/material'
import { string } from 'zod'
import { ContactDataType, IContactData, IPerson, IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
//mport { companyDataContext} from '../../Contexts/ContactsContext/ContactsProvider'
import { useSnackBar } from '../../Hooks/useSnackBar'
import axios, { AxiosResponse } from 'axios'
import JournalEntry from '../Journal/JournalEntry'
import { journalDTO } from '../../Interfaces/Journal'
import InputField from '../InputField/InputField'
import JournalCard from '../JournalCard/JournalCard'
import { useJournal } from '../../Contexts/JournalContext/JournalProvider'
import { VariableDeclaration } from 'typescript'
import { IOrganization, IOrganizationDTO } from '../../Interfaces/Company'
import { useUser } from '../../Contexts/UserContext/UserContext'
import LiveTicker from '../LiveTicker/LiveTicker'
import useAuthConnection from '../../Hooks/useAuthConnection'
import FormRow from '../Forms/FormUtils/FormRow'


export type ITaskSelectMode = "select" | "normal"

{/**************************************************************************************/ }
//Daten lesen! 
//Journaleintrag muss erstellt werden

export type PhoneCardModes = "person" | "organization" | "undefined"



interface SmsCard extends blankcard{
  clickedPerson?: IPerson
  clickedCompany?: IOrganization
  phoneCardMode?: PhoneCardModes
  calledPerson: IPersonDTO, setCalledPerson?: Dispatch<SetStateAction<IPersonDTO>>,
  phoneJournalTitle: string, setPhoneJournalTitle?: Dispatch<SetStateAction<string>>,
  phoneJournalEntry: string, setPhoneJournalEntry?: Dispatch<SetStateAction<string>>,
  phoneJournalTime: Date, setPhoneJournalTime?: Dispatch<SetStateAction<Date>>,
  phoneJournalDuration: number, setPhoneJournalDuration?: Dispatch<SetStateAction<number>>,
  message: string, setMessage?: Dispatch<SetStateAction<string>>
}

{/**************************************************************************************/ }



const getTimeNumber = (phoneJournalTime: Date) => {

  var anrufEnde = new Date();
  var numberOfMlSecondsEnde = anrufEnde.getTime();
  var numberOfMlSecondsStart = phoneJournalTime.getTime();
  var durationRaw = (numberOfMlSecondsEnde - numberOfMlSecondsStart) / 60000;
  var duration = Math.round(durationRaw * 100) / 100;


  return duration;
}


//Hier wird ein SME via API ausgelöst
const SMSViaAPI = async (recipient: string, message: string, connection:any, journal:boolean, clickedCompany?: IOrganization, clickedPerson?: IPerson) => {
  //console.log("######## ", link, " ######### ", userIP)

  
  //const connection = useAuthConnection();
  
      //Objekt zur Übergabe d. Parameter gestalten:
      const smsParams = {
          "phoneNumber" : recipient,
          "message" : message,
          "receiverOrg" : clickedCompany,
          "receiverPers" : clickedPerson,
          "journal" : journal
      }

      //console.log("######## ", link, " ######### ", userIP)
  
  // Make API call to get CSV string
  
      connection.post("/Phone/Sms", smsParams) //damit ruft UI die API auf...
          .then((res: AxiosResponse) => {
              // Der Anruf wird ausgeführt, hier Snackbar + Journaleintrag setzen. Idee: Journaleintrag erst, wenn aufgelegt wurde? Kann das der Server melden?
              
          })
          .catch((error: any) => {
              console.error(`Error: ${error}`);
          })

};


//Wir brauchen die Empfängernummer und den Text, danach müssen wir einen Journaleintrag kreieren.
function SmsCard({ height = "100%", width = "100%", order, cta, clickedPerson, clickedCompany, phoneCardMode, setCalledPerson, phoneJournalTitle, setPhoneJournalTitle, phoneJournalEntry, setPhoneJournalEntry,
  phoneJournalTime, setPhoneJournalTime, phoneJournalDuration, setPhoneJournalDuration }: SmsCard) {
  const [modalOpen, setModalOpen] = useState(false);
  const connection = useAuthConnection();

  useEffect(() => {
    if (phoneCardMode === "person" && clickedPerson) {
      let numbers = clickedPerson.contactData?.filter((temp: IContactData) => temp.type === ContactDataType.phone);
      if (numbers && (numbers?.length ?? 0 > 0)) {
        setLink(numbers[0].value ?? "")
      }

      let mails = clickedPerson.contactData?.filter((temp: IContactData) => temp.type === ContactDataType.mail);
      if (mails && (mails?.length ?? 0 > 0)) {
        setMail(mails[0].value ?? 0)
      }
    }
    else if(phoneCardMode === "organization" && clickedCompany) {
      setLink(clickedCompany.telephone ?? "")
      setMail(clickedCompany.eMail ?? "")
    }
    else {
      setLink("");
      setMail("");
    }

  }, [phoneCardMode])

  const { user } = useUser();
  let userIP: string = "";

  if (user) {
    userIP = String(user!!.voipAddress);
  }
  else {
    userIP = "keine IP hinterlegt."
  }

  //const CreateJournalEntry = ({ object, setObject }: { object?: journalDTO | null, setObject: Dispatch<SetStateAction<journalDTO>> }) => {}

  // Erstelle einen state-Wert für den Link
  const [link, setLink] = useState('');
  const [message, setMessage] = useState<string>("");
  const [mail, setMail] = useState('');
  const [name, setName] = useState('');
  const { enqueueSnackbar } = useSnackBar();
  const [journal, setJournal] = useState(true);


  // Erstelle eine Funktion, die den Link auslöst
  const handleSMSClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    if (setPhoneJournalTime) setPhoneJournalTime(new Date());
    //Hier muss ich den State auf die angerufene Person setzen:

    if (setCalledPerson && clickedPerson) setCalledPerson(clickedPerson)
     //{ enqueueSnackbar("CalledPerson gesetzt: " + clickedPerson?.completeName, { variant: "info" }) }

    if (setPhoneJournalTitle && (clickedPerson || clickedCompany)) setPhoneJournalTitle("SMS an Firma/Person: " + (clickedPerson?.completeName ?? (clickedCompany?.name ?? "")));

    //if (setPhoneJournalEntry) setPhoneJournalEntry("Start des Telefongesprächs: " + phoneJournalTime.toLocaleString() + "\n ");
    //enqueueSnackbar("SMS wird gesendet. An: "  + clickedPerson?.completeName, { variant: "info" }) 
    //if (setPhoneJournalDuration) setPhoneJournalDuration(getTimeNumber(phoneJournalTime));

    try {

      if (userIP !== undefined) {

        SMSViaAPI(link, message, connection,journal, clickedCompany, clickedPerson);
        //raiseCallViaURL(link, userIP);
      }

      { enqueueSnackbar("SMS wird gesendet. Von: "  + user!!.username + " an " + link, { variant: "info" }) 
      //if (setPhoneJournalEntry) setPhoneJournalEntry("Der/die TeilnehmerIn war am " + phoneJournalTime.toLocaleString() + " nicht erreichbar.");
    }
    }
    catch (error) {
      { enqueueSnackbar("Fehler beim Senden des SMS: " + error, { variant: "error" }) }
      
    }
    refreshPhoneCard();
  }


  const refreshPhoneCard = () => {

    setLink("");
    setMessage("");

  }

  

  // Erstelle eine Funktion für Abbrechen
  const handleAbbrechenClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
   
    refreshPhoneCard();

    if (setCalledPerson && clickedPerson) setCalledPerson(clickedPerson)

    //if (setPhoneJournalEntry) setPhoneJournalEntry("Der/die TeilnehmerIn war am " + phoneJournalTime.toLocaleString() + " nicht erreichbar.");
    //{ enqueueSnackbar("Journaleintrag - nicht erreichbar - erstellt", { variant: "info" }) }
  }


  

  const dialogprovider = useModals();

  return (
    <Card
      title="SMS Versand"
      style={{ height, width, order }}
      cta={cta ?? true}
      ctaTitle='Zum Telefonbuch hinzufügen?'
      buttonIcon={<AddRounded fontSize='small' />}

      ctaFunction={() => { dialogprovider.changeVisibility!("editCreatePersonfromPhone", true, { props: { object: { telefonnummer: link }, mode: "create" } }) }}

    >
      <div className='flex column' style={{ gap: "2px" }}>
        {
          <form>
            <div>
              <span className="label">
                Kontaktperson i.d. Personenliste gewählt: {phoneCardMode === "person" ? clickedPerson?.completeName : (phoneCardMode === "organization" ? clickedCompany?.name : "")}
              </span>
            </div>
            <div>
              <div>
                <InputField
                  type="tel"
                  //padding = "2px"
                  //align="center"
                  placeholder='Telefonnummer eingeben oder Kontakt anklicken'
                  value={link}
                  onChangeHandler={(val) => setLink(val)}
                />
                
              </div>
              <div>
              
                <FormRow
                label='SMS Text eingeben'
                onChange={(val: string) => { setMessage(val) }}   //{ setJournalToSubmit((old: journalDTO) => ({ ...old, description: val })) }}
                placeholder='Auf diese SMS kann nicht geantwortet werden.'
                value={message}
                type='multiline'
                error={false}
                errorMessage={""}
            />
            </div>
            
            
              {/* 
                <TextField id="outlined-basic" style={{ width: "75%" }} label="Telefonnummer" variant="filled" value={link} onChange={
                  (e) => setLink(e.target.value)
                }
                />*/}
                 <span style={{ width: "20%" }}><FormControlLabel control={<Checkbox color='secondary' checked={journal} onChange={() => {                               
                            setJournal((old:boolean)=>!old)}} />} label={"SMS öffentlich im Journal anzeigen?"} />
                            </span>
              <div>
                <span> </span>
               
                <Tooltip title="abbrechen">
                <Button variant="contained" onClick={handleAbbrechenClick} color="inherit" style={{ width: "49%", padding: "2px", margin: "4px" }}><Cancel>abbrechen</Cancel></Button>
              </Tooltip>
                <Tooltip title="SMS absenden">
                  <Button onClick={handleSMSClick} color="success" variant='contained' style={{ width: "49%", padding: "2px", margin: "4px" }}><Sms /></Button>
                </Tooltip>
                
              </div>
            </div>
            <div>
              
            

            </div>

            <div>

            </div>
                
          </form>
        }
        
      </div>
    </Card>
  )
}

export default SmsCard