import { AddRounded } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import Card from '../Card/Card'
import { blankcard } from '../ServiceCard/ServiceCard'
import FormRow from '../Forms/FormUtils/FormRow'
import { User } from '../../Interfaces/User'
import { useUser } from '../../Contexts/UserContext/UserContext'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import { UpdateUserDTO } from '../PersonalSettingsCard/PersonalSettingsCard'


type BannerProps = {
  link: string;
  backgroundColor: string;
  textColor: string;
  buttonText: string;
}

function Header(props: BannerProps) {
  return (
    <div style={{
      backgroundColor: props.backgroundColor,
      borderRadius: '5px',
      color: props.textColor,
      padding: '10px',
    }}>
      <div style={{ backgroundColor: props.backgroundColor, color: props.textColor, padding: '30px', textAlign: 'center' }}>
        <h2>Mit diesem Modul sind wir noch nicht ganz fertig ...</h2>
        <p>... entdecke unsere neuesten Entwicklungen unterdessen gerne auf unserer Website:</p>
        <a href={props.link} style={{ backgroundColor: '#fff', color: '#EBA049', padding: '5px 20px', borderRadius: '5px', textDecoration: 'none' }} target="_blank">
          {props.buttonText}
        </a>
      </div>
    </div>
  );
}


function ShowHeader() {
  return (
    <div className="App">
      <Header
        link="https://www.ecockpit.at"
        backgroundColor="#EBA049"
        textColor="#fff"
        buttonText="Jetzt besuchen"
      />
    </div>
  );
}

function Footer(props: BannerProps) {
  return (
    <div style={{
      backgroundColor: props.backgroundColor,
      color: props.textColor,
      borderRadius: '5px',
      padding: '10px',
    }}>
      <div style={{ backgroundColor: props.backgroundColor, color: props.textColor, padding: '30px', textAlign: 'center' }}>
        <h2>Du willst auf dem Laufenden bleiben!</h2>
        <p>Damit gehörst Du zur glücklichen eCockpit-Community und wirst automatisch via Email informiert.</p>
      </div>
    </div>
  );
}

function ShowFooter() {
  return (
    <div className="App">
      <Footer
        link="https://www.ecockpit.at"
        backgroundColor="#EBA049"
        textColor="#fff"
        buttonText="Anmelden"
      />
    </div>
  );
}


function InputWithBorderRadius() {
  const inputStyle = {
    borderRadius: '5px',
  };
  return (
    <input type="text" style={inputStyle} />
  );
}

const UnderConstructionCard = ({ height, width, order, cta, ctaTitle }: blankcard) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // TODO: send form data to server for newsletter subscription
    setShowConfirmation(true);
    setFirstName("");
    setLastName("");
    setEmail("");
    setIsConsentGiven(false);
  };

  const handleFirstNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmail(e.target.value);
  };

  const handleConsentChange = (e: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
    setIsConsentGiven(e.target.checked);
  };


  const defaultUser: User = {
    email: "",
    username: "",
    organizationId: 0
  }

  const { user, updatePersonalSettings } = useUser();
  const [userToSubmit, setUserToSubmit] = useState<User>(user ?? defaultUser);
  const [loading, setLoading] = useState(false);

  const handleNewsletterConfirm = async () => {
    try {
      setLoading(true);
      let tempUser: UpdateUserDTO = {
        id: userToSubmit.userId,
        birthdate: userToSubmit.birthdate,
        emailMFA: userToSubmit.emailMFA,
        SVNR: userToSubmit.svnr,
        receivesNewsletter: userToSubmit.receivesNewsletter,
        voipAddress: userToSubmit.voipAddress
      }
      updatePersonalSettings!(tempUser);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Card
      title='Bitte um etwas Geduld.'
      cta={cta}
      ctaTitle={ctaTitle}
      fullHeightBody
      buttonIcon={<AddRounded fontSize='small' />}
      style={{ width, height, order }}
    >
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", width: "100%" }}>
        <div> {ShowHeader()}
        </div>

        {/*Text f. d. Einleitung (Fließtext)*/}

        <div className="container" style={{ textAlign: "center" }}>
          <h3>Hier wird fleissig gearbeitet!</h3>
          <p>Das eCockpit entwickelt sich zusehends. Wir wachsen mit unseren Kunden und freuen uns immer über nützlichen Input zur Weiterentwicklung. <br />
            Dieses Modul wurde auf Grund häufiger Nachfrage ausgewählt und wird jetzt im nächsten Schritt umgesetzt. Das Projekt eCockpit entstand <br />
            im März 2020 als einfaches Rechnungsprogramm. Seither verging kein Monat ohne geniales Update! </p>


          {user?.receivesNewsletter ? <p>Du bist zum Newsletter angemeldet und somit immer auf dem neuesten Stand! Du kannst Dich in den Einstellungen jederzeit von unserem Newsletter abmelden.</p> :
            <div>
              <p>
                Wenn Du über die neuesten Updates und Entwicklungen auf dem Laufenden
                bleiben möchten, kannst Du Dich für unseren Newsletter anmelden.
              </p>
              <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                <div style={{ width: "20%", marginBottom: "2%" }}>
                  <FormRow
                    value={userToSubmit?.receivesNewsletter ?? false}
                    type="switch"
                    onChange={(val) => setUserToSubmit((prev) => ({ ...prev, receivesNewsletter: val }))}
                    title='Newsletter:'
                  />
                </div>
              </div>


              <CTAButton
                style={{
                  width: "20%",
                  marginBottom: "2%"
                }}
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Bestätigen"}
                onClickHandler={() => handleNewsletterConfirm()}
                disabled={loading}
              />
            </div>
          }


        </div>


        <div>{ShowFooter()}</div>
      </div>


    </Card>
  );
};

export default UnderConstructionCard;
