import React, { SetStateAction, useEffect, useState } from 'react'
import styles from './BillingPosition.module.css'

import { IBillDTO, IBillingPosition, DiscountType } from '../../Interfaces/Bill';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import EditBillingPositionDialog from '../Dialogs/EditBillingPosition/EditBillingPositionDialog';
import useAuthConnection from '../../Hooks/useAuthConnection';
import { AxiosResponse } from 'axios';

function BillingPositionRow({ bill, billingPosition, billingPositions, setBillingPositions, backgroundColor, generatePdf, getOpenServicesOfCustomerId }: { bill: IBillDTO | undefined, billingPosition: IBillingPosition, billingPositions: any, setBillingPositions: any, backgroundColor?: string, generatePdf: () => void, getOpenServicesOfCustomerId?: () => void }) {
    const [open, setOpen] = useState<boolean>(false);

    const onPressEdit = () => {
        setOpen(true);
    }

    const connection = useAuthConnection();

    const onPressDelete = () => {
        connection.delete("/bill/billingposition", { data: billingPosition }).then((res: AxiosResponse) => {
            generatePdf();
        });
        setBillingPositions(billingPositions.filter((bp: IBillingPosition) => {
            if (bp.id === billingPosition.id) {
                return false;
            }
            return true;
        }));
        if (getOpenServicesOfCustomerId !== undefined) {
            getOpenServicesOfCustomerId();
        }
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    const onSubmitEdit = (tempBillingPosition: IBillingPosition) => {
        let tempBillingPositions = billingPositions;

        tempBillingPositions = tempBillingPositions.map((tempBP: IBillingPosition) => {
            if (tempBP.id === billingPosition.id) {
                return tempBillingPosition;
            }
            return tempBP;
        });

        setBillingPositions(tempBillingPositions);
    }

    return (
        <>
            <div className='flex row' style={{ width: "100%", alignItems: "center", justifyContent: "space-between", backgroundColor: backgroundColor ?? "transparent" }}>
                <div className={'flex column '.concat(styles.container)} style={{ backgroundColor: backgroundColor ?? "transparent" }}>
                    <header className='flex centered-aligned stretched-justify'>
                        <div className='flex centered-aligned' style={{ gap: "6px" }}>
                            <div className={styles.accent} />
                            <span className='bold-big'>{billingPosition.title}</span>
                        </div>
                    </header>
                    <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                        <span>
                            Netto: {billingPosition.netto?.toFixed(2).replace(".", ",")} €
                        </span>
                        <div style={{ height: "12px", width: "1px", backgroundColor: 'black', borderRadius: "100px" }} />
                        <span>
                            Steuer: {billingPosition.tax?.toFixed(2).replace(".", ",")} %
                        </span>
                        <div style={{ height: "12px", width: "1px", backgroundColor: 'black', borderRadius: "100px" }} />
                        <span>
                            Menge: {billingPosition?.quantity?.toFixed(2).replace(".", ",")}
                        </span>
                        {billingPosition.hasDiscount === true &&
                            <>
                                <div style={{ height: "12px", width: "1px", backgroundColor: 'black', borderRadius: "100px" }} />
                                <span>
                                    Rabatt: {billingPosition.discount!.toFixed(2).replace(".", ",")} {billingPosition.discountType === DiscountType.FixedValue && " €"} {billingPosition.discountType === DiscountType.Percentage && " %"}
                                </span>
                            </>
                        }
                    </section>
                </div>
                <MoreOptionsButton options={options}></MoreOptionsButton>
            </div>
            <EditBillingPositionDialog generatePdf={generatePdf} bill={bill} open={open} setOpen={setOpen} object={billingPosition} billingPositions={billingPositions} setBillingPositions={setBillingPositions}></EditBillingPositionDialog>
        </>
    )
}

export default BillingPositionRow