import { useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import { IntervallFormModes } from '../../Components/Forms/EditCreateIntervall'
import ExpanseIntervallCard from '../../Components/IntervallCards/ExpanseIntervallCard'
import ExpanseIntervallDataCard from '../../Components/IntervallCards/ExpanseIntervallDataCard'
import { IIntervallDTO, defaultIntervall } from '../../Contexts/IntervallContext/IntervallProvider'

function Ausgangsintervalle() {
    
    const [clickedIntervall, setClickedIntervvall] = useState<IIntervallDTO>(defaultIntervall);
    const [formMode, setFormMode] = useState<IntervallFormModes>("createIntervall");

    return (
        <>
            <CardColumn
                height='100%'
                width='50%'>
                <ExpanseIntervallCard
                    height='100%'
                    width='100%'
                    setClickedIntervall={setClickedIntervvall}
                    setFormMode={setFormMode}
                />
            </CardColumn>
            <CardColumn
                height='100%'
                width='50%'>
                <ExpanseIntervallDataCard
                    height='100%'
                    width='100%'
                    clickedIntervall={clickedIntervall}
                    setClickedIntervall={setClickedIntervvall}
                    formMode={formMode}
                    setFormMode={setFormMode}
                    mode={formMode === "editIntervall" ? "edit" : "create"}
                />
            </CardColumn>
        </>
    )
}

export default Ausgangsintervalle