import { AxiosResponse } from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useBills } from '../../../Contexts/BillContext/BillProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IProductDTO } from '../../../Contexts/ProductContext/ProductProvider'
import { useServices } from '../../../Contexts/ServiceContext/ServiceContext'
import useAuthConnection from '../../../Hooks/useAuthConnection'
import { IBillDTO, IBillingPosition, IBillListDTO } from '../../../Interfaces/Bill'
import { IService, IServiceDTO } from '../../../Interfaces/Service'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateBillingPosition from '../../Forms/CreateBillingPositionForm'
import CreateServiceForm from '../../Forms/CreateServiceForm'
import { CircularProgress } from '@mui/material'


export type dialog = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    onClose?: () => void
    generatePdf: () => void
    object?: IBillingPosition | undefined
    billingPositions: IBillingPosition[]
    setBillingPositions: Dispatch<SetStateAction<IBillingPosition[]>>
    bill: IBillDTO | undefined
    openServices?: IService[]
    setOpenServices?: Dispatch<SetStateAction<IService[]>>
    isOffer?: boolean
}

export const validateBillingPositionForm = (setError: Dispatch<SetStateAction<any>>, billingPosition?: IBillingPosition) => {
    let valid = true;

    if (billingPosition?.title === "" || billingPosition?.title === undefined) {
        setError((old: any) => ({ ...old, titleError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, titleError: false }))
    }

    if (billingPosition?.netto === undefined || billingPosition?.netto === 0 || isNaN(billingPosition.netto)) {
        setError((old: any) => ({ ...old, nettoError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, nettoError: false }))
    }

    if (billingPosition?.tax === undefined || isNaN(billingPosition.tax)) {
        setError((old: any) => ({ ...old, taxError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, taxError: false }))
    }

    return valid;
}

function EditBillingPositionDialog({ open, setOpen, onClose, generatePdf, object, billingPositions, setBillingPositions, bill, openServices, setOpenServices, isOffer = false }: dialog) {
    const [exists, setExists] = useState<boolean>(object !== undefined);

    const [product, setProduct] = useState<IProductDTO | undefined>(undefined);

    const [loading, setLoading] = useState(false);

    const [serviceToSubmit, setServiceToSubmit] = useState<IBillingPosition>({
        billId: bill?.id ? bill?.id : 0,
        unitId: 0,
        tax: 20,
        quantity: 1
    });

    useEffect(() => {
        if (bill) {
            setServiceToSubmit({ ...object, billId: bill?.id, unitId: object?.unitId ?? 0, tax: object?.tax ?? 20, quantity: object?.quantity ?? 1 })
        }
    }, [bill])

    const connection = useAuthConnection();

    const onSubmitEdit = () => {
        if (validateBillingPositionForm(setError, serviceToSubmit)) {
            setLoading(true);
            if (exists === true) {
                connection.put("/bill/billingposition", serviceToSubmit)
                    .then((res: AxiosResponse) => {
                        generatePdf();
                    })
                    .catch((reason: any) => {
                        console.log(reason);
                    });

                setBillingPositions(billingPositions.map((tempBP: IBillingPosition) => {
                    if (tempBP.id === object?.id) {
                        return serviceToSubmit;
                    }
                    return tempBP;
                }));
                setLoading(false);
                setOpen(false);
            } else {
                connection.post("/bill/billingposition", serviceToSubmit).then((res: AxiosResponse) => {
                    generatePdf();
                    //setBillingPositions((prev) => ([...prev, res.data]));
                    setLoading(false);
                    setOpen(false);
                    if (setOpenServices !== undefined && openServices !== undefined) {
                        setOpenServices(openServices?.filter((service: IService) => {
                            if (service.id === serviceToSubmit?.serviceId) {
                                return false;
                            }
                            return true;
                        }))
                    }
                    setServiceToSubmit({ ...object, billId: bill?.id, unitId: 0, tax: 20, quantity: 1 })
                    setProduct(undefined);
                }).catch((reason: any) => {
                    setLoading(false);
                    console.log(reason);
                });
            }
        }
    }

    const [error, setError] = useState<any>({})

    return (
        <Modal
            open={open}
            title={object !== undefined ? (isOffer ? 'Angebotsposition bearbeiten' : 'Rechnungsposition bearbeiten') : isOffer ? 'Angebotsposition erstellen' : 'Rechnungsposition erstellen'}
            onClose={onClose ? onClose : () => setOpen(false)}
        >
            <CreateBillingPosition
                object={serviceToSubmit}
                setObject={setServiceToSubmit}
                services={openServices}
                setServices={setOpenServices}
                product={product}
                setProduct={setProduct}
                error={error}
            />
            <div style={{ marginBottom: "2%" }}></div>
            <CTAButton
                title={loading ? <CircularProgress size={10} /> : exists ? 'Speichern' : 'Erstellen'}
                onClickHandler={onSubmitEdit}
            />
        </Modal>
    )
}

export default EditBillingPositionDialog