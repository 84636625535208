import React from 'react'
import { IIntervallDTO, IIntervallPosition } from './IntervallProvider'

const ProductReducer = (state: any, action: any) => {
    switch (action.type) {
        case "SET_INTERVALLS":
            return {
                ...state,
                intervalls: action.payload
            }
        case "ADD_INTERVALL":
            return {
                ...state,
                intervalls: [...state.intervalls, action.payload]
            }
        case "ADD_INTERVALLPOSITION":
            return {
                ...state,
                intervalls: state.intervalls.map((intervall: IIntervallDTO) => {
                    if (intervall.id === action.payload.subscriptionId) {
                        return { ...intervall, subscriptionPositions: [...intervall?.subscriptionPositions ?? [], action.payload] }
                    }
                })
            }
        case "REMOVE_INTERVALL":
            return {
                ...state,
                intervalls: state.intervalls.filter((intervall: IIntervallDTO) => {
                    if (intervall.id === action.payload.id) {
                        return false;
                    }
                    return true;
                })
            }
        case "REMOVE_INTERVALLPOSITION":
            return {
                ...state,
                intervalls: state.intervalls.map((intervall: IIntervallDTO) => {
                    if (intervall.id === action.payload.subscriptionId) {
                        return { ...intervall, subscriptionPositions: intervall.subscriptionPositions?.filter((temp: IIntervallPosition) => temp.id !== action.payload.id) }
                    }
                    return true;
                })
            }
        case "UPDATE_INTERVALL":
            return {
                ...state,
                intervalls: state.intervalls.map((intervall: IIntervallDTO) => {
                    if (intervall.id === action.payload.id) {
                        return action.payload;
                    }
                    return intervall;
                })
            }
        case "UPDATE_INTERVALLPOSITION":
            let intervall = state.intervalls.find((temp: IIntervallDTO) => temp.id === action.payload.subscriptionId);
            let intervallPositions = intervall.subscriptionPositions.map((temp: IIntervallPosition) => {
                if (temp.id === action.payload.id) {
                    return action.payload;
                }
                return temp;
            })

            return {
                ...state,
                intervalls: state.intervalls.map((intervall: IIntervallDTO) => {
                    if (intervall.id === action.payload.subscriptionId) {
                        return { ...intervall, subscriptionPositions: intervallPositions }
                    }
                })
            }
        default:
            return {
                ...state
            }
    }

}

export default ProductReducer