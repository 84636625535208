import { CircularProgress } from '@mui/material'
import { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { card } from '../../Interfaces/Card'
import { User } from '../../Interfaces/User'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import Card from '../Card/Card'
import FormRow from '../Forms/FormUtils/FormRow'
import DateInputField from '../InputField/DateInputField'
import InputField from '../InputField/InputField'
import styles from './PersonalSettingsCard.module.css'

export interface UpdateUserDTO {
    id?: number
    birthdate?: Dayjs | null
    SVNR?: string
    voipAddress?: string
    emailMFA?: boolean
    receivesNewsletter?: boolean
    automaticPause?: boolean
}

const PersonalSettingsCard = ({ height, width, order }: card) => {

    const { user, updatePersonalSettings } = useUser()
    const { enqueueSnackbar } = useSnackBar()

    const defaultUser: User = {
        email: "",
        username: "",
        organizationId: 0
    }

    const [userToSubmit, setUserToSubmit] = useState<User>(user ?? defaultUser);

    useEffect(() => {
        if (user) {
            setUserToSubmit(user);
        }
    }, [user])

    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        try {
            setLoading(true);
            let tempUser: UpdateUserDTO = {
                id: userToSubmit.userId,
                birthdate: userToSubmit.birthdate,
                emailMFA: userToSubmit.emailMFA,
                SVNR: userToSubmit.svnr,
                receivesNewsletter: userToSubmit.receivesNewsletter,
                voipAddress: userToSubmit.voipAddress,
                automaticPause: userToSubmit.automaticPause

            }
            updatePersonalSettings!(tempUser);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Card
            title="Persönliche Einstellungen"
            style={{ height, width, order }}
        >
            <div id={styles["containerDiv"]}>

                <div className='form-row-label'>
                    <span className='label'>
                        Geburtstag:
                    </span>
                    <DateInputField
                        type="Date"
                        value={userToSubmit?.birthdate}
                        onChangeHandler={(val) => setUserToSubmit((old) => ({ ...old, birthdate: val }))}
                    />
                </div>

                <div className='form-row-label'>
                    <span className='label'>
                        SVNR:
                    </span>
                    <InputField
                        type='string'
                        placeholder='SVNR'
                        value={userToSubmit?.svnr}
                        onChangeHandler={(val) => setUserToSubmit((prev) => ({ ...prev, svnr: val }))}
                    />
                </div>

                <div className='form-row-label'>
                    <span className='label'>
                        VoIP-Adresse:
                    </span>
                    <InputField
                        type='string'
                        placeholder='VoIP-Adresse'
                        value={userToSubmit?.voipAddress}
                        onChangeHandler={(val) => setUserToSubmit((prev) => ({ ...prev, voipAddress: val }))}
                    />
                </div>


                {/*<FormRow
                    value={userToSubmit?.emailMFA ?? false}
                    type="switch"
                    onChange={(val) => setUserToSubmit((prev) => ({ ...prev, emailMFA: val }))}
                    title="Multi-Faktor-Authentifizierung"

                />*/}


                <FormRow
                    value={userToSubmit?.receivesNewsletter ?? false}
                    type="switch"
                    onChange={(val) => setUserToSubmit((prev) => ({ ...prev, receivesNewsletter: val }))}
                    title="Newsletter"
                />
                <FormRow
                    title='Automatische Pause'
                    value={userToSubmit.automaticPause ?? false}
                    type='switch'
                    onChange={(val) => setUserToSubmit((prev) => ({ ...prev, automaticPause: val }))}
                    tooltip
                    tooltipMessage='Wenn diese Einstellung aktiviert ist, werden Pausen automatisch für Arbeitszeiten erstellt, die länger als 6 Stunden sind'
                />



            </div>

            <CTAButton
                style={{
                    marginTop: "20px"
                }}
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Einstellungen aktualisieren"}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Card>

    )
}

export default PersonalSettingsCard