import React from 'react'
import Card from '../Card/Card'
import { useTasks } from '../../Contexts/TaskContext/TaskContext';
import { useNavigate } from 'react-router-dom';
import DeliveryNoteRow from './DeliveryNoteRow';
import { useMobileView } from '../../Hooks/useMobileView';
import { AddRounded } from '@mui/icons-material';

function DeliveryNoteOverviewCard() {

    const { deliveryNotes } = useTasks();
    const navigate = useNavigate();

    const isMobile = useMobileView();


    return (
        <Card
            title="Lieferscheine"
            ctaTitle="Lieferschein erstellen"
            ctaFunction={() => { navigate(`/aufgaben/lieferschein/erstellen`) }}
            cta
            buttonIcon={<AddRounded fontSize='small' />}
        >
            {!isMobile &&
                <div>
                    <div className='w-[100%] flex p-4'>
                        <div className='w-[32.5%] font-medium'>Kunde</div>
                        <div className='w-[15%] font-medium'>Datum</div>
                        <div lang="de" className='w-[5%] text-center break-words hyphens-auto font-medium'>Status</div>
                        <div lang='de' className='w-[10%] text-center break-words hyphens-auto font-medium'>Nummer</div>
                        <div className='w-[32.5%] font-medium'>Titel</div>
                    </div>
                    {deliveryNotes?.map((deliveryNote: any) => <DeliveryNoteRow deliveryNote={deliveryNote} />)}
                </div>
            }

            {isMobile &&
                <>
                    {deliveryNotes?.map((deliveryNote: any) => {
                        return (
                            <div className='flex flex-row hover:bg-slate-50'>
                                {/*                                 <div className='flex flex-col p-4'>
                                    <div className=''>Kunde</div>
                                    <div className=''>Datum</div>
                                    <div lang="de" className=''>Status</div>
                                    <div lang='de' className=''>Nummer</div>
                                    <div className=''>Titel</div>
                                </div> */}
                                <DeliveryNoteRow deliveryNote={deliveryNote} />
                            </div>

                        )
                    })}
                </>
            }

        </Card>
    )
}

export default DeliveryNoteOverviewCard