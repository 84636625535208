import { AddRounded } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { IEmployee, useEmployees } from "../../Contexts/EmployeeContext/EmployeeProvider";
import { AppointmentCategoryVisibilityTypes, useTasks } from "../../Contexts/TaskContext/TaskContext";
import { IAppointment, ICreateAppointment, appointmentVisibilityList } from "../../Interfaces/Appointment";
import CreateAppointmentCategoryModal from "../../Pages/Calendar/CreateAppointmentCategoryModal";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import Android12Switch from "../Buttons/Switch/Android12Switch";
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored";
import FormRow from "./FormUtils/FormRow";

interface props {
    onClose: () => void,
    errorVariables?: Record<string, boolean>,
    validateInput: (a: ICreateAppointment) => boolean,
    appointmentToEdit?: IAppointment,
    mode?: string
    setShowAppointmentInfoModal?: Dispatch<SetStateAction<boolean>>
}



const CreateAppointmentForm = ({ onClose, errorVariables, validateInput, appointmentToEdit, mode, setShowAppointmentInfoModal }: props) => {

    const [appointment, setAppointment] = useState<IAppointment>({ id: 0, start: dayjs(), end: dayjs().add(1, 'hour'), visibility: AppointmentCategoryVisibilityTypes.Organization });
    const { addAppointment, updateAppointment, selectedDay, appointmentCategories } = useTasks();
    const { companies } = useContacts();
    const { persons } = useContacts();
    const { employees } = useEmployees();

    const [activeEmployees, setActiveEmployees] = useState(employees.filter((employee: IEmployee) => employee.active))
    const [openCreateCategoryModal, setOpenCreateCategoryModal] = useState(false)

    useEffect(() => {
        setActiveEmployees(employees.filter((employee: IEmployee) => employee.active))
    }, [employees])

    const handleSubmit = () => {
        if (validateInput(appointment!)) {
            if (mode === "edit") {
                updateAppointment!(appointment)
            } else {
                addAppointment!(appointment)
            }
            if (setShowAppointmentInfoModal) {
                setShowAppointmentInfoModal(false)
            }
            onClose()
        }
    }

    function getStartDate() {
        //checks if a day is selected to adjust the day that is filled into the form when opened
        if (selectedDay != null) {
            if (selectedDay.format('DD-MM-YYYY') === dayjs().format('DD-MM-YYYY')) {
                //if selected day is the current day use current time as start time
                return appointment.start;
            }
            //if the selected day is not the current day, the standard time that is filled into form is 12PM
            const newStart = selectedDay.set('hour', 12)
            setAppointment((old) => ({ ...old, start: newStart, end: dayjs(newStart).add(1, 'hour') }))
        }
        return appointment.start;
    }

    const handleAddCategory = () => {
        setOpenCreateCategoryModal(true)
    }

    useEffect(() => {
        getStartDate();
    }, [selectedDay])

    useEffect(() => {
        if (appointmentToEdit) {
            setAppointment(appointmentToEdit)
        }
    }, [appointmentToEdit])

    return (
        <>
            <form
                className="form">
                <FormRow type="text"
                    value={appointment?.title}
                    onChange={(val) => setAppointment((old) => ({ ...old, title: val }))}
                    label="Termin-Überschrift"
                    placeholder="Bezeichnung"
                    maxLength={500}
                    error={errorVariables?.titleError}
                    errorMessage={InputFieldErrorMessages.TitleError}
                />
                <FormRow
                    type="multiline"
                    value={appointment?.description}
                    onChange={(val) => setAppointment((old) => ({ ...old, description: val }))}
                    label="Beschreibung (optional)"
                    placeholder="Beschreibung des Termins (max. 5000 Zeichen)"
                    maxLength={5000}
                />
                <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                    <FormRow type="date"
                        value={appointment?.start}
                        onChange={(val) => {
                            setAppointment((old) => ({ ...old, start: dayjs(val), end: dayjs(val).add(1, 'hour') }))
                        }}
                        label="Start des Termins"
                    />
                    <FormRow type="date"
                        value={appointment?.end}
                        onChange={(val) => setAppointment((old) => ({ ...old, end: val }))}
                        label="Ende des Termins"
                        dateOptions={{
                            minDate: appointmentToEdit ? dayjs(appointmentToEdit.start).toDate() : dayjs(appointment.start)?.toDate(),
                            type: 'DateTime'
                        }}
                    />
                </div>
                <FormRow type="select"
                    label="Sichtbarkeit"
                    placeholder="Sichtbarkeit auswählen um verfügbare Kategorien anzuzeigen"
                    value={appointment.visibility}
                    onChange={(val) => setAppointment((old) => ({ ...old, visibility: val }))}
                    selectOptions={{
                        titleKey: 'name',
                        valueKey: 'visibility',
                        options: appointmentVisibilityList
                    }}
                    error={errorVariables?.visibilityError}
                    errorMessage={InputFieldErrorMessages.VisibilityRequired} />
                <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', alignItems: 'center' }}>
                    <FormRow type="select"
                        label="Kategorie (optional)"
                        placeholder="Kategorie auswählen"
                        value={appointment.appointmentCategory}
                        onChange={(val) => setAppointment((old) => ({ ...old, appointmentCategory: val }))}
                        selectOptions={{
                            titleKey: 'name',
                            valueKey: 'id',
                            options: appointment.visibility != null ? (appointment.visibility === AppointmentCategoryVisibilityTypes.Organization ? appointmentCategories?.filter(ac => ac.id! > 0 && ac.visibility === AppointmentCategoryVisibilityTypes.Organization) :
                                appointmentCategories?.filter(ac => ac.id! > 0 && ac.visibility === AppointmentCategoryVisibilityTypes.Private)) : []
                        }}
                    />
                    <Tooltip title='Neue Kategorie erstellen' arrow disableInteractive>
                        <IconButton style={{ marginTop: '16px' }}
                            onClick={handleAddCategory}><AddRounded fontSize="medium" style={{ color: 'darkslategray' }} /></IconButton>
                    </Tooltip>
                </div>
                <h3 style={{ marginBottom: '0px', lineHeight: '10px' }}>Einladungen</h3>
                <FormRow type="select"
                    label="Eingeladene Organisationen (optional)"
                    placeholder="Organisationen auswählen"
                    value={appointment.invitedOrganizationIds}
                    onChange={(val) => setAppointment((old) => ({ ...old, invitedOrganizationIds: val }))}
                    selectOptions={{
                        titleKey: 'name',
                        valueKey: 'id',
                        options: companies,
                        multiple: true
                    }} />
                <FormRow type="select"
                    label="Eingeladene Personen (optional)"
                    placeholder="Personen auswählen"
                    value={appointment.invitedPersonIds}
                    onChange={(val) => setAppointment((old) => ({ ...old, invitedPersonIds: val }))}
                    selectOptions={{
                        titleKey: 'completeName',
                        valueKey: 'id',
                        options: persons,
                        multiple: true
                    }} />
                <FormRow type="select"
                    label="Eingeladene Mitarbeiter*innen (optional)"
                    placeholder="Mitarbeiter*innen auswählen"
                    value={appointment.invitedUserIds}
                    onChange={(val) => setAppointment((old) => ({ ...old, invitedUserIds: val }))}
                    selectOptions={{
                        titleKey: 'username',
                        valueKey: 'id',
                        options: activeEmployees,
                        multiple: true
                    }} />
                {mode !== "edit" &&
                    <div className="form-row" style={{ marginBottom: "1em" }}>
                        <span>Teilnehmer per E-Mail benachrichtigen?</span><Android12Switch color="secondary" checked={appointment.notifyParticipants} onChange={(event, checked) => setAppointment((old: any) => ({ ...old, notifyParticipants: checked }))}></Android12Switch>
                    </div>}

                <CTAButton
                    title="Speichern"
                    onClickHandler={() => handleSubmit()}
                />
            </form>
            <CreateAppointmentCategoryModal
                open={openCreateCategoryModal}
                setOpen={setOpenCreateCategoryModal}
                mode="create"
                visibility={AppointmentCategoryVisibilityTypes.Organization} />
        </>
    )
}

export default CreateAppointmentForm;