import { useState } from "react"
import { ITextModule } from "../../../../redux/apiSlices/TextModule"
import FormRow from "../../../Forms/FormUtils/FormRow"
import { Card, CardContent, CardHeader, CardTitle } from "../../../UI/CardNew"
import { convertTextModuleTypeString } from "../../utils"
import { useForm } from "react-hook-form"
import { useHandleEditDefaultTextModule } from "../../../../redux/requestHandlers/TextModule"
import CTAButton from "../../../Buttons/CTAButton/CTAButton"

type EditOfferTextModuleCardProps = {
    textModule: ITextModule
}

export default function EditOfferTextModuleCard({ textModule }: EditOfferTextModuleCardProps) {
    const [editedTextModule, setEditedTextModule] = useState(textModule);
    const { register, formState: { errors }, handleSubmit } = useForm<ITextModule>({ defaultValues: textModule });

    const { handleEditDefaultTextModule, isLoading } = useHandleEditDefaultTextModule();

    const onSubmit = (data: ITextModule) => {
        handleEditDefaultTextModule(data);
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Standardtext Angebot bearbeiten</CardTitle>
            </CardHeader>
            <CardContent>
                <div className="flex flex-col gap-2 mb-10">
                    <FormRow
                        label="Name:"
                        value={textModule.title}
                        type="text"
                        onChange={() => { }}
                        readonly
                    />

                    <FormRow
                        label="Typ:"
                        value={convertTextModuleTypeString(textModule.type)}
                        type="text"
                        onChange={() => { }}
                        readonly
                    />
                </div>
                <FormRow
                    label="Text vor Übersicht:"
                    placeholder="Sehr geehrte Damen und Herren, ..."
                    value={editedTextModule?.textStart ?? ""}
                    type="multiline"
                    onChange={(val) => setEditedTextModule((old) => ({ ...old, textStart: val }))}
                    register={register("textStart", { required: "Bitte Text angeben", maxLength: { value: 2500, message: "Maximale Zeichenanzahl überschritten" } })}
                    error={!!errors.textStart}
                    errorMessage={errors.textStart?.message}
                />

                <FormRow
                    placeholder="Bitte überweisen Sie..."
                    label="Text nach Übersicht:"
                    value={editedTextModule?.textEnd ?? ""}
                    type="multiline"
                    onChange={(val) => setEditedTextModule((old) => ({ ...old, textEnd: val }))}
                    register={register("textEnd", { required: "Bitte Text angeben", maxLength: { value: 2500, message: "Maximale Zeichenanzahl überschritten" } })}
                    error={!!errors.textEnd}
                    errorMessage={errors.textEnd?.message}
                />

                <div className="flex w-[40%] mt-10 self-center">
                    <CTAButton title="Speichern" isLoading={isLoading} onClickHandler={handleSubmit(() => onSubmit(editedTextModule))} />
                </div>
            </CardContent>

        </Card>
    )
}