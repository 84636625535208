import React, { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider';
import { useUser } from '../../../Contexts/UserContext/UserContext'
import { card } from '../../../Interfaces/Card'
import Card from '../Card';
import styles from "../../PersonalSettingsCard/PersonalSettingsCard.module.css"
import InputField from '../../InputField/InputField';
import { User } from '../../../Interfaces/User';
import { Button, Tooltip } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import EditIcon from '@mui/icons-material/Edit';
import { display } from '@mui/system';
import { Logout } from '@mui/icons-material';

function AccountSettingsCard({ height, width, order }: card) {

    const { user, logout } = useUser();
    const { changeVisibility } = useModals();

    const defaultUser: User = {
        email: "",
        username: "",
        organizationId: 0
    }

    const [userToSubmit, setUserToSubmit] = useState<User>(user ?? defaultUser);

    useEffect(() => {
        if (user) {
            setUserToSubmit(user);
        }
    }, [user])

    const handleEdit = () => {
        changeVisibility!("editEmail", true, { props: { object: userToSubmit, mode: "edit" } })
    }

    const openChangePassword = () => {
        changeVisibility!("editPassword", true, { props: { object: userToSubmit, mode: "edit" } })
    }

    const logoutHandler = () => {
        logout();
    }

    return (
        <Card
            title="Account Einstellungen"
            style={{ height, width, order }}
        >
            <div id={styles["containerDiv"]}>

                <div className='form-row-label'>
                    <span className='label'>
                        Username:
                    </span>
                    <InputField
                        type='string'
                        placeholder='Username'
                        value={userToSubmit.username}
                        isReadOnly={true}
                        onChangeHandler={() => { }}
                    />
                </div>

                <div className='form-row-label'>
                    <span className='label'>
                        Email:
                    </span>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "90%", marginRight: ".5vw" }}>
                            <InputField
                                type='string'
                                placeholder='Email'
                                value={userToSubmit.email}
                                isReadOnly={true}
                                onChangeHandler={(val) => setUserToSubmit((prev) => ({ ...prev, email: val }))}
                            />
                        </div>
                        <Tooltip title="Bearbeiten">
                            <Button color='primary'
                                variant='outlined'
                                component="label"
                                size='small'
                                onClick={() => handleEdit()}
                            >
                                <EditIcon style={{ color: "gray" }} />

                            </Button>
                        </Tooltip>
                    </div>
                </div>

                <div style={{ textAlign: "center", marginBottom: "1em" }}>
                    <Button color='primary'
                        variant='outlined'
                        component="label"
                        style={{ marginBottom: "1vh", width: "50%" }}
                        onClick={() => openChangePassword()}
                    >
                        <LockIcon style={{ marginRight: ".5vw", color: "gray" }} />
                        Passwort ändern
                    </Button>
                </div>

                <div style={{ textAlign: "center", marginBottom: "" }}>
                    <Button color='error'
                        variant='outlined'
                        component="label"
                        style={{ marginBottom: "1vh", width: "50%" }}
                        onClick={() => logout()}
                    >
                        <Logout style={{ marginRight: ".5vw", color: "gray" }} />
                        Abmelden
                    </Button>
                </div>

            </div>
            {/*<div style={{ textAlign: "center", marginTop: "4vh" }}>
                Funktionalität noch nicht implementiert!
    </div>*/}
        </Card>


    )
}

export default AccountSettingsCard