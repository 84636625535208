import { IOrganizationDTO } from "../../../Interfaces/Company"
import styles from "./SelectableOrganizationRow.module.css"
import { ReactComponent as UserCheckIcon } from '../../../Assets/icons/user-check.svg'
import { ReactComponent as UserXIcon } from '../../../Assets/icons/user-x.svg'
import { useState } from "react"


interface ISelectableOrganizationRow {
    organization: IOrganizationDTO
    index: number
    handleSelect: (id: number) => void
    rowIsChecked: (id: number) => boolean
    handleSelectAllUsersForOrganisation: (organizationId: number) => void
    organizationIsMarkedForAddAllPersons: (id: number) => boolean
}

function SelectableOrganizationRow({ organization, index, handleSelect, rowIsChecked, handleSelectAllUsersForOrganisation, organizationIsMarkedForAddAllPersons }: ISelectableOrganizationRow) {

    const [addPersons, setAddPersons] = useState(false);

    const handleIconClick = (organizationId: number) => {
        handleSelectAllUsersForOrganisation(organizationId);
        setAddPersons(!addPersons);
    }

    return (
        <div className="flex">
            <label className={styles.row} htmlFor={`checkboxSelectableOrganization-${organization.id}`}>
                <input className={styles.checkbox} type="checkbox" id={`checkboxSelectableOrganization-${organization.id}`} onChange={() => handleSelect(organization.id!)} checked={rowIsChecked(organization.id!)} />
                <div className={styles.nameContainer}>
                    {organization.name}
                </div>
            </label>
            <div className="flex justify-center items-center mr-4">
                {addPersons ?
                    <UserCheckIcon className="hover:cursor-pointer stroke-green-600" onMouseDown={() => handleIconClick(organization.id!)} />
                    : <UserXIcon className="hover:cursor-pointer stroke-red-600" onMouseDown={() => handleIconClick(organization.id!)} />
                }
            </div>

        </div>
    )
}

export default SelectableOrganizationRow