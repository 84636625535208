import { AddRounded } from '@mui/icons-material'
import { CircularProgress, Modal } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useJournal } from '../../Contexts/JournalContext/JournalProvider'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { card } from '../../Interfaces/Card'
import { journalDTO } from '../../Interfaces/Journal'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import Card from '../Card/Card'
import { dialog } from '../Dialogs/CreateServiceDialog/CreateServiceDialog'
import CreateJournalForm from '../Forms/CreateJournalForm'
import { useSnackBar } from '../../Hooks/useSnackBar'
import JournalCard from '../JournalCard/JournalCard'
import { IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { blankcard } from '../ServiceCard/ServiceCard'
import InputField from '../InputField/InputField'
import FormRow from '../Forms/FormUtils/FormRow'
import MultiLineInputField from '../InputField/MultiLineInputField'
import JournalEntry from '../Journal/JournalEntry'
import { journal } from '../../Interfaces/Journal'
import { useStaff } from '../../Contexts/StaffContext/StaffProvider'
import { validateJournalForm } from '../Dialogs/EditCreateJournalDialog/EditCreateJournalDialog'
import { InputFieldErrorMessages } from '../InputField/InputFieldRefactored'
import useAuthConnection from '../../Hooks/useAuthConnection'



export interface dialogWithProps<T> extends dialog {
    object: T | null
    mode?: string | null
}

interface JournalCard extends blankcard {
    calledPerson: any, setCalledPerson?: Dispatch<SetStateAction<any>>
    clickedPerson: IPersonDTO, setClickedPerson?: Dispatch<SetStateAction<IPersonDTO>>,
    phoneJournalTitle: string, setPhoneJournalTitle?: Dispatch<SetStateAction<string>>,
    phoneJournalEntry: string, setPhoneJournalEntry?: Dispatch<SetStateAction<string>>,
    phoneJournalTime: Date, setPhoneJournalTime?: Dispatch<SetStateAction<Date>>
    phoneJournalDuration: number, setPhoneJournalDuration?: Dispatch<SetStateAction<number>>

}


/*Objekt JournalDTO? */
/**************************************************************************************** */


/**************************************************************************************** */


//JournalDTO von TelefonPage her übergeben! Dort eine Vererbung (extend) inkl. Usestate setJournalDTO
//const EditCreatePhoneJournal = ({ object, setObject }: { object?: journalDTO | null, setObject: Dispatch<SetStateAction<journalDTO>> }) => {
const EditCreatePhoneJournal = ({ width, height, order, calledPerson, setCalledPerson, phoneJournalTitle, setPhoneJournalTitle, phoneJournalEntry,
    setPhoneJournalEntry, phoneJournalTime, setPhoneJournalTime, phoneJournalDuration, setPhoneJournalDuration }: JournalCard) => {

    

    const { addJournal, updateJournal } = useJournal();

    //UseState verändert: Titel und Eintrag übergeben - hätte ich gleich journalToSubmit bearbeitet, dann wäre es mit leerer Klammer gegangen
    const [journalToSubmit, setJournalToSubmit] = useState<journalDTO>({ title: "", description: "" });
    const { user } = useUser();
    const { staff } = useStaff();
    const { persons } = useContacts();
    const { companies } = useContacts();

    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackBar()

    //enqueueSnackbar("EditCreatePhoneJournal." + calledPerson, { variant: "info" }) 

    useEffect(() => {
        setName(calledPerson.completeName?.length ?? 0 > 0 ? calledPerson.completeName! ?? ' ' : ' ')

    }, [calledPerson])

    const [name, setName] = useState(calledPerson.completeName);

    /*export interface journalDTO {
    id?: number
    title?: string
    description?: string
    channelID?: number
    userID?: number
    author?: number
    date?: string
    document?: string
    priority?: number
    documentType?: string
    tags?: string
    organizationId?: number
} */

    const [error, setError] = useState<any>({})

    useEffect(() => { validateJournalForm(journalToSubmit, setError) }, [calledPerson])

    const handleSubmit = async () => {
        try {
            if (validateJournalForm(journalToSubmit, setError)) {
                await addJournal!(journalToSubmit)
                //{ enqueueSnackbar("eingetragen.", { variant: "info" }) }
                setJournalToSubmit({ title: "", description: "" })
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }


    //Immer wenn sich eines von dem verändert, dann verändere das JournalToSubmit
    useEffect(() => {
        setJournalToSubmit((old: journalDTO) =>
        ({
            ...old, belongsToCall: true, category: 1,
            title: calledPerson.completeName ? "Anruf an " + calledPerson.completeName : (calledPerson.name ? "Anruf an " + calledPerson.name : ""),
            personId: calledPerson.name === undefined ? calledPerson.id : null,
            customerOrganizationId: calledPerson.name ? calledPerson.id : calledPerson.belongsToOrganizationId ?? null
        }))
    }, [calledPerson])
    useEffect(() => { setJournalToSubmit((old: journalDTO) => ({ ...old, description: phoneJournalEntry ? phoneJournalEntry : "" })) }, [phoneJournalEntry])

    return (
        <Card
            title={"Journal erstellen"}

            style={
                { width, height, order }
            }
            //Das Plus vor "Erstellen"
            buttonIcon={<AddRounded fontSize='small' />}
            //Der violette Bereich unten, mit dem Text "Erstellen" und dem Handler dafür
            cta
            ctaTitle={loading ? <CircularProgress size={16} color="inherit" /> : "Telefonjournaleintrag speichern"}
            ctaFunction={() => handleSubmit()}
        >
            <FormRow
                label='Journaltext'
                onChange={(val: string) => { setJournalToSubmit((old: journalDTO) => ({ ...old, description: val })) }}
                placeholder='Inhalt des Telefongesprächs eintragen'
                value={journalToSubmit.description!}
                type='multiline'
                error={error.descriptionError || error.descriptionTooLongError}
                errorMessage={error.descriptionError ? InputFieldErrorMessages.JournaltextError : InputFieldErrorMessages.JournaltextTooLongError}
            />
            {/* <FormRow
                value={journalToSubmit.personId}
                type="Select"
                onChange={(val) => setJournalToSubmit((old: journalDTO) => ({ ...old, personId: val }))}
                label="Angerufene Person"
                placeholder='Person auswählen'
                selectOptions={{
                    options: persons,
                    titleKey: "completeName",
                    valueKey: "id",
                }}
            />
            <FormRow
                value={journalToSubmit.customerOrganizationId}
                type="Select"
                onChange={(val) => setJournalToSubmit((old: journalDTO) => ({ ...old, customerOrganizationId: val }))}
                label="Angerufene Firma"
                placeholder='Firma auswählen'
                selectOptions={{
                    options: companies,
                    titleKey: "name",
                    valueKey: "id",
                }}
            />*/}
        </Card>
    )


}

export default EditCreatePhoneJournal