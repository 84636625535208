import React from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CurrentMonthStatsCard from '../../Components/StatsCards/CurrentMonthStatsCard/CurrentMonthStatsCard'
import StatsCard from '../../Components/StatsCards/StatsCard/StatsCard'
import { YearlyStatsCard } from '../../Components/StatsCards/YearlyStatsCard/YearlyStatsCard'
import Page from '../../Navigation/Page'
import LiveTicker from '../../Components/LiveTicker/LiveTicker'

const Stats = () => {
  return (
    <>
      <CardColumn
        width='100%'
        height='98%'
      >
        <CurrentMonthStatsCard
          height='100%'
        />
      </CardColumn>
      <LiveTicker></LiveTicker>
    </>
  )
}

export default Stats