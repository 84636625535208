import { Axios, AxiosError, AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useUser } from '../UserContext/UserContext'
import { TimeTrackingReducer } from './TimeTrackingReducer'
import { errorHandler } from '../../Connection/BaseConnection'
import { autoHideDurationDefault } from '../../Global/Variables'
import { GenerateWorktimePdfDTO } from '../../Interfaces/Worktime'
import { Dayjs } from 'dayjs'

type status = "work" | "pause" | "absent"
type numericStatus = 0 | 1 | 2



interface timeTrackingContext {
    status: status
    begin?: string | null
    pauses?: IPause[] //pauses of the current user
    duration?: { inMonth: number, inWeek: number }
    durationForAll?: { inMonth: Array<{ userId: number, duration: number, serviceDuration: number }>, inWeek: Array<{ userId: number, duration: number, serviceDuration: number }> }
    worktimes?: IWorkTime[] //worktimes of the current user
    worktimesOrganization: IWorkTime[] //all worktimes of all users of the organization
    pausesOrganization?: IPause[] //all pauses of all users of the organization
    holidays?: IHoliday[]
    organizationHolidays?: IHoliday[]
    currentWorktime?: IWorkTime
    setStatus?: (newStatus: status) => void
    getTimeStatus?: (timeStatus: status) => "Nicht eingestempelt" | "Pause" | "Arbeitszeit"
    getWorktimeInPeriod?: any
    startWork?: () => Promise<void>
    dispatchWork?: () => Promise<void>
    dispatchEndOfWork?: () => Promise<void>
    dispatchPause?: () => Promise<void>
    dispatchEndOfPause?: () => Promise<void>
    postscriptWork?: (workTime: IWorkTimeDTO) => Promise<void>
    postscriptPause?: (pause: IPauseDTO) => Promise<void>
    deleteWorktime?: (worktime: IWorkTime) => void
    editWorktime?: (worktime: IWorkTime) => Promise<void>
    editPause?: (pause: IPause) => Promise<void>
    deletePause?: (pause: IPause) => Promise<void>
    generateWorktimeOverviewForUser?: (pdfDTO: GenerateWorktimePdfDTO) => Promise<void>
    createHolidayRequest?: (holiday: IHoliday) => Promise<void>
    acceptHolidayRequest?: (holiday: IHoliday) => Promise<void>
    rejectHolidayRequest?: (holiday: IHoliday) => Promise<void>
    resetHoliday?: (holiday: IHoliday) => Promise<void>
    addHolidayNote?: (holiday: IHoliday) => Promise<void>
    cancelHoliday?: (holiday: IHoliday) => Promise<void>
    fetchHolidays?: () => Promise<void>
}

export interface IPauseDTO {
    userId: number
    organizationId: number
    start: Dayjs
    end?: Dayjs
    workTimeId?: number
    isAutomatic?: boolean
}


export interface IPause extends IPauseDTO {
    id: number
}

export interface IWorkTimeDTO {
    userId: number
    organizationId: number
    start?: Dayjs
    end?: Dayjs
}

export interface IWorkTime extends IWorkTimeDTO {
    id: number
}

interface IWorkTimeStatus {
    username: string
    status: numericStatus
    beginWork: string
}

const TimeTrackingContext = createContext<timeTrackingContext>({
    status: "absent",
    worktimesOrganization: []
})

export interface IHoliday {
    id: number
    userId?: number
    organizationId: number
    requestedDate: Date
    startDate: Date
    endDate: Date
    assignedToUserId?: number
    accepted?: boolean
    rejected?: boolean
    canceled?: boolean
    requesterNote: string
    granterNote: string
    processedDate?: Date
}

export const defaultHoliday: IHoliday = {
    id: 0,
    userId: 0,
    organizationId: 0,
    requestedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    accepted: false,
    rejected: false,
    requesterNote: "",
    granterNote: ""
}

const TimeTrackingProvider = ({ children }: { children: ReactNode }) => {

    const connection = useAuthConnection()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const { user } = useUser();

    const [state, dispatch] = useReducer(TimeTrackingReducer, {
        status: "absent"
    })

    const setPauses = (pauses: IPause[]) => {
        dispatch({
            type: "SET_PAUSES",
            payload: pauses
        })
    }

    //*Utility für das Setzen des Status
    const setStatus = (newStatus: status) => {
        dispatch({
            type: "SET_STATUS",
            payload: newStatus
        })
    }

    const getTimeStatus = (timeStatus: status) => {
        switch (timeStatus) {
            case "absent":
                return "Nicht eingestempelt"
            case "pause":
                return "Pause"
            case "work":
                return "Arbeitszeit"
        }
    }

    const getTimeStatusNumeric = (workStatus: numericStatus) => {
        switch (workStatus) {
            case 1:
                return "work"
            case 2:
                return "pause"
            case 0:
                return "absent"
        }
    }

    const startWork = async (time?: Date | string) => {
        setStatus("work")
        setBegin(time as string)
        dispatch({
            type: "SET_BEGIN",
            payload: time ?? new Date().toISOString()
        })
    }

    const dispatchWork = async () => {
        let x = enqueueSnackbar("Einstempeln wird durchgeführt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/worktime/start")
            .then((res: AxiosResponse) => {
                closeSnackbar(x)

                startWork(new Date())
                setCurrentWorktime(res.data);
                dispatch({
                    type: "ADD_WORKTIME",
                    payload: res.data
                })
                closeSnackbar(x);
                enqueueSnackbar(`Arbeitszeit um ${new Date(res.data.start).toLocaleTimeString()} gestartet.`, { variant: "success" })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const dispatchEndOfWork = async () => {
        let x = enqueueSnackbar("Ausstempeln wird durchgeführt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/worktime/end")
            .then((res: AxiosResponse) => {
                closeSnackbar(x);

                setBegin(null)
                setStatus("absent")
                dispatch({
                    type: "EDIT_WORKTIME",
                    payload: res.data
                })

                //Store response worktime
                const responseWorktime: IWorkTime = res.data as IWorkTime;

                //get start time of worktime
                const startTime = responseWorktime.start!.valueOf();

                //get end time of worktime
                const endTime = responseWorktime.end!.valueOf();

                //calculate length of worktime
                const timeDifferenceMilliseconds = endTime - startTime;
                const timeDifferenceHours = timeDifferenceMilliseconds / (1000 * 60 * 60);

                //if worktime is > 6hours, an automatic pause was added to the worktime => fetch pauses
                if (timeDifferenceHours > 6) {
                    connection.get("/worktime/getallpausefromuser")
                        .then((response: AxiosResponse) => {
                            dispatch({
                                type: "SET_PAUSES",
                                payload: response.data
                            })
                        });

                    connection.get("/worktime/getallpause")
                        .then((response: AxiosResponse) => {
                            dispatch({
                                type: "SET_PAUSES_ORG",
                                payload: response.data
                            })
                        });
                }

                closeSnackbar(x);
                enqueueSnackbar(`Arbeitszeit um ${new Date().toLocaleTimeString()} beendet.`, { variant: "success" })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const dispatchPause = async () => {
        let x = enqueueSnackbar("Pause wird begonnen", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/worktime/startpause")
            .then((res: AxiosResponse) => {
                setBegin(res.data.start)
                setStatus("pause")
                dispatch({
                    type: "ADD_PAUSE",
                    payload: res.data
                });
                closeSnackbar(x);
                enqueueSnackbar(`Pause um ${new Date(res.data.start).toLocaleTimeString()} begonnen.`, { variant: "success" })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const dispatchEndOfPause = async () => {
        let x = enqueueSnackbar("Pause wird beendet", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/worktime/endpause").then((res: AxiosResponse) => {
            const { data } = res;
            dispatch({
                type: "EDIT_PAUSE",
                payload: res.data
            })
            closeSnackbar(x);
            enqueueSnackbar(`Pause um ${new Date(data.end).toLocaleTimeString()} beendet.`)

            getWorkStatus();
        })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const setCurrentWorktime = (worktime: IWorkTime) => {
        dispatch({
            type: "SET_CURRENTWORKTIME",
            payload: worktime
        })
    }

    const setBegin = (begin: string | null) => {
        dispatch({
            type: "SET_BEGIN",
            payload: begin
        })
    }

    const setDuration = (duration: { inMonth: number, inWeek: number }) => {
        dispatch({
            type: "SET_DURATION",
            payload: duration
        })
    }

    const setDurationForAll = (durationForAll: { inMonth: Array<any>, inWeek: Array<any> }) => {
        dispatch({
            type: "SET_DURATIONFORALL",
            payload: durationForAll
        })
    }

    const deleteWorktimeFromState = (worktime: IWorkTime) => {
        dispatch({
            type: "DELETE_WORKTIME",
            payload: worktime
        })
    }

    const editWorktime = async (worktime: IWorkTime) => {
        let x = enqueueSnackbar("Arbeitszeit wird geändert", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.put("/worktime/update", worktime)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Arbeitszeit erfolgreich gespeichert", { variant: "success" })

                dispatch({
                    type: "EDIT_WORKTIME",
                    payload: worktime
                });
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const editPause = async (pause: IPause) => {
        let x = enqueueSnackbar("Pause wird geändert", { variant: "default", autoHideDuration: autoHideDurationDefault });

        connection.put("/worktime/updatepause", pause)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Pause erfolgreich geändert", { variant: "success" })

                dispatch({
                    type: "EDIT_PAUSE",
                    payload: res.data
                });
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const deletePause = async (pause: IPause) => {
        let x = enqueueSnackbar("Pause wird gelöscht", { variant: "default", autoHideDuration: autoHideDurationDefault });

        connection.delete("/worktime/deletepause", { data: pause })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Pause erfolgreich gelöscht", { variant: "success" })

                dispatch({
                    type: "DELETE_PAUSE",
                    payload: pause
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const getWorkStatus = async () => {
        try {
            const { data } = await connection.get("/worktime/workingstateofself")

            setStatus(getTimeStatusNumeric(data?.status))

            setBegin(data.beginWork)

            switch (data.status) {
                case 1:
                    startWork(data.beginWork)
                    break
                case 2:
                    setStatus("pause")
                    break
                case 0:
                    setStatus("absent")
            }

        } catch (error) {
            console.log(error)
        }
    }

    const setWorkTimes = async (worktimes: IWorkTime[]) => {
        dispatch({
            type: "SET_WORKTIMES",
            payload: worktimes
        })
    }

    const setWorkTimesOrganization = async (worktimesOrg: IWorkTime[]) => {
        dispatch({
            type: "SET_WORKTIMES_ORG",
            payload: worktimesOrg
        })
    }

    const setPausesOrganization = async (pausesOrg: IPause[]) => {
        dispatch({
            type: "SET_PAUSES_ORG",
            payload: pausesOrg
        })
    }

    const setHolidays = async (holidays: IHoliday[]) => {
        dispatch({
            type: "SET_HOLIDAYS",
            payload: holidays
        })
    }

    const setOrganizationHolidays = async (holidays: IHoliday[]) => {
        dispatch({
            type: "SET_HOLIDAYS_ORG",
            payload: holidays
        })
    }

    const getWorkTimeMonth = async () => {
        try {
            let duration = { inMonth: 0, inWeek: 0 }
            let durationForAll: { inMonth: Array<any>, inWeek: Array<any> } = { inMonth: [], inWeek: [] }

            connection.get('/worktime/durationincurrentmonth')
                .then((res) => {
                    duration.inMonth = (res.data.duration);
                })
            connection.get('/worktime/durationincurrentweek')
                .then((res) => {
                    duration.inWeek = (res.data.duration)
                })

            let forAllInMonth = await connection.get('/worktime/durationincurrentmonthofall');
            durationForAll.inMonth = forAllInMonth.data;

            let forAllInWeek = await connection.get('/worktime/durationincurrentweekofall');
            durationForAll.inWeek = forAllInWeek.data;

            connection.get('/worktime/getallasyncfromuser')
                .then((res) => {
                    setWorkTimes(res.data)
                    if (res.data[0] != undefined && (res.data[0].end === null || res.data[0].end === undefined)) {
                        setCurrentWorktime(res.data[0])
                    }
                })
            connection.get('/worktime/getallpausefromuser')
                .then((res) => {
                    setPauses(res.data)
                })

            connection.get('/worktime')
                .then((res) => {
                    setWorkTimesOrganization(res.data)
                })

            connection.get('/worktime/getallpause')
                .then((res) => {
                    setPausesOrganization(res.data);
                })

            connection.get('/holiday')
                .then((res) => {
                    setHolidays(res.data);
                });

            connection.get('/holiday/organization')
                .then((res) => {
                    setOrganizationHolidays(res.data);
                });

            setDuration(duration);
            setDurationForAll(durationForAll);
        } catch (error) {
            console.log(error);
        }
    }

    const postscriptWork = async (workTime: IWorkTimeDTO) => {
        try {
            let x = enqueueSnackbar("Arbeitszeit wird nachgetragen", { variant: "default", autoHideDuration: autoHideDurationDefault })

            connection.post("/worktime/create", workTime)
                .then((res: AxiosResponse) => {
                    closeSnackbar(x);
                    enqueueSnackbar("Arbeitszeit erfolgreich nachgetragen.", { variant: "success" })

                    dispatch({
                        type: "ADD_WORKTIME",
                        payload: workTime
                    })
                })
        }
        catch (error) {

        }
        finally {
        }
    }

    const postscriptPause = async (pause: IPauseDTO) => {
        try {
            const { data } = await connection.post("/worktime/createpause", pause)
        }
        catch (error) {

        }
        finally {
            enqueueSnackbar("Pause erfolgreich nachgetragen.", { variant: "success" })
        }
    }

    const deleteWorktime = async (worktime: IWorkTime) => {
        let x = enqueueSnackbar("Arbeitszeit wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.delete("/worktime/delete", { data: worktime })
            .then((res: AxiosResponse) => {
                closeSnackbar(x)
                enqueueSnackbar("Arbeitszeit erfolgreich entfernt.", { variant: "success" })
                deleteWorktimeFromState(worktime)
            })
            .catch((error: any) => {
                enqueueSnackbar("Arbeitszeit konnte nicht entfernt werden", { variant: "error" });
            })
    }

    const getWorktimeInPeriod = async ({ start, end }: { start: Date, end: Date }) => {
        try {
            if (start && end) {
                const { data } = await connection.get(`/worktime/durationin/start=${new Date(start).toISOString()}&end=${new Date(end).toISOString()}`)

                return data;
            }
        } catch (exception) {

        }
    };

    const createHolidayRequest = async (holiday: IHoliday) => {
        let x = enqueueSnackbar("Urlaubsantrag wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })
        await connection.post("/holiday", holiday)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Urlaubsantrag erfolgreich gestellt", { variant: "success" });
                dispatch({
                    type: "ADD_HOLIDAY_REQUEST",
                    payload: res.data
                })
            })
            .catch((error: any) => {
                enqueueSnackbar("Ein Fehler ist aufgetreten", { variant: "error" });
            })
    }

    const acceptHolidayRequest = async (holiday: IHoliday) => {
        await connection.post("/holiday/accept", holiday)
            .then((res: AxiosResponse) => {
                enqueueSnackbar("Urlaubsanfrage erfolgreich akzeptiert.", { variant: "success" });
                dispatch({
                    type: "PROCESS_HOLIDAY",
                    payload: res.data
                })
            })
            .catch((error: AxiosError) => {
                enqueueSnackbar("Urlaubsanfrage konnte nicht akzeptiert werden", { variant: "error" })
            })

    }

    const fetchHolidays = async () => {
        let x = enqueueSnackbar("Urlaube werden aktualisiert", { variant: "default", autoHideDuration: autoHideDurationDefault });
        await connection.get("/holiday")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: "SET_HOLIDAYS",
                    payload: res.data
                })
            })

        await connection.get("/holiday/organization")
            .then((res: AxiosResponse) => {
                dispatch({
                    type: "SET_HOLIDAYS_ORG",
                    payload: res.data
                })
            })
        closeSnackbar(x);
        enqueueSnackbar("Urlaube erfolgreich aktualisert", { variant: "success" })
    }

    const rejectHolidayRequest = async (holiday: IHoliday) => {
        await connection.post("/holiday/reject", holiday)
            .then((res: AxiosResponse) => {
                enqueueSnackbar("Urlaubsanfrage erfolgreich abgelehnt.", { variant: "success" });
                dispatch({
                    type: "PROCESS_HOLIDAY",
                    payload: res.data
                })
            })
            .catch((error: AxiosError) => {
                enqueueSnackbar("Urlaub konnte nicht abgelehnt werden", { variant: "error" })
            })

    }

    const resetHoliday = async (holiday: IHoliday) => {
        await connection.post("/holiday/reset", holiday)
            .then((res: AxiosResponse) => {
                enqueueSnackbar("Eintrag erfolgreich als 'Offen' markiert", { variant: "success" });
                dispatch({
                    type: "PROCESS_HOLIDAY",
                    payload: res.data
                })
            })
    }

    const addHolidayNote = async (holiday: IHoliday) => {
        await connection.post("/holiday/note", holiday)
            .then((res: AxiosResponse) => {
                enqueueSnackbar("Anmerkung erfolgreich hinzugefügt", { variant: "success" });
                dispatch({
                    type: "PROCESS_HOLIDAY",
                    payload: res.data
                })
            })
    }

    const cancelHoliday = async (holiday: IHoliday) => {
        let x = enqueueSnackbar("Urlaub wird storniert", { variant: "default", autoHideDuration: autoHideDurationDefault });

        await connection.post("/holiday/cancel", holiday).then((res: AxiosResponse) => {
            closeSnackbar(x);
            enqueueSnackbar("Urlaub erfolgreich storniert", { variant: "success" });
            dispatch({
                type: "PROCESS_HOLIDAY",
                payload: res.data
            })
        })
            .catch((error: any) => {
                closeSnackbar(x);
                enqueueSnackbar("Fehler beim stornieren", { variant: "error" });
            })
    }

    const generateWorktimeOverviewForUser = async (pdfDTO: GenerateWorktimePdfDTO) => {
        let x = enqueueSnackbar("Übersicht wird generiert", { variant: "default", autoHideDuration: autoHideDurationDefault })
        await connection.post("/worktime/generateWorktimeOverview", pdfDTO)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                const pdfBlob = res.data as Blob;
                return pdfBlob;
            })
            .catch((error: any) => {
                enqueueSnackbar("Fehler beim generieren der Übersicht", { variant: "error" });
            })
    }

    useEffect(() => {
        getWorkStatus()
        getWorkTimeMonth();
    }, [])

    return (
        <TimeTrackingContext.Provider value={{
            status: state.status,
            setStatus,
            begin: state.begin,
            pauses: state.pauses,
            duration: state.duration,
            durationForAll: state.durationForAll,
            worktimes: state.worktimes,
            worktimesOrganization: state.worktimesOrganization,
            pausesOrganization: state.pausesOrganization,
            holidays: state.holidays,
            organizationHolidays: state.organizationHolidays,
            getTimeStatus,
            getWorktimeInPeriod,
            startWork,
            dispatchWork,
            dispatchEndOfWork,
            dispatchPause,
            dispatchEndOfPause,
            postscriptWork,
            postscriptPause,
            deleteWorktime,
            editWorktime,
            editPause,
            deletePause,
            generateWorktimeOverviewForUser,
            createHolidayRequest,
            acceptHolidayRequest,
            rejectHolidayRequest,
            resetHoliday,
            addHolidayNote,
            cancelHoliday,
            fetchHolidays
        }}>
            {children}
        </TimeTrackingContext.Provider>
    )
}

export default TimeTrackingProvider

export const useTimeTracking = () => useContext(TimeTrackingContext)