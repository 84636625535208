import { useEffect, useState } from "react"
import { IEmployee, useEmployees } from "../../Contexts/EmployeeContext/EmployeeProvider"
import { useTimeTracking } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import useAuthConnection from "../../Hooks/useAuthConnection"
import { AxiosResponse } from "axios"
import { useSnackBar } from "../../Hooks/useSnackBar"
import { IDocument, downloadFile } from "../../Contexts/DocumentContext/DocumentContext"

import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, IconButton } from "@mui/material"
import { useParams } from "react-router-dom"

import { card } from "../../Interfaces/Card"
import CTAButton from "../../Components/Buttons/CTAButton/CTAButton"
import FormRow from "../../Components/Forms/FormUtils/FormRow"
import { InputFieldErrorMessages } from "../../Components/InputField/InputFieldRefactored"
import PDFViewer from "../../Components/PDF/all-pages"
import { Dayjs } from "dayjs"
import { useForm } from "react-hook-form"


const AccExportForm = () => {
    const connection = useAuthConnection();

    const [startDate, setStartDate] = useState<Dayjs>();
    const [endDate, setEndDate] = useState<Dayjs>();

    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    useEffect(() => {
        setValue("startDate", startDate);
        setValue("endDate", endDate);
    }, [startDate, endDate])

    //DEBUG
    //useEffect(() => { console.log(selectedDate?.getMonth(), selectedDate?.getFullYear(), userId, pdf) }, [selectedDate, userId, pdf])

    const downloadCSV = (type: string) => {
        //Objekt zur Übergabe d. Parameter gestalten:

        const csvParams = {
            "startMonth": startDate?.month(),
            "startYear": startDate?.year(),
            "endMonth": endDate?.month(),
            "endYear": endDate?.year(),
            "accType": type //temp, falls wir einmal zwischen RZL und BMD oddgl. unterscheiden wollen.
        }


        // Make API call to get CSV string
        connection.post("/bill/generateBuchhaltungsExport", csvParams) //damit ruft UI die API auf...
            .then((res: AxiosResponse) => {
                const names = ["Einnahmen", "Einnahmen_Kum", "Ausgaben"];
                // Jedes Element im res.data ist das Text für ein CSV-Datei. x Elemente im res.data ergeben x CSV-Dateien.
                res.data.forEach((x: any) => {
                    // Installation für die Files aufsetzen und die Installierung starten.
                    const url = window.URL.createObjectURL(new Blob(['\uFEFF' + x], { type: '/bill/generateBuchhaltungsExport;charset=utf-8;' }));
                    const link = document.createElement('a');
                    link.href = url;

                    link.download = `${names.pop()}_${startDate?.month()}-${startDate?.year()}_${endDate?.month()}-${endDate?.year()}.csv`;

                    document.body.appendChild(link);
                    link.click();

                    // Alles was nötig war für die Installierung der Files wird hier aufgeräumt.
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                });

                return;

                // Save CSV string as a local file
                const blob = new Blob(['\uFEFF' + res.data], { type: '/bill/generateBuchhaltungsExport;charset=utf-8;' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                const today = new Date()

                link.href = url;
                link.download = `${"EAReport"}_${today.getFullYear()}_${(today.getMonth() + 1).toString().padStart(2, '0')}_${today.getDay().toString().padStart(2, '0')}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error(`Error: ${error}`);
            })
    }

    return (

        <div className="flex flex-col justify-center items-center w-full">
            <FormRow
                type="date"
                dateOptions={{
                    type: "Month"
                }}
                value={startDate ?? ""}
                label="Startdatum (Monat/Jahr) auswählen"
                onChange={(val) => setStartDate(val)}
                register={register("startDate", { required: "Bitte einen Monat auswählen" })}
                error={!!errors.startDate}
                errorMessage={errors.startDate?.message as string}
                style={{ width: "40%" }}
            />
            <FormRow
                type="date"
                dateOptions={{
                    type: "Month"
                }}
                value={endDate ?? ""}
                label="Enddatum (Monat/Jahr) auswählen"
                onChange={(val) => setEndDate(val)}
                register={register("endDate", { required: "Bitte einen Monat auswählen" })}
                error={!!errors.endDate}
                errorMessage={errors.endDate?.message as string}
                style={{ width: "40%", marginBottom: "2vh" }}
            />
            <CTAButton
                onClickHandler={handleSubmit(() => downloadCSV("CSV"))} /* TODO: implement BMD format in API */
                title="Buchhaltungsdateien (BMD) herunterladen"
                style={{ width: "40%", marginTop: "2vh" }}
            />
            <CTAButton
                onClickHandler={handleSubmit(() => downloadCSV("CSV"))} /* TODO: implement RZL format in API */
                title="Buchhaltungsdateien (RZL) herunterladen"
                style={{ width: "40%", marginTop: "2vh" }}
            />
            <CTAButton
                onClickHandler={handleSubmit(() => downloadCSV("CSV"))} /* TODO: implement Schweighofer format in API */
                title="Buchhaltungsdateien (Schweighofer) herunterladen"
                style={{ width: "40%", marginTop: "2vh" }}
            />
            <CTAButton
                onClickHandler={handleSubmit(() => downloadCSV("CSV"))}
                title="Buchhaltungsdateien (CSV) herunterladen"
                style={{ width: "40%", marginTop: "2vh" }}
            />
        </div>
    )
}
export default AccExportForm;