import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import ButtonControl from "../../../../Components/Buttons/ButtonControl/ButtonControl"
import Card from "../../../../Components/Card/Card"
import { card } from "../../../../Interfaces/Card"
import HolidayRequestRow from "./HolidayRequestRow"
import HolidayRow from "./HolidayRow"
import { IHoliday, useTimeTracking } from "../../../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import { StaffDTO, useStaff } from "../../../../Contexts/StaffContext/StaffProvider"
import HolidayNoteModal from "../../../../Components/Dialogs/ShowHolidayNoteDialog/HolidayNoteModal"
import Pagination from "../../../../Components/Pagination/Pagination"
import ConfirmationDialog from "../../../../Components/Dialogs/ConfirmationDialog/ConfirmationDialog"
import { RefreshRounded } from "@mui/icons-material"
import IconTooltipButton from "../../../../Components/Buttons/IconTooltipButton/IconTooltipButton"
import { CircularProgress } from "@mui/material"
import { useUser } from "../../../../Contexts/UserContext/UserContext"
import { useEmployees } from "../../../../Contexts/EmployeeContext/EmployeeProvider"
import Filter, { IFilterOption } from "../../../../Components/Filter/Filter"

interface IHolidayOverviewCard extends card {
    cardMode: HolidayCardModes
    setCardMode: Dispatch<SetStateAction<HolidayCardModes>>
}

export type HolidayCardModes = "requests" | "confirmed"

function HolidayOverviewCard({ width, height, order, cardMode, setCardMode }: IHolidayOverviewCard) {

    const { holidays, organizationHolidays, fetchHolidays } = useTimeTracking();

    const { staff } = useStaff();

    const { user } = useUser();

    const [holidayRequests, setHolidayRequests] = useState<IHoliday[]>([]);

    const originalHolidayRequests = useMemo(() => getHolidayRequests(), [holidays, organizationHolidays])

    function getHolidayRequests() {
        let holidayRequests: IHoliday[] = []
        if (user?.organizationRoleName === "Admin" || user?.rights?.manageHolidays) {
            if(organizationHolidays) {
                holidayRequests = organizationHolidays.filter((holiday: IHoliday) => (holiday?.accepted) === false && (holiday?.rejected) === false && (holiday?.canceled) === false)  
            }
        }
        else {
            if (holidays !== undefined) {
                holidayRequests = holidays.filter((holiday: IHoliday) => (holiday?.accepted) === false && (holiday?.rejected) === false && (holiday?.canceled) === false)
            }
        }
        return holidayRequests;
    }

    const [holidaysConfirmed, setHolidaysConfirmed] = useState<IHoliday[]>([]);

    const originalHolidaysConfirmed = useMemo(() => getHolidaysConfirmed(), [holidays, organizationHolidays])

    function getHolidaysConfirmed() {
        let holidaysConfirmed: IHoliday[] = [];
        if (user?.organizationRoleName === "Admin" || user?.rights?.manageHolidays) {
            if(organizationHolidays) {
                holidaysConfirmed = organizationHolidays.filter((holiday: IHoliday) => ((holiday?.accepted) || (holiday?.rejected)) && (holiday?.canceled === false));
            }
        }
        else {
            if (holidays !== undefined) {
                holidaysConfirmed = holidays.filter((holiday: IHoliday) => ((holiday?.accepted) || (holiday?.rejected)) && (holiday?.canceled === false));
            }
        }
        return holidaysConfirmed
    }

    const [showNote, setShowNote] = useState(false);
    const [noteHoliday, setNoteHoliday] = useState<IHoliday>();

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmFunction, setConfirmFunction] = useState<() => void>(() => { })

    const [refreshLoading, setRefreshLoading] = useState(false);

    const handleRefresh = async () => {
        setRefreshLoading(true);
        await fetchHolidays!().then(() => setRefreshLoading(false));
    }

    useEffect(() => {

        setHolidayRequests(getHolidayRequests());
        setHolidaysConfirmed(getHolidaysConfirmed());
    }, [organizationHolidays, holidays])

    const buttons = [
        {
            body: (active: boolean) => <span>Offen</span>,
            value: "requests"
        },
        {
            body: (active: boolean) => <span>Erledigt</span>,
            value: "confirmed"
        }

    ]

    const filterOptions: IFilterOption[] = [
        {title: "Gestellt von", valueKey: "userId", referenceList: staff, referenceListValueKey: "person.completeName"},
        {title: "Zugewiesen an", valueKey: "assignedToUserId", referenceList: staff, referenceListValueKey: "person.completeName"}
    ]

    const [page, setPage] = useState<number>(0);
    const itemsPerPage = 9;

    useEffect(() => {
        setPage(0);
    }, [cardMode])

    return (
        <React.Fragment>
            <HolidayNoteModal open={showNote} setOpen={setShowNote} holiday={noteHoliday} />
            <ConfirmationDialog
                open={showConfirmationModal} setOpen={setShowConfirmationModal}
                cancelFunction={() => setShowConfirmationModal(false)}
                modalText="Möchten Sie diesen Urlaub wirklich stornieren? Der betroffene Mitarbeiter erhält eine Benachrichtigung per Email"
                modalTitle="Urlaub stornieren"
                confirmFunction={() => confirmFunction()}
            />
            <Card
                title={cardMode === "requests" ? (user?.rights?.manageHolidays ? "Urlaubsanträge" : "Meine Urlaubsanträge") : (user?.rights?.manageHolidays ? "Urlaube" : "Meine Urlaube")}
                right={() => <div style={{display: "flex", flexDirection: "row"}}><ButtonControl buttons={buttons} value={cardMode} setValue={setCardMode} /><Filter dataToFilter={cardMode==="requests" ? holidayRequests : holidaysConfirmed} setDataToFilter={cardMode === "requests" ? setHolidayRequests : setHolidaysConfirmed} filterOptions={filterOptions} originalData={cardMode === "requests" ? originalHolidayRequests: originalHolidaysConfirmed}/></div>}
                left={<IconTooltipButton title='Aktualisieren' onClick={() => { handleRefresh() }}>{refreshLoading ? <CircularProgress size={16} /> : <RefreshRounded fontSize='small' />}</IconTooltipButton>}
            >
                {cardMode === "requests" &&
                    <header>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", paddingLeft: "1%", borderBottomWidth: "1px", borderBottomColor: "grey", borderBottomStyle: "solid", borderBottomRightRadius: "1px", borderBottomLeftRadius: "1px" }}>
                            <div style={{ width: "15%", textAlign: "center" }}>Gestellt von</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Gestellt am</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Urlaubsstart</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Urlaubsende</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Zugewiesen an</div>
                            <div style={{ width: "10%", textAlign: "center" }}>Urlaubstage</div>
                        </div>
                    </header>
                }
                {cardMode === "confirmed" &&
                    <header>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", paddingLeft: "1%", borderBottomWidth: "1px", borderBottomColor: "grey", borderBottomStyle: "solid", borderBottomRightRadius: "1px", borderBottomLeftRadius: "1px" }}>
                            <div style={{ width: "15%", textAlign: "center" }}>Gestellt von</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Gestellt am</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Urlaubsstart</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Urlaubsende</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Zugewiesen an</div>
                            <div style={{ width: "15%", textAlign: "center" }}>Akzeptiert/Abgelehnt am</div>
                        </div>
                    </header>
                }
                {cardMode === "requests" && holidayRequests.map((request: IHoliday, index) => {
                    if (index >= (page) * itemsPerPage && index < (page + 1) * itemsPerPage) {
                        return (
                            <HolidayRequestRow key={request.id} setNoteHoliday={setNoteHoliday} setShowNote={setShowNote} holidayRequest={request} user={staff.find((staff: StaffDTO) => staff.id === request?.userId)} assignedUser={staff.find((staff: StaffDTO) => staff.id === request?.assignedToUserId)} />
                        )
                    }
                })}
                {cardMode === "requests" &&
                    <div style={{ position: "absolute", bottom: "5%", width: "100%" }}>
                        <Pagination items={holidayRequests ?? []} itemsPerPage={itemsPerPage} page={page} setPage={setPage} />
                    </div>
                }
                {cardMode === "confirmed" && holidaysConfirmed.map((confirmed: IHoliday, index) => {
                    if (index >= (page) * itemsPerPage && index < (page + 1) * itemsPerPage) {
                        return (
                            <HolidayRow setShowConfirmationModal={setShowConfirmationModal} setModalConfirmFunction={setConfirmFunction} key={confirmed.id} setNoteHoliday={setNoteHoliday} setShowNote={setShowNote} holiday={confirmed} user={staff.find((staff: StaffDTO) => staff.id === confirmed?.userId)} assignedUser={staff.find((staff: StaffDTO) => staff.id === confirmed?.assignedToUserId)} />
                        )
                    }
                })}
                {cardMode === "confirmed" &&
                    <div style={{ position: "absolute", bottom: "5%", width: "100%" }}>
                        <Pagination items={holidaysConfirmed ?? []} itemsPerPage={itemsPerPage} page={page} setPage={setPage} />
                    </div>
                }

            </Card>
        </React.Fragment>
    )
}

export default HolidayOverviewCard