import { IDocument, IPersonHasDocuments } from "./DocumentContext"

//Reducer handelt die Aktionen, um den komplexen State zu manipulieren
const userReducer = (state: any, action: any) => {
    switch (action.type) {
        case "SET_DOCUMENTS":
            return {
                ...state,
                documents: action.payload
            }
        case "SET_DOCUMENTSFORPERSON":
            let tempDocumentsForPerson = new Map<number, Array<IDocument>>(state.documentsForPerson)
            tempDocumentsForPerson.set(action.payload.personId, action.payload.documents);

            return {
                ...state,
                documentsForPerson: tempDocumentsForPerson
            }
        case "ADD_DOCUMENTTOPERSON":
            let tempDocumentsForPerson1 = new Map<number, Array<IDocument>>(state.documentsForPerson)
            let documents = tempDocumentsForPerson1.get(action.payload.personId);
            tempDocumentsForPerson1.set(action.payload.personId, [action.payload.document, ...documents ?? []]);

            return {
                ...state,
                documentsForPerson: tempDocumentsForPerson1
            }
        case "SET_DOCUMENTSFORORGANIZATION":
            let tempDocumentsForOrganization = new Map<number, Array<IDocument>>(state.documentsForOrganization)
            tempDocumentsForOrganization.set(action.payload.organizationId, action.payload.documents);

            console.log("tempDocumentsForOrganization", tempDocumentsForOrganization)

            return {
                ...state,
                documentsForCompany: tempDocumentsForOrganization
            }
        case "ADD_DOCUMENTTOORGANIZATION":
            let tempDocumentsForOrganization1 = new Map<number, Array<IDocument>>(state.documentsForCompany)
            let documentsOrganization = tempDocumentsForOrganization1.get(action.payload.organizationId);
            tempDocumentsForOrganization1.set(action.payload.organizationId, [action.payload.document, ...documentsOrganization ?? []]);

            return {
                ...state,
                documentsForCompany: tempDocumentsForOrganization1
            }
        case "SET_USER":
            return {
                ...state,
                user: action.payload
            }
        default:
            return {
                ...state
            }
    }
}

export default userReducer