import React from 'react'
import { IPause } from '../TimeTrackingContext/TimeTrackingProvider'
import { StaffDTO } from './StaffProvider'

const StaffReducer = (state: any, action: any) => {
    switch (action.type) {
        case "SET_STAFF":
            return {
                ...state,
                staff: action.payload,
            }
        case "ADD_STAFF":
            return {
                ...state,
                staff: [...state.staff, action.payload]
            }
        case "UPDATE_STAFF":
            return {
                ...state,
                staff: state.staff.map((staff: StaffDTO) => staff.id == action.payload.id ? { ...action.payload } : staff)
            }
        case "DELETE_STAFF":
            return {
                ...state,
                staff: state.staff.filter((staff: StaffDTO) => staff.id !== action.payload.id)
            }
        case "SET_PB":
            return {
                ...state,
                staff: state.staff.map((tempStaff: StaffDTO) => {
                    if (tempStaff.id === action.payload.id) {
                        return { ...tempStaff, base64: action.payload.base64 }
                    }
                    return tempStaff;
                })
            }
        case "SET_WORKINGSTATES":
            return {
                ...state,
                staff: state.staff.map((tempStaff: StaffDTO) => {
                    let x = action.payload.find((worktime: { username: string, status: number, beginWork: Date, Pauses: IPause[] }) => {
                        if (worktime.username === tempStaff.username) {
                            return true;
                        }
                    })
                    return {...tempStaff, workingStatus: x.status};
                })
            }
        default:
            return {
                ...state
            }
    }

}

export default StaffReducer