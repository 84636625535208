import { useSnackbar } from "notistack";
import { useEffect } from "react";

export const useDeleteTravelMutationNotifications = (deleteTravelSuccess: boolean, deleteTravelError: boolean, deleteTravelIsLoading: boolean, error: any) => {

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (deleteTravelSuccess) {
            enqueueSnackbar("Fahrt erfolgreich gelöscht", { variant: "success" });
        }
    }, [deleteTravelSuccess])

    useEffect(() => {
        if (deleteTravelError && !deleteTravelIsLoading) {
            enqueueSnackbar("Fehler beim Löschen der Fahrt", { variant: "error" });
            console.log("error :D:D:", error)
        }
    }, [deleteTravelError])
}