import { IDepartement, IDepartementDTO } from "./DepartementProvider"

export const DepartementReducer = (state: any, action: any) => {
    switch (action.type) {
        case "SET_EMPLOYEES":
            return {
                ...state,
                employees: action.payload
            }
        case "SET_DEPARTEMENTS":
            return {
                ...state,
                departements: action.payload
            }
        case "UPDATE_DEPARTEMENT":
            return {
                ...state,
                departements: state.departements.map((departement: IDepartement) => {
                    if (departement.id === action.payload.id) {
                        return action.payload;
                    }
                    return departement;
                })
            }
        case "ADD_DEPARTEMENT":
            return {
                ...state,
                departements: [...state.departements, action.payload]
            }
        case "DELETE_DEPARTEMENT":
            return {
                ...state,
                departements: state.departements.filter((dep: IDepartement) => dep.id !== action.payload.id)
            }
        default:
            return {
                ...state
            }
    }
}