import { Dispatch, SetStateAction, useState } from 'react';

import CreateHolidayRequestModal from '../../Components/Dialogs/CreateHolidayRequestDialog/CreateHolidayRequestModal';
import styles from "./Calendar.module.css";
import CalendarCategories from './CalendarCategories';
import CalendarOptionsButton from './CalendarOptionsButton';
import CreateAppointmentModal from './CreateAppointmentModal';
import SmallCalendar from './SmallCalendar';
import { useMobileView } from '../../Hooks/useMobileView';

interface CalendarSidebarProps {
    privateCheckedCategories: { [categoryId: number]: boolean };
    setPrivateCheckedCategories: React.Dispatch<React.SetStateAction<{ [categoryId: number]: boolean }>>;
    selectedView?: string;
    setWeekIndex?: Dispatch<SetStateAction<number>>
    organizationCheckedCategories: { [categoryId: number]: boolean };
    setOrganizationCheckedCategories: React.Dispatch<React.SetStateAction<{ [categoryId: number]: boolean }>>;
}

export default function CalendarSidebar({ selectedView, setWeekIndex, privateCheckedCategories, setPrivateCheckedCategories, organizationCheckedCategories, setOrganizationCheckedCategories }: CalendarSidebarProps) {
    const [createAppointmentOpen, setCreateAppointmentOpen] = useState(false);
    const [createHolidayRequestOpen, setCreateHolidayRequestOpen] = useState(false);

    const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);

    const isMobileView = useMobileView();

    return (
        <>
            {!isMobileView &&
                <aside className={styles.calendarSidebar}>
                    <CreateAppointmentModal open={createAppointmentOpen}
                        setOpen={setCreateAppointmentOpen} />
                    <CreateHolidayRequestModal open={createHolidayRequestOpen} setOpen={setCreateHolidayRequestOpen} />

                    <CalendarOptionsButton setCreateAppointmentOpen={setCreateAppointmentOpen} setCreateHolidayRequestOpen={setCreateHolidayRequestOpen} />
                    <SmallCalendar setWeekIndex={setWeekIndex}
                        selectedView={selectedView} />
                    <CalendarCategories privateCheckedCategories={privateCheckedCategories}
                        setPrivateCheckedCategories={setPrivateCheckedCategories}
                        organizationCheckedCategories={organizationCheckedCategories}
                        setOrganizationCheckedCategories={setOrganizationCheckedCategories} />
                </aside>
            }

            {isMobileView &&
                <aside className={styles.calendarSidebar}>
                    <CreateAppointmentModal open={createAppointmentOpen}
                        setOpen={setCreateAppointmentOpen} />
                    <CreateHolidayRequestModal open={createHolidayRequestOpen} setOpen={setCreateHolidayRequestOpen} />

                    <CalendarOptionsButton setCreateAppointmentOpen={setCreateAppointmentOpen} setCreateHolidayRequestOpen={setCreateHolidayRequestOpen} />
                    <SmallCalendar setWeekIndex={setWeekIndex}
                        selectedView={selectedView} />
                    <CalendarCategories privateCheckedCategories={privateCheckedCategories}
                        setPrivateCheckedCategories={setPrivateCheckedCategories}
                        organizationCheckedCategories={organizationCheckedCategories}
                        setOrganizationCheckedCategories={setOrganizationCheckedCategories} />
                </aside>
            }


        </>

    )
}

/*          <Collapsible open={isCollapsibleOpen} onOpenChange={setIsCollapsibleOpen}>
            <CollapsibleTrigger>
                Collapse
            </CollapsibleTrigger>
            <CollapsibleContent>
                <aside className={styles.calendarSidebar}>
                    <CreateAppointmentModal open={createAppointmentOpen}
                        setOpen={setCreateAppointmentOpen} />
                    <CreateHolidayRequestModal open={createHolidayRequestOpen} setOpen={setCreateHolidayRequestOpen} />
 
                    <CalendarOptionsButton setCreateAppointmentOpen={setCreateAppointmentOpen} setCreateHolidayRequestOpen={setCreateHolidayRequestOpen} />
                    <SmallCalendar setWeekIndex={setWeekIndex}
                        selectedView={selectedView} />
                    <CalendarCategories privateCheckedCategories={privateCheckedCategories}
                        setPrivateCheckedCategories={setPrivateCheckedCategories}
                        organizationCheckedCategories={organizationCheckedCategories}
                        setOrganizationCheckedCategories={setOrganizationCheckedCategories} />
                </aside>
            </CollapsibleContent>
 
        </Collapsible>
  */