import React, { SetStateAction, useEffect } from "react";
import Modal from "../../Components/Dialog/Dialog";
import { BillPrefix } from "../../Interfaces/Accounting";
import EditCreateBillPrefix from "./EditCreateBillPrefix";
import CTAButton from "../../Components/Buttons/CTAButton/CTAButton";
import { useHandleEditBillPrefix } from "../../redux/requestHandlers/BillPrefix";

interface IEditBillPrefixModal {
    billPrefix: BillPrefix
    setBillPrefix: React.Dispatch<SetStateAction<BillPrefix>>
    open: boolean
    setOpen: React.Dispatch<SetStateAction<boolean>>
}



export default function EditBillPrefixModal({ billPrefix, setBillPrefix, open, setOpen }: IEditBillPrefixModal) {

    const { handleEditBillPrefix, isSuccess } = useHandleEditBillPrefix();

    return (
        <Modal
            title="Rechungskreis bearbeiten"
            open={open}
            onClose={() => setOpen(false)}
        >
            <EditCreateBillPrefix
                object={billPrefix}
                setObject={setBillPrefix}
                mode="edit"
                setModalOpen={setOpen}
            />
        </Modal>
    )
}