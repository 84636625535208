import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Input, TextareaAutosize } from '@mui/material'
import React, { useState } from 'react'
import styles from './InputField.module.css'
import { useForm, SubmitHandler } from "react-hook-form"

interface inputField {
    label?: string
    onChangeHandler: (value: string) => void
    onBlurHandler?: (value: string) => void
    value: any
    isSecure?: boolean
    placeholder?: string
    autofocus?: boolean
    multiline?: boolean
    type?: string
    step?: string
    textColor?: string
    error?: boolean
    errorMessage?: string
    required?: boolean
    isRegister?: boolean
    isLogin?: boolean
    isPassReset?: boolean
    usernameAvailable?: boolean
    isReadOnly?: boolean
}

function InputField({ onChangeHandler, onBlurHandler, usernameAvailable, value, isSecure, placeholder, label, autofocus, multiline, type, step, textColor, error, errorMessage, required, isRegister, isLogin, isPassReset, isReadOnly }: inputField) {

    //Falls das Feld ein Passwortfeld sein soll, so regelt dieser State das Anzeigen des eingebenen Strings
    const [showPassword, setShowPassword] = useState(false)
    const [showError, setShowError] = useState(false)

    if (!multiline && !isRegister && !isLogin && !isPassReset) {
        return (
            <div className={styles.wrapper.concat(" flex column")}>
                {label && (<span className="label">
                    {label}
                </span>)}
                <div className={styles.container.concat(" flex centered stretched-justify")}>
                    <input
                        readOnly={isReadOnly}
                        autoFocus={autofocus ?? false}
                        placeholder={placeholder ?? ""}
                        type={isSecure && !showPassword ? "password" : (type ? type : "text")}
                        step={step ?? "1"}
                        onChange={(e) => { onChangeHandler(e.target.value) }} value={value} style={{ width: "100%" }} />
                    {isSecure && (<button className={styles.button.concat(" flex centered pointer")} onClick={() => setShowPassword((prevVal => !prevVal))}>
                        {showPassword ? (
                            <VisibilityOff fontSize="small" />
                        ) : (
                            <Visibility fontSize='small' />
                        )}
                    </button>)}

                    {/*<input
                        type="number"
                        onChange={(e) => { onChangeHandler(e.target.value); console.log(e.target.value) }} 
                        value={value}
                        step=".01"
                        style={{ width: "100%" }}
                    />*/}
                </div>
                {error && <span className={styles.errorLogin}>{errorMessage}</span>}
            </div>
        )
    }
    else if (!multiline && (isRegister || isLogin || isPassReset)) {
        return (
            <div className={styles.wrapper.concat(" flex column")}>
                {label && (<span className="label">
                    {label}
                </span>)}
                <div className={styles.container.concat(" flex centered stretched-justify")}>
                    <input

                        readOnly={isReadOnly}
                        onBlur={(e) => { onBlurHandler?.(e.target.value) }}
                        autoFocus={autofocus ?? false}
                        placeholder={placeholder ?? ""}
                        type={isSecure && !showPassword ? "password" : type ? type : "text"}
                        step={step ?? "1"}
                        onChange={(e) => { onChangeHandler(e.target.value) }} value={value} style={{ width: "100%" }} />
                    {isSecure && (<button className={styles.button.concat(" flex centered pointer")} onClick={() => setShowPassword((prevVal => !prevVal))}>
                        {showPassword ? (
                            <VisibilityOff fontSize="small" />
                        ) : (
                            <Visibility fontSize='small' />
                        )}
                    </button>)}

                    {/*<input
                        type="number"
                        onChange={(e) => { onChangeHandler(e.target.value); console.log(e.target.value) }} 
                        value={value}
                        step=".01"
                        style={{ width: "100%" }}
                    />*/}

                </div>
                {error && <span className={isRegister || isPassReset ? styles.error : styles.errorLogin}>{errorMessage}</span>}
                {usernameAvailable === false && <span className={styles.error}>Benutzername ist bereits vergeben!</span>}
            </div>
        )
    }
    else {
        return (
            <div className={styles.wrapper.concat(" flex column centered")}>
                {label && (<span className="label">
                    {label}
                </span>)}
                <div className={styles.container.concat(" flex centered stretched-justify")}>
                    <TextareaAutosize
                        autoFocus={autofocus ?? false}
                        placeholder={placeholder ?? ""}
                        onChange={(e) => onChangeHandler(e.target.value)}
                        value={value}
                        minRows={5}
                        style={{
                            width: "100%",
                            fontFamily: "inherit",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            lineHeight: 1
                        }}
                    />
                    {isSecure && (<button className={styles.button.concat(" flex centered pointer")} onClick={() => setShowPassword((prevVal => !prevVal))}>
                        {showPassword ? (
                            <VisibilityOff fontSize="small" />
                        ) : (
                            <Visibility fontSize='small' />
                        )}
                    </button>)}
                </div>
            </div>
        )
    }
}

export default InputField