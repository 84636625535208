import CardColumn from '../../../Components/Card/CardColumn/CardColumn';
import DeliveryNoteOverviewCard from '../../../Components/DeliveryNote/DeliveryNoteOverviewCard';
import { useMobileView } from '../../../Hooks/useMobileView';

function DeliveryNote() {
    const isMobile = useMobileView();

    return (
        <>
            <CardColumn height="100%" width="100%">
                <DeliveryNoteOverviewCard />
            </CardColumn>
        </>

    )
}

export default DeliveryNote