import AssignToCompanyDialog from '../../Components/Dialogs/AssignToCompanyDialog/AssignToCompanyDialog'
import React, { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react'
import CreateOfferDialog from '../../Components/Dialogs/CreateOfferDialog/CreateOfferDialog'
import CreateServiceDialog from '../../Components/Dialogs/CreateServiceDialog/CreateServiceDialog'
import CreateTaskDialog from '../../Components/Dialogs/CreateTaskDialog/CreateTaskDialog'
import CreateTravelDialog from '../../Components/Dialogs/CreateTravelDialog/CreateTravelDialog'
import EditCreateCompanyDialog from '../../Components/Dialogs/EditCreateCompanyDialog/EditCreateCompanyDialog'
import EditCreatePersonalDialog from '../../Components/Dialogs/EditCreatePersonalDialog/EditCreatePersonalDialog'
import { EditCreatePersonDialog } from '../../Components/Dialogs/EditCreatePersonDialog/EditCreatePersonDialog'
import { EditCreatePersonfromPhone } from '../../Components/Dialogs/EditCreatePersonfromPhone/EditCreatePersonfromPhone'
import EditCreateProductDialog from '../../Components/Dialogs/EditCreateProductDialog/EditCreateProductDialog'
import EditCreateServiceDialog from '../../Components/Dialogs/EditCreateServiceDialog/EditCreateServiceDialog'
import EditStatusDialog from '../../Components/Dialogs/EditStatusDialog/EditStatusDialog'
import ErrorDialog from '../../Components/Dialogs/ErrorDialog/ErrorDialog'
import PostscriptWorkPauseDialog from '../../Components/Dialogs/TimeTrackingDialog/TimeTrackingDialog'
import AbortTaskDialog from '../../Components/Dialogs/AbortTaskDialog/AbortTaskDialog'
import CreateMultipleServicesDialog from '../../Components/Dialogs/CreateMultipleServicesDialog/CreateMultipleServicesDialog'
import CreateEditPersonalForm from '../../Components/PersonalCards/CreateEditPersonalForm'
import FinishTaskDialog from '../../Components/Dialogs/FinishTaskDialog/FinishTaskDialog'
import EditCreateJournalDialog from '../../Components/Dialogs/EditCreateJournalDialog/EditCreateJournalDialog'
import ProfilePictureDialog from '../../Components/Dialogs/ProfilePictureDialog/ProfilePictureDialog'
import WorkTimeOverviewDialog from '../../Components/Dialogs/WorkTimeOverviewDialog/WorkTimeOverviewDialog'
import PausesOverviewDialog from '../../Components/Dialogs/PausesOverviewDialog/PausesOverviewDialog'
import YesNoDialog from '../../Components/Dialogs/YesNoDialog/YesNoDialog'
import EditCreateContactDynamicDialog from '../../Components/Dialogs/EditCreateContactDynamicDialog/EditCreateContactDynamicDialog'
import EditCreateDepartmentDialog from '../../Components/Dialogs/EditCreateDepartmentDialog/EditCreateDepartmentDialog'
import EditStatusDialogPerson from '../../Components/Dialogs/EditStatusDialog/EditStatusDialogPerson'
import EditEmailDialog from '../../Components/Dialogs/EditEmailDialog/EditEmailDialog'
import ChangePasswordDialog from '../../Components/Dialogs/ChangePasswordDialog/ChangePasswordDialog'
import EditCreateIntervalDialog from '../../Components/Dialogs/EditCreateIntervalDialog/EditCreateIntervalDialog'
import { useSnackBar } from '../../Hooks/useSnackBar'
import EditWorktimePauseAdminDialog from '../../Components/Dialogs/TimeTrackingDialog/EditWorktimePauseAdminDialog'
import PausesOverivewAdminDialog from '../../Components/Dialogs/PausesOverviewAdminDialog/PausesOverviewAdminDialog'

type dialogs = "editCreateIntervall" | "editPassword" | "editEmail" | "editStatusPerson" | "editCreateDepartment" | "editCreateContactDynamic" | "yesNoDialog" | "pausesOverview" | "pausesOverviewAdmin" | "worktimeOverview" | "editCreateProfilePicture" | "editCreateJournal" | "createTask" | "finishTask" | "createService" | "editCreateProduct" | "error" | "createOffer" | "editCreateTravel" | "editCreateCompany" | "editStatus" | "editCreatePerson" | "editCreatePersonfromPhone" | "assignToCompany" | "editCreateService" | "postscriptWorkPause" | "postscriptWorkPauseAdmin" | "editBillingPosition" | "editCreateProduct" | "abortTask" | "transformIntoService" | "editCreatePersonal"



interface IDialogContext {
    changeVisibility?: (dialog: dialogs, visible: boolean, options?: {
        error?: {
            title: string;
            message: string;
        } | undefined;
        props?: {
            object?: any;
            mode?: string;
        } | undefined;
    } | undefined) => void
}



const DialogContext = createContext<IDialogContext>({

})

const DialogProvider = ({ children }: { children: ReactNode }) => {


    const [visibility, setVisibility] = useState({
        createTask: false,
        finishTask: false,
        createService: false,
        error: false,
        createOffer: false,
        createTravel: false,
        editCreateCompany: false,
        editStatus: false,
        editCreatePerson: false,
        editCreatePersonfromPhone: false,
        assignToCompany: false,
        editCreateService: false,
        postscriptWorkPause: false,
        postscriptWorkPauseAdmin: false,
        editBillingPosition: false,
        editCreateProduct: false,
        abortTask: false,
        transformIntoService: false,
        editCreatePersonal: false,
        editCreateJournal: false,
        editCreateProfilePicture: false,
        worktimeOverview: false,
        pausesOverview: false,
        pausesOverviewAdmin: false,
        yesNoDialog: false,
        editCreateContactDynamic: false,
        editCreateDepartment: false,
        editStatusPerson: false,
        editEmail: false,
        editPassword: false,
        editCreateIntervall: false
    })

    /* temporär, zum Testen: */
    const { enqueueSnackbar } = useSnackBar()
    /* temporär, zum Testen: */

    const [error, setError] = useState<null | { message: string, title: string }>(null)

    const [props, setProps] = useState<any | null>(null)

    const changeVisibility = (dialog: dialogs, visible: boolean, options?: { error?: { title: string, message: string }, props?: { object?: any, mode?: string } }) => {
        switch (dialog) {
            case "createTask":
                setVisibility((prev) => ({ ...prev, createTask: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "createService":
                setVisibility((prev) => ({ ...prev, createService: visible }))
                break
            case "error":
                setError(options?.error ?? { title: "Warnung", message: "" });
                setVisibility((prev) => ({ ...prev, error: visible }))
                break
            case "createOffer":
                setVisibility((prev) => ({ ...prev, createOffer: visible }))
                break
            case "editCreateTravel":
                setVisibility((prev) => ({ ...prev, createTravel: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editCreateCompany":
                setVisibility((prev) => ({ ...prev, editCreateCompany: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editCreateService":
                setVisibility((prev) => ({ ...prev, editCreateService: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "assignToCompany":
                setVisibility((prev) => ({ ...prev, assignToCompany: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editCreatePerson":
                setVisibility((prev) => ({ ...prev, editCreatePerson: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    //enqueueSnackbar("Dialog-Provider: Kontakt hinzufügen gestartet", { variant: "info" })
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editCreatePersonfromPhone":
                setVisibility((prev) => ({ ...prev, editCreatePersonfromPhone: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    //enqueueSnackbar("Dialog-Provider: Phone-Kontakt hinzufügen gestartet", { variant: "info" })
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editStatus":
                setVisibility((prev) => ({ ...prev, editStatus: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editCreateProduct":
                setVisibility((prev) => ({ ...prev, editCreateProduct: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "abortTask":
                setVisibility((prev) => ({ ...prev, abortTask: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "transformIntoService":
                setVisibility((prev) => ({ ...prev, transformIntoService: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "postscriptWorkPause":
                setVisibility((prev) => ({ ...prev, postscriptWorkPause: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "postscriptWorkPauseAdmin":
                setVisibility((prev) => ({ ...prev, postscriptWorkPauseAdmin: visible }));
                if (visible) {
                    setProps(options?.props);
                } else {
                    setProps(null);
                }
                break;
            case "editCreatePersonal":
                setVisibility((prev) => ({ ...prev, editCreatePersonal: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "finishTask":
                setVisibility((prev) => ({ ...prev, finishTask: visible }));
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editCreateJournal":
                setVisibility((prev) => ({ ...prev, editCreateJournal: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editCreateProfilePicture":
                setVisibility((prev) => ({ ...prev, editCreateProfilePicture: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break;
            case "worktimeOverview":
                setVisibility((prev) => ({ ...prev, worktimeOverview: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break;
            case "pausesOverview":
                setVisibility((prev) => ({ ...prev, pausesOverview: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "pausesOverviewAdmin":
                setVisibility((prev) => ({ ...prev, pausesOverviewAdmin: visible }))
                if (visible) {
                    setProps(options?.props)
                } else {
                    setProps(null);
                }
                break;
            case "yesNoDialog":
                setVisibility((prev) => ({ ...prev, yesNoDialog: visible }))
                if (visible) {
                    //Falls sichtbar -> Props werden in den State eingefügt, um im Kontext verfügbar zu sein
                    setProps(options?.props)
                } else {
                    //Falls nicht sichtbar im nächsten Render, so sollen die Props zurückgesetzt werden.
                    setProps(null)
                }
                break
            case "editCreateContactDynamic":
                setVisibility((prev) => ({ ...prev, editCreateContactDynamic: visible }))
                if (visible) {
                    setProps(options?.props)
                } else {
                    //setting props not necessary for this component as it updates based on the object.id, resetting props causes crash
                    //setProps(null)
                }
                break
            case "editCreateDepartment":
                setVisibility((prev) => ({ ...prev, editCreateDepartment: visible }))
                if (visible) {
                    setProps(options?.props)
                } else {
                    setProps(null)
                }
                break;
            case "editStatusPerson":
                setVisibility((prev) => ({ ...prev, editStatusPerson: visible }))
                if (visible) {
                    setProps(options?.props)
                } else {
                    setProps(null)
                }
                break;
            case "editEmail":
                setVisibility((prev) => ({ ...prev, editEmail: visible }));
                if (visible) {
                    setProps(options?.props);
                } else {
                    setProps(null);
                }
                break;
            case "editPassword":
                setVisibility((prev) => ({ ...prev, editPassword: visible }));
                if (visible) {
                    setProps(options?.props);
                } else {
                    setProps(null);
                }
                break;
            case "editCreateIntervall":
                setVisibility((prev) => ({ ...prev, editCreateIntervall: visible }));
                if (visible) {
                    setProps(options?.props);
                } else {
                    setProps(null);
                }
                break;
        }
    }


    return (
        <DialogContext.Provider
            value={{
                changeVisibility
            }}
        >
            {children}
            {/* Dialoge */}
            <CreateTaskDialog
                open={visibility.createTask}
                mode={props?.mode}
                object={props?.object}
            />
            <FinishTaskDialog
                open={visibility.finishTask}
                mode={props?.mode}
                object={props?.object}
            />
            <CreateServiceDialog
                open={visibility.createService}
            />
            <ErrorDialog
                title={error?.title ?? ""}
                open={visibility.error}
                errorMessage={error?.message!}
            />
            <CreateOfferDialog
                open={visibility.createOffer}
            />
            <CreateTravelDialog
                object={props?.object}
                mode={props?.mode}
                open={visibility.createTravel}
            />
            <EditCreateCompanyDialog
                open={visibility.editCreateCompany}
                object={props?.object}
                mode={props?.mode}
            />
            <EditStatusDialog
                open={visibility.editStatus}
                object={props?.object}
            />
            <EditCreatePersonDialog
                open={visibility.editCreatePerson}
                object={props?.object}
                mode={props?.mode}
            />
            <EditCreatePersonfromPhone
                open={visibility.editCreatePersonfromPhone}
                object={props?.object}
                mode={props?.mode}
            />
            <AssignToCompanyDialog
                object={props?.object}
                open={visibility.assignToCompany}
            />
            <EditCreateServiceDialog
                object={props?.object}
                open={visibility.editCreateService}
                mode={props?.mode}
            />
            <PostscriptWorkPauseDialog
                open={visibility.postscriptWorkPause}
                mode={props?.mode}
                object={props?.object}
            />
            <EditWorktimePauseAdminDialog
                open={visibility.postscriptWorkPauseAdmin}
                mode={props?.mode}
                object={props?.object}
            />
            <EditCreateProductDialog
                object={props?.object}
                open={visibility.editCreateProduct}
                mode={props?.mode}
            />
            <AbortTaskDialog
                open={visibility.abortTask}
                object={props?.object}
            />
            <CreateMultipleServicesDialog
                open={visibility.transformIntoService}
                object={props?.object}
            />
            <EditCreatePersonalDialog
                object={props?.object}
                open={visibility.editCreatePersonal}
            />
            <EditCreateJournalDialog
                object={props?.object}
                mode={props?.mode}
                open={visibility.editCreateJournal}
            />
            <ProfilePictureDialog
                object={props?.object}
                mode={props?.mode}
                open={visibility.editCreateProfilePicture}
            />
            <WorkTimeOverviewDialog
                open={visibility.worktimeOverview}
                object={props?.object}
            />
            <PausesOverviewDialog
                open={visibility.pausesOverview}
                object={props?.object}
                mode={props?.mode}
            />
            <PausesOverivewAdminDialog
                open={visibility.pausesOverviewAdmin}
                object={props?.object}
                mode={props?.mode}
            />
            <YesNoDialog
                open={visibility.yesNoDialog}
                object={props?.object}
            />
            <EditCreateContactDynamicDialog
                object={props?.object}
                mode={props?.mode}
                open={visibility.editCreateContactDynamic}

            />
            <EditCreateDepartmentDialog
                open={visibility.editCreateDepartment}
                mode={props?.mode}
                object={props?.object}
            />
            <EditStatusDialogPerson
                open={visibility.editStatusPerson}
                mode={props?.mode}
                object={props?.object}
            />
            <EditEmailDialog
                open={visibility.editEmail}
                mode={props?.mode}
                object={props?.object}
            />
            <ChangePasswordDialog
                open={visibility.editPassword}
                mode={props?.mode}
                object={props?.object}
            />
            <EditCreateIntervalDialog
                open={visibility.editCreateIntervall}
                mode={props?.mode}
                object={props?.object}
            />
        </DialogContext.Provider>
    )

}

export default DialogProvider

export const useModals = () => useContext(DialogContext)