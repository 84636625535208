import { Avatar, Button, CircularProgress, IconButton } from '@mui/material'
import { AxiosResponse } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from 'react-image-crop'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useStaff } from '../../Contexts/StaffContext/StaffProvider'
import { useUser } from '../../Contexts/UserContext/UserContext'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { card } from '../../Interfaces/Card'
import { stringToColour } from '../../Navigation/Navigation'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import Card from '../Card/Card'
import styles from './ProfilePictureCard.module.css'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useDebounceEffect } from '../Dialogs/ProfilePictureDialog/useDebounceEffect'
import { canvasPreview } from '../Dialogs/ProfilePictureDialog/canvasPreview'

const ProfilePictureCard = ({ height, width, order }: card) => {

    const { user, setProfilePicture } = useUser()
    const { enqueueSnackbar } = useSnackBar()
    const { changeVisibility } = useModals()
    const { fetchStaff } = useStaff();

    const connection = useAuthConnection();

    var textColorToBackground = function (str: string) {
        var rgb = [0, 0, 0];
        rgb[0] = parseInt(str.substring(1, 3), 16);
        rgb[1] = parseInt(str.substring(3, 5), 16);
        rgb[2] = parseInt(str.substring(5, 8), 16);

        const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

        if (brightness > 125) {
            return "black"
        } else {
            return "white"
        }
    }

    const avatarBgColor = stringToColour(user?.username ?? "");
    const avatarTextColor = textColorToBackground(avatarBgColor);



    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement|null>(null)
    const imgRef = useRef<HTMLImageElement|null>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(1)
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [loading, setLoading] = useState(false)

    const resetData = () => {
        setImgSrc('');
        setCompletedCrop(undefined)
        //setCrop(undefined);
        setScale(1);
        setRotate(0);
        setAspect(1);
        previewCanvasRef.current =null;
        imgRef.current = null;
        fileInputRef.current!.value = '';
    }

    function blobToBase64(blob: any) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    const handleSubmit = async () => {
        try {
            setLoading(true)

            previewCanvasRef.current?.toBlob((blob) => {
                blobToBase64(blob).then((bob64: any) => {
                    let formData = new FormData();
                    formData.append("file", blob!)
                    setProfilePicture!(bob64)

                    connection.post("/user/profilepicture", formData)
                        .then((res: AxiosResponse) => {
                            fetchStaff!();
                        })
                })
            })
            resetData();

        } catch (error) {
            enqueueSnackbar(`Fehler beim Nachtragen.`, { variant: "error" })
            console.error(error)
        } finally {
            setLoading(false);
           
        }

    }

    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () => 
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        if (aspect) {
            const { width, height } = e.currentTarget
            setCrop(centerAspectCrop(width, height, aspect))
        }
    }

    function centerAspectCrop(
        mediaWidth: number,
        mediaHeight: number,
        aspect: number,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 90,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )


    return (

        <Card
            title="Profilbild anpassen"
            style={{ height, width, order }}
        >
            <section className='flex column' style={{ marginBottom: "1.5vh", alignItems: "center", justifyContent: "center", gap: "5px" }}
                >
                <Avatar
                    src={`data:image/jpeg;base64,${user?.profilePictureBase64}`}
                    alt={user?.username?.substring(0, 2)}
                    sx={{ width: 100, height: 100, backgroundColor: avatarBgColor, color: avatarTextColor }}
                >
                </Avatar>
                <span className='subheading'>{user?.username ?? "loading..."}</span>
            </section>
            <div className={styles['picture-upload-container']}>

                <Button color='primary' variant='outlined' component="label" style={{ marginBottom: "1vh" }}>
                    <input ref={fileInputRef} hidden type="file" accept="image/*" onChange={onSelectFile} />
                    <AddAPhotoIcon color='action' style={{ marginRight: "5px" }} />
                    Datei auswählen
                </Button>
            </div>
            {!!imgSrc && (
                <>
                    <div className={styles['text']}>Profilbild anpassen:</div>
                    <div className={styles['crop-container']}>

                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => { setCrop(percentCrop); }}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, width: "100%" }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    </div>
                </>
            )}
            {!!completedCrop && (
                <>
                    <div className={styles['text']}>Vorschau:</div>
                    <div className={styles['completed-crop-container']}>

                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </div>
                </>
            )}

            <CTAButton
                style={{
                    marginTop: "20px"
                }}
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Profilbild aktualisieren"}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Card>
    )
}

export default ProfilePictureCard
