import React, { useEffect, useState } from 'react'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useEmployees } from '../../Contexts/EmployeeContext/EmployeeProvider'
import { ITask, ITaskDTO } from '../../Interfaces/Task'
import InputField from '../InputField/InputField'
import SearchableSelect from '../InputField/SearchableSelect'
import FormRow from './FormUtils/FormRow'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import dayjs from 'dayjs'
import { useGetAllProjectsQuery } from '../../redux/apiSlices/Project'

export interface form<T> {
    object: T
    setObject: React.Dispatch<React.SetStateAction<T>>

}


const FinishTaskForm = ({ object, setObject }: form<ITaskDTO>) => {

    useEffect(() => {
        setObject((old) => ({ ...old, finishedDate: dayjs() }))
    }, [])

    return (
        <form className='form'>
            <FormRow
                value={object.finishedDate}
                type="date"
                onChange={(val) => setObject((old) => ({ ...old, finishedDate: val }))}
                label="Fertigstellungsdatum"
            />
        </form>
    )
}

export default FinishTaskForm