import { useEffect, useState } from 'react';
import FormRow from '../../Components/Forms/FormUtils/FormRow';
import { useUser } from '../../Contexts/UserContext/UserContext'
import styles from './Login.module.css'
import { IOrganization } from '../../Interfaces/Company';
import CreateCompanyForm from '../../Components/Forms/CreateCompanyForm';
import { InputFieldErrorMessages } from '../../Components/InputField/InputFieldRefactored';
import InputField from '../../Components/InputField/InputField';
import CTAButton from '../../Components/Buttons/CTAButton/CTAButton';
import { East, West } from '@mui/icons-material';
import { Divider, LinearProgress, linearProgressClasses, styled } from '@mui/material';
import useAuthConnection from '../../Hooks/useAuthConnection';
import { AxiosResponse } from 'axios';
import Navbar from '../../Components/ShoppingCart/Navbar';
import NavbarComponent from '../../Components/ShoppingCart/Navbar';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Cancel from '../ShoppingCart/Cancel';
import StoreComponent from '../../Components/ShoppingCart/Store';
import Success from '../ShoppingCart/Success';
import CartProvider from '../../Contexts/ShoppingCartContext/CartContext';

interface props {
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorSecondary}`]: {
        backgroundColor: 'var(--brand-light)',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'var(--brand)',
    },
}));

function RegisterNewOrganization({ }: props) {

    const { user } = useUser();

    const [organization, setOrganization] = useState<IOrganization>();
    const [errorVariables, setErrorVariables] = useState<any>({})

    const [currentStep, setCurrentStep] = useState<number>(0);
    const [title, setTitle] = useState<string>("Allgemein");
    const [lastStep, setLastStep] = useState<string>("");
    const [nextStep, setNextStep] = useState<string>("Kontaktdaten");

    const steps = [
        "Allgemein",
        "Kontaktdaten",
        "Adresse",
        "Steuerdaten",
        "Bankdaten",
        "Abonnieren"
    ]

    useEffect(() => {
        if (currentStep > 0) {
            setLastStep(steps[currentStep - 1])
        } else {
            setLastStep("")
        }
        if (currentStep < steps.length - 1) {
            setNextStep(steps[currentStep + 1])
        } else {
            setNextStep("")
        }
        setTitle(steps[currentStep])
    }, [currentStep])

    const checkAllgemeinError = () => {
        let valid = true;

        if (!organization?.name || organization?.name === "") {
            setErrorVariables((old: any) => ({ ...old, organizationNameError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationNameError: false }))
        }

        if (!organization?.managingDirector || organization?.managingDirector === "") {
            setErrorVariables((old: any) => ({ ...old, managingDirectorError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, managingDirectorError: false }))
        }

        return valid;
    }

    const checkContactDataError = () => {
        let valid = true;

        if (!organization?.eMail || organization?.eMail === "") {
            setErrorVariables((old: any) => ({ ...old, organizationEMailError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationEMailError: false }))
        }

        return valid;
    }

    const checkAddressError = () => {
        let valid = true;

        if (!organization?.address?.street || organization?.address?.street === "") {
            setErrorVariables((old: any) => ({ ...old, organizationStreetError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationStreetError: false }))
        }

        if (!organization?.address?.housenumber || organization?.address?.housenumber === "") {
            setErrorVariables((old: any) => ({ ...old, organizationHousenumberError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationHousenumberError: false }))
        }

        if (!organization?.address?.postalcode || organization?.address?.postalcode === "") {
            setErrorVariables((old: any) => ({ ...old, organizationPostalcodeError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationPostalcodeError: false }))
        }

        if (!organization?.address?.city || organization?.address?.city === "") {
            setErrorVariables((old: any) => ({ ...old, organizationCityError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationCityError: false }))
        }

        return valid;
    }

    const checkTaxDataError = () => {
        let valid = true;

        if (!organization?.taxNumber || organization?.taxNumber === "") {
            setErrorVariables((old: any) => ({ ...old, organizationTaxNumberError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationTaxNumberError: false }))
        }

        if (!organization?.court || organization?.court === "") {
            setErrorVariables((old: any) => ({ ...old, organizationCourtError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationCourtError: false }))
        }

        return valid;
    }

    const checkBankError = () => {
        let valid = true;

        if (!organization?.bank?.bank || organization?.bank?.bank === "") {
            setErrorVariables((old: any) => ({ ...old, organizationBankError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationBankError: false }))
        }

        if (!organization?.bank?.iban || organization?.bank?.iban === "") {
            setErrorVariables((old: any) => ({ ...old, organizationIBANError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationIBANError: false }))
        }

        if (!organization?.bank?.bic || organization?.bank?.bic === "") {
            setErrorVariables((old: any) => ({ ...old, organizationBICError: true }))
            valid = false;
        } else {
            setErrorVariables((old: any) => ({ ...old, organizationBICError: false }))
        }

        return valid;
    }

    const connection = useAuthConnection();

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        try {
            connection.get('/organization/ofself')
                .then((res: AxiosResponse) => {
                    setOrganization(res.data);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                })
        } catch (error) {
            setLoading(false);
        }
    }, [])

    const [organizationChanged, setOrganizationChanged] = useState<boolean>(false);

    useEffect(() => {
        setOrganizationChanged(true);
    }, [organization])

    const handleNextStep = () => {
        let valid = false;

        if (currentStep === 0) {
            if (checkAllgemeinError()) {
                setCurrentStep(1);
                valid = true;
            }
        } else if (currentStep === 1) {
            if (checkContactDataError()) {
                setCurrentStep(2);
                valid = true;
            }
        } else if (currentStep === 2) {
            if (checkAddressError()) {
                setCurrentStep(3);
                valid = true;
            }
        } else if (currentStep === 3) {
            if (checkTaxDataError()) {
                setCurrentStep(4);
                valid = true;
            }
        } else if (currentStep === 4) {
            if (checkBankError()) {
                setCurrentStep(5);
                valid = true;
            }
        }

        if (valid && organizationChanged) {
            if (!organization?.id && !loading) {
                connection.post('/organization/createecockpitorganization', organization)
                    .then((res: AxiosResponse) => {
                        setOrganization(res.data);
                        setOrganizationChanged(false);
                    })
            }
            if (organization?.id && !loading) {
                connection.put('/organization/UpdateECockpitOrganization', organization)
                    .then((res: AxiosResponse) => {
                        setOrganization(res.data);
                        setOrganizationChanged(false);
                    })
            }
        }
    }

    useEffect(() => {
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.key === "Enter") {
                e.preventDefault();
                handleNextStep();
            }
        }
        window.addEventListener('keydown', onKeyDown);

        return () => {
            window.removeEventListener('keydown', onKeyDown);
        };

    }, [currentStep, organization])

    const navigate = useNavigate();

    return (
        <div className='page flex centered'>
            <section className={styles.container.concat(" flex column")} style={{ width: currentStep < 5 ? "50%" : "80%"}}>
                <div className='flex centered' style={{ marginTop: "1%", marginBottom: "1%", fontSize: "3vh", fontWeight: "600" }}>
                    Registrierung Ihrer Organisation
                </div>
                {/*currentStep < 5 &&
                    <span style={{ fontWeight: "600", fontSize: "large" }}>{title}</span>
    */}
                <div style={{ overflow: 'scroll', height: /*currentStep < 5*/ false ? "40vh" : "70vh", display: "flex", flexDirection: "column", gap: "0.5em" }}>
                    {/*currentStep === 0 &&
                        <>
                            <FormRow
                                type='text'
                                label='Organisationsname:'
                                placeholder='Musterfirma KG'
                                value={organization?.name}
                                onChange={(val) => setOrganization((prev: any) => ({ ...prev, name: val }))}
                                error={errorVariables?.organizationNameError}
                                errorMessage={InputFieldErrorMessages.NameRequired}
                            />
                            <FormRow
                                type='text'
                                label='Geschäftsführer:'
                                placeholder='Max Mustermann'
                                value={organization?.managingDirector}
                                onChange={(val) => setOrganization((prev: any) => ({ ...prev, managingDirector: val }))}
                                error={errorVariables?.managingDirectorError}
                                errorMessage={InputFieldErrorMessages.NameRequired}
                            />
                        </>
                    }
                    {currentStep === 1 &&
                        <>
                            <FormRow
                                type='text'
                                label='E-Mail*'
                                placeholder='office@ecockpit.at'
                                value={organization?.eMail}
                                onChange={(val) => setOrganization((prev: any) => ({ ...prev, eMail: val }))}
                                error={errorVariables?.organizationEMailError}
                                errorMessage={InputFieldErrorMessages.EMailError}
                            />
                            <FormRow
                                type='text'
                                label='Telefonnummer'
                                placeholder='+43 463 890133 '
                                value={organization?.telephone}
                                onChange={(val) => setOrganization((prev: any) => ({ ...prev, telephone: val }))}
                            />
                            <FormRow
                                type='text'
                                label='Website'
                                placeholder='ecockpit.at'
                                value={organization?.website}
                                onChange={(val) => setOrganization((prev: any) => ({ ...prev, website: val }))}
                            />
                        </>
                    }
                    {currentStep === 2 &&
                        <>
                            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                                <div style={{ width: "70%" }}>
                                    <FormRow
                                        type='text'
                                        label='Straße*'
                                        placeholder='Völkermarkter Straße'
                                        value={organization?.address?.street ?? ""}
                                        onChange={(val) => setOrganization((prev: any) => {
                                            if (prev.address) {
                                                return { ...prev, address: { ...prev.address, street: val } }
                                            }
                                            return { ...prev, address: { id: 0, street: val } }
                                        })}
                                        error={errorVariables?.organizationStreetError}
                                        errorMessage={InputFieldErrorMessages.StreetRequiredError}
                                    />
                                </div>
                                <div style={{ width: "30%" }}>
                                    <FormRow
                                        type='text'
                                        label='Hausnummer*'
                                        placeholder='62'
                                        value={organization?.address?.housenumber ?? ""}
                                        onChange={(val) => setOrganization((prev: any) => {
                                            if (prev.address) {
                                                return { ...prev, address: { ...prev.address, housenumber: val } }
                                            }
                                            return { ...prev, address: { id: 0, housenumber: val } }
                                        })}
                                        error={errorVariables?.organizationHousenumberError}
                                        errorMessage={InputFieldErrorMessages.HousenumberRequiredError}
                                    />
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: "1em" }}>
                                <div style={{ width: "30%" }}>
                                    <FormRow
                                        type='text'
                                        label='Postleitzahl*'
                                        placeholder='9020'
                                        value={organization?.address?.postalcode ?? ""}
                                        onChange={(val) => setOrganization((prev: any) => {
                                            if (prev.address) {
                                                return { ...prev, address: { ...prev.address, postalcode: val } }
                                            }
                                            return { ...prev, address: { id: 0, postalcode: val } }
                                        })}
                                        error={errorVariables?.organizationPostalcodeError}
                                        errorMessage={InputFieldErrorMessages.PostalcodeRequiredError}
                                    />
                                </div>
                                <div style={{ width: "70%" }}>
                                    <FormRow
                                        type='text'
                                        label='Stadt*'
                                        placeholder='Klagenfurt am Wörthersee'
                                        value={organization?.address?.city ?? ""}
                                        onChange={(val) => setOrganization((prev: any) => {
                                            if (prev.address) {
                                                return { ...prev, address: { ...prev.address, city: val } }
                                            }
                                            return { ...prev, address: { id: 0, city: val } }
                                        })}
                                        error={errorVariables?.organizationCityError}
                                        errorMessage={InputFieldErrorMessages.CityRequiredError}
                                    />
                                </div>
                            </div>
                        </>
                    }
                    {currentStep === 3 &&
                        <>
                            {false && <FormRow
                                title='Pauschaliert'
                                type='switch'
                                value={organization?.pauschaliert}
                                onChange={(val) => setOrganization((old: any) => ({ ...old, pauschaliert: val }))}
                            />}
                            <FormRow
                                type='text'
                                label='Steuernummer*'
                                placeholder='Steuernummer'
                                value={organization?.taxNumber}
                                onChange={(val) => setOrganization((prev: any) => ({ ...prev, taxNumber: val }))}
                                error={errorVariables?.organizationTaxNumberError}
                                errorMessage={InputFieldErrorMessages.TaxNumberRequiredError}
                            />
                            <FormRow
                                type='text'
                                label='UID'
                                placeholder='UID'
                                value={organization?.uid}
                                onChange={(val) => setOrganization((prev: any) => ({ ...prev, uid: val }))}
                            />
                            <FormRow
                                type='text'
                                label='Gericht*'
                                placeholder='Gericht'
                                value={organization?.court ?? ""}
                                onChange={(val) => setOrganization((prev: any) => ({ ...prev, court: val }))}
                                error={errorVariables?.organizationCourtError}
                                errorMessage={InputFieldErrorMessages.CourtNameRequiredError}
                            />
                            <FormRow
                                title='Kleinunternehmer'
                                type='switch'
                                value={organization?.kleinunternehmer}
                                onChange={(val) => setOrganization((old: any) => ({ ...old, kleinunternehmer: val }))}
                            />
                        </>
                    }
                    {currentStep === 4 &&
                        <>
                            <span>Hinweis: Diese Bankdaten werden nicht für Ihr eCockpit-Abonnenment genutzt. Sie dienen lediglich dazu zum Beispiel auf Ihre Rechnungen, die Sie mit dem eCockpit erstellen, gedruckt zu werden.</span>
                            <FormRow
                                label='Bank*'
                                placeholder='Bank'
                                type='text'
                                value={organization?.bank?.bank ?? ""}
                                onChange={(val) => setOrganization((old: any) => ({ ...old, bank: { ...old.bank, bank: val } }))}
                                error={errorVariables?.organizationBankError}
                                errorMessage={InputFieldErrorMessages.BankNameRequiredError}
                            />
                            <FormRow
                                label='IBAN*'
                                placeholder='ATXX XXXX XXXX XXXX'
                                type='text'
                                value={organization?.bank?.iban ?? ""}
                                onChange={(val) => setOrganization((old: any) => ({ ...old, bank: { ...old.bank, iban: val } }))}
                                error={errorVariables?.organizationIBANError}
                                errorMessage={InputFieldErrorMessages.IBANRequiredError}
                            />
                            <FormRow
                                label='BIC*'
                                placeholder='XXXXXXXX'
                                type='text'
                                value={organization?.bank?.bic ?? ""}
                                onChange={(val) => setOrganization((old: any) => ({ ...old, bank: { ...old.bank, bic: val } }))}
                                error={errorVariables?.organizationBICError}
                                errorMessage={InputFieldErrorMessages.BICRequiredError}
                            />
                        </>
                    */}
                    {/*currentStep === 5*/ true &&
                        <>
                            {process.env!.NODE_ENV === "production" &&
                                <>
                                    {/*@ts-ignore*/}
                                    <stripe-pricing-table
                                        pricing-table-id="prctbl_1MzOzbCJ5DwX1VoadPd4vfzl"
                                        publishable-key="pk_live_51MyZMcCJ5DwX1VoaUNKnlxO3beokaBU9J0IhhiNJwvtD03ngDLRLn9NL6tXTec6vexJHVPxaGTPq5Gib422vLblk00fD0B5t0L"
                                        client-reference-id={user?.userId}
                                    />
                                </>
                            }
                            {process.env!.NODE_ENV === "development" &&
                                /*<>
                                    
                                    <stripe-pricing-table
                                        pricing-table-id="prctbl_1MyidFCJ5DwX1VoasZtbkn5C"
                                        publishable-key="pk_test_51MyZMcCJ5DwX1Voab0vZx8ObKkqbR2cgn0aIyGckXOafnkgRZjHUbwXk2v5ApUQk6CUFoJJs91HsjlpfFq4kg5QY00LZ4AlTSl"
                                        client-reference-id={user?.userId}
                                    />
                                </>*/

                                <>
                                    <CartProvider>
                                        <NavbarComponent userId={user?.userId} />
                                        <div style={{overflow: 'visible'}}>
                                            <StoreComponent />
                                        </div>
                                    </CartProvider>                                    
                                </>
                            }
                        </>
                    }
                </div>


                {process.env!.NODE_ENV === "development" && currentStep < 5 &&
                    <>
                        <div style={{ marginTop: "1em" }}>
                            <BorderLinearProgress color='secondary' variant="determinate" value={((currentStep + 1) / (steps.length)) * 100} />
                        </div>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "1em" }}>
                            <CTAButton
                                style={{ width: "33%" }}
                                onClickHandler={() => setCurrentStep((old: number) => (old - 1))}
                                disabled={currentStep - 1 < 0}
                                title={<><West fontSize='small' />{lastStep}</>}
                            />
                            <CTAButton
                                style={{ width: "33%" }}
                                onClickHandler={handleNextStep}
                                title={<>{nextStep}<East fontSize='small' /></>}
                            />
                        </div>
                    </>
                }

                {currentStep === 5 &&
                    <>
                        <Divider sx={{boxShadow: '0px 2px 3px grey'}}></Divider>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <CTAButton
                                style={{ width: "33%" }}
                                onClickHandler={() => setCurrentStep((old: number) => (old - 1))}
                                disabled={currentStep - 1 < 0}
                                title={<><West fontSize='small' />{lastStep}</>}
                            />
                        </div>
                        
                    </>
                }
            </section >
            
        </div >
        
    )
}

export default RegisterNewOrganization