import { styled, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useState } from 'react';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

import { DatePicker, DateTimePicker, MobileDatePicker, TimeField } from '@mui/x-date-pickers';
import styles from './InputField.module.css';
import AlarmIcon from '@mui/icons-material/Alarm';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

/* const getDayComponent = (day: Date, selectedDate: Date, dayComponent: JSX.Element) => {
  return React.cloneElement(
      PickersDay,
      {style: { backgroundColor: "red" }},
  );
}; */

/*Für Deutsch umstellen Anfang */

const localeTexts = {
  previousMonth: 'Vorheriger Monat',
  nextMonth: 'Nächster Monat',
  openPreviousView: 'Voriges Fenster öffnen',
  openNextView: 'Nächstes Fenster öffnen',
  cancelButtonLabel: 'Abbrechen',
  clearButtonLabel: 'Löschen',
  okButtonLabel: 'OK',
  todayButtonLabel: 'Heute',
  start: 'Anfang',
  end: 'Ende',
  date: 'Datum',
  time: 'Uhrzeit',
  year: 'Jahr',
  month: 'Monat',
  day: 'Tag',
  hours: 'Stunden',
  minutes: 'Minuten',
  seconds: 'Sekunden',
  openDatePickerDialogue: () => 'Datum auswählen',
  selectDate: () => 'Datum auswählen',
  selectTime: () => 'Uhrzeit auswählen',
  datePickerToolbarTitle: 'Datum auswählen',
  dateTimePickerToolbarTitle: 'Datum & Uhrzeit auswählen',
  timePickerToolbarTitle: 'Uhrzeit auswählen',
  dateRangePickerToolbarTitle: 'Datumsbereich auswählen',
  mobileDatePickerToolbarTitle: 'Datum auswählen',
  mobileDateTimePickerToolbarTitle: 'Datum & Uhrzeit auswählen',
  mobileTimePickerToolbarTitle: 'Uhrzeit auswählen',
  mobileDateRangePickerToolbarTitle: 'Datumsbereich auswählen',
  //class="MuiTypography-root MuiTypography-overline css-1hbyad5-MuiTypography-root" liefer noch immer "Select Date&Time"

};

/*Für Deutsch umstellen Ende */

interface IDateInputField {
  value: Dayjs | null | undefined
  onChangeHandler: (value: Dayjs | null | undefined) => void
  type?: DateInputTypes
  maxDate?: Date | string
  disablePast?: boolean
  disableFuture?: boolean
  minDate?: Date | string
  error?: boolean
  readonly?: boolean
}

export type DateInputTypes = "DateTime" | "Date" | "Month" | "Time"

//TODO Date-Input stylen und funktional machen -> Fabian oder wer Interessa hat
function DateInputField({ value, onChangeHandler, type = "DateTime", maxDate, disablePast, minDate, error, readonly, disableFuture }: IDateInputField) {
  const handleChange = (newValue: Dayjs | null) => {
    onChangeHandler(newValue)
  }

  const handleCancelIconClick = () => {
    onChangeHandler(null);
  }

  const [open, setOpen] = useState(false);

  const setCurrentTime = () => {
    onChangeHandler(dayjs(new Date()));
  }

  if (type == "DateTime") {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
        <div className={styles.datePickerContainer}>
          <DateTimePicker
            disablePast={disablePast}
            disableFuture={disableFuture}
            readOnly={readonly}
            ampm={false}
            /* @ts-ignore */
            minDateTime={minDate ? dayjs(minDate) : undefined}
            /* @ts-ignore */
            maxDateTime={maxDate ? dayjs(maxDate) : undefined}
            value={value ? dayjs(value) : null}
            onChange={handleChange}
            slotProps={{
              textField: {
                className: `${styles.container}`,
                InputProps: {
                  style: { height: '40px', fontSize: '16px' },
                }
              }
            }}
          />
          {!readonly &&
            <AccessTimeIcon onClick={() => setCurrentTime()} sx={{ margin: "0px 8px 0px 8px", '&:hover': { cursor: 'pointer' } }} />
          }        </div>
      </LocalizationProvider>
    )
  }
  else if (type == "Month") {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
        <DatePicker
          className={styles.dateInput}
          views={['month', 'year']}
          value={value ? dayjs(value) : null}
          onChange={handleChange}
          slotProps={{
            textField: {
              className: `${error ? styles.containerError : styles.container}`,
              InputProps: {
                style: { height: '40px', fontSize: '16px' },
              }
            }
          }}
        />
      </LocalizationProvider>
    )
  }
  else if (type === "Time") {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
        <div className={styles.datePickerContainer}>
          <TimeField
            value={value ? dayjs(value) : null}
            readOnly={readonly}
            disableFuture={disableFuture}
            disablePast={disablePast}
            onChange={handleChange}
            slotProps={{
              textField: {
                className: `${styles.container}`,
                InputProps: {
                  style: { height: '40px', fontSize: '16px' },
                }
              }
            }}
          />
        </div>

      </LocalizationProvider>
    )
  }
  else {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='de'>
        <div className={styles.datePickerContainer}>
          <DatePicker
            readOnly={readonly}
            disablePast={false}
            disableFuture={false}
            value={value ? dayjs(value) : null}
            onChange={handleChange}
            slotProps={{
              textField: {
                className: `${styles.container}`,
                InputProps: {
                  style: { height: '40px', fontSize: '16px' },
                }
              }
            }}

          />
          {!readonly && <CancelRoundedIcon className='hover:bg-gray-300' sx={{ margin: "0px 8px 0px 8px", cursor: 'pointer' }} onClick={() => handleCancelIconClick()} />}
          <AccessTimeIcon className='hover:bg-gray-300' onClick={() => setCurrentTime()} sx={{ margin: "0px 8px 0px 8px", '&:hover': { cursor: 'pointer' } }} />
        </div>
      </LocalizationProvider>
    )
  }
}

export default DateInputField;