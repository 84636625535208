import { Collapse, IconButton } from '@mui/material'
import React, { ReactNode, useState } from 'react'
import { getClassNames } from '../../../Hooks/Utilities/Utlitites'
import styles from './CardExpandable.module.css'

import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

interface cardExpandable {
    children: ReactNode
    title: string
    style?: React.CSSProperties
}

const CardExpandable = ({ children, title, style }: cardExpandable) => {

    const [expanded, setExpanded] = useState(false)

    return (
        <div className={styles.wrapper} style={{ ...style, borderWidth: "1.5px 0", borderColor: "var(--light-contrast)", borderStyle: "solid" }}>
            <header className={getClassNames([styles.header])} onClick={() => setExpanded((old) => !old)} >
                <span style={{ fontWeight: "500", fontSize: "14px" }}>{title}</span>
                <IconButton>
                    <KeyboardArrowDownRoundedIcon fontSize='small' />
                </IconButton>
            </header>
            <Collapse in={expanded} >
                <div className={getClassNames([styles.container, "flex", "column"])} style={{ gap: "10px", paddingBottom: "15px" }}>
                    {children}
                </div>
            </Collapse>
        </div>
    )
}

export default CardExpandable