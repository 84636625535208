import dayjs from "dayjs";
import 'dayjs/locale/de';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';

/**
 * This function is used to get a 2d array that includes the days that would be included in a standard calender for this month
 * @param month the month to get the array for, or the current month if no parameter is given
 * @returns returns a 2d array of the given/current month
 */
export function getMonth(month = dayjs().month()) {
    dayjs.locale('de')
    dayjs.extend(updateLocale);
    dayjs.extend(weekOfYear);
    dayjs.extend(advancedFormat);

    dayjs.updateLocale('de', {
        weekdaysShort: [
            "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"
        ]
    })
    month = Math.floor(month);

    const year = dayjs().year();
    const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
    //negative numbers can be used to refer to days before current month
    let currentMonthCount = firstDayOfTheMonth === 0 ? -6 - firstDayOfTheMonth : 1 - firstDayOfTheMonth; //if first day of month is sunday (0) the month display starts one week before so the sunday is displayed in the view for the current month aswell

    getWeek()
    //5 rows in each month if first day of month is sunday add additional row because otherwise not all rows are displayed
    const daysMatrix = new Array(firstDayOfTheMonth === 0 ? 6 : 5).fill([]).map(() => {
        //7 columns represent every day in a week 
        return new Array(7).fill(null).map(() => {
            //every indivdual day
            currentMonthCount++;
            return dayjs(new Date(year, month, currentMonthCount));
        })
    })
    return daysMatrix;
}

export function getWeek(week = dayjs().week()) {
    dayjs.locale('de')
    dayjs.extend(updateLocale);
    dayjs.extend(weekOfYear);

    dayjs.updateLocale('de', {
        weekdaysShort: [
            "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"
        ]
    })
    //saves the date of monday of the given week
    const firstDayOfTheWeek = dayjs().week(week).day(1)

    //sets the counter to the value of the day of the month (1-31) (subtracts 1 so it starts with correct value after incrementing it)
    let counter = firstDayOfTheWeek.date().valueOf() - 1;

    const daysMatrix = new Array(7).fill(null).map(() => {
        counter++;
        //returns the date and incrementing counter -> if counter overflows current month the date starts with next month automatically (e.g 32.August = 1.September)
        return dayjs(new Date(firstDayOfTheWeek.year(), firstDayOfTheWeek.month(), counter))

    })

    return daysMatrix
}