import { Button } from '@mui/material'
import { ReactNode } from 'react'

interface button {
    title: string
    onClick?: () => void
    icon?: ReactNode
}

const BaseButton = ({title, onClick, icon} : button) => {
  return (
    <Button color='inherit' onClick={onClick} style={{textTransform: "none", borderRadius: "5px"}}>
            {icon}
            {title}
    </Button>
  )
}

export default BaseButton