import BillPrefixCard from "../../Components/BillCards/BillPrefix/BillPrefixCard"


const ManageBillPrefix = () => {

    return (
        <>
            <BillPrefixCard
                height="100%"
                width="100%"
            />
        </>
    )
}

export default ManageBillPrefix