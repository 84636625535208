import { Dispatch, SetStateAction, useEffect, useState } from "react"
import Modal from "../../Dialog/Dialog"
import { IHoliday, defaultHoliday, useTimeTracking } from "../../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import FormRow from "../../Forms/FormUtils/FormRow"
import CTAButton from "../../Buttons/CTAButton/CTAButton"

interface IHolidayNoteModal {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    holiday?: IHoliday
}

function HolidayNoteModal({ open, setOpen, holiday }: IHolidayNoteModal) {

    const [holidayToSubmit, setHolidayToSubmit] = useState<IHoliday>(holiday ?? defaultHoliday)
    const { addHolidayNote } = useTimeTracking();

    useEffect(() => {
        if (holiday) {
            setHolidayToSubmit(holiday);
        }
    }, [holiday])

    const handleSubmit = async () => {
        if (holidayToSubmit) {
            await addHolidayNote!(holidayToSubmit).then(() => setOpen(false));
        }
    }

    return (
        <Modal
            title="Anmerkungen"
            open={open}
            onClose={() => setOpen(false)}
        >
            <form className="form">
                <FormRow
                    label="Anmerkung Antragssteller*in"
                    placeholder="Es wurden keine Anmerkungen von dem/der Antragsteller*in hinzugefügt"
                    type="multiline"
                    value={holidayToSubmit?.requesterNote}
                    onChange={() => { }}
                    readonly
                />
                <FormRow
                    label="Anmerkung Antragsbearbeiter*in"
                    placeholder="Es wurden keine Anmerkungen von dem/der Antragsbearbeiter*in hinzugefügt"
                    type="multiline"
                    value={holidayToSubmit?.granterNote}
                    onChange={(val) => setHolidayToSubmit((old: IHoliday) => ({...old, granterNote: val}))}
                />
                <CTAButton
                    title="Speichern"
                    onClickHandler={() => handleSubmit()}
                    style={{ width: "66%", alignSelf: "center", margin: "2%" }}
                />
            </form>
        </Modal>
    )
}

export default HolidayNoteModal