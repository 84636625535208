import { PersonOutline } from "@mui/icons-material"
import Tooltip from "@mui/material/Tooltip"
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import MoreOptionsButton from "../../../../Components/Buttons/MoreOptionsButton/MoreOptionsButton";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton } from "@mui/material";
import { IHoliday, useTimeTracking } from "../../../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { User } from "../../../../Interfaces/User";
import { StaffDTO } from "../../../../Contexts/StaffContext/StaffProvider";
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import FlightOutlinedIcon from '@mui/icons-material/FlightOutlined';
import { useEmployees } from "../../../../Contexts/EmployeeContext/EmployeeProvider";
import { useUser } from "../../../../Contexts/UserContext/UserContext";

interface IHolidayRequestRow {
    holidayRequest: IHoliday
    user?: StaffDTO
    assignedUser?: StaffDTO
    setShowNote?: Dispatch<SetStateAction<boolean>>
    setNoteHoliday?: Dispatch<SetStateAction<IHoliday | undefined>>
}

function HolidayRequestRow({ holidayRequest, user, assignedUser, setShowNote, setNoteHoliday }: IHolidayRequestRow) {

    const { acceptHolidayRequest, rejectHolidayRequest } = useTimeTracking();

    const [slideOut, setSlideOut] = useState(false);

    const { user: currentUser } = useUser();

    const options = [
        {
            text: "Bearbeiten",
            function: () => handleEdit()
        },
        {
            text: "Löschen",
            function: () => handleDelete()
        },
    ]

    const handleAccept = async () => {
        setSlideOut(true);
        setTimeout(() => {
            acceptHolidayRequest!(holidayRequest);
        }, 300)
    }

    const handleReject = () => {
        setSlideOut(true);
        setTimeout(() => {
            rejectHolidayRequest!(holidayRequest);
        }, 300)
    }

    const handleEdit = () => {

    }

    const handleDelete = () => {

    }

    const showNote = () => {
        if (setNoteHoliday) {
            setNoteHoliday(holidayRequest);
        }
        if (setShowNote) {
            setShowNote(true);
        }
    }

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "row", padding: "1%", alignItems: "center", borderWidth: "1px", borderColor: "#f2f2f2", borderStyle: "solid", borderRadius: "8px", transform: slideOut ? "translateX(110%)" : "", transition: "width 0.3s, transform 0.6s" }}>
            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Mitarbeiter" placement="bottom">
                    <PersonOutline style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {user?.person?.completeName}
                </span>
            </div>
            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Gestellt am" placement="bottom">
                    <DateRangeOutlinedIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {new Date(holidayRequest.requestedDate.toString()).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                </span>
            </div>

            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Urlaubsstart" placement="bottom">
                    <DateRangeOutlinedIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {new Date(holidayRequest.startDate.toString()).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                </span>
            </div>

            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Urlaubsende" placement="bottom">
                    <DateRangeOutlinedIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {new Date(holidayRequest.endDate.toString()).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                </span>
            </div>
            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Zugewiesen an" placement="bottom">
                    <SupervisorAccountIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {assignedUser?.person?.completeName ?? assignedUser?.username ?? "-"}
                </span>
            </div>
            <div style={{ width: "10%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Verfügbare Urlaubstage" placement="bottom">
                    <FlightOutlinedIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {`${user?.unusedHolidays ?? "0"} Tage`}
                </span>
            </div>
            <div style={{ width: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Anmerkungen anzeigen" placement="bottom">
                    <IconButton onClick={() => showNote()}>
                        <NoteOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
            {(currentUser?.organizationRoleName === "Admin" || currentUser?.rights?.manageHolidays) &&

                <>
                    <div style={{ width: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <Tooltip title="Annehmen" placement="bottom">
                            <IconButton onClick={() => handleAccept()}>
                                <CheckCircleIcon style={{ color: "green", transform: "scale(1.1)" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div style={{ width: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <Tooltip title="Ablehnen" placement="bottom">
                            <IconButton onClick={() => handleReject()}>
                                <CancelIcon style={{ color: "red", transform: "scale(1.1)" }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </>
            }

            {/*<div style={{ width: "10%", display: "flex", flexDirection: "row", justifyContent: "right" }}>
                <MoreOptionsButton options={options}></MoreOptionsButton>
    </div>*/}
        </div>
    )
}

export default HolidayRequestRow