import { useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import Card from '../Card/Card'
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useIntervalls } from '../../Contexts/IntervallContext/IntervallProvider'
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { IOrganization } from '../../Interfaces/Company'
import { IService } from '../../Interfaces/Service'
import { ITask } from '../../Interfaces/Task'
import "./UnternehmerCard.css"

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['Einnahmen', 'Kleinunternehmerregelung'],
  datasets: [
    {
      label: 'Kleinunternehmerregelung',
      data: [0, 0],
      backgroundColor: [
        'rgba(10, 10, 200, 1)',
        'rgba(10, 10, 200, 0.5)',
      ],
      borderColor: [
        'rgba(10, 10, 200, 1)',
        'rgba(10, 10, 200, 0.5)',
      ],
      borderWidth: 1,
    },
  ],
};

interface Props {
  items: string[];
}


let openServicesCnt = 0;
let kundenCnt = 0;
let firmenCnt = 0;
let personenCnt = 0;
let tasksCnt = 0;
let interneTasksCnt = 0;
let externeTasksCnt = 0;
let intervalleCnt = 0;
let mtlSumme = 0; //Summe d. Intervallrechnungen mtl.;
let mtlAusgabe = 0;
let jaehrlSumme = 0; //Summe d. jährl. Intervallrechnungen;
let jaehrlAusgabe = 0;

function UnternehmerCard({ width, height, order }: card) {

  const [tickerItems, setTickerItems] = useState([""]);

  //Anzahl d. Firmen
  const { companies } = useContacts();
  let tempCompanies = companies;
  firmenCnt = tempCompanies.length;

  //Anzahl offene Leistungen
  const { services } = useServices();
  let tempServices = services.filter((service: IService) => service.billed === false) //tempServices sind jetzt alle Leistungen die noch nicht verrechnet wurden
  openServicesCnt = tempServices.length

  //Anzahl Personen
  const { persons } = useContacts();
  let tempPersons = persons;
  personenCnt = tempPersons.length;

  //Anzahl Kunden
  let tempCustomer = companies.filter((company: IOrganization) => company.customer === true)
  kundenCnt = tempCustomer.length;

  //Anzahl Intervalle
  const { intervalls } = useIntervalls();
  let tempIntervalle = intervalls;//Array aller Intervalle
  intervalleCnt = tempIntervalle.length;


  // Iteration über die Objekte und Aufsummieren der intervall.summe
  mtlSumme = 0;
  mtlAusgabe = 0;
  jaehrlSumme = 0;
  jaehrlAusgabe = 0;
  tempIntervalle.forEach(function (intervall) {
    if (intervall.active) {
      if (intervall.periodIsMonths) {


        let tempPositions = intervall.subscriptionPositions;

        //Iteration über die Intervallpositionen, da dort der Preis gespeichert ist
        tempPositions?.forEach(function (iPosition) {

          //Unternehmer verrechnen Steuer
          if (intervall.active) {
            if (intervall.periodIsMonths) {
              if (iPosition.netto > 0) {
                if (iPosition.tax! > 0) {
                  if (iPosition.discount! > 0) {
                    if (iPosition.discountType!) {
                      mtlSumme += (iPosition.netto! * iPosition.quantity!) - iPosition.discount!;/* * (1 + iPosition.tax! / 100);*/
                    }
                    else {

                      mtlSumme += iPosition.netto! * iPosition.quantity! * (1 - (iPosition.discount! / 100));/* * (1 + iPosition.tax! / 100);*/
                    }
                  }
                  else {
                    mtlSumme += iPosition.netto! * iPosition.quantity!;/* * (1 + iPosition.tax! / 100);*/
                  }
                }
                //Kleinunternehmer verrechnen keine Steuer
                else {
                  if (iPosition.discount! > 0) {
                    if (iPosition.discountType!) {
                      mtlSumme += (iPosition.netto! * iPosition.quantity!) - iPosition.discount!;/* * (1 + iPosition.tax! / 100);*/
                    }
                    else {

                      mtlSumme += iPosition.netto! * iPosition.quantity! * (1 - (iPosition.discount! / 100));/* * (1 + iPosition.tax! / 100);*/
                    }
                  }
                  else {
                    mtlSumme += iPosition.netto! * iPosition.quantity!;/* * (1 + iPosition.tax! / 100);*/
                  }
                }
              }
              /*Hier werden monatliche Ausgaben berechnet */
              else {
                if (iPosition.tax! > 0) {
                  if (iPosition.discount! > 0) {
                    
                    mtlAusgabe += iPosition.netto! * iPosition.quantity! * (1 - (iPosition.discount! / 100));/* (1 + iPosition.tax! / 100);*/
                  }
                  else {
                    mtlAusgabe += iPosition.netto! * iPosition.quantity!;/* * (1 + iPosition.tax! / 100);*/
                  }
                }
                //Kleinunternehmer verrechnen keine Steuer
                else {
                  if (iPosition.discount! > 0) {
                    mtlAusgabe += iPosition.netto! * iPosition.quantity! * (1 - (iPosition.discount! / 100));
                  }
                  else {
                    mtlAusgabe += iPosition.netto! * iPosition.quantity!;
                  }
                }
              }
            }
          }
        })

      }

      if (intervall.periodIsYears) {
        let tempPositions = intervall.subscriptionPositions;


        //Iteration über die Intervallpositionen, da dort der Preis gespeichert ist
        tempPositions?.forEach(function (iPosition) {

          //Unternehmer verrechnen Steuer
          if (intervall.active) {
          if (iPosition.tax! > 0) {
            if (iPosition.discount! > 0) {
              if (iPosition.discountType!) {
                jaehrlSumme += (iPosition.netto! * iPosition.quantity!) - iPosition.discount!;/* * (1 + iPosition.tax! / 100);*/
              }
              else {

                jaehrlSumme += iPosition.netto! * iPosition.quantity! * (1 - (iPosition.discount! / 100));/* * (1 + iPosition.tax! / 100);*/
              }
             
            }
            else {
              jaehrlSumme += iPosition.netto! * iPosition.quantity!;
            }
          }
          //Kleinunternehmer verrechnen keine Steuer
          else {
            if (iPosition.discount! > 0) {
              if (iPosition.discountType!) {
                jaehrlSumme += (iPosition.netto! * iPosition.quantity!) - iPosition.discount!;/* * (1 + iPosition.tax! / 100);*/
              }
              else {

                jaehrlSumme += iPosition.netto! * iPosition.quantity! * (1 - (iPosition.discount! / 100));/* * (1 + iPosition.tax! / 100);*/
              }
             
            }
            else {
              jaehrlSumme += iPosition.netto! * iPosition.quantity!;
            }
          }
        }})
      }
    }
  });



  //Anzahl aller Tasks
  const { tasks, tasksUnformated } = useTasks();
  let tempTasks = tasksUnformated;
  /*In TypeScript haben Arrays die Eigenschaft "length", aber das Objekt tempTasks ist ein assoziatives Array. 
  Ein assoziatives Array (auch bekannt als "Dictionary" oder "Map") ist ein Objekt, das Schlüssel-Wert-Paare enthält, wobei der Schlüssel kein numerischer Index ist.
  Wenn die Anzahl der Elemente gefragt ist, muß man die "Object.keys" Methode verwenden, um ein Array der Schlüssel zu erhalten,
  und dann die Länge des resultierenden Arrays ermitteln: */
  const objectKeys = Object.keys(tempTasks);
  tasksCnt = objectKeys.length; //gibt nur meine Tasks zurück, und dabei nur solche, die einem Kunden zugeordnet sind.

  //Anzahl interner Tasks
  const { companyData } = useCompanyData(); //CompanyData ist immer die eigene Firma!
  interneTasksCnt = tasksUnformated.filter((task: ITask) => task.customerOrganizationId === companyData.id).length;

  //Anzahl externe Tasks
  externeTasksCnt = tasksUnformated.filter((task: ITask) => task.customerOrganizationId != companyData.id).length;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setTickerItems([
          "Zu Verrechnen: " + openServicesCnt,
          "Kunden: " + kundenCnt,
          "Firmen: " + firmenCnt,
          "Personen: " + personenCnt,
          "Tasks: " + tasksCnt,
          "Interne Tasks: " + interneTasksCnt,
          "Externe Tasks: " + externeTasksCnt,
          "Intervalle: " + intervalleCnt,
          "Mtl. Intervallsumme (netto): " + mtlSumme.toFixed(2) + " €",
          "Mtl. Ausgaben (netto): " + mtlAusgabe.toFixed(2) + " €",
          "Jährl. Intervallsumme (netto): " + jaehrlSumme.toFixed(2) + " €"
        ]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  // Set the --ticker-items CSS variable to the length of tickerItems
  const tickerItemCount = tickerItems.length;
  const tickerStyles = { "--ticker-items": tickerItemCount };



  function Kachel({ items }: Props) {
    const rows = [];

    // Schleife durch das Array und gruppieren von 3 Elementen in einer neuen Zeile
    for (let i = 0; i < items.length; i += 3) {
      const rowItems = items.slice(i, i + 3); // 3 Items in der aktuellen Zeile
      const row = (
        <div className="kachel-row" key={i}>
          {rowItems.map((item, j) => (
            <div key={j} className="kachel">
              {item}
            </div>
          ))}
        </div>
      );
      rows.push(row); // Fügen Sie die Zeile in das `rows` Array hinzu
    }
    return <>{rows}</>;
  }


  return (
    <Card
      title='KPI - Key Performance Indicator'
      style={{ width, height, order }}
      fullHeightBody={false}
      right={() => <div></div>}
    >
      <div className='tile-container'>

        {tickerItems.map((item, j) => (
          <div key={j} className='tile'>
            {item}
          </div>
        ))}
      </div>
      {/*<Kachel items={tickerItems} />*/}


    </Card>
  )
}

export default UnternehmerCard