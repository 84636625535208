import { ExpandLessRounded, ExpandMoreRounded, PersonOutline, SnoozeOutlined, WorkHistoryOutlined, WorkOffOutlined, WorkOutline } from "@mui/icons-material";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Collapse, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useModals } from "../../Contexts/DialogContext/DialogProvider";
import { StaffDTO } from "../../Contexts/StaffContext/StaffProvider";
import { IPause, IWorkTime, useTimeTracking } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { IService, serviceWithNames } from "../../Interfaces/Service";
import MoreOptionsButton from "../Buttons/MoreOptionsButton/MoreOptionsButton";
import ServiceOverview from "../Service/ServiceOverview";
import dayjs from "dayjs";
import { getWorktimeDurationAsString } from "../../utils/WorktimeUtils";

interface IWorkTimeRowAdminProps {
    worktime?: IWorkTime
    pauses?: IPause[]
    n?: number
    id: any
    staffMember?: StaffDTO
    servicesToWorktime?: IService[]
}

const WorkTimeRowAdmin = ({ worktime, pauses, n, id, staffMember, servicesToWorktime }: IWorkTimeRowAdminProps) => {

    dayjs.locale('de');

    const dialogContext = useModals();
    const worktimeContext = useTimeTracking();

    const defaultService: serviceWithNames = {
        id: 0,
        employeeDatas: [
            {
                id: 0,
                serviceId: 0,
                organizationId: 0,
                userId: 33,
                from: dayjs(),
                to: dayjs(),
                hourlyRate: 80,
            }
        ],
        organizationId: 4,
        clientOrganizationId: 4,
        type: 1,
        billable: true,
        billed: true,
        description: "Beschreibung",
        title: "Leistungstitel",
        intern: false,
    }

    const [expanded, setExpanded] = useState(false);

    const onPressEdit = () => {
        dialogContext.changeVisibility!("postscriptWorkPauseAdmin", true, { props: { object: { t: worktime, staffMember: staffMember }, mode: "editwork" } })
    }

    const onPressDelete = () => {
        if (worktime) {
            dialogContext.changeVisibility!("yesNoDialog", true, { props: { object: { modalTitle: "Arbeitszeit löschen", submitFunction: () => worktimeContext.deleteWorktime!(worktime), cancelButtonText: "Abbrechen", confirmButtonText: "Löschen", modalText: "Möchten Sie diese Arbeitszeit wirklich löschen?" } } })
        }
    }

    const showPauses = () => {
        dialogContext.changeVisibility!("pausesOverviewAdmin", true, { props: { object: worktime, mode: "admin" } });
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: 'Pausen anzeigen',
            function: showPauses
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    let worktimeStart = dayjs(worktime?.start)

    worktimeStart.second(0);
    worktimeStart.millisecond(0)

    let workTimeEnd = dayjs(worktime?.end)
    workTimeEnd.second(0);
    workTimeEnd.millisecond(0)


    let timeDifferenceMin = (workTimeEnd.valueOf() - worktimeStart.valueOf()) / 1000 / 60;
    let timeDifferenceHours = ((timeDifferenceMin - (timeDifferenceMin % 60)) / 60);

    let tempTimeDifferencePauseMin = 0;
    let timeDifferencePauseHours = 0;

    const [timeDifferencePauseMin, setTimeDifferencePauseMin] = useState<number>(0);
    const [pauseActive, setPauseActive] = useState(false);

    useEffect(() => {
        tempTimeDifferencePauseMin = 0;
        if (pauses) {

            pauses.forEach((element: IPause) => {
                if (element.end) {
                    let pauseTimeEnd = dayjs(element.end);
                    pauseTimeEnd.millisecond(0);
                    pauseTimeEnd.second(0);

                    let pauseTimeStart = dayjs(element.start);
                    pauseTimeStart.millisecond(0);
                    pauseTimeStart.second(0);

                    tempTimeDifferencePauseMin += (pauseTimeEnd.valueOf() - pauseTimeStart.valueOf()) / 1000 / 60;
                }
                else {
                    setPauseActive(true);
                }
            });
            timeDifferencePauseHours = (tempTimeDifferencePauseMin - (tempTimeDifferencePauseMin % 60)) / 60;
            setTimeDifferencePauseMin(tempTimeDifferencePauseMin);
        }
    }, [pauses])

    return (
        <div style={{ borderWidth: "1px", borderColor: "#f2f2f2", borderStyle: "solid", borderRadius: "8px" }} key={id}>
            <div className="itemContainer" style={{ width: "100%", display: "flex", flexDirection: "row", padding: "1%", alignItems: "center", cursor: "pointer", borderBottom: expanded ? "1px solid #e6e6e6" : "" }}>
                <div onClick={() => setExpanded(!expanded)} style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ width: "20%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />}
                        <Tooltip title="Mitarbeiter" placement="bottom">
                            <PersonOutline style={{ marginRight: "0.5vh" }} />
                        </Tooltip>
                        <span>
                            {(staffMember?.person?.completeName !== null && staffMember?.person?.completeName != "") ? staffMember?.person?.completeName : staffMember?.username}
                        </span>
                    </div>
                    <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title="Datum" placement="bottom">
                            <CalendarTodayOutlinedIcon style={{ marginRight: "0.5vh" }} />
                        </Tooltip>
                        <span>
                            {dayjs(worktime?.start).format('ddd DD.MM.YYYY')}
                        </span>
                    </div>
                    <div style={{ width: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title="Beginn der Arbeitszeit" placement="bottom">
                            <WorkOutline style={{ marginRight: "0.5vh" }} />
                        </Tooltip>
                        <span>
                            {dayjs(worktime?.start).format('HH:mm')}
                        </span>
                    </div>

                    <div style={{ width: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title="Ende der Arbeitszeit" placement="bottom">
                            <WorkOffOutlined style={{ marginRight: "0.5vh" }} />
                        </Tooltip>
                        <span>
                            {worktime?.end ? new Date(worktime?.end.toString() + "").toLocaleTimeString("de-DE", { hour: "2-digit", minute: "2-digit" }) : "Aktiv"}
                        </span>
                    </div>

                    <div style={{ width: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title="Pausendauer" placement="bottom">
                            <SnoozeOutlined style={{ marginRight: "0.5vh" }} />
                        </Tooltip>
                        <span>
                            {!pauseActive ? (timeDifferencePauseMin < 60.01 ? timeDifferencePauseMin.toFixed(0) + " min" : (((timeDifferencePauseMin - (timeDifferencePauseMin % 60)) / 60).toFixed(0)) + " h " + (timeDifferencePauseMin % 60).toFixed(0) + " min") : "Aktiv"}
                        </span>
                    </div>
                    <div style={{ width: "10%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title="Arbeitszeit (ohne Pause)" placement="bottom">
                            <WorkHistoryOutlined style={{ marginRight: "0.5vh" }} />
                        </Tooltip>
                        <span>
                            {getWorktimeDurationAsString(dayjs(worktime?.start), worktime?.end, pauses)}
                        </span>
                    </div>
                </div>
                <div style={{ width: "5%", marginLeft: "auto", marginRight: "1vw" }}>
                    <MoreOptionsButton options={options}></MoreOptionsButton>
                </div>
            </div>
            <Collapse in={expanded}>
                <div style={{ width: "100%" }}>

                    {/*<div style={{ display: "flex", flexDirection: "row", width: "100%", marginLeft: "2vw", alignItems: "center", justifyContent: "center" }}>
                        <div style={{width: "10%"}}>
                            Leistungstitel
                        </div>
                        <div style={{width: "10%"}}>
                            Kunde
                        </div>
                        <div style={{width: "10%"}}>
                            Start der Leistung
                        </div>
                        <div style={{width: "10%"}}>
                            Ende der Leistung
                        </div>
                        <div style={{width: "10%"}}>
                            Dauer der Leistung
                        </div>
                    </div>*/}

                    {(servicesToWorktime && servicesToWorktime.length > 0) ?
                        <div className="bold-big" style={{ fontSize: "16px", marginLeft: "2vw", marginTop: "1vh", textAlign: "center", marginBottom: "1vh" }}>
                            Leistungen zur Arbeitszeit
                        </div>
                        : <div style={{ fontSize: "16px", marginLeft: "2vw", marginTop: "1vh", textAlign: "center", marginBottom: "1vh" }}>Keine Leistungen</div>}
                    {servicesToWorktime && servicesToWorktime.length > 0 &&
                        servicesToWorktime?.map((service) => (
                            <div key={service.id} style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
                                <div style={{ width: "75%", marginBottom: "3px", border: "1px #e6e6e6 solid", borderRadius: "8px" }}>
                                    <div style={{ marginLeft: "10px" }}> {/*same as negative margin on accent of ServiceOverview*/}
                                        <ServiceOverview service={service} />
                                    </div>
                                </div>
                            </div>
                        ))
                    }


                </div>
            </Collapse >
        </div >
    )

}

export default WorkTimeRowAdmin