import React, { useEffect, useState } from 'react'
import { ITask } from '../../../Interfaces/Task'
import Modal from '../../Dialog/Dialog'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import TimeOverviewContent from './TimeOverviewChartContent'


interface ITimeOverviewChartDialog extends dialogWithProps<ITask> {
    forall?: boolean
}

const TimeOverviewChartDialog = ({ object, onClose, open, forall = false }: ITimeOverviewChartDialog) => {

    return (
        <Modal
            title="Zeitübersicht Graph"
            open={open}
            onClose={onClose ? () => onClose() : () => { }}
        >
            <TimeOverviewContent open={open} forall={false}/>
        </Modal>
    )
}

export default TimeOverviewChartDialog
