import { ArrowForward, CloseRounded, Password, WindowSharp } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Dropdown from '../../Components/Dropdown/Dropdown'
import InputField from '../../Components/InputField/InputField'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { SavedUser } from '../../Interfaces/User'
import styles from './DoubleOptInConfirmationPage.module.css'

/* Icons */
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import useKeypress from '../../Hooks/useKeyPress'
import { useLocation, useNavigate } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import { boolean } from 'zod'
import InputFieldRefactored from '../../Components/InputField/InputFieldRefactored'
import { baseConnection } from '../../Connection/BaseConnection'

function HeaderElement({ user, setExpanded, expanded, setActiveUser }: { user: SavedUser | null, setExpanded: React.Dispatch<React.SetStateAction<boolean>>, expanded: boolean, setActiveUser: React.Dispatch<React.SetStateAction<SavedUser | null>> }) {

    return (
        <div className={'flex stretched-justify'}>
            <div className='flex centered-aligned'>
                <div>
                    {/* Company-Logo */}
                </div>
                <div className='flex column'>
                    {/* Accout-Daten */}
                    <span className='heading'>
                        {user?.company}
                    </span>
                    <span className='heading-small' style={{ color: "var(--text-contrast)" }}>
                        {user?.email}
                    </span>
                </div>
            </div>
            <div className='flex' style={{ gap: "5px" }}>
                {/* Button-Sektion */}
                <IconButton onClick={() => setExpanded((val) => !val)}>
                    <KeyboardArrowDownRoundedIcon fontSize='small' />
                </IconButton>
                <IconButton onClick={() => setActiveUser(null)}>
                    <CloseRounded fontSize='small' />
                </IconButton>
            </div>
        </div>
    )
}

function DoubleOptInConfirmationPage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('Token');

    const [verificationText, setVerificationText] = useState<string>("");

    useEffect(() => {
        if (token) {
            baseConnection.post(`/doubleoptin/${token}`)
                .then((res: AxiosResponse) => {
                    if (res.status >= 200 && res.status < 300) {
                        setVerificationText("Sie haben sich erfolgreich zum Newsletter angemeldet.")
                    } else if (res.status == 403) {
                        setVerificationText("Sie sind bereits zum Newsletter angemeldet.")
                    } else {
                        setVerificationText("Bei der Verifizierung Ihrer E-Mail Adresse ist ein fehler aufgetreten. Bitte versuchen Sie es erneut. Sollte es weiterhin ein Problem geben melden Sie sich bitte bei unserer Hotline.")
                    }
                }).catch((error: string) => {
                    if (error.toString().includes("403")) {
                        setVerificationText("Sie sind bereits zum Newsletter angemeldet.")
                    } else {
                        setVerificationText("Bei der Verifizierung Ihrer E-Mail Adresse ist etwas fehlgeschlagen. Bitte versuchen Sie es erneut. Sollte es weiterhin ein Problem geben melden Sie sich bitte bei unserer Hotline.")
                    }
                })
        }
    }, [token])


    return (
        <div className='page flex centered'>
            <section className={styles.container.concat(" flex column")}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <a href='https://ecockpit.at/' target='_blank' style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <img src='https://ecockpit.at/wp-content/uploads/2022/08/eCockpit-Logo.png' style={{ width: "33%" }} ></img>
                    </a>
                </div>
                <div style={{ textAlign: "center", marginTop: "1%", marginBottom: "1%", fontSize: "3vh", fontWeight: "600" }}>
                    {verificationText}
                </div>
            </section>
        </div>
    )
}

export default DoubleOptInConfirmationPage