import React, { lazy, Suspense } from 'react'

//Provider-Imports
import BillProvider from '../Contexts/BillContext/BillProvider'
import CompanyDataProvider from '../Contexts/CompanyDataContext/CompanyDataProvider'
import DeliveryNoteProvider from '../Contexts/DeliveryNoteContext/DeliveryNoteContext'
import DepartementProvider from '../Contexts/DepartementContext/DepartementProvider'
import DialogProvider from '../Contexts/DialogContext/DialogProvider'
import { DocumentProvider } from '../Contexts/DocumentContext/DocumentContext'
import EMailProvider from '../Contexts/EMailContext/EMailProvider'
import EmployeeProvider from '../Contexts/EmployeeContext/EmployeeProvider'
import IntervallProvider from '../Contexts/IntervallContext/IntervallProvider'
import JournalProvider from '../Contexts/JournalContext/JournalProvider'
import OfferProvider from '../Contexts/OfferContext/OfferProvider'
import OrganizationRightsProvider from '../Contexts/OrganizationRightsContext/OrganizationRightsProvider'
import ProductProvider from '../Contexts/ProductContext/ProductProvider'
import ServiceProvider from '../Contexts/ServiceContext/ServiceContext'
import SettingsProvider from '../Contexts/SettingsContext/SettingsProvider'
import StaffProvider from '../Contexts/StaffContext/StaffProvider'
import TaskProvider from '../Contexts/TaskContext/TaskContext'
import TimeTrackingProvider from '../Contexts/TimeTrackingContext/TimeTrackingProvider'
import { useUser } from '../Contexts/UserContext/UserContext'

//Router-Imports
import AuthRouter from './AuthRouter'
import OnboardingRouter from './OnboardingRouter'
import TagProvider from '../Contexts/ContactsContext/ContactsProvider'


//Hauptrouter -> Entscheidet über Router in Nutzung
function MainRouter() {

    const { user } = useUser() ?? {}

    if (user && user?.needsToRegisterOrganization !== true) {
        return (
            <OrganizationRightsProvider>
                <SettingsProvider>
                    <TagProvider>
                        <DocumentProvider>
                            <DepartementProvider>
                                <StaffProvider>
                                    <EmployeeProvider>
                                        <IntervallProvider>
                                            <ProductProvider>
                                                <TaskProvider>
                                                    <ServiceProvider>
                                                        <DeliveryNoteProvider>
                                                            <JournalProvider>
                                                                <TimeTrackingProvider>
                                                                    <CompanyDataProvider>
                                                                        <BillProvider>
                                                                            <OfferProvider>
                                                                                <DialogProvider>
                                                                                    <EMailProvider>
                                                                                        <AuthRouter />
                                                                                    </EMailProvider>
                                                                                </DialogProvider>
                                                                            </OfferProvider>
                                                                        </BillProvider>
                                                                    </CompanyDataProvider>
                                                                </TimeTrackingProvider>
                                                            </JournalProvider>
                                                        </DeliveryNoteProvider>
                                                    </ServiceProvider>
                                                </TaskProvider>
                                            </ProductProvider>
                                        </IntervallProvider>
                                    </EmployeeProvider>
                                </StaffProvider>
                            </DepartementProvider>
                        </DocumentProvider>
                    </TagProvider>
                </SettingsProvider>
            </OrganizationRightsProvider>
        )
    } else {
        return (
            <OnboardingRouter />
        )
    }
}

export default MainRouter