import React from 'react'
import { IOrganization, IOrganizationDTO } from '../../Interfaces/Company'
import CardExpandable from '../Card/CardExpandable/CardExpandable'
import { contactFormModes } from '../ContactCards/ContactFormCard'
import { form } from './CreateTaskForm'
import EditCompanyDataForm from './EditCompanyDataForm'
import Form from './FormUtils/Form'
import FormRow from './FormUtils/FormRow'

const CreateCompanyForm = ({ object, setObject, setFormMode, errorVariables }: any) => {
  return (
    <Form
    >
      <EditCompanyDataForm
        object={object}
        /* @ts-ignore */
        setObject={setObject}
        setFormMode={setFormMode}
        errorVariables={errorVariables}
      />
    </Form>
  )
}

export default CreateCompanyForm