import { Axios, AxiosResponse } from 'axios'
import React, { ReactNode, useContext, useEffect, useReducer } from 'react'
import { createContext } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import IntervallReducer from './IntervallReducer'
import { errorHandler } from '../../Connection/BaseConnection'
import { useCompanyData } from '../CompanyDataContext/CompanyDataProvider'
import { autoHideDurationDefault } from '../../Global/Variables'

export interface IIntervall extends IIntervallDTO {
    id: number
}

export interface IIntervallPosition {
    id?: number,
    subscriptionId?: number,
    productId?: number,
    hasDiscount?: boolean,
    discountType?: boolean,
    discount?: number,
    title: string,
    description: string,
    netto: number,
    tax: number,
    organizationId?: number,
    quantity: number
}

export interface IIntervallDTO {
    id: number
    title: string
    description?: string
    period: number
    periodIsDays?: boolean
    periodIsMonths?: boolean
    periodIsYears?: boolean
    begin?: Date
    lastBillingDate?: Date
    nextBillingDate?: Date
    validUntil?: Date | null
    Note?: string
    prefix?: number
    customerOrganizationId?: number
    customerPersonId?: number
    personId?: number
    organizationId?: number
    subscriptionPositions?: Array<ISubscriptionPosition>
    sendEmailAutomatically?: boolean
    active?: boolean
    emailSubject?: string
    emailBody?: string
    textBefore?: string
    textAfter?: string
    automatedPaymentProcessing?: boolean
    inAdvance?: boolean
}

export interface ISubscriptionPosition extends IIntervallPosition {
}

interface IIntervallContext {
    intervalls: IIntervall[],
    intervallPositions: IIntervallPosition[],
    updateIntervall: any,
    addIntervall: any,
    removeIntervall: any,
    fetchIntervalls: any
    updateIntervallPosition: any,
    addIntervallPosition: any,
    removeIntervallPosition: any,
    generatePdfPreview?: any
    sendPdfPreview?: any
}

export const TextTemplates = {
    EMailText: `Sehr geehrte Damen und Herren,\r\n\r\nvielen Dank für Ihr Vertrauen in unseren Service.\r\n\r\nIm Anhang finden Sie die Rechnung über den Zeitraum von [DatumVon] bis [DatumBis].\r\nSollten Sie Rückfragen haben, stehen wir Ihnen jederzeit zur Verfügung.\r\n\r\nMit freundlichen Grüßen,\r\n[Name]`,
    TextBefore: `Sehr geehrte Damen und Herren,\n\nvielen Dank für Ihren Auftrag und das damit verbundene Vertrauen!\n\n\nHiermit stellen wir Ihnen auf der Seite 2 ff angeführte Leistungen zu folgendem Gesamtbetrag in Rechnung:`,
    TextAfter: `Bitte überweisen Sie den Rechnungsbetrag unter Angabe der Rechnungsnummer auf das unten angegebene Konto.\n\n\n\nMit freundlichen Grüßen\nIhr Team von [Name]`,
    TextAfterAutomatedPaymentProcessing: `Wir erlauben uns den Betrag vertragsgemäß im Laufe der folgenden Tage von Ihrem Konto via SEPA-Lastschriftverfahren einzuziehen.\n\n\n\nMit freundlichen Grüßen\nIhr Team von [Name]`
}

export const defaultIntervall: IIntervallDTO = {
    id: 0,
    title: "",
    description: "",
    period: 1,
    validUntil: null,
    begin: new Date(),
    periodIsDays: false,
    periodIsMonths: true,
    periodIsYears: false,
    Note: "",
    active: true,
    sendEmailAutomatically: true,
    inAdvance: true,
}

const IntervallContext = createContext<IIntervallContext>({
    intervalls: [],
    intervallPositions: [],
    updateIntervall: () => { },
    addIntervall: () => { },
    removeIntervall: () => { },
    fetchIntervalls: () => { },
    updateIntervallPosition: () => { },
    addIntervallPosition: () => { },
    removeIntervallPosition: () => { },
})

const IntervallProvider = ({ children }: { children: ReactNode }) => {

    const [state, dispatch] = useReducer(IntervallReducer, {
        intervalls: []
    })

    const connection = useAuthConnection();

    const { enqueueSnackbar, closeSnackbar } = useSnackBar();

    const setIntervalls = (intervalls: IIntervallDTO[]) => {
        dispatch({
            type: "SET_INTERVALLS",
            payload: intervalls
        })
    }

    const { companyData } = useCompanyData();



    const addIntervall = (intervall: IIntervallDTO) => {
        let x = enqueueSnackbar("Intervall wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/subscription", { ...intervall, validUntil: intervall.validUntil ? new Date(intervall.validUntil).toJSON() : undefined, begin: new Date(intervall.begin!).toJSON() })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Intervall erfolgreich erstellt", { variant: "success" })

                dispatch({
                    type: "ADD_INTERVALL",
                    payload: res.data
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const updateIntervall = (intervall: IIntervallDTO) => {
        let x = enqueueSnackbar("Intervall wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.put("/subscription", intervall)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Intervall erfolgreich bearbeitet", { variant: "success" })

                dispatch({
                    type: "UPDATE_INTERVALL",
                    payload: intervall
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })

    }

    const removeIntervall = (intervall: IIntervallDTO) => {
        let x = enqueueSnackbar("Intervall wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.delete("/subscription", { data: intervall })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Intervall erfolgreich entfernt", { variant: "success" })

                dispatch({
                    type: "REMOVE_INTERVALL",
                    payload: intervall
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })

    }

    const addIntervallPosition = (intervallPosition: IIntervallPosition) => {
        let x = enqueueSnackbar("Intervallposition wird hinzugefügt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/subscription/subscriptionposition", intervallPosition)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Intervallposition erfolgreich hinzugefügt", { variant: "success" })

                dispatch({
                    type: "ADD_INTERVALLPOSITION",
                    payload: res.data
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const updateIntervallPosition = (intervallPosition: IIntervallPosition) => {
        let x = enqueueSnackbar("Intervallposition wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.put("/subscription/subscriptionposition", intervallPosition)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Intervallposition erfolgreich bearbeitet", { variant: "success" })

                dispatch({
                    type: "UPDATE_INTERVALLPOSITION",
                    payload: intervallPosition
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            });
    }

    const removeIntervallPosition = (intervallPosition: IIntervallPosition) => {
        let x = enqueueSnackbar("Intervallposition wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.delete("/subscription/subscriptionposition", { data: intervallPosition })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Intervallposition erfolgreich entfernt", { variant: "success" })

                dispatch({
                    type: "REMOVE_INTERVALLPOSITION",
                    payload: intervallPosition
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const generatePdfPreview = (intervall: IIntervallDTO) => {
        return new Promise(function (resolve, reject) {
            let x = enqueueSnackbar("Vorschau wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

            connection.post("/bill/GeneratePreviewPdf", { ...intervall, validUntil: intervall.validUntil ? new Date(intervall.validUntil).toJSON() : undefined, begin: new Date(intervall.begin!).toJSON() })
                .then((res: AxiosResponse) => {
                    closeSnackbar(x);
                    enqueueSnackbar("Vorschau erfolgreich erstellt", { variant: "success" })

                    resolve(res.data);
                })
                .catch((error: any) => {
                    //errorHandler(error, x, enqueueSnackbar, closeSnackbar)
                    reject(error);
                })
        })
    }

    const sendPdfPreview = (intervall: IIntervallDTO) => {
        return new Promise(function (resolve, reject) {
            let x = enqueueSnackbar("Vorschau wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

            connection.post("/bill/SendBillPreviewForSubscription", { ...intervall, validUntil: intervall.validUntil ? new Date(intervall.validUntil).toJSON() : undefined, begin: new Date(intervall.begin!).toJSON() })
                .then((res: AxiosResponse) => {
                    closeSnackbar(x);
                    enqueueSnackbar("Vorschau erfolgreich erstellt", { variant: "success" })

                    resolve(res.data);
                })
                .catch((error: any) => {
                    //errorHandler(error, x, enqueueSnackbar, closeSnackbar)
                    reject(error);
                })
        })
    }

    const fetchIntervalls = async () => {
        await connection.get("/subscription").then((res: AxiosResponse) => {
            setIntervalls(res.data);
        })
    }

    useEffect(() => {
        fetchIntervalls();
    }, [])

    return (
        <IntervallContext.Provider
            value={{
                intervalls: state.intervalls,
                intervallPositions: state.intervallPositions,
                updateIntervall: updateIntervall,
                addIntervall: addIntervall,
                removeIntervall: removeIntervall,
                fetchIntervalls: fetchIntervalls,
                updateIntervallPosition: updateIntervallPosition,
                addIntervallPosition: addIntervallPosition,
                removeIntervallPosition: removeIntervallPosition,
                generatePdfPreview: generatePdfPreview,
                sendPdfPreview: sendPdfPreview
            }}
        >
            {children}
        </IntervallContext.Provider>
    )
}

export default IntervallProvider

export const useIntervalls = () => useContext(IntervallContext)