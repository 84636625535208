import React, { useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'

/* Icons */
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import Card from '../Card/Card'
import styles from './TimeTrackingCard.module.css'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import BaseButton from '../Buttons/BaseButton/BaseButton'
import Tag from '../Tag/Tag'
import { IPause, useTimeTracking } from '../../Contexts/TimeTrackingContext/TimeTrackingProvider'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useMobileView } from '../../Hooks/useMobileView'
import { Scale } from '@mui/icons-material'
import { useUser } from '../../Contexts/UserContext/UserContext'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

function TimeTrackingCard({ width, height = "100%", order }: card) {

  const { status, getTimeStatus, dispatchWork, dispatchEndOfPause, dispatchEndOfWork, dispatchPause, begin, pauses, currentWorktime } = useTimeTracking()

  const { changeVisibility } = useModals();
  const mobile = useMobileView();

  const [time, setTime] = useState<number>(0)

  const navigate = useNavigate();

  useEffect(() => {
    let timer: NodeJS.Timer
    const interval = 1000 * 60 // Interval of 1 minute
    // Get the difference between current time and the start time of the worktime
    if (begin && status !== "absent") {
      let difference = Date.now() - new Date(begin).getTime()
      // If the status is pause, calculate the difference in minutes, else calculate it in seconds
      difference = status == "pause" ? (difference) / 1000 / 60 : (difference) / 1000 / 60

      // Subtract the pauses from the total difference
      if (pauses && pauses.length > 0) {
        pauses.forEach((pause: IPause) => {
          if (pause.workTimeId === currentWorktime?.id)
            difference = difference - ((new Date(dayjs(pause.end).toString() ?? "").getTime() - new Date(dayjs(pause.start).toString()).getTime()) / 1000 / 60)
        });
      }

      // Set the time to the rounded up value of the difference
      setTime(Math.ceil(difference))
    }

    //Intervall, zeigt die Daten jedoch erst nach 1 Minute aktuell an
    // This code checks the status of the timer and sets an interval accordingly
    if (begin && status === "work") {
      // Set an interval to calculate the difference between the current time and the start time
      timer = setInterval(() => {

        // Calculate the difference in minutes
        let difference = Date.now() - new Date(begin).getTime()
        difference = (difference) / 1000 / 60

        // If pauses exist, subtract the pause time from the total difference
        if (pauses && pauses.length > 0) {
          pauses.forEach((pause: IPause) => {
            if (pause.workTimeId === currentWorktime?.id)
              difference = difference - ((new Date(dayjs(pause.end).toString() ?? Date.now.toString()).getTime() - new Date(dayjs(pause.start).toString()).getTime()) / 1000 / 60)
          });
        }

        // Set the time to the rounded up difference
        setTime(Math.ceil(difference))
      }, interval)
    } else if (begin && status === "pause") {
      // Set an interval to calculate the difference between the current time and the start time
      timer = setInterval(() => {

        // Calculate the difference in minutes
        let difference = Date.now() - new Date(begin).getTime()
        difference = (difference) / 1000 / 60

        // Set the time to the rounded up difference
        setTime(Math.ceil(difference))

      }, interval)
    }


    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(timer)
    }
  }, [begin, status])


  const { user } = useUser();

  return (
    <>
      {mobile === false &&

        <Card
          title={<div style={{ paddingRight: "10px" }}><span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>Zeiterfassung</span></div>}
          style={{ width, height, order }}
          flex
          fullHeightBody
          disableBodyMargin
          right={() => <Tag title={getTimeStatus!(status)} tooltipTitle={getTimeStatus!(status) + (begin ? " seit " + new Date(begin).toLocaleTimeString() : "")} color='var(--text)' backgroundColor='var(--light)' icon={<AccessTimeRoundedIcon fontSize='inherit' />} />}
        >
          <div className='flex column stretched-justify' style={{ height: "100%" }}>
            <div className='flex centered-aligned' style={{ height: "30%", gap: "10px" }}>
              <div className='flex row' style={{ justifyContent: 'center', maxWidth: '25%', width: '25%' }}>
                {status !== "work" && (<IconTooltipButton onClick={() => status == "pause" ? dispatchEndOfPause!() : dispatchWork!()} arrow title={status == "pause" ? "Arbeitszeit fortsetzen" : 'Arbeitszeit beginnen'} placement="right">
                  <PlayArrowRoundedIcon sx={{ color: 'var(--green)', fontSize: 30 }} />
                </IconTooltipButton>)}
                {(status !== "pause" && status !== "absent") && (<IconTooltipButton onClick={() => dispatchPause!()} arrow title='Pause beginnen' placement="right">
                  <PauseRoundedIcon sx={{ color: 'grey' }} fontSize='medium' />
                </IconTooltipButton>)}
                {status !== "absent" && (<IconTooltipButton onClick={() => dispatchEndOfWork!()} arrow title='Arbeitszeit beenden' placement="right">
                  <StopRoundedIcon sx={{ color: 'var(--red-light)' }} fontSize='medium' />
                </IconTooltipButton>)}

              </div>
              <div className={getClassNames(["centered", styles.callout])} style={{ maxWidth: '75%', width: '75%', borderRadius: "10px" }}>
                <span style={{ fontSize: "30px", fontWeight: "bolder" }}>{status == "work" ? (Math.floor(time / 60) > 0 ? Math.floor(time / 60) + "h " : "") + time % 60 + "min" : status == "pause" ? time + "m" : ""}</span>
                {status !== "work" && status !== "pause" ? <span>Nicht eingestempelt</span> : ""}
              </div>
            </div>
            <div className='flex column' style={{ width: "100%", gap: "5px" }}>
              {user?.rights?.readWorktime &&
                <BaseButton title='Übersicht öffnen' onClick={() => changeVisibility!("worktimeOverview", true, { props: {} })} />
              }
              {/* Macht hier nicht so wirklich Sinn, Pausen müssen zu einer bestimmten WorkTime gehören, muss in Worktime-Overview nachgetragen werden */false && <BaseButton title='Pause nachtragen' onClick={() => changeVisibility!("postscriptWorkPause", true, { props: { mode: "pause" } })} />}
              {user?.rights?.createWorktime &&
                <BaseButton title='Arbeitszeit nachtragen' onClick={() => changeVisibility!("postscriptWorkPause", true, { props: { mode: "work" } })} />
              }
            </div>
          </div>
        </Card>
      }
      {mobile === true &&
        <Card
          title={<div style={{ paddingRight: "10px" }}><span style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>Zeiterfassung</span></div>}
          style={{ width, height, order }}
          flex
          fullHeightBody
          disableBodyMargin
          right={() => <Tag title={getTimeStatus!(status)} tooltipTitle={getTimeStatus!(status) + (begin ? " seit " + new Date(begin).toLocaleTimeString() : "")} color='var(--text)' backgroundColor='var(--light)' icon={<AccessTimeRoundedIcon fontSize='inherit' />} />}
        >
          <div className='flex column stretched-justify' style={{ height: "100%", justifyContent: "center" }}>
            <div className='flex centered-aligned' style={{ height: "30%", gap: "10px" }}>
              <div className='flex column'>
                {status !== "work" && (<IconTooltipButton onClick={() => status == "pause" ? dispatchEndOfPause!() : dispatchWork!()} arrow title={status == "pause" ? "Arbeitszeit fortsetzen" : 'Arbeitszeit beginnen'} placement="right">
                  <PlayArrowRoundedIcon sx={{ color: 'var(--green)' }} fontSize='medium' />
                </IconTooltipButton>)}
                {(status !== "pause" && status !== "absent") && (<IconTooltipButton onClick={() => dispatchPause!()} arrow title='Pause beginnen' placement="right">
                  <PauseRoundedIcon sx={{ color: 'grey' }} fontSize='medium' />
                </IconTooltipButton>)}
                {status !== "absent" && (<IconTooltipButton onClick={() => dispatchEndOfWork!()} arrow title='Arbeitszeit beenden' placement="right">
                  <StopRoundedIcon sx={{ color: 'var(--red-light)' }} fontSize='medium' />
                </IconTooltipButton>)}

              </div>
              <div className={getClassNames(["centered", styles.callout])} style={{ borderRadius: "10px", marginRight: "9vw" }}>
                <span style={{ fontSize: "30px", fontWeight: "bolder" }}>{status == "work" ? (Math.floor(time / 60) > 0 ? Math.floor(time / 60) + "h " : "") + time % 60 + "min" : status == "pause" ? time + "m" : ""}</span>
                {status !== "work" && status !== "pause" ? <span>Nicht eingestempelt</span> : ""}
              </div>
            </div>
            <div className='flex column' style={{ width: "100%", gap: "5px", marginTop: "2vh" }}>
              <BaseButton title='Übersicht öffnen' onClick={() => changeVisibility!("worktimeOverview", true, { props: {} })} />
              {/* Macht hier nicht so wirklich Sinn, Pausen müssen zu einer bestimmten WorkTime gehören, muss in Worktime-Overview nachgetragen werden */false && <BaseButton title='Pause nachtragen' onClick={() => changeVisibility!("postscriptWorkPause", true, { props: { mode: "pause" } })} />}
              <BaseButton title='Arbeitszeit nachtragen' onClick={() => changeVisibility!("postscriptWorkPause", true, { props: { mode: "work" } })} />
            </div>
          </div>
        </Card>
      }
    </>
  )
}

export default TimeTrackingCard