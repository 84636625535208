import { AddRounded } from "@mui/icons-material"
import Card from "../../../Components/Card/Card"
import { IPerson, useContacts } from "../../../Contexts/ContactsContext/ContactsProvider"
import SelectablePersonRow from "./SelectablePersonRow"
import { SetStateAction, useState } from "react"
import Filter, { IFilterOption } from "../../../Components/Filter/Filter"

interface IPersonSelectCard {
    addablePersons: IPerson[]
    handlePersonSelect: (personId: number) => void
    rowIsChecked: (id: number) => boolean
    addSelectedPersonsToTag: () => void
}

function PersonSelectCard({ addablePersons, handlePersonSelect, rowIsChecked, addSelectedPersonsToTag }: IPersonSelectCard) {

    const { companies } = useContacts();

    const [filteredAddablePersons, setFilteredAddablePersons] = useState<IPerson[]>([])

    const filterOptions: IFilterOption[] = [
        { title: "Name", valueKey: "completeName" },
        { title: "Notiz", valueKey: "note" },
        { title: "Organisation", valueKey: "belongsToOrganizationId", referenceList: companies, referenceListValueKey: "name" },
    ]

    return (
        <Card
            title={"Personen"}
            cta
            ctaTitle={"Zu Tag hinzufügen"}
            buttonIcon={<AddRounded fontSize='small' />}
            ctaFunction={() => addSelectedPersonsToTag()}
            right={() => <Filter dataToFilter={filteredAddablePersons} setDataToFilter={setFilteredAddablePersons} originalData={addablePersons} filterOptions={filterOptions} />}
        >
            {filteredAddablePersons.length === 0 ? <>Diesem Tag wurden bereits alle Personen zugewiesen</> : filteredAddablePersons.map((person, index) => {
                return (<SelectablePersonRow person={person} index={index} handleSelect={handlePersonSelect} rowIsChecked={rowIsChecked} />)
            })}
        </Card>
    )
}

export default PersonSelectCard