import { CircularProgress } from '@mui/material'
import React, { Dispatch, useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IOrganization, IOrganizationDTO } from '../../../Interfaces/Company'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateCompanyForm from '../../Forms/CreateCompanyForm'
import Form from '../../Forms/FormUtils/Form'
import FormRow from '../../Forms/FormUtils/FormRow'
import { dialog } from '../CreateServiceDialog/CreateServiceDialog'
import { IPersonDTO, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider';

export interface dialogWithProps<T> extends dialog {
    object: T | null
    mode?: string | null
}

const EditStatusDialogPerson = ({open, onClose, object, mode} : dialogWithProps<IPersonDTO>) => {

    const {defaultPerson, updatePerson} = useContacts()

    const {changeVisibility} = useModals()
    
    const handleClose = () => {
        setLoading(false)
        if (onClose) {
          handleClose()
        }
        changeVisibility!("editStatusPerson", false)
    }



    useEffect(() => {
        setPersonToSubmit(object ?? defaultPerson!)
    }, [object])

    const [loading, setLoading] = useState(false)

    const [personToSubmit, setPersonToSubmit] = useState<IPersonDTO>(object ?? defaultPerson!)

    const handleSubmit = async () => {
        try {
            setLoading(true)
            await updatePerson!(personToSubmit as IPersonDTO)
            changeVisibility!("editStatusPerson", false)
        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }
    
  return (
    <Modal
    open={open}
    title="Status bearbeiten"
    onClose={() => handleClose()}
    >
        <Form>
            <FormRow
            label="Status"
            type='select'
            value={personToSubmit.status ?? 1}
            onChange={(val) => setPersonToSubmit((old) => ({...old, status: val}))}
            selectOptions={{
                options: [
                    {
                        id: 1,
                        title: "Ok"
                    },
                    {
                        id: 2,
                        title: "Mäßig"
                    },
                    {
                        id: 3,
                        title: "Optimierungsbedarf"
                    },
                    {
                        id: 4,
                        title: "Verstorben"
                    },
                ],
                titleKey: "title",
                valueKey: "id"
            }}
            />
        </Form>
        <CTAButton
        style={{
            marginTop: "20px",
            zIndex: 1,
        }}
        title={loading ? <CircularProgress size={18} color='inherit'/> : "Speichern"}
        onClickHandler={() => handleSubmit()}
        disabled={loading}
        />
    </Modal>
  )
}

export default EditStatusDialogPerson