import React, { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useTasks } from '../../../Contexts/TaskContext/TaskContext'
import { ITask, ITaskDTO } from '../../../Interfaces/Task'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import FormRow from '../../Forms/FormUtils/FormRow'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'

const AbortTaskDialog = ({ object, onClose, open }: dialogWithProps<ITask>) => {
    const { updateTask, defaultTask } = useTasks()

    const { changeVisibility } = useModals()

    const [taskToSubmit, setTaskToSubmit] = useState<ITask>({
        ...(object ?? defaultTask as ITask)
    })

    const handleSubmit = () => {
        updateTask!({ ...taskToSubmit, aborted: true });
        onClose ? onClose() : changeVisibility!("abortTask", false);
    }

    useEffect(() => {
        setTaskToSubmit(object ?? defaultTask as ITask)
    }, [object])

    return (
        <Modal
            title="Aufgabe stornieren"
            open={open}
            onClose={onClose ? () => onClose() : () => changeVisibility!("abortTask", false)}
        >
            <FormRow
                type='multiline'
                label="Begründung"
                value={taskToSubmit.note}
                onChange={(val) => null}
                placeholder="Begründung eingeben"

            />
            <CTAButton
                style={{ marginTop: "20px" }}
                title="Speichern"
                onClickHandler={() => handleSubmit()}
            />
        </Modal>
    )
}

export default AbortTaskDialog
