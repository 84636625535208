import React, { createContext, ReactNode, useContext, useReducer } from 'react'
import { settingsReducer } from './SettingsReducer'

const SettingsContext = createContext({

})

function SettingsProvider({children} : {children: ReactNode}) {


    const [state, dispatch] = useReducer(settingsReducer, {

    })
    
  return (
    <SettingsContext.Provider
    value={{

    }}
    >
        {children}
    </SettingsContext.Provider>
  )
}

export default SettingsProvider

export const useSettings = () => useContext(SettingsContext)