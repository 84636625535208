import React from 'react'
import { useMobileView } from '../../../Hooks/useMobileView'

function CardColumn({ height, width, children }: { width: string, height: string, children: React.ReactNode }) {

  const mobile = useMobileView()

  return (
    <div className='flex column centered-justified' style={{ width: mobile ? "100%" : width, height, gap: "20px" }}>
      {children}
    </div>
  )
}

export default CardColumn