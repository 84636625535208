import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { card } from '../../Interfaces/Card'
import { IIntervall, IIntervallDTO, ISubscriptionPosition, useIntervalls } from '../../Contexts/IntervallContext/IntervallProvider'
import Card from '../Card/Card'
import IntervallRow from './IntervallRow'
import Filter, { IFilterOption } from '../Filter/Filter'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { IntervallFormModes } from '../Forms/EditCreateIntervall'

interface IExpanseIntervallCard extends card {
  setClickedIntervall?: Dispatch<SetStateAction<IIntervallDTO>>
  setFormMode?: Dispatch<SetStateAction<IntervallFormModes>>
}

function ExpanseIntervallCard({ width, height, order, setClickedIntervall, setFormMode }: IExpanseIntervallCard) {

  const { intervalls, fetchIntervalls } = useIntervalls();
  const [expenseIntervalls, setExpenseIntervalls] = useState<IIntervallDTO[]>([])

  const expenseIntervallsOriginal = useMemo(() => getExpenseIntervalls(intervalls), [intervalls]);

  function getExpenseIntervalls(intervalls: IIntervall[]) {
    const expenseIntervalls = intervalls.filter((intervall) => {
      let tempSum = 0
      intervall.subscriptionPositions?.forEach((subscriptionPosition: ISubscriptionPosition) => {
        tempSum += ((subscriptionPosition.netto * subscriptionPosition.quantity) -
          (subscriptionPosition.hasDiscount ? (subscriptionPosition.discountType! === false ? subscriptionPosition.netto! * subscriptionPosition.quantity! * (subscriptionPosition.discount! / 100) : subscriptionPosition.discount!) : 0))
      })
      if (tempSum < 0) {
        return true
      }
      return false;
    })
    return expenseIntervalls;
  }

  const { companies } = useContacts();
  const { persons } = useContacts();


  useEffect(() => {
    fetchIntervalls();
  }, [])

  useEffect(() => {
    setExpenseIntervalls(intervalls.filter((intervall) => {
      let tempSum = 0
      intervall.subscriptionPositions?.forEach((subscriptionPosition: ISubscriptionPosition) => {
        tempSum += ((subscriptionPosition.netto * subscriptionPosition.quantity) -
          (subscriptionPosition.hasDiscount ? (subscriptionPosition.discountType! === false ? subscriptionPosition.netto! * subscriptionPosition.quantity! * (subscriptionPosition.discount! / 100) : subscriptionPosition.discount!) : 0))
      })
      if (tempSum < 0) {
        return true
      }
      return false;
    }))
  }, [intervalls])

  const filterOptions: IFilterOption[] = [
    { title: "Titel", valueKey: "title" },
    { title: "Beschreibung", valueKey: "description" },
    { title: "Organisation", valueKey: "customerOrganizationId", referenceList: companies, referenceListValueKey: "name" },
    { title: "Person", valueKey: "customerPersonId", referenceList: persons, referenceListValueKey: "completeName" }
  ]

  return (
    <Card
      title='Intervallausgaben'
      style={{ width, height, order }}
      fullHeightBody={true}
      right={() => <Filter dataToFilter={expenseIntervalls} setDataToFilter={setExpenseIntervalls} filterOptions={filterOptions} originalData={expenseIntervallsOriginal}/>}
    >
      {expenseIntervalls && expenseIntervalls.map((intervall: any) => {
        return (
          <IntervallRow key={intervall.id} intervall={intervall} isExpense setClickedIntervall={setClickedIntervall} setFormMode={setFormMode}/>
        )
      })}
    </Card>
  )
}

export default ExpanseIntervallCard