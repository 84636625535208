import { useEffect, useState } from "react";
import CardColumn from "../../../Components/Card/CardColumn/CardColumn";
import { IPerson, ITagDTO, useContacts } from "../../../Contexts/ContactsContext/ContactsProvider";
import { useSnackBar } from "../../../Hooks/useSnackBar";
import { IOrganization } from "../../../Interfaces/Company";
import OrgSelectCard from "./OrgSelectCard";
import OrgsToTagCard from "./OrgsToTagCard";
import PersonSelectCard from "./PersonSelectCard";
import PersonToTagCard from "./PersonToTagCard";
import TagOverviewCard from "./TagOverviewCard";

export type TagOverviewCardModes = "person" | "org"

const TagAdministration = () => {

    const { persons, companies, tags, addTagToOrganizationsViaId, removeTagFromOrganizationsViaId, addTagToPersonsViaId, addAllPersonsFromOrganizationToTag, removeTagFromPersonsViaId } = useContacts();


    const [selectedTag, setSelectedTag] = useState<ITagDTO>({});
    const { enqueueSnackbar } = useSnackBar();

    const handleTagSelect = (tag: ITagDTO) => {
        if (tag === selectedTag) {
            setSelectedTag({});
            return;
        }
        setSelectedTag(tag);
    }

    //Persons/organizations which are already added to the currently selected tag
    const [personsToTag, setPersonsToTag] = useState<IPerson[]>([]);
    const [organizationsToTag, setOrganizationsToTag] = useState<IOrganization[]>([]);

    //Persons/organizations which can be added to the currently selected tag
    const [addablePersons, setAddablePersons] = useState<IPerson[]>([]);
    const [addableOrganizations, setAddableOrganizations] = useState<IOrganization[]>([]);


    //reset logic
    useEffect(() => {
        console.log("selectedTag", selectedTag);
        setPersonsToTag(getPersonsToSelectedTag());
        setOrganizationsToTag(getOrgsToSelectedTag());

        setAddablePersons(getAddablePersonsToSelectedTag());
        setAddableOrganizations(getAddableOrganizationsToSelectedTag());

        resetSelectedEntries()

    }, [selectedTag, persons, companies])


    //Selected persons which shall be added to/removed from a tag
    const [personsToAdd, setPersonsToAdd] = useState<number[]>([]);
    const [personsToRemove, setPersonsToRemove] = useState<number[]>([]);

    //Handle selection of persons which will be added to a tag
    const handlePersonSelect = (personId: number) => {
        const index = personsToAdd.indexOf(personId);

        if (index === -1) {
            setPersonsToAdd([...personsToAdd, personId]);
        }
        else {
            const newArray = [...personsToAdd];
            newArray.splice(index, 1);
            setPersonsToAdd(newArray);
        }
    }

    //Handle selection of persons which will be removed from a tag
    const handlePersonSelectForRemove = (personId: number) => {
        const index = personsToRemove.indexOf(personId);

        if (index === -1) {
            setPersonsToRemove([...personsToRemove, personId]);
        }
        else {
            const newArray = [...personsToRemove];
            newArray.splice(index, 1);
            setPersonsToRemove(newArray);
        }
    }

    //Selected organizations which shall be added to/removed from a tag
    const [orgsToAdd, setOrgsToAdd] = useState<number[]>([]);
    const [orgsToRemove, setOrgsToRemove] = useState<number[]>([]);

    //Handle selection of organiztions which will be added to a tag
    const handleOrganizationSelect = (orgId: number) => {
        const index = orgsToAdd.indexOf(orgId);

        if (index === -1) {
            setOrgsToAdd([...orgsToAdd, orgId]);
        }
        else {
            const newArray = [...orgsToAdd];
            newArray.splice(index, 1);
            setOrgsToAdd(newArray);
        }
    }

    //Handle selection of organizations whichh will be removed from a tag
    const handleOrganizationSelectForRemove = (orgId: number) => {
        const index = orgsToRemove.indexOf(orgId);

        if (index === -1) {
            setOrgsToRemove([...orgsToRemove, orgId]);
        }
        else {
            const newArray = [...orgsToRemove];
            newArray.splice(index, 1);
            setOrgsToRemove(newArray);
        }
    }


    //array to store the organization, from which all the users should be added when adding organizations to tag
    const [orgsToAddAllPersonsFrom, setOrgsToAddAllPersonsFrom] = useState<number[]>([]);

    //DEBUG
    useEffect(() => {
        console.log("orgsToAddAllPersonsFrom", orgsToAddAllPersonsFrom);
    }, [orgsToAddAllPersonsFrom])

    const handleSelectAllUsersForOrganisation = (orgId: number) => {
        const index = orgsToAddAllPersonsFrom.indexOf(orgId);

        if (index === -1) {
            setOrgsToAddAllPersonsFrom([...orgsToAddAllPersonsFrom, orgId])
        }
        else {
            const newArray = [...orgsToAddAllPersonsFrom];
            newArray.splice(index, 1);
            setOrgsToAddAllPersonsFrom(newArray);
        }
    }

    const [tagOverviewCardMode, setTagOverviewCardMode] = useState<TagOverviewCardModes>("person");

    return (
        <>
            <CardColumn
                width="50%"
                height="100%"
            >
                <TagOverviewCard mode={tagOverviewCardMode} setMode={setTagOverviewCardMode} selectedTag={selectedTag} handleTagSelect={handleTagSelect} />
            </CardColumn>

            <CardColumn
                width="25%"
                height="100%"
            >
                {tagOverviewCardMode === "person" ? <PersonToTagCard personsToTag={personsToTag} handlePersonSelect={handlePersonSelectForRemove} rowIsChecked={personMarkedForRemove} removeSelectedPersonsFromTag={removeTagFromPersons} /> : <OrgsToTagCard orgsToTag={organizationsToTag} handleOrganizationSelect={handleOrganizationSelectForRemove} removeSelectedOrgsFromTag={removeOrganizationsFromSelectedTag} rowIsChecked={organizationMarkedForRemove} />}
            </CardColumn>

            <CardColumn
                width="25%"
                height="100%"
            >
                {tagOverviewCardMode === "person" ? <PersonSelectCard addablePersons={addablePersons} handlePersonSelect={handlePersonSelect} rowIsChecked={personMarkedForAdd} addSelectedPersonsToTag={addTagToPersons} /> : <OrgSelectCard addableOrganizations={addableOrganizations} handleOrganizationSelect={handleOrganizationSelect} addSelectedOrgsToTag={addTagToOrganizations} rowIsChecked={organizationMarkedForAdd} handleSelectAllUsersForOrganisation={handleSelectAllUsersForOrganisation} organizationIsMarkedForAddAllPersons={organizationMarkedForAdd} />}
            </CardColumn>
        </>
    )


    function getPersonsToSelectedTag() {
        const personsToTag = persons.filter(person => {
            const tagIds = person.tags?.map(tag => tag.id);
            if (tagIds?.includes(selectedTag.id)) {
                return person;
            }

        })
        return personsToTag;
    }

    function getAddablePersonsToSelectedTag() {
        const addablePersonsToSelectedTag = persons.filter(person => {
            const tagIds = person.tags?.map(tag => tag.id);
            if (!tagIds?.includes(selectedTag.id)) {
                return person;
            }
        })

        return addablePersonsToSelectedTag;
    }

    function getOrgsToSelectedTag() {
        const orgsToTag = companies.filter(org => {
            const tagIds = org.tags?.map(tag => tag.id)
            if (tagIds?.includes(selectedTag.id)) {
                return org;
            }
        })

        return orgsToTag;
    }

    function getAddableOrganizationsToSelectedTag() {
        const addableOrgsToSelectedTag = companies.filter(org => {
            const tagIds = org.tags?.map(tag => tag.id);
            if (!tagIds?.includes(selectedTag.id)) {
                return org;
            }
        })

        return addableOrgsToSelectedTag;
    }

    //reset selected entries when selecting a new tag
    function resetSelectedEntries() {
        setPersonsToAdd([]);
        setPersonsToRemove([]);
        setOrgsToAdd([]);
        setOrgsToRemove([]);
        setOrgsToAddAllPersonsFrom([]);
    }

    function addTagToOrganizations() {
        if (addTagToOrganizationsViaId && Object.keys(selectedTag).length > 0) {

            if (orgsToAdd.length === 0 && orgsToAddAllPersonsFrom.length === 0) {
                enqueueSnackbar("Wählen Sie Organisationen aus, die Sie dem Tag hinzufügen möchten", { variant: 'warning' });
            }

            if (orgsToAdd.length > 0) {
                addTagToOrganizationsViaId(selectedTag, orgsToAdd);
            }

            if (orgsToAddAllPersonsFrom.length > 0 && addAllPersonsFromOrganizationToTag) {
                //add all users from orgs
                addAllPersonsFromOrganizationToTag(selectedTag, orgsToAddAllPersonsFrom);
            }

            setOrgsToAddAllPersonsFrom([]);

        }
        else {
            enqueueSnackbar("Wählen Sie einen Tag aus", { variant: "warning" });
        }

    }

    function removeOrganizationsFromSelectedTag() {
        if (removeTagFromOrganizationsViaId && Object.keys(selectedTag).length > 0) {
            removeTagFromOrganizationsViaId(selectedTag, orgsToRemove);
        }
        else {
            enqueueSnackbar("Wählen Sie einen Tag aus", { variant: "warning" });
        }
    }

    function addTagToPersons() {
        if (addTagToPersonsViaId && Object.keys(selectedTag).length > 0) {
            addTagToPersonsViaId(selectedTag, personsToAdd);
        }
        else {
            enqueueSnackbar("Wählen Sie einen Tag aus", { variant: "warning" });
        }
    }

    function removeTagFromPersons() {
        if (removeTagFromPersonsViaId && Object.keys(selectedTag).length > 0) {
            removeTagFromPersonsViaId(selectedTag, personsToRemove);
        }
        else {
            enqueueSnackbar("Wählen Sie einen Tag aus", { variant: "warning" });
        }
    }

    function personMarkedForAdd(id: number) {
        return personsToAdd.includes(id);
    }

    function personMarkedForRemove(id: number) {
        return personsToRemove.includes(id);
    }

    function organizationMarkedForAdd(id: number) {
        return orgsToAdd.includes(id);
    }

    function organizationMarkedForRemove(id: number) {
        return orgsToRemove.includes(id);
    }

    function orgMarkedForAddAllPersons(orgId: number) {
        return orgsToAddAllPersonsFrom.includes(orgId);
    }


}

export default TagAdministration;