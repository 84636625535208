import { Avatar, Button, CircularProgress } from "@mui/material";
import { useCompanyData } from "../../Contexts/CompanyDataContext/CompanyDataProvider";
import { card } from "../../Interfaces/Card";
import { stringToColour } from "../../Navigation/Navigation";
import Card from "../Card/Card";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import styles from '../ProfilePictureCard/ProfilePictureCard.module.css'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from "react-image-crop";
import CTAButton from "../Buttons/CTAButton/CTAButton";
import { useDebounceEffect } from "../Dialogs/ProfilePictureDialog/useDebounceEffect";
import { canvasPreview } from "../Dialogs/ProfilePictureDialog/canvasPreview";
import useAuthConnection from "../../Hooks/useAuthConnection";
import { useSnackBar } from "../../Hooks/useSnackBar";
import ButtonControl from "../Buttons/ButtonControl/ButtonControl";
import { AccountBox, AccountCircle, Rectangle, Square, Storefront } from "@mui/icons-material";

interface IOrganisationLogoCard extends card {
    mode: string
    setMode: Dispatch<SetStateAction<"Rectangle" | "Square">>
}

const OrganisationLogoCard = ({ height, width, order, mode, setMode }: IOrganisationLogoCard) => {

    const { companyData, setLogo } = useCompanyData();
    const connection = useAuthConnection();
    const { enqueueSnackbar } = useSnackBar();

    var textColorToBackground = function (str: string) {
        var rgb = [0, 0, 0];
        rgb[0] = parseInt(str.substring(1, 3), 16);
        rgb[1] = parseInt(str.substring(3, 5), 16);
        rgb[2] = parseInt(str.substring(5, 8), 16);

        const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

        if (brightness > 125) {
            return "black"
        } else {
            return "white"
        }
    }


    const avatarBgColor = stringToColour(companyData?.name ?? "");
    const avatarTextColor = textColorToBackground(avatarBgColor);

    const [loading, setLoading] = useState(false);
    const [imgSrc, setImgSrc] = useState('')
    const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)
    const imgRef = useRef<HTMLImageElement | null>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState<number | undefined>(mode === "Rectangle" ? undefined : 1)
    const fileInputRef = useRef<HTMLInputElement>(null);


    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect ?? (width / height)))
    }

    function centerAspectCrop(
        mediaWidth: number,
        mediaHeight: number,
        aspect: number,
    ) {
        return centerCrop(
            makeAspectCrop(
                {
                    unit: '%',
                    width: 100,
                },
                aspect,
                mediaWidth,
                mediaHeight,
            ),
            mediaWidth,
            mediaHeight,
        )
    }


    function blobToBase64(blob: any) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    const resetData = () => {
        setImgSrc('');
        setCompletedCrop(undefined)
        //setCrop(undefined);
        setScale(1);
        setRotate(0);
        setAspect(mode === "Rectangle" ? undefined : 1);
        previewCanvasRef.current = null;
        imgRef.current = null;
        fileInputRef.current!.value = '';
    }

    const handleSubmit = async () => {
        try {
            setLoading(true)

            previewCanvasRef.current?.toBlob((blob) => {
                blobToBase64(blob).then((bob64: any) => {
                    let formData = new FormData();
                    formData.append("file", blob!)

                    if (setLogo) {
                        setLogo(formData, bob64, mode);
                    }
                    //connection.post("/organization/setLogo", formData)
                })
            })
            resetData();
            enqueueSnackbar("Logo aktualisiert.", { variant: "success" })
        } catch (error) {
            enqueueSnackbar(`Fehler beim Nachtragen.`, { variant: "error" })
            console.error(error)
        } finally {
            setLoading(false);

        }

    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    const buttons: Array<{ body: any, value: ("Rectangle" | "Square") }> = [
        {
            body: (active: boolean) => active ? <span className='centered' style={{ gap: "2px", fontSize: "small" }}><AccountBox />Logo</span> : <span className='centered' style={{ gap: "2px", fontSize: "small" }}><AccountBox /></span>,
            value: "Rectangle"
        },
        {
            body: (active: boolean) => active ? <span className='centered' style={{ gap: "2px", fontSize: "small" }}><AccountCircle />Profilbild</span> : <AccountCircle />,
            value: "Square"
        }
    ]

    useEffect(() => { resetData() }, [mode])

    return (
        <Card
            title={mode === "Rectangle" ? "Firmenlogo anpassen" : "Firmenprofilbild anpassen"}
            style={{ height, width, order }}
            //@ts-ignore
            right={() => <ButtonControl setValue={(val: "Rectangle" | "Square") => setMode(val)} value={mode} buttons={buttons} />}
        >
            <section className='flex column' style={{ marginBottom: "1.5vh", alignItems: "center", justifyContent: "center", gap: "5px" }}
            >
                <img
                    src={mode === "Rectangle" ? (companyData?.logoRectangleBase64?.startsWith("data") ? companyData?.logoRectangleBase64 : `data:image/jpeg;base64,${companyData?.logoRectangleBase64}`) : (companyData?.logoSquareBase64?.startsWith("data") ? companyData?.logoSquareBase64 : `data:image/jpeg;base64,${companyData?.logoSquareBase64}`)}
                    alt={mode === "Rectangle" ? "Logo" : "Profilbild"}
                    style={{ width: "75%" }}
                /*sx={{ width: 100, height: 100, backgroundColor: avatarBgColor, color: avatarTextColor }}*/
                >
                </img>
                <span className='subheading'>{companyData?.name ?? "loading..."}</span>
            </section>
            <div className={styles['picture-upload-container']}>

                <Button color='primary' variant='outlined' component="label" style={{ marginBottom: "1vh" }}>
                    <input ref={fileInputRef} hidden type="file" accept="image/*" onChange={onSelectFile} />
                    <AddAPhotoIcon color='action' style={{ marginRight: "5px" }} />
                    Datei auswählen
                </Button>
            </div>
            {!!imgSrc && (
                <>
                    <div className={styles['text']}>Logo anpassen:</div>
                    <div className={styles['crop-container']}>

                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => { setCrop(percentCrop); }}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, width: "100%" }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    </div>
                </>
            )}
            {!!completedCrop && (
                <>
                    <div className={styles['text']}>Vorschau:</div>
                    <div className={styles['completed-crop-container']}>

                        <canvas
                            ref={previewCanvasRef}
                            style={{
                                border: '1px solid black',
                                objectFit: 'contain',
                                width: completedCrop.width,
                                height: completedCrop.height,
                            }}
                        />
                    </div>
                </>
            )}
            <CTAButton
                style={{
                    marginTop: "20px"
                }}
                title={loading ? <CircularProgress size={18} color='inherit' /> : (mode === "Rectangle" ? "Logo aktualisieren" : "Profilbild aktualisieren")}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Card>

    )
}

export default OrganisationLogoCard