import { Drafts, Edit, NoteAdd, Person, ScheduleSend, Send, Title } from "@mui/icons-material"
import { INewsletterDesign, INewsletterSent, useContacts } from "../../Contexts/ContactsContext/ContactsProvider"
import MoreOptionsButton from "../Buttons/MoreOptionsButton/MoreOptionsButton"
import { useStaff } from "../../Contexts/StaffContext/StaffProvider"

interface props {
    sentNewsletter: INewsletterSent,
    onPressEdit: (draft: INewsletterDesign) => void
    onPressDelete: (draft: INewsletterDesign) => void
    onPressCopy: (draft: INewsletterDesign) => void
}

const NewsletterSentRow = ({ sentNewsletter, onPressEdit, onPressDelete, onPressCopy }: props) => {
    const { staff } = useStaff();
    const { newsletterDrafts } = useContacts();
    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        },
        {
            text: "Kopie erstellen",
            function: onPressCopy
        }
    ]

    return <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
        <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "center", paddingLeft: "1em", paddingRight: "1em" }}>
            <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>{new Date(sentNewsletter.date + "") < new Date() ? <><Send />Versendet</> : <><Drafts />In Warteschlange</>}</div>
            <div style={{ display: "flex", flexDirection: "row", width: "25%", alignItems: "center", gap: "0.33em" }}><Title />{newsletterDrafts?.find(x => x.id === sentNewsletter.newsletterDesignId)?.title}</div>
            <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}><Person />{staff.find(temp => temp.id === sentNewsletter.authorId)?.username}</div>
            <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}><Person />{sentNewsletter.recipientCount}</div>
            <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}><NoteAdd />{new Date(sentNewsletter.date ?? "").toLocaleString()}</div>
        </div>
        <MoreOptionsButton options={options}></MoreOptionsButton>
    </div>
}

export default NewsletterSentRow