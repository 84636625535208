import React, { useEffect, useState } from 'react';
//@ts-ignore
import { Document, Page, pdfjs } from "react-pdf";

export async function base64PdfToImage(base64Input: string) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const base64Pdf = base64Input.split(",")[1];
    const pdfData = atob(base64Pdf); // Convert base64 to binary data

    // Load the PDF data
    const loadingTask = pdfjs.getDocument({ data: pdfData });

    try {
        const pdf = await loadingTask.promise;

        // Get the first page
        const page = await pdf.getPage(1); // Change the page number as needed

        // Get the viewport
        const viewport = page.getViewport({ scale: 1.5 });

        // Create a canvas with the same dimensions as the page
        const canvas = document.createElement('canvas');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render the PDF page on the canvas
        const context = canvas.getContext('2d');
        await page.render({ canvasContext: context!, viewport: viewport }).promise;

        // Convert the canvas to a data URL (image)
        const dataURL = canvas.toDataURL('image/png'); // Change the format as needed (e.g., 'image/jpeg')

        return dataURL;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
