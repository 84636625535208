import React, { useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import Card from '../Card/Card'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import BillRow from './BillRow'
import ButtonControl from '../Buttons/ButtonControl/ButtonControl'
import { IBillListDTO } from '../../Interfaces/Bill'
import { AddRounded, Tune } from '@mui/icons-material'
import Filter, { IFilterOption } from '../Filter/Filter'
import { BillPrefix } from '../../Interfaces/Accounting'
import { useNavigate } from 'react-router-dom'

function ReceiptsCard({ width, height, order }: card) {

  const navigate = useNavigate();
  const { bills, billPrefixes } = useBills();
  const [filteredBills, setFilteredBills] = useState<IBillListDTO[]>([]);
  const [filteredBillsOriginal, setFilteredBillsOriginal] = useState<IBillListDTO[]>([]);


  const [mode, setMode] = useState("receipts");

  useEffect(() => {
    setMode("receipts")
  }, [])

  const buttons = [
    {
      body: (active: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Alle</span>,
      value: "all"
    },
    {
      body: (active: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Rechnungen</span>,
      value: "bills"
    },
    {
      body: (custom: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Belege</span>,
      value: "receipts"
    },
    /* ToDo: Add custom filtering
    {
      body: (active: boolean) => active ? <span onClick={() => { }} className='centered' style={{ gap: "2px", fontSize: "small" }}><Tune fontSize='small' /></span> : <Tune fontSize='small' />,
      value: "custom"
    }*/
  ]

  const [nonOfferPrefixes, setNonOfferPrefixes] = useState<Array<BillPrefix>>(billPrefixes.filter(prefix => !prefix.isOffer))

  useEffect(() => {
    setNonOfferPrefixes(billPrefixes.filter(prefix => !prefix.isOffer))
  }, [billPrefixes])

  useEffect(() => {
    const billsFiltered = bills.filter((billDTO: IBillListDTO) => {
      return billDTO.bill.isReceipt && nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
    })
    setFilteredBills(billsFiltered);
    setFilteredBillsOriginal(billsFiltered);
  }, [bills, nonOfferPrefixes])

  useEffect(() => {
    let billsFiltered;
    switch (mode) {
      case "all":
        billsFiltered = bills.filter((billDTO: IBillListDTO) => {
          return nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
        })
        setFilteredBills(billsFiltered);
        setFilteredBillsOriginal(billsFiltered);
        return;
      case "bills":
        billsFiltered = bills.filter((billDTO: IBillListDTO) => {
          return !billDTO.bill.isReceipt && nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
        })
        setFilteredBills(billsFiltered);
        setFilteredBillsOriginal(billsFiltered);
        return
      case "receipts":
        billsFiltered = bills.filter((billDTO: IBillListDTO) => {
          return billDTO.bill.isReceipt && nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
        })
        setFilteredBills(billsFiltered);
        setFilteredBillsOriginal(billsFiltered);
        return
      case "custom":
        return
      default: //same as case "receipts"
        billsFiltered = bills.filter((billDTO: IBillListDTO) => {
          return billDTO.bill.isReceipt && nonOfferPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
        })
        setFilteredBills(billsFiltered);
        setFilteredBillsOriginal(billsFiltered);
        return;
    }
  }, [mode])

  useEffect(() => {

  }, [filteredBills])

  const filterOptions: IFilterOption[] = [
    { title: "Rechnungskreis", valueKey: "bill.billPrefix" },
    { title: "Kunde", valueKey: "bill.customerName" }
  ]

  return (
    <Card
      title='Rechnungen'
      style={{ width, height, order }}
      fullHeightBody={true}
      right={() => <div style={{ display: "flex", flexDirection: "row" }}><ButtonControl setValue={(val) => setMode(val)} value={mode} buttons={buttons} /><Filter dataToFilter={filteredBills} setDataToFilter={setFilteredBills} filterOptions={filterOptions} originalData={filteredBillsOriginal} /></div>}
      cta
      ctaTitle="Beleg eingeben"
      ctaFunction={() => navigate("/rechnungswesen/belege/erstellen")}
      buttonIcon={<AddRounded fontSize='small'/>}
    >

      {filteredBills.map((billDTO: IBillListDTO) => {
        return (
          <BillRow key={billDTO.bill.id} billDTO={billDTO} />
        )
      })}

    </Card>
  )


}
export default ReceiptsCard