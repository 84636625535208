import { ArrowForward, CloseRounded, Password, WindowSharp } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Dropdown from '../../Components/Dropdown/Dropdown'
import InputField from '../../Components/InputField/InputField'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { SavedUser } from '../../Interfaces/User'
import styles from './Login.module.css'

/* Icons */
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import useKeypress from '../../Hooks/useKeyPress'
import { useLocation, useNavigate } from 'react-router-dom'
import { AxiosError, AxiosResponse } from 'axios'
import { boolean } from 'zod'
import InputFieldRefactored from '../../Components/InputField/InputFieldRefactored'
import { baseConnection } from '../../Connection/BaseConnection'

function HeaderElement({ user, setExpanded, expanded, setActiveUser }: { user: SavedUser | null, setExpanded: React.Dispatch<React.SetStateAction<boolean>>, expanded: boolean, setActiveUser: React.Dispatch<React.SetStateAction<SavedUser | null>> }) {

    return (
        <div className={'flex stretched-justify'}>
            <div className='flex centered-aligned'>
                <div>
                    {/* Company-Logo */}
                </div>
                <div className='flex column'>
                    {/* Accout-Daten */}
                    <span className='heading'>
                        {user?.company}
                    </span>
                    <span className='heading-small' style={{ color: "var(--text-contrast)" }}>
                        {user?.email}
                    </span>
                </div>
            </div>
            <div className='flex' style={{ gap: "5px" }}>
                {/* Button-Sektion */}
                <IconButton onClick={() => setExpanded((val) => !val)}>
                    <KeyboardArrowDownRoundedIcon fontSize='small' />
                </IconButton>
                <IconButton onClick={() => setActiveUser(null)}>
                    <CloseRounded fontSize='small' />
                </IconButton>
            </div>
        </div>
    )
}

function Login() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    const [verificationText, setVerificationText] = useState<string>("");

    useEffect(() => {
        if (code) {
            baseConnection.get(`/authentication/verify/${code}`)
                .then((res: AxiosResponse) => {
                    if (res.status >= 200 && res.status < 300) {
                        setVerificationText("Ihr Account ist nun freigeschaltet, bitte loggen Sie sich ein.")
                    } else {
                        setVerificationText("Bei der Verifizierung Ihres Accounts ist etwas fehlgeschlagen.\nBitte versuchen Sie es erneut.\nSollte es weiterhin ein Problem geben melden Sie sich bitte bei unserer Hotline.")
                    }
                }).catch((error: AxiosError) => {
                    setVerificationText("Bei der Verifizierung Ihres Accounts ist etwas fehlgeschlagen.\nBitte versuchen Sie es erneut.\nSollte es weiterhin ein Problem geben melden Sie sich bitte bei unserer Hotline.")
                })
        }
    }, [code])

    //Login-Funktion aus dem User-Context -> Syntax dekonstruiert das Objekt, das useUser() returned. -> Nur die Werte/Funktionen in den geschwungenen Klammern können verwendet werden, wenn diese Syntax gewählt wurde.
    //Der Wert nach den zwei Rufzeichen geben den Fallback-Wert an, der verwendet wird, wenn der Wert links neben den ?? undefined ist.
    const { login, accounts } = useUser() ?? {}

    //To display error span
    const [loginError, setLoginError] = useState(false);

    //Speichert die Login-Daten im Format, das an die API weitergegeben wird.
    const [loginData, setLoginData] = useState<{ username: string, password: string }>({
        username: "",
        password: ""
    })
    const loginDataRef = useRef<{ username: string, password: string }>();
    loginDataRef.current = loginData;

    //Regelt das Anzeigen eines Spinners innerhalb des Ladebuttons
    const [loading, setLoading] = useState(false)

    //Kombiniert die LoginFunktion mit dem Lade-State der Seite
    const handleLogin = async () => {

        try {
            setLoading(true)
            login!((loginDataRef.current!.username), loginDataRef.current!.password)
                .then(() => {
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })

        } catch (error) {
            //TODO Ein Fehlerstate zum Anzeigen einer Fehlermeldung bei fehlgeschlagener Anmeldung -> Fabian oder wer Interesse hat
            console.log(error)
            setLoginError(true);

        } finally {
        }
    }

    const options: any = []

    const renderOptions = (item: any) => {
        const { option, setActiveElement } = item
        return (
            <div onClick={() => setActiveElement(option)}>{option.company}</div>
        )
    }

    const [activeElement, setActiveElement] = useState<SavedUser | null>(options[0] ?? null)

    const navigation = useNavigate()

    const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
    const [errorUsername, setErrorUsername] = useState(true);
    const [errorPassword, setErrorPassword] = useState(true);

    useEffect(() => {
        if (loginData.username === "" || loginData.username.length < 3) {
            setErrorUsername(true);
        } else {
            setErrorUsername(false);
        }

        if (loginData.password === "") {
            setErrorPassword(true);
        } else {
            setErrorPassword(false);
        }

        if (errorUsername || errorPassword) {
            setContinueButtonDisabled(true);
        } else {
            setContinueButtonDisabled(false);

        }

        function onKeyup(e: KeyboardEvent) {
            if (e.key === "Enter" && !continueButtonDisabled) handleLogin()
        }
        window.addEventListener('keyup', onKeyup);
        return () => window.removeEventListener('keyup', onKeyup);

    }, [loginData.username, loginData.password, errorUsername, errorPassword, continueButtonDisabled])

    return (
        <div className='page flex centered'>
            <section className={styles.container.concat(" flex column")}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <a href='https://ecockpit.at/' target='_blank' style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <img src='https://ecockpit.at/wp-content/uploads/2022/08/eCockpit-Logo.png' style={{ width: "33%" }} ></img>
                    </a>
                </div>
                <div style={{ textAlign: "center", marginTop: "1%", marginBottom: "1%", fontSize: "3vh", fontWeight: "600" }}>
                    Login
                </div>
                {verificationText !== "" &&
                    <div>
                        {verificationText}
                    </div>
                }
                {accounts?.length === 0 || activeElement === null ? <InputFieldRefactored type='text' label='Benutzer:' placeholder="Benutzername" value={loginData.username} onChange={(username) => setLoginData((prevData) => ({ ...prevData, username }))} autocomplete /> : (
                    <Dropdown activeElement={activeElement} setActiveElement={setActiveElement} renderFunction={(props) => renderOptions(props)} options={options} headerElement={({ activeElement, setExpanded, expanded, setActiveElement }) => <HeaderElement setActiveUser={setActiveElement} user={activeElement} expanded={expanded} setExpanded={setExpanded} />} />
                )}
                <InputFieldRefactored label='Passwort:' type='password' error={loginError} errorMessage="Benutzername oder Kennwort sind falsch" value={loginData.password} onChange={(password) => setLoginData((prevData) => ({ ...prevData, password }))} />
                <div className='flex stretched-justify' style={{ marginTop: "20px" }}>
                    <button className='secondary-button pointer' onClick={() => navigation("/reset")}>
                        Passwort vergessen?
                    </button>
                    <button onClick={async () => handleLogin()} className="cta-button pointer" style={{ width: "40%" }}>
                        {loading ? <CircularProgress size={"18px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                    </button>
                </div>
                <div className='flex stretched-justify' style={{ alignItems: "center", justifyContent: "center", marginTop: "1vh" }}>
                    <button className='secondary-button pointer' onClick={() => navigation("/register")}>
                        Noch kein Konto? Klicken Sie hier!
                    </button>
                </div>


            </section>
        </div>
    )
}

export default Login