import { AddRounded } from "@mui/icons-material";
import { card } from "../../../Interfaces/Card";
import { useNavigate } from "react-router-dom";
import { useGetBillPrefixesQuery } from "../../../redux/apiSlices/BillPrefix";
import { CircularProgress } from "@mui/material";
import BillPrefixRow from "./BillPrefixRow";
import { Card, CardContent, CardFooter, CardFooterButton, CardHeader, CardTitle } from "../../UI/CardNew";
import EditBillPrefixModal from "../../../Pages/Buchhaltung/EditBillPrefixModal";
import { useState } from "react";
import { BillPrefix, BillPrefixDTO, defaultBillPrefixDTO } from "../../../Interfaces/Accounting";

function BillPrefixCard({ width, height, order }: card) {

    const navigate = useNavigate();

    const { data: billPrefixes, isLoading } = useGetBillPrefixesQuery();
    const [isEditBillPrefixModalOpen, setIsEditBillPrefixModalOpen] = useState<boolean>(false);
    const [billPrefixToEdit, setBillPrefixToEdit] = useState<BillPrefix>(defaultBillPrefixDTO as BillPrefix);

    const handleEditClicked = (billPrefix: BillPrefixDTO) => {
        setBillPrefixToEdit(billPrefix);
        setIsEditBillPrefixModalOpen(true);
    }

    return (
        <Card>
            <CardHeader>
                <CardTitle>Rechnungskreise</CardTitle>
            </CardHeader>
            <CardContent>
                {isLoading &&
                    <div className="w-full h-full flex items-center justify-center">
                        <CircularProgress />
                    </div>
                }
                {!isLoading && /* Table Header */
                    <div className="flex flex-row gap-2 p-3 border-b">
                        <div className="w-[15%] font-semibold overflow-hidden break-words hyphens-auto">
                            Bezeichnung
                        </div>
                        <div className="w-[15%] text-center font-semibold overflow-hidden break-words hyphens-auto">
                            Rechnungen
                        </div>
                        <div className="w-[15%] text-center font-semibold overflow-hidden break-words hyphens-auto">
                            Bezahlt
                        </div>
                        <div className="w-[15%] text-center font-semibold overflow-hidden break-words hyphens-auto">
                            Offen
                        </div>
                        <div className="w-[15%] text-center font-semibold overflow-hidden break-words hyphens-auto">
                            Im Verzug
                        </div>
                        <div className="w-[10%] text-center font-semibold overflow-hidden break-words hyphens-auto">
                            Angebot
                        </div>
                        <div className="w-[10%]"></div>
                    </div>
                }

                {billPrefixes && billPrefixes.map((billPrefix, index: number) => (
                    <BillPrefixRow key={billPrefix.id} billPrefix={billPrefix} index={index} handleEditClicked={handleEditClicked} />
                ))}
            </CardContent>
            <CardFooter>
                <CardFooterButton onClick={() => navigate("/rechnungswesen/rechnungskreise/erstellen")}>
                    <AddRounded fontSize='small' /> Rechnungskreis erstellen
                </CardFooterButton>
            </CardFooter>

            <EditBillPrefixModal
                open={isEditBillPrefixModalOpen}
                setOpen={setIsEditBillPrefixModalOpen}
                billPrefix={billPrefixToEdit}
                setBillPrefix={setBillPrefixToEdit}
            />
        </Card>
    )
}

export default BillPrefixCard