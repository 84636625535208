import { useState } from 'react'
import ButtonControl from '../../Components/Buttons/ButtonControl/ButtonControl'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CreateTaskCard from '../../Components/FormCards/CreateTaskCard'
import ProjectCard from '../../Components/ProjectCard/ProjectCard'
import TaskCard from '../../Components/TaskCard/TaskCard'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { useMobileView } from '../../Hooks/useMobileView'
import { ITaskDTO } from '../../Interfaces/Task'

export type taskFormModes = "createTask" | "editTask";

interface props {
  adminView?: boolean
}

function Aufgaben({ adminView = false }: props) {

  const mobile = useMobileView()

  const { defaultTask } = useTasks();

  const [clickedTask, setClickedTask] = useState<ITaskDTO>(defaultTask!);
  const [formMode, setFormMode] = useState<taskFormModes>("createTask");

  const [cardMode, setCardMode] = useState<"project" | "task">("project");
  const buttons = [
    {
      body: (active: boolean) => <span>Projekte</span>,
      value: "project"
    },
    {
      body: (active: boolean) => <span>Aufgaben</span>,
      value: "task"
    }

  ]

  const buttonControl = () => {
    return (
      <ButtonControl buttons={buttons} setValue={setCardMode} value={cardMode} />
    )
  }

  return (
    <>
      {mobile !== true &&
        <>
          <CardColumn
            height='100%'
            width='25%'
          >
            {cardMode === "task" ?
              <TaskCard
                cta={false}
                showOnlyOwnTasks={false}
                openModalOnTaskClick={false}
                setClickedTask={setClickedTask}
                setFormMode={setFormMode}
                right={buttonControl}
                cardMode={cardMode}
                setCardMode={setCardMode}
              />
              :
              <ProjectCard
                height='100%'
                right={buttonControl}
              />}

          </CardColumn>
          <CardColumn
            height='100%'
            width='25%'
          >
            <TaskCard
              cta={false}
              showOnlyOwnTasks={true}
              openModalOnTaskClick={false}
              setClickedTask={setClickedTask}
              setFormMode={setFormMode}
            />
          </CardColumn>
          <CardColumn
            height='100%'
            width='50%'
          >
            <CreateTaskCard
              height='100%'
              clickedTask={clickedTask}
              setClickedTask={setClickedTask}
              formMode={formMode}
              setFormMode={setFormMode}
            />
          </CardColumn>
        </>
      }

      {mobile === true &&

        <>
          {cardMode === "task" ?
            <TaskCard
              cta={false}
              showOnlyOwnTasks={false}
              openModalOnTaskClick={false}
              setClickedTask={setClickedTask}
              setFormMode={setFormMode}
              right={buttonControl}
              cardMode={cardMode}
              setCardMode={setCardMode}
            />
            :
            <ProjectCard
              height='100%'
              right={buttonControl}
            />}
          <TaskCard
            cta={false}
            showOnlyOwnTasks={true}
            openModalOnTaskClick={false}
            setClickedTask={setClickedTask}
            setFormMode={setFormMode}
          />
          <CreateTaskCard />
        </>

      }
    </>


  )
}

export default Aufgaben