import { Dispatch, SetStateAction, useEffect, useState } from "react";
import CTAButton from "../../Components/Buttons/CTAButton/CTAButton";
import Modal from "../../Components/Dialog/Dialog"
import FormRow from "../../Components/Forms/FormUtils/FormRow";
import { InputFieldErrorMessages } from "../../Components/InputField/InputFieldRefactored";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { IBillDTO } from "../../Interfaces/Bill";
import { AxiosResponse } from "axios";
import useAuthConnection from "../../Hooks/useAuthConnection";
import { IDeliveryNoteDTO } from "./LieferscheinErstellen";
import { useNavigate } from "react-router-dom";
import { useTasks } from "../../Contexts/TaskContext/TaskContext";
import { CircularProgress } from "@mui/material";
import CreateServiceForm from "../../Components/Forms/CreateServiceForm";
import { useServices } from "../../Contexts/ServiceContext/ServiceContext";
import { IService, IServiceDTO } from "../../Interfaces/Service";
import { validateServiceInput } from "../../Components/Dialogs/CreateServiceDialog/CreateServiceDialog";
import { BillPrefix } from "../../Interfaces/Accounting";

interface props {
    open: boolean
    onSubmit: () => void
    onClose: () => void
    deliveryNote?: IDeliveryNoteDTO
}

export const validateCreateBillFromDeliveryNote = (setError: Dispatch<SetStateAction<any>>, billNumberLoading?: boolean, bill?: IBillDTO) => {
    let valid = true;

    if (bill?.prefix === undefined) {
        setError((old: any) => ({ ...old, prefixError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, prefixError: false }))
    }


    if (billNumberLoading) {
        setError((old: any) => ({ ...old, billNumberLoadingError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, billNumberLoadingError: false }))
    }

    if (bill?.billNumber === undefined) {
        setError((old: any) => ({ ...old, billNumberError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, billNumberError: false }))
    }

    return valid;
}

const DeliveryNoteToServiceDialog = ({ open, deliveryNote, onSubmit, onClose }: props) => {
    const { billPrefixes, addBillFromDeliveryNote } = useBills();
    const { deliveryNotes, updateDeliveryNote } = useTasks();

    const [newBill, setNewBill] = useState<IBillDTO>({});
    const [filteredBillPrefixes, setFilteredBillPrefixes] = useState<Array<BillPrefix>>((billPrefixes ?? []).filter(prefix => prefix.isOffer === false))
    const [billNumberLoading, setBillnumberLoading] = useState(false);
    const [error, setError] = useState<any>({})

    const connection = useAuthConnection();

    const [loading, setLoading] = useState(false);

    const updateBillnumber = (val?: string) => {
        if (newBill?.isCreated !== true) {
            setBillnumberLoading(true);
            setNewBill((prev: any) => ({ ...prev, billNumber: "" }))
            connection.get(`/bill/NextBillNumberOfPrefix/${val ?? newBill?.prefix}`)
                .then((res: AxiosResponse) => {
                    setNewBill((prev: any) => ({ ...prev, billNumber: res.data }));
                    setBillnumberLoading(false);
                })
                .catch((error: any) => {
                    setBillnumberLoading(false);
                });
        }
    }

    const navigate = useNavigate();

    const handleSubmit = () => {
        if (validateServiceInput(serviceToSubmit, setEmployeeError, setCustomerError, setTtitleError, setDateStartError)) {
            setLoading(true);
            addService!(serviceToSubmit)
            onClose();
        }
    }
    //form error handling variables
    const [employeeError, setEmployeeError] = useState(false);
    const [customerError, setCustomerError] = useState(false);
    const [titleError, setTtitleError] = useState(false);
    const [dateStartError, setDateStartError] = useState(false);

    const { defaultService, addService } = useServices();

    const [serviceToSubmit, setServiceToSubmit] = useState<IServiceDTO | IService>({ ...defaultService!, title: "Lieferung zu " + deliveryNote!.title!, products: deliveryNote?.products, clientOrganizationId: deliveryNote?.customerOrganization?.id })

    useEffect(() => {
        setServiceToSubmit({ ...defaultService!, title: "Lieferung zu " + deliveryNote!.title!, products: deliveryNote?.products, clientOrganizationId: deliveryNote?.customerOrganization?.id })
    }, [deliveryNote])

    return <>
        <Modal open={open} title="Leistung zu Lieferschein erstellen" onClose={onClose}>
            <CreateServiceForm object={serviceToSubmit} setObject={setServiceToSubmit} />
            <CTAButton disabled={loading} onClickHandler={handleSubmit} title={loading ? <><CircularProgress size={16}></CircularProgress></> : "Leistung erstellen"} />
        </Modal>
    </>
}

export default DeliveryNoteToServiceDialog;