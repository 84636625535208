import React, { useEffect, useState } from 'react';
import { baseConfig } from '../../Connection/BaseConnection';
import { useUser } from '../UserContext/UserContext';
import { useJournal } from '../JournalContext/JournalProvider';
import { useCompanyData } from '../CompanyDataContext/CompanyDataProvider';
import { useStaff } from '../StaffContext/StaffProvider';
import { useBills } from '../BillContext/BillProvider';
import { debug } from 'console';

const convertKeysToCamelCase: any = (data: any) => {
    if (!data || typeof data !== 'object') {
        return data;
    }

    if (Array.isArray(data)) {
        return data.map((item: any) => convertKeysToCamelCase(item));
    }

    const result: any = {};
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
            result[camelCaseKey] = convertKeysToCamelCase(data[key]);
        }
    }
    return result;
};

const WebSocketComponent = () => {
    const { user } = useUser();
    const { companyData } = useCompanyData();
    const { receiveNewJournal } = useJournal();
    const { setWorkingStateOfAll } = useStaff();
    const { receiveBill, receiveUpdatedBill, receiveDeleteBill, setAccounting } = useBills();

    const [socket, setSocket] = useState<any>(null);
    const [retryCount, setRetryCount] = useState<number>(0)

    const connectWebSocket = () => {
        if (user !== undefined && user !== null && companyData.id !== null && socket === null) {
            const socket = new WebSocket('wss://' + baseConfig.baseURL.replace("https://", "") + "/ws");

            // WebSocket event listeners
            socket.onopen = () => {
                console.log('WebSocket connection opened');
                socket.send(`${companyData.id}_${user.userId}_${localStorage.getItem("AccessToken")}`)
                setRetryCount(0);
            };

            socket.onmessage = (event) => {


                console.log(event);
                if ((event.data + "").includes("Blob")) {
                    debugger
                    return;
                }
                const data = JSON.parse(event.data)

                if (data.Type) {
                    const payload = convertKeysToCamelCase((JSON.parse(data.Payload)));

                    if (data.Type === "Create_Journal") {
                        console.log("Create_Journal", payload);
                        receiveNewJournal!(payload);
                    } else if (data.Type === "Create_Bill") {
                        receiveBill!(payload);
                    } else if (data.Type === "Update_Bill") {
                        receiveUpdatedBill!(payload);
                    } else if (data.Type === "Delete_Bill") {
                        receiveDeleteBill!(payload);
                    } else if (data.Type === "Accounting") {
                        setAccounting!(payload)
                    } else if (data.Type === "Get_Workstate_All") {
                        setWorkingStateOfAll!(payload);
                    }
                }
            };

            socket.onclose = () => {
                console.log('WebSocket connection closed');
                // Retry the connection after a short delay
                setTimeout(() => {
                    setSocket(null); // Clear the existing socket
                    setRetryCount((old) => old + 1);
                }, 3000); // Adjust the delay as needed
            };

            setSocket(socket);

            // Clean up the WebSocket connection when the component unmounts
            return () => {
                socket.close();
            };
        }
    }

    useEffect(() => {
        if (!socket) {
            connectWebSocket();
        }

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [user, companyData, socket, retryCount]);


    return <></>
}

export default WebSocketComponent