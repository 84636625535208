import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAuthQuery } from "../baseAuthQuery";

export interface ITextModule {
    id: number
    title: string
    textStart?: string
    textMiddle?: string
    textEnd?: string
    type: TextModuleDocumentType
    isDefault?: boolean
    organizationId: number
}

export enum TextModuleDocumentType {
    Bill = "Bill",
    BillSEPA = "Bill-SEPA",
    Offer = "Offer",
    Intervall = "Intervall",
    IntervallSEPA = "Intervall-SEPA",
    Reminder = "Reminder",
    ReminderFinal = "Reminder-FINAL",
    Other = "Other"
}

export const placeholderTextModule: ITextModule = {
    id: 0,
    title: "Standardtext",
    type: TextModuleDocumentType.Other,
    organizationId: 0
}

export interface ITextModuleDTO extends ITextModule {
    billPrefixIds: number[]
}

export const TextModuleApi = createApi({
    reducerPath: "TextModuleApi",
    baseQuery: baseAuthQuery,
    tagTypes: ["TextModule", "DefaultTextModule"],
    endpoints: (builder) => ({
        getTextModules: builder.query<ITextModule[], void>({
            query: () => ({
                url: "/TextModule"
            }),
            providesTags: (result) => result ? result.map(({ id }) => ({ type: "TextModule", id })) : []
        }),
        addTextModule: builder.mutation({
            query: (data: ITextModuleDTO) => ({
                url: "/TextModule",
                method: "POST",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "TextModule" }],
        }),
        getDefaultTextModules: builder.query<ITextModule[], void>({
            query: () => ({
                url: "/TextModule/default"
            }),
            providesTags: (result) => result ? result.map(({ id }) => ({ type: "DefaultTextModule", id })) : []
        }),
        getDefaultTextModuleById: builder.query<ITextModule, number>({
            query: (id) => ({
                url: `/TextModule/${id}`
            }),
            providesTags: (result) => result ? [{ type: "DefaultTextModule", id: result.id }] : []
        }),
        editDefaultTextModule: builder.mutation({
            query: (data: ITextModule) => ({
                url: "/TextModule/default",
                method: "PUT",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "DefaultTextModule", id: arg.id }],
        })
    })
})

export const {
    useAddTextModuleMutation,
    useGetTextModulesQuery,
    useGetDefaultTextModulesQuery,
    useGetDefaultTextModuleByIdQuery,
    useEditDefaultTextModuleMutation
} = TextModuleApi
