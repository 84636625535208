import { ReactNode } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMobileView } from "../../Hooks/useMobileView";

type RouteInfo = {
    name: string
    path: string
}

export default function PageTabsWrapper({ children, routes }: { children: ReactNode, routes: RouteInfo[] }) {

    const isMobile = useMobileView();

    const navLinkSize = 100 / routes.length;

    return (
        <div className='flex flex-col items-center w-[100%] h-[100%] gap-2 mt-[-8px]'>
            <div className='h-[5%] max-h-[40px] flex flex-row bg-default justify-between items-center box-border p-1 w-[20%] rounded-xl min-w-fit shadow gap-2 max-md:w-[60%]'>
                {routes && routes.map((route: RouteInfo) => (
                    <TabNavItem route={route} navLinkSize={navLinkSize} />
                ))}
            </div>
            <div className='flex flex-row w-[100%] h-[95%] gap-4 max-md:flex-col'>
                {children}
            </div>
        </div>
    )
}

const TabNavItem = ({ route, navLinkSize }: { route: RouteInfo, navLinkSize: number }) => {

    const { pathname } = useLocation();

    const active = (pathname === route.path)

    return (
        <div className={`text-center rounded-xl p-1 w-[${navLinkSize}%] ${active && "bg-brand-light"}`}>
            <NavLink style={{ width: "100%" }} to={route.path}>{route.name}</NavLink>
        </div>
    )
}