import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Login from '../Pages/Login/Login'
import Registration from '../Pages/Login/Registration'
import ResetPassword from '../Pages/Login/ResetPassword'
import NewPassword from '../Pages/Login/NewPassword'
import { useUser } from '../Contexts/UserContext/UserContext'
import RegisterNewOrganization from '../Pages/Login/RegisterNewOrganization'
import Store from '../Components/ShoppingCart/Store'
import Success from '../Pages/ShoppingCart/Success'
import Cancel from '../Pages/ShoppingCart/Cancel'
import DoubleOptInConfirmationPage from '../Pages/DoubleOptIn/DoubleOptInConfirmationPage'

function OnboardingRouter() {

  const { user } = useUser();

  return (
    <BrowserRouter>
      <Routes>
        {user?.needsToRegisterOrganization === true &&
          <>
            <Route path='/registerneworganization' element={<RegisterNewOrganization />} />
            <Route path="*" element={<Navigate to="/registerneworganization" replace />} />
          </>
        }
        {!(user?.needsToRegisterOrganization === true) &&
          <>
            <Route path='/success' element={<Success />} />
            <Route path='/cancel' element={<Cancel />} />
            <Route path='/' element={<Login />} />
            <Route path='/register' element={<Registration />} />
            <Route path='/reset' element={<ResetPassword />} />
            <Route path='/reset/:code' element={<NewPassword />} />
            <Route path="*" element={<Navigate to="/" replace />} />
            <Route path="/verifydoubleoptin" element={<DoubleOptInConfirmationPage />} />
          </>
        }
      </Routes>
    </BrowserRouter>
  )

}

export default OnboardingRouter

