// FileUpload.js
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const FileUpload = ({ onFilesSelected }: any) => {
    const onDrop = useCallback((acceptedFiles: any) => {
        onFilesSelected(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()} style={{
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
        }}>
            <input {...getInputProps()} />
            <p>{isDragActive ? 'Dateien hier ablegen' : 'Klicken oder Dateien hier ablegen'}</p>
        </div>
    );
};

export default FileUpload;
