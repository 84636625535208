import { DragHandleRounded, GroupsRounded } from '@mui/icons-material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import FormRow from '../../../Components/Forms/FormUtils/FormRow';
import { IPerson, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider';
import { useModals } from "../../../Contexts/DialogContext/DialogProvider";
import { IEmployee, useEmployees } from '../../../Contexts/EmployeeContext/EmployeeProvider';
import { AppointmentCategoryVisibilityTypes, useTasks } from "../../../Contexts/TaskContext/TaskContext";
import { IAppointment } from "../../../Interfaces/Appointment";
import { IOrganization } from '../../../Interfaces/Company';
import { calculateContrastColor } from '../ColorUtils';
import CreateAppointmentModal from '../CreateAppointmentModal';
import useDragger from '../useDragger';
import modalStyles from "./AppointmentInfoModal.module.css";

interface props {
    appointment?: IAppointment,
    setAppointment?: Dispatch<SetStateAction<IAppointment>>
    showAppointmentInfoModal?: boolean,
    setShowAppointmentInfoModal?: Dispatch<SetStateAction<boolean>>
}

const AppointmentInfoModal = ({ appointment, setAppointment, setShowAppointmentInfoModal }: props) => {
    const { deleteAppointment, updateAppointment, appointmentCategories } = useTasks();
    const dialogContext = useModals()
    const [openEditModal, setOpenEditModal] = useState(false);
    const { employees } = useEmployees()
    const { persons } = useContacts()
    const { companies } = useContacts();

    const [createdBy, setCreatedBy] = useState(employees.find((e: IEmployee) => e.id == appointment?.authorId))
    const [invitedOrganizations, setInvitedOrganizations] = useState(companies.filter((o: IOrganization) => appointment?.invitedOrganizationIds?.includes(o.id!)))
    const [invitedPersons, setInvitedPersons] = useState(persons.filter((p: IPerson) => appointment?.invitedPersonIds?.includes(p.id!)))
    const [invitedEmployees, setInvitedEmployees] = useState(employees.filter((e: IEmployee) => appointment?.invitedUserIds?.includes(e.id!)))
    const [activeEmployees, setActiveEmployees] = useState(employees.filter((employee: IEmployee) => employee.active))

    const [editParticipants, setEditParticipants] = useState<boolean>(false);
    const [initialAppointment, setInitialAppointment] = useState(appointment);
    const [category, setCategory] = useState(appointmentCategories.find((ac) => ac.id == appointment?.appointmentCategory))

    useDragger('target', 'AppointmentInfoModal')

    useEffect(() => {
        setActiveEmployees(employees.filter((employee: IEmployee) => employee.active))
    }, [employees])

    const handleCancel = () => {
        setAppointment!(initialAppointment!);
        setEditParticipants(false)
    }

    const handleSave = () => {
        updateAppointment!(appointment!)
        setEditParticipants(false)
    }

    useEffect(() => {
        setInvitedOrganizations(companies.filter((o: IOrganization) => appointment?.invitedOrganizationIds?.includes(o.id!)))
        setInvitedPersons(persons.filter((p: IPerson) => appointment?.invitedPersonIds?.includes(p.id!)));
        setInvitedEmployees(employees.filter((e: IEmployee) => appointment?.invitedUserIds?.includes(e.id!)))
    }, [appointment])

    const onPressDelete = () => {
        dialogContext.changeVisibility!("yesNoDialog", true,
            {
                props: {
                    object: {
                        submitFunction: () => {
                            deleteAppointment!(appointment!);
                            setShowAppointmentInfoModal!(false);
                        },
                        modalTitle: "Termin löschen",
                        confirmButtonText: "Löschen",
                        modalText: "Möchten Sie diesen Termin wirklich löschen?"
                    }
                }
            });
    }

    return (
        <div className={modalStyles.modalContainer}>
            <form className={modalStyles.modalForm}>
                <header className={modalStyles.modalHeader} style={{ background: category?.color }} id='target'>
                    {<DragHandleRounded style={{ color: category?.color ? calculateContrastColor(category.color) : "", marginLeft: '0px', marginRight: 'auto' }} />}
                    <Button onClick={() => {
                        setShowAppointmentInfoModal!(false)
                    }}
                        style={{ minWidth: '5%' }} size="small"><CloseRoundedIcon fontSize='small' style={{ color: category?.color ? calculateContrastColor(category.color) : "" }} /></Button>
                </header>
                <div className={modalStyles.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Titel:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{appointment?.title}</Grid>
                        <Grid item xs={12} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}><hr style={{ border: '1px solid whitesmoke' }} /></Grid>
                        {appointment?.description &&
                            <>
                                <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Beschreibung:</Grid>
                                <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{appointment?.description}</Grid>
                                <Grid item xs={12} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}><hr style={{ border: '1px solid whitesmoke' }} /></Grid>
                            </>
                        }

                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Start:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{dayjs(appointment?.start!).format('DD.MM.YYYY - HH:mm')}</Grid>
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Ende:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{dayjs(appointment?.end!).format('DD.MM.YYYY - HH:mm')}</Grid>
                        {appointment?.appointmentCategory &&
                            <>
                                <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Kategorie:</Grid>
                                <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{category?.name}</Grid>
                            </>}
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Sichtbarkeit:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{appointment?.visibility === AppointmentCategoryVisibilityTypes.Organization ? "Organisation" : "Privat"}</Grid>
                        {/* Only render Participant list when atleast one of the invite lists contains elements */}
                        {((appointment?.invitedOrganizationIds != null && appointment?.invitedOrganizationIds?.length > 0) ||
                            (appointment?.invitedPersonIds != null && appointment?.invitedPersonIds?.length > 0) ||
                            (appointment?.invitedUserIds != null && appointment?.invitedUserIds.length > 0) || editParticipants) && <>
                                <Grid item xs={12} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}><hr style={{ border: '1px solid whitesmoke' }} /></Grid>
                                <Grid item xs={12} style={{ whiteSpace: 'normal', wordWrap: 'break-word', fontWeight: 'bold', marginTop: '0.5em' }}>Teilnehmer</Grid>
                                {(editParticipants || invitedOrganizations.length > 0) && <>
                                    <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Organisationen:</Grid>
                                    <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                        {/* Depending on whether edit is enabled render a formrow or just the list of elements */}
                                        {editParticipants ?
                                            <FormRow type="select"
                                                label=""
                                                placeholder="Organisationen auswählen"
                                                value={appointment?.invitedOrganizationIds ?? []}
                                                onChange={(val) => setAppointment!((old) => ({ ...old, invitedOrganizationIds: val }))}
                                                selectOptions={{
                                                    titleKey: 'name',
                                                    valueKey: 'id',
                                                    options: companies,
                                                    multiple: true
                                                }} />
                                            :
                                            invitedOrganizations.map((o, idx) => {
                                                if (idx === invitedOrganizations.length - 1) {
                                                    return (<span key={idx}>{o.name}</span>)
                                                } else {
                                                    return (<span key={idx}>{o.name}, </span>)
                                                }
                                            })}
                                    </Grid>
                                </>}
                                {(editParticipants || invitedPersons.length > 0) && <>
                                    <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Personen:</Grid>
                                    <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                        {editParticipants ?
                                            <FormRow type="select"
                                                label=""
                                                placeholder="Personen auswählen"
                                                value={appointment?.invitedPersonIds ?? []}
                                                onChange={(val) => setAppointment!((old) => ({ ...old, invitedPersonIds: val }))}
                                                selectOptions={{
                                                    titleKey: 'completeName',
                                                    valueKey: 'id',
                                                    options: persons,
                                                    multiple: true
                                                }} />
                                            :
                                            invitedPersons.map((p, idx) => {
                                                if (idx === invitedPersons.length - 1) {
                                                    return (<span key={idx}>{p.completeName}</span>)
                                                } else {
                                                    return (<span key={idx}>{p.completeName}, </span>)
                                                }
                                            })}
                                    </Grid>
                                </>}
                                {(editParticipants || invitedEmployees.length > 0) && <>
                                    <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Mitarbeiter:</Grid>
                                    <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                                        {editParticipants ?
                                            <FormRow type="select"
                                                label=""
                                                placeholder="Mitarbeiter*innen auswählen"
                                                value={appointment?.invitedUserIds ?? []}
                                                onChange={(val) => setAppointment!((old) => ({ ...old, invitedUserIds: val }))}
                                                selectOptions={{
                                                    titleKey: 'username',
                                                    valueKey: 'id',
                                                    options: activeEmployees,
                                                    multiple: true
                                                }} />
                                            :
                                            invitedEmployees.map((e, idx) => {
                                                if (idx === invitedEmployees.length - 1) {
                                                    return (<span key={idx}>{e.username}</span>)
                                                } else {
                                                    return (<span key={idx}>{e.username}, </span>)
                                                }
                                            })}
                                    </Grid>
                                </>}
                            </>}
                        <span style={{ marginRight: '0px', marginLeft: 'auto', marginTop: '1em', color: 'grey', fontSize: '0.9em', fontStyle: 'italic' }}>
                            Eingetragen von {createdBy?.username} am {dayjs(appointment?.createdDate!).format('DD.MM.YYYY - HH:mm')}
                        </span>
                    </Grid>
                </div>
                <footer className={modalStyles.modalFooter}>
                    {editParticipants ?
                        <>
                            <Tooltip title='Änderungen verwerfen' arrow disableInteractive style={{ marginRight: 'auto' }}>
                                <IconButton
                                    onClick={handleCancel}>
                                    <CancelRoundedIcon color='error' fontSize='medium' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Änderungen speichern' arrow disableInteractive>
                                <IconButton style={{ color: 'green' }}
                                    onClick={handleSave}>
                                    <CheckCircleRoundedIcon fontSize='medium' />
                                </IconButton>
                            </Tooltip>
                        </>
                        :
                        <>
                            <Tooltip title='Termin löschen' arrow disableInteractive style={{ marginRight: 'auto' }}>
                                <IconButton
                                    onClick={onPressDelete}>
                                    <DeleteRoundedIcon color='error' fontSize='medium' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Teilnehmer verwalten' arrow disableInteractive>
                                <IconButton style={{ color: 'darkslategrey' }}
                                    onClick={() => {
                                        setEditParticipants(true)
                                    }}>
                                    <GroupsRounded fontSize='medium' />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title='Termininformationen bearbeiten' arrow disableInteractive>
                                <IconButton
                                    onClick={() => {
                                        setOpenEditModal(true)
                                    }}>
                                    <EditRoundedIcon color='primary' fontSize='medium' />
                                </IconButton>
                            </Tooltip>
                        </>}
                </footer>
            </form>
            <CreateAppointmentModal open={openEditModal}
                setOpen={setOpenEditModal}
                mode="edit"
                appointment={appointment}
                setShowAppointmentInfoModal={setShowAppointmentInfoModal} />
        </div>
    )
}

export default AppointmentInfoModal;