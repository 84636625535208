import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../UI/Chart";
import { ServiceMonthlySummary } from "../../redux/apiSlices/Project";

const chartConfig = {
  totalBilled: {
    label: "Verr.",
    color: "hsl(var(--chart-green))",
  },
  totalNotBilled: {
    label: "Offen",
    color: "hsl(var(--chart-red))",
  },
} satisfies ChartConfig;

type ServiceSummaryChartProps = {
  chartData: ServiceMonthlySummary[];
};
export function ServiceSummaryChart({ chartData }: ServiceSummaryChartProps) {
  return (
    <ChartContainer config={chartConfig}>
      <BarChart accessibilityLayer data={chartData}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="month"
          tickLine={false}
          tickMargin={10}
          axisLine={false}
          tickFormatter={(value) => value.slice(0, 3)}
        />
        <ChartTooltip content={<ChartTooltipContent />} />
        <ChartLegend content={<ChartLegendContent />} />
        <Bar
          dataKey="totalBilled"
          stackId="a"
          fill="var(--color-totalBilled)"
          radius={[0, 0, 4, 4]}
        />
        <Bar
          dataKey="totalNotBilled"
          fill="var(--color-totalNotBilled)"
          stackId="a"
          radius={[4, 4, 0, 0]}
        />
      </BarChart>
    </ChartContainer>
  );
}
