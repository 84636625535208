import { CircularProgress } from '@mui/material'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useJournal } from '../../../Contexts/JournalContext/JournalProvider'
import { useUser } from '../../../Contexts/UserContext/UserContext'
import { journal, journalDTO } from '../../../Interfaces/Journal'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateJournalForm from '../../Forms/CreateJournalForm'
import { dialog } from '../CreateServiceDialog/CreateServiceDialog'

export interface dialogWithProps<T> extends dialog {
    object: T | null
    mode?: string | null
}

export const validateJournalForm = (journal: journal, setError: Dispatch<SetStateAction<any>>) => {
    let valid = true;

    if ((journal.title?.length ?? 0) < 1) {
        setError((old: any) => ({ ...old, titleError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, titleError: false }))
    }

    if ((journal.title?.length ?? 0) > 500) {
        setError((old: any) => ({ ...old, titleTooLongError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, titleTooLongError: false }))
    }

    if ((journal.description?.length ?? 0) < 1) {
        setError((old: any) => ({ ...old, descriptionError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, descriptionError: false }))
    }

    if ((journal.description?.length ?? 0) > 2500) {
        setError((old: any) => ({ ...old, descriptionTooLongError: true }))
        valid = false;
    } else {
        setError((old: any) => ({ ...old, descriptionTooLongError: false }))
    }

    return valid;
}

const EditCreateJournalDialog = ({ open, onClose, object, mode }: dialogWithProps<journalDTO>) => {
    const { addJournal, updateJournal } = useJournal();
    const { changeVisibility } = useModals();

    const handleClose = () => {
        setLoading(false)
        if (onClose) {
            handleClose()
        }
        if (!loading) {
            changeVisibility!("editCreateJournal", false)
        }
    }

    const { user } = useUser();

    useEffect(() => {
        setJournalToSubmit({ ...object, organizationId: user?.organizationId!, author: user?.userId, documents: undefined, files: undefined })
    }, [object])

    const [loading, setLoading] = useState(false)

    const [journalToSubmit, setJournalToSubmit] = useState<journalDTO>(object ?? { organizationId: user?.organizationId, author: user?.userId })

    const handleSubmit = async () => {
        try {
            if (validateJournalForm(journalToSubmit, setError)) {
                setLoading(true)
                if (mode == "create") {
                    await addJournal!(journalToSubmit)
                } else {
                    await updateJournal!(journalToSubmit)
                }
            }
        } catch (error) {

        } finally {
            if (validateJournalForm(journalToSubmit, setError)) {
                setLoading(false)
                setJournalToSubmit({ organizationId: user?.organizationId, author: user?.userId })
                changeVisibility!("editCreateJournal", false)
            }
        }
    }

    const [error, setError] = useState<any>({});

    return (
        <Modal
            open={open}
            title={mode == "create" ? "Journal erstellen" : "Journal bearbeiten"}
            onClose={() => handleClose()}
        >
            <CreateJournalForm
                object={journalToSubmit}
                setObject={setJournalToSubmit}
                error={error}
            />
            <div style={{ height: "20px" }} />
            <CTAButton
                style={{ zIndex: 0 }}
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Speichern"}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}

export default EditCreateJournalDialog