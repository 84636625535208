import { AddRounded } from '@mui/icons-material'
import React, { Dispatch, SetStateAction } from 'react'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import Card from '../Card/Card'
import { blankcard } from '../ServiceCard/ServiceCard'
import Travel from '../Tavel/Travel'
import { IStaff } from '../../Contexts/StaffContext/StaffProvider'
import { useGetAllTravelsQuery } from '../../redux/apiSlices/Travel'

//wir brauchen in zusätzliches Interface für die ClickedUsers
interface iTravelCard extends blankcard {
  setClickedUsers?: Dispatch<SetStateAction<IStaff[]>>
}

const TravelCard = ({ height, width, order, cta, ctaTitle }: iTravelCard) => {

  const { changeVisibility } = useModals()

  const { data: travels } = useGetAllTravelsQuery();

  return (
    <Card
      title='Meine Fahrten'
      cta={cta}
      ctaTitle={ctaTitle}
      ctaFunction={() => changeVisibility!("editCreateTravel", true, { props: { mode: "create" } })}
      buttonIcon={<AddRounded fontSize='small' />}
      style={{ width, height, order }}
    >
      <div>
        {
          travels && travels.map((travel, index) => (
            <Travel key={index} index={index} travel={travel} />
          ))
        }
      </div>
    </Card>
  )
}

export default TravelCard