import { useForm } from "react-hook-form"
import ResetFormButton from "../../Components/Buttons/ResetFormButton/ResetFormButton"
import { form } from "../../Components/Forms/CreateTaskForm"
import Form from "../../Components/Forms/FormUtils/Form"
import FormRow from "../../Components/Forms/FormUtils/FormRow"

import { useUser } from "../../Contexts/UserContext/UserContext"
import { BillPrefix } from "../../Interfaces/Accounting"
import CTAButton from "../../Components/Buttons/CTAButton/CTAButton"
import { useHandleAddBillPrefix, useHandleEditBillPrefix } from "../../redux/requestHandlers/BillPrefix"
import { useEffect } from "react"

const EditCreateBillPrefix = ({ object, setObject, mode, setFormMode, errorVariables, setModalOpen }: form<BillPrefix>) => {

    const { user } = useUser()
    const { handleAddBillPrefix } = useHandleAddBillPrefix();
    const { handleEditBillPrefix, isSuccess: isEditSuccess } = useHandleEditBillPrefix();

    const addBillPrefix = () => {
        handleAddBillPrefix(object);
    }

    useEffect(() => {
        if (setModalOpen) {
            if (isEditSuccess) {
                setModalOpen(false);
            }
        }
    }, [isEditSuccess])

    const editBillPrefix = async () => {
        await handleEditBillPrefix(object);
    }

    const handleReset = () => {
        let billPrefix: BillPrefix = {
            id: 0,
            prefix: "",
            isOffer: false,
            organizationId: user?.organizationId ?? 0
        }
        setObject(billPrefix);
    }

    const { register, formState: { errors }, handleSubmit, setValue } = useForm<BillPrefix>({ defaultValues: object });

    return (
        <Form>
            <FormRow
                type="text"
                value={object.prefix}
                label="Rechnungskreisbezeichnung"
                placeholder="RE"
                onChange={(val) => setObject((old) => ({ ...old, prefix: val }))}
                maxLength={20}
                register={register("prefix", { required: "Bitte geben Sie eine Bezeichnung an", maxLength: { value: 20, message: "Bezeichnung darf max. 20 Zeichen enthalten" } })}
                error={!!errors.prefix}
                errorMessage={errors.prefix?.message}
            />
            <FormRow
                type="switch"
                value={object.isOffer}
                label="Angebot"
                onChange={(val) => setObject((old) => ({ ...old, isOffer: val }))}
            />
            <ResetFormButton
                title="Felder zurücksetzen"
                onClickHandler={() => handleReset()}
            />
            <div className="w-[100%] flex justify-center">
                <CTAButton
                    title="Speichern"
                    onClickHandler={mode === "edit" ? handleSubmit(editBillPrefix) : handleSubmit(addBillPrefix)}
                    style={{ width: "20%" }}
                />
            </div>

        </Form>
    )
}

export default EditCreateBillPrefix