import { Switch, Tooltip } from '@mui/material'
import { AxiosResponse } from 'axios'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IIntervallPosition } from '../../Contexts/IntervallContext/IntervallProvider'
import { IProductDTO, useProducts } from '../../Contexts/ProductContext/ProductProvider'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { IBillingPosition } from '../../Interfaces/Bill'
import { IService } from '../../Interfaces/Service'
import Android12Switch from '../Buttons/Switch/Android12Switch'
import DateInputField from '../InputField/DateInputField'
import InputField from '../InputField/InputField'
import MultiLineInputField from '../InputField/MultiLineInputField'
import SearchableSelect from '../InputField/SearchableSelect'

interface props {
    object: IIntervallPosition,
    setObject: Dispatch<SetStateAction<IIntervallPosition>>,
    product?: IProductDTO,
    setProduct?: Dispatch<SetStateAction<IProductDTO | undefined>>,
}

const EditCreateExpanseIntervallPositionForm = ({ object, setObject, product, setProduct }: props) => {

    const [service, setService] = useState<IService | undefined>(undefined);


    const { products, updateProduct } = useProducts();

    const connection = useAuthConnection();

    useEffect(() => {
        if (setProduct && object.productId !== undefined && object.productId > 0) {
            setProduct(products.find(temp => temp.id === object.productId));
        }
        console.log(object.quantity)
    }, [object])

    useEffect(() => {
        if (object.hasDiscount === true) {
            setObject((old) => ({ ...old, discountType: false }));
        }
    }, [object.hasDiscount])

    function diff_hours(dt2: Date, dt1: Date) {

        var diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60);
        return Math.abs(Math.ceil(diff / 0.25) * 0.25);

    }

    const productToBillingPosition = (val: number) => {
        if (val > 0 && products) {
            let tempObj: IProductDTO | undefined = products.find(temp => temp.id === val);
            if (setProduct && tempObj) {
                setProduct(tempObj);
            }
            setObject({ ...object, title: tempObj?.title ?? "", description: tempObj?.description ?? "", productId: tempObj?.id ?? 0, netto: tempObj?.salesPrice ?? 0, quantity: 1 })
        }
    }

    return (
        <form className="form">

            {/*(products && products!.length > 0 && (service === undefined || product !== undefined) || (products && object.productId !== undefined && object.productId > 0)) && <div className='form-row-label'>
                <span className="label">
                    Produkt:
                </span>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <SearchableSelect
                        width="100%"
                        titleKey='title'
                        valueKey='id'
                        options={products}
                        value={object.productId}
                        setValue={(val: any) => productToBillingPosition(val)}
                        placeholder='Produkt auswählen'
                    />
                </div>
    </div>*/}

            <div className='form-row-label'>

                <span className="label">
                    Bezeichnung:
                </span>
                <InputField
                    value={object.title}
                    onChangeHandler={(title) => setObject((old) => ({ ...old, title }))}
                    placeholder="Bitte geben Sie einen sprechenden Namen ein..."
                />
            </div>
            <div className='form-row-label'>
                <span className="label">
                    Beschreibung:
                </span>
                <MultiLineInputField
                    value={object?.description ?? ""}
                    onChangeHandler={(description) => setObject((old) => ({ ...old, description }))}
                    placeholder="Hier können Sie die Ausgabe näher beschreiben..."
                />
            </div>
            <div className='form-row-label'>
                <span className="label">
                    Netto:
                </span>
                <InputField
                    type='number'
                    step='.01'
                    value={object.netto}
                    onChangeHandler={(netto) => {
                        const parsedNetto = parseFloat(netto);
                        const newNetto = parsedNetto > 0 ? parsedNetto * -1 : parsedNetto;
                        setObject((old) => ({ ...old, netto: newNetto }));
                    }}
                    placeholder="Netto"
                />
            </div>

            <div className='form-row-label'>
                <span className="label">
                    Steuer:
                </span>
                <InputField
                    type='number'
                    step='1'
                    value={object.tax}
                    onChangeHandler={(tax) => { setObject((old) => ({ ...old, tax: parseFloat(tax) })) }}
                    placeholder="Netto"
                />
            </div>
            <div className='form-row-label'>
                <span className="label">
                    Stückzahl:
                </span>
                <InputField
                    type='number'
                    step='1'
                    value={(object.quantity && object.quantity > 0) ? object.quantity : 1}
                    onChangeHandler={(quantity) => {
                        if (parseFloat(quantity) >= 0) {
                            setObject((old) => ({ ...old, quantity: parseFloat(quantity) }))
                        }
                    }}
                    placeholder="Netto"
                />
                {product?.count !== undefined && product.count !== null && (object?.quantity ?? 0) > product?.count && <span className="label" style={{ color: "#FF9494" }}>
                    Es sind nur {product?.count} Stück verfügbar.
                </span>}
            </div>
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Rabatt</span>
                <span>
                    <Android12Switch color="secondary" checked={object?.hasDiscount === undefined ? false : object.hasDiscount} onChange={(e, val) => setObject((oldVal: IIntervallPosition) => ({ ...oldVal, hasDiscount: val }))} />
                </span>
            </div>
            {object?.hasDiscount === true &&
                <>
                    <div className='form-row noselect'>
                        <span style={{ fontSize: "14px" }}>Prozentual</span>
                        <Android12Switch color="secondary" checked={object?.discountType === undefined ? false : object.discountType} onChange={(e, val) => setObject((oldVal: IIntervallPosition) => ({ ...oldVal, discountType: val ?? false }))} />
                        <span style={{ fontSize: "14px" }}>Betrag</span>
                    </div>
                    <div className='form-row-label'>
                        <span className="label">
                            Rabatt:
                        </span>
                        <InputField
                            type='number'
                            step='.1'
                            value={object.discount}
                            onChangeHandler={(discount) => setObject((old) => ({ ...old, discount: parseFloat(discount) }))}
                            placeholder="Rabatt in %"
                        />
                    </div>
                </>
            }
        </form>
    )
}

export default EditCreateExpanseIntervallPositionForm