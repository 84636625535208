import React from 'react'

const OfferReducer = (state: any, action: any) => {
  switch(action.type) {
      default:
          return {
              ...state
          }
  }
}

export default OfferReducer