import React from 'react'
import { IOrganizationRole } from './OrganizationRightsProvider'

const OrganizationRightsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_ROLES":
      return {
        ...state,
        organizationRoles: action.payload
      }
    default:
      return {
        ...state
      }
  }
}

export default OrganizationRightsReducer