
//Reducer handelt die Aktionen, um den komplexen State zu manipulieren
const userReducer = (state: any, action: any) => {
    switch(action.type) {
        case "SET_USER":
            return {
                ...state,
                user: action.payload
            }
        case "UPDATE_USER_SETTINGS":
            return {
                ...state, user: {
                    ...state.user, 
                    birthdate: action.payload.birthdate, 
                    svnr: action.payload.svnr, 
                    voipAddress: action.payload.voipAddress,
                    emailMFA: action.payload.emailMFA,
                    receivesNewsletter: action.payload.receivesNewsletter,
                    automaticPause: action.payload.automaticPause
                }
            }
        default:
            return {
                ...state
            }
    }
}

export default userReducer