import React from 'react'
import Page from '../../Navigation/Page'

const CheckBill = () => {
  return (
    <>
        Rechnungsansicht
    </>
  )
}

export default CheckBill