import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";

function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * @summary Util function that checks if a custom error message from the API is available
 * @param error Error from catch clause in request handler hook
 * @returns Error Messsage as a string to be displayed in snackbar or elsewhere
 */
export function RTKQueryErrorHandler(error: unknown): string {
    let errorMessage = "Ein Fehler ist aufgetreten";
    if (isFetchBaseQueryError(error)) {
        errorMessage = JSON.stringify(error.data)
    }

    errorMessage = errorMessage.replace(/^[\"']|[\"']$/g, ''); //remove boundary quotes from serialized error message

    return errorMessage;
}