import { Dispatch, SetStateAction, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useServices } from '../../../Contexts/ServiceContext/ServiceContext'
import { IService, IServiceDTO } from '../../../Interfaces/Service'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateServiceForm from '../../Forms/CreateServiceForm'


export type dialog = {
    open: boolean
    onClose?: () => void
}

export function validateServiceInput(service: IServiceDTO | IService, setEmployeeError: Dispatch<SetStateAction<boolean>>, setCustomerError: Dispatch<SetStateAction<boolean>>, setTtitleError: Dispatch<SetStateAction<boolean>>, setDateStartError: Dispatch<SetStateAction<boolean>>) {
    if (service.employeeDatas.length === 0) {
        setEmployeeError(true);
    } else {
        setEmployeeError(false);
    }

    if (!service.clientOrganizationId || service.clientOrganizationId <= 0) {
        setCustomerError(true);
    } else {
        setCustomerError(false);
    }

    if (service.title.length === 0) {
        setTtitleError(true);
    } else {
        setTtitleError(false);
    }

    const from = service.employeeDatas[0].from;
    const to = service.employeeDatas[0].to;

    if (from.isAfter(to)) {
        setDateStartError(true);
    } else {
        setDateStartError(false);
    }

    if (
        service.employeeDatas.length === 0 ||
        !service.clientOrganizationId ||
        service.clientOrganizationId <= 0 ||
        service.title.length === 0 ||
        from.isAfter(to)
    ) {
        return false;
    }
    return true;
}

function CreateServiceDialog({ open, onClose }: dialog) {

    const { changeVisibility } = useModals()

    const { addService, defaultService } = useServices() ?? {}

    //TODO -> Fabian
    const [serviceToSubmit, setServiceToSubmit] = useState<IServiceDTO>({
        ...defaultService!
    })

    //Funktion zum Handeln des Hinzufügens -> Wird ein Promise werden
    const handleSubmit = async (service: IServiceDTO) => {
        if (validateServiceInput(service, setEmployeeError, setCustomerError, setTtitleError, setDateStartError)) {
            addService!(service)
            onClose ? onClose() : changeVisibility!("createService", false)
        }
    }

    //form error handling variables
    const [employeeError, setEmployeeError] = useState(false);
    const [customerError, setCustomerError] = useState(false);
    const [titleError, setTtitleError] = useState(false);
    const [dateStartError, setDateStartError] = useState(false);




    return (
        <Modal
            open={open}
            title='Leistung erstellen'
            onClose={onClose ? onClose : () => changeVisibility!("createService", false)}
        >
            <CreateServiceForm
                object={serviceToSubmit}
                setObject={setServiceToSubmit}
                errorVariables={{ employeeError, customerError, titleError, dateStartError }}
            />
            <CTAButton
                title='Speichern'
                onClickHandler={() => handleSubmit(serviceToSubmit)}
            />
        </Modal>
    )
}

export default CreateServiceDialog