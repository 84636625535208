import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { IconButton } from '@mui/material';
import React, { useEffect, useRef, useState } from "react";
import PageNew from "../Page/PageNew";

import { CSSTransition } from "react-transition-group";
import itemStyles from './CarouselItem.module.css';
import styles from './ComponentCarousel.module.css';

export interface ICarouselItem {
    Component: React.FC
    componentProps?: any
    nodeRef: any
}

interface IComponentCarousel {
    componentsToDisplay: ICarouselItem[]
}

function ComponentCarousel({ componentsToDisplay }: IComponentCarousel) {

    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const componentCount = useRef<number>(0);

    useEffect(() => {
        componentCount.current = componentsToDisplay.length;
    }, [componentsToDisplay])

    const [swipeDirection, setSwipeDirection] = useState({ isLeftSwipe: false, isRightSwipe: false });

    function updateActiveItemIndex(newIndex: number) {
        if (newIndex < 0) {
            setActiveItemIndex(componentCount.current - 1);
        }
        else if (newIndex === componentCount.current) {
            setActiveItemIndex(0);
        }
        else {
            setActiveItemIndex(newIndex);
        }
    }

    //TODO: Implement update of activeItemIndex on swipe left/right

    const minSwipeDistance = 100;

    const touchStart = useRef<number | null>(null);
    const touchEnd = useRef<number | null>(null);

    const onTouchStart: React.TouchEventHandler<HTMLDivElement> = (e) => {
        touchEnd.current = null;
        touchStart.current = e.targetTouches[0].clientX;
        console.log("touchStart", touchStart.current);
    }

    const onTouchMove: React.TouchEventHandler<HTMLDivElement> = (e) => {
        touchEnd.current = e.targetTouches[0].clientX;
        console.log("touchEnd", touchEnd.current)
    }

    const onTouchEnd: React.TouchEventHandler<HTMLDivElement> = () => {
        if (!touchStart.current || !touchEnd.current) return;

        const distance = (touchStart.current as number) - (touchEnd.current as number);
        console.log("distance", distance);
        const isLeftSwipe = distance > minSwipeDistance;
        const isRightSwipe = distance < -minSwipeDistance;

        if (isLeftSwipe) {
            //TODO: increment activeItemIndex
            console.log("left swipe")
            updateActiveItemIndex(activeItemIndex + 1);
        }
        else if (isRightSwipe) {
            //TODO: decrement activeItemIndex
            console.log("right swipe");
            updateActiveItemIndex(activeItemIndex - 1);
        }
    }

    const transitionClasses = {
        enter: itemStyles.itemEnter,
        enterActive: itemStyles.itemEnterActive,
        enterDone: itemStyles.itemEnterDone,
        exit: itemStyles.itemExit,
        exitActive: itemStyles.itemExitActive,
        exitDone: itemStyles.itemExitDone
    };

    const transitionClassesRightSwipe = {
        enter: itemStyles.itemEnter,
        enterActive: itemStyles.itemEnterActiveRight,
        enterDone: itemStyles.itemEnterDone,
        exit: itemStyles.itemExit,
        exitActive: itemStyles.itemExitActive,
        exitDone: itemStyles.itemExitDone
    }

    //timeout: number | { appear?: number | undefined; enter?: number | undefined; exit?: number | undefined };
    const timeout = { appear: 0, enter: 500, exit: 0 }
    return (
        <>
            <div onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} onTouchMove={onTouchMove}>

                {componentsToDisplay.map((item, index) => (
                    <CSSTransition key={index} nodeRef={item.nodeRef} in={activeItemIndex === index} timeout={timeout} classNames={swipeDirection.isLeftSwipe ? transitionClasses : transitionClassesRightSwipe} mountOnEnter unmountOnExit>
                        {(state) => (
                            <div ref={item.nodeRef} className={`${itemStyles[state]}`} key={index} style={{ height: "100%" }}>
                                {/*<CarouselItem Component={item.Component} index={index} key={index} />*/}
                                <item.Component {...item.componentProps}/>
                            </div>
                        )}
                    </CSSTransition>
                ))}

            </div>
            <div className={styles.indicatorContainer}>
                <IconButton onClick={() => { updateActiveItemIndex(activeItemIndex - 1); setSwipeDirection({ isLeftSwipe: false, isRightSwipe: true }) }}><ArrowBackIosNewIcon /></IconButton>
                <div >
                    {componentsToDisplay.map((Component, index) => (
                        activeItemIndex === index ?
                            <RadioButtonCheckedIcon key={index} /> : <RadioButtonUncheckedIcon key={index} />
                    ))}

                </div>
                <IconButton onClick={() => { updateActiveItemIndex(activeItemIndex + 1); setSwipeDirection({ isLeftSwipe: true, isRightSwipe: false }) }}><ArrowForwardIosIcon /></IconButton>
            </div>
        </>
    )

}

export default ComponentCarousel;