import { useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useServices } from '../../../Contexts/ServiceContext/ServiceContext'
import { IServiceDTO } from '../../../Interfaces/Service'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateServiceForm from '../../Forms/CreateServiceForm'
import {dialog} from '../CreateServiceDialog/CreateServiceDialog'



function CreateOfferDialog({open, onClose} : dialog) {

    const {changeVisibility} = useModals()

    const [offerToSubmit, setOfferToSubmit] = useState({

    })

    
    //TODO Implementieren -> Bernhard obzw. Nico
    //Funktion zum Handeln des Hinzufügens -> Wird ein Promise werden
    const handleSubmit = async () => {
        
        onClose ? onClose() : changeVisibility!("createService", false)
    }
    
  return (
    <Modal
    open={open}
    title='Angebot erstellen'
    onClose={onClose ? onClose : () => changeVisibility!("createOffer", false)}
    >
        {/* OfferForm als eigene Komponente */}
        <CTAButton
        title="Speichern"
        onClickHandler={() => handleSubmit()}
        />
    </Modal>
  )
}

export default CreateOfferDialog