import UnderConstructionCard from "../../Components/UnderConstructionCard/UnderConstructionCard";

const Messenger = () => {

    return (
        <UnderConstructionCard
            height='100%'
            width='100%'
        />
    )
};

export default Messenger;

