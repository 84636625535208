import { AddRounded } from "@mui/icons-material";
import { useState } from "react";
import Card from "../../Components/Card/Card";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { useUser } from "../../Contexts/UserContext/UserContext";
import useAuthConnection from "../../Hooks/useAuthConnection";
import { BillPrefix } from "../../Interfaces/Accounting";
import { useMobileView } from "../../Hooks/useMobileView";
import { useAddBillPrefixMutation } from "../../redux/apiSlices/BillPrefix";
import { useSnackbar } from "notistack";
import { useHandleAddBillPrefix } from "../../redux/requestHandlers/BillPrefix";
import EditCreateBillPrefix from "./EditCreateBillPrefix";

const CreateBillPrefix = () => {

    const { user } = useUser();

    const [billPrefix, setBillPrefix] = useState<BillPrefix>({
        id: 0,
        prefix: "",
        isOffer: false,
        organizationId: user?.organizationId ?? 0
    });

    return (
        <>
            <Card
                title="Rechnungskreis erstellen"
                style={{ width: "100%", height: "100%" }}
            >
                <EditCreateBillPrefix
                    object={billPrefix}
                    setObject={setBillPrefix}
                    mode="create"
                />
            </Card>
        </>
    )
}

export default CreateBillPrefix
