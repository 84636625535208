import { Collapse } from '@mui/material'
import React, { ReactNode, useId, useLayoutEffect, useRef, useState } from 'react'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import styles from './Card.module.css'

interface card {
    children: ReactNode
    style?: { width?: string, height?: string, order?: string, backgroundColor?: string }
    css?: React.CSSProperties
    buttonIcon?: ReactNode
    title?: ReactNode
    cta?: boolean
    ctaTitle?: ReactNode
    ctaFunction?: () => void
    right?: (props: { expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>> }) => ReactNode
    left?: ReactNode
    defaultExpanded?: boolean
    expandedElement?: ReactNode
    isButtonPrimaryAction?: boolean
    /* Dient dazu, das Parent-Element auf display: flex zu setzen */
    flex?: boolean
    classes?: string[]
    fullHeightBody?: boolean
    disableBodyMargin?: boolean
    disableScroll?: boolean
    onScroll?: any
    innerListRef?: any
    bodyPadding?: any
}

const heightChanger = (entries: ResizeObserverEntry[]) => {

}
function Card({ onScroll, innerListRef, children, disableBodyMargin, disableScroll, fullHeightBody, cta, css, classes, ctaTitle, ctaFunction, right, title, buttonIcon, bodyPadding = true, style = { height: "100%", width: "100%", order: undefined }, expandedElement, isButtonPrimaryAction, left, defaultExpanded, flex }: card) {

    //* Komponentenlogik

    //Regeln des Aufklappens
    const [expanded, setExpanded] = useState(defaultExpanded ?? false)

    //* Festlegen der Button-Position

    //TODO Resize-Observer -> Fabian

    const bodyRef = useRef(null)



    //ID der Card, um die Position des CTA-Buttons festzulegen
    const cardID = useId()

    //ID des Contents der Card
    const bodyID = useId()

    //State, der speichert, ob der button mit postion absolute oder sticky unten an der Card zu sehen soll
    const [fixed, setFixed] = useState(false)


    //Beim Painten der Elemente soll die Entscheidung gefällt werden
    useLayoutEffect(() => {

        //Referenz für das Bestimmen der Höhe
        const scrollContent = document.getElementById(cardID)

        //Höhen des Elements
        const heights = {
            scroll: scrollContent?.scrollHeight,
            visible: scrollContent?.offsetHeight
        }

        setFixed(heights.scroll! / heights.visible! <= 1)

    }, [children])


    useLayoutEffect(() => {

        //Referenz für das Bestimmen der Höhe
        const scrollContent = document.getElementById(cardID)

        new ResizeObserver((entries) => heightChanger(entries)).observe(scrollContent!)

        return () => {
            new ResizeObserver(heightChanger).disconnect()
        }
    }, [])

    //TODO Farbe für CTA-Button durch Typ/generell bestimmen -> Fabian

    return (
        <div id={cardID} className={getClassNames([styles.container, "shadow", `${flex ? "flex column" : ""} no-scrollbar`, ...classes ?? []])} style={{ width: style.width, height: style.height, order: style.order, ...css, backgroundColor: style.backgroundColor }}>
            {title &&
                <div className={styles.headerContainer}>
                    <header className={styles.header}>
                        <div className='flex centered-aligned' style={{ gap: "5px" }}>
                            {left}
                            <h3 className={styles.title}>
                                {title}
                            </h3>
                        </div>
                        {right && (
                            right({ expanded, setExpanded })
                        )}
                    </header>
                    <Collapse in={expanded}>
                        <div style={{ padding: "0 10px 10px 10px" }}>
                            {expandedElement}
                        </div>
                    </Collapse>
                </div>
            }
            <div style={{ height: title ? "calc(100%)" : "100%", width: "100%", overflow: disableScroll ? "hidden" : "scroll" }} className="scroll no-scrollbar" onScroll={onScroll ?? (() => { })} ref={innerListRef ?? (undefined)}>
                <div id={bodyID} style={{ marginBottom: !disableBodyMargin ? "100px" : undefined, width: "100%", height: fullHeightBody || disableScroll ? !title ? "calc(100%)" : "calc(100% - 50px)" : "auto", padding: bodyPadding ? "10px" : 0 }} className={getClassNames([styles.body, "scroll"])} ref={bodyRef}>
                    {children}
                </div>
            </div>
            {cta && (
                    <button className={styles.ctaButton} style={{ backgroundColor: isButtonPrimaryAction ? "var(--brand)" : "var(--brand-light)", color: isButtonPrimaryAction ? "var(--brand-contrast)" : "var(--brand)" }} onClick={() => ctaFunction!()}>
                        <span className={'flex centered-aligned button-text'} style={{ gap: "5px" }}>
                            {buttonIcon}
                            {ctaTitle}
                        </span>
                    </button>
                )}
        </div>
    )
}

export default Card