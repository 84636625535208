import { useState } from 'react'
import CardColumn from '../../../Components/Card/CardColumn/CardColumn'
import PersonalCard from '../../../Components/PersonalCards/PersonalCard'
import PersonalDataCard from '../../../Components/PersonalCards/PersonalDataCard'
import { StaffDTO, useStaff } from '../../../Contexts/StaffContext/StaffProvider'

export type userFormModes = "createUser" | "editUser";

function Personal() {

    const { defaultStaff } = useStaff();

    const [clickedUser, setClickedUser] = useState<StaffDTO>(defaultStaff!);
    const [formMode, setFormMode] = useState<userFormModes>("createUser");

    return (
        <>
                <CardColumn
                    height='100%'
                    width='50%'>
                    <PersonalCard
                        height='100%'
                        width='100%'
                        setClickedUser={setClickedUser}
                        setFormMode={setFormMode}
                    />
                </CardColumn>
                <CardColumn
                    height='100%'
                    width='50%'>
                    <PersonalDataCard
                        height='100%'
                        width='100%'
                        clickedUser={clickedUser}
                        setClickedUser={setClickedUser}
                        formMode={formMode}
                        setFormMode={setFormMode}
                    />
                </CardColumn>
        </>
    )
}

export default Personal