import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { baseConfig } from "../Connection/BaseConnection";

export const baseAuthQuery = fetchBaseQuery({
    baseUrl: baseConfig.baseURL,
    prepareHeaders: async (headers) => {
        const token = localStorage.getItem("AccessToken");

        if (token) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    }
})