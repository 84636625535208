import { AxiosResponse } from 'axios'
import { baseConnection } from '../Connection/BaseConnection'
import { useUser } from '../Contexts/UserContext/UserContext'
import { useLocalStorage } from './useLocalStorage'

//Hook zum Verwenden des JWT-Tokens
export default function useRefreshToken() {
    const { setUser, token, setToken, user } = useUser()

    const refresh = async () => {
        try {
            const { data } = await baseConnection.post('/authentication/refreshtoken', {
                accessToken: token ?? user?.token
            }, {
                withCredentials: true
            })
            
            setUser!({
                ...data,
                email: data.eMail
            })

            setToken!(data.token)
            return data.token
        } catch (error) {
            console.log(error)
        }

    }

    return refresh
}