import { PersonOutline } from "@mui/icons-material"
import Tooltip from "@mui/material/Tooltip"
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import MoreOptionsButton from "../../../../Components/Buttons/MoreOptionsButton/MoreOptionsButton";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { IHoliday, useTimeTracking } from "../../../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { StaffDTO } from "../../../../Contexts/StaffContext/StaffProvider";
import NoteOutlinedIcon from '@mui/icons-material/NoteOutlined';
import { IconButton } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useEmployees } from "../../../../Contexts/EmployeeContext/EmployeeProvider";
import { useUser } from "../../../../Contexts/UserContext/UserContext";

interface IHolidayRow {
    holiday: IHoliday
    user?: StaffDTO
    assignedUser?: StaffDTO
    setShowNote?: Dispatch<SetStateAction<boolean>>
    setNoteHoliday?: Dispatch<SetStateAction<IHoliday | undefined>>
    setModalConfirmFunction?: Dispatch<SetStateAction<() => void>>
    setShowConfirmationModal?: Dispatch<SetStateAction<boolean>>
}

function HolidayRow({ holiday, user, assignedUser, setShowNote, setNoteHoliday, setModalConfirmFunction, setShowConfirmationModal }: IHolidayRow) {

    const { resetHoliday, cancelHoliday } = useTimeTracking();
    const [slideOut, setSlideOut] = useState(false);

    const {user: currentUser} = useUser();

    const handleHolidayCancel = () => {
        //Open Modal and set Confirm function of modal
        if (setShowConfirmationModal) {
            setShowConfirmationModal(true);
        }

        if (setModalConfirmFunction) {
            setModalConfirmFunction(() => async () => {
                await cancelHoliday!(holiday)
                    .then(() => {
                        if (setShowConfirmationModal) {
                            setShowConfirmationModal(false)
                        }
                    })
            })
        }
    }
    const options = [
        {
            text: "Zurücksetzen",
            function: () => {
                setSlideOut(true)
                setTimeout(() => {
                    resetHoliday!(holiday)
                }, 300)
            },
            tooltipText: 'Urlaub wird zu einem Antrag zurückgesetzt, somit wieder als "Offen" markiert und kann erneut bestätigt oder abgelehnt werden.'
        }
    ]

    if (holiday.accepted === true) {
        options.push({
            text: "Stornieren",
            function: () => {
                handleHolidayCancel()
            },
            tooltipText: "Bestätigter Urlaub wird storniert. Der Mitarbeiter erhält eine E-Mail und wird über die Stornierung informiert."
        })
    }

    const showNote = () => {
        if (setNoteHoliday) {
            setNoteHoliday(holiday);
        }
        if (setShowNote) {
            setShowNote(true);
        }
    }

    return (
        <div style={{ width: "100%", display: "flex", flexDirection: "row", padding: "1%", alignItems: "center", backgroundColor: holiday.accepted ? "#e6fbe6" : "#ffebe6", borderWidth: "1px", borderStyle: "solid", borderColor: "white", borderRadius: "8px", transform: slideOut ? "translateX(-110%)" : "", transition: "width 0.3s, transform 0.3s" }}>
            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Mitarbeiter" placement="bottom">
                    <PersonOutline style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {user?.person?.completeName}
                </span>
            </div>
            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Gestellt am" placement="bottom">
                    <DateRangeOutlinedIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {new Date(holiday.requestedDate.toString()).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                </span>
            </div>

            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Urlaubsstart" placement="bottom">
                    <DateRangeOutlinedIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {new Date(holiday.startDate.toString()).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                </span>
            </div>

            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Urlaubsende" placement="bottom">
                    <DateRangeOutlinedIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {new Date(holiday.endDate.toString()).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                </span>
            </div>
            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Zugewiesen an" placement="bottom">
                    <SupervisorAccountOutlinedIcon style={{ marginRight: "0.5vh" }} />
                </Tooltip>
                <span>
                    {assignedUser?.person?.completeName ?? assignedUser?.username ?? "-"}
                </span>
            </div>
            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>

                {holiday.accepted ? <Tooltip title="Akzeptiert am" placement="bottom"><EventAvailableOutlinedIcon style={{ marginRight: "0.5vh" }} /></Tooltip> : <Tooltip title="Abgelehnt am" placement="bottom"><EventBusyOutlinedIcon style={{ marginRight: "0.5vh" }} /></Tooltip>}

                <span>
                    {holiday.processedDate && new Date(holiday.processedDate.toString()).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                </span>
            </div>
            <div style={{ width: "5%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <Tooltip title="Anmerkungen anzeigen" placement="bottom">
                    <IconButton onClick={() => showNote()}>
                        <NoteOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
            {(currentUser?.organizationRoleName === "Admin" || currentUser?.rights?.manageHolidays) &&
                <div style={{ width: "5%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <MoreOptionsButton options={options}></MoreOptionsButton>
                </div>
            }

        </div>
    )
}

export default HolidayRow