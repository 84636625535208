import React, { ReactNode, useEffect } from 'react'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import styles from './SearchableItem.module.css'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Fade, Grow } from '@mui/material';

//TODO any ausbessern -> Fabian bzw. wer Interesse hat
interface searchableSelect {
    title: ReactNode
    onClick?: () => void
    active?: boolean
    value?: any
    arrowKeyHovered?: boolean
}

const SearchableItem = ({title, active, onClick, arrowKeyHovered } : searchableSelect) => {


  
  return (
    <div onClick={onClick} className={!arrowKeyHovered ? getClassNames([styles.container, "flex", "stretched-justify"]) : getClassNames([styles.containerArrowHover, "flex", "stretched-justify"])}>
        {title}
        <Grow in={active}>
          <CheckCircleRoundedIcon color="success" fontSize='inherit' />
        </Grow>
    </div>
  )
}

export default SearchableItem