import { Switch, Tooltip } from '@mui/material'
import React, { ReactNode } from 'react'
import Android12Switch from '../../Buttons/Switch/Android12Switch'
import DateInputField, { DateInputTypes } from '../../InputField/DateInputField'
import InputField from '../../InputField/InputField'
import InputFieldRefactored, { IInputField, InputFieldTypes } from '../../InputField/InputFieldRefactored'
import SearchableSelect from '../../InputField/SearchableSelect'
import styles from '../../InputField/InputField.module.css'
import { Dayjs } from 'dayjs'

interface IFormRow extends IInputField {
    title?: string
    selectOptions?: {
        titleKey: string
        valueKey: string
        options: any[]
        multiple?: boolean
    }
    dateOptions?: {
        type?: DateInputTypes
        disablePast?: boolean
        disableFuture?: boolean
        maxDate?: Date | string
        minDate?: Date | string
    }
    style?: any
    disabled?: boolean
}

const FormRow = ({ disabled = false, value, type, onChange, onBlurHandler, label, placeholder, error, errorMessage, errorStyle, autofocus, autocomplete, readonly, step, title, maxLength, selectOptions, dateOptions, style, newPassword, tooltip, tooltipMessage, register }: IFormRow) => {
    return (
        <div className={label ? "form-row-label" : "form-row-label"} onClick={typeof value === "boolean" ? () => onChange(!value as boolean) : () => null} style={style}>
            {label && (<span className="label" style={error ? { color: "var(--error)" } : {}}>
                {label}
            </span>)
            }
            {type === "date" &&
                <div>
                    <DateInputField
                        value={value as Dayjs | undefined}
                        /* @ts-ignore */
                        onChangeHandler={(val) => onChange(val as Dayjs | undefined)}
                        type={dateOptions?.type}
                        maxDate={dateOptions?.maxDate}
                        disablePast={dateOptions?.disablePast}
                        disableFuture={dateOptions?.disableFuture}
                        minDate={dateOptions?.minDate}
                        error={error}
                        readonly={readonly || disabled}
                    />
                    {error && <span className={errorStyle === "medium" ? styles.errorLogin : styles.error}>{errorMessage}</span>}
                </div>
            }
            {type === "switch" && (

                <div className='form-row'>
                    <span>{title ?? ""}</span>
                    {tooltip ?
                        <Tooltip placement='bottom' title={tooltipMessage ?? ""}>
                            <Android12Switch color="secondary" checked={value as boolean} onChange={(e, val) => onChange(val)} />
                        </Tooltip>
                        :
                        <Android12Switch color="secondary" checked={value as boolean} onChange={(e, val) => onChange(val)} />
                    }

                </div>
            )}
            {type === "select" &&
                (
                    <div>
                        <SearchableSelect
                            titleKey={selectOptions?.titleKey!}
                            valueKey={selectOptions?.valueKey!}
                            options={selectOptions?.options!}
                            value={value}
                            multiple={selectOptions?.multiple}
                            //@ts-ignore
                            setValue={onChange}
                            placeholder={placeholder}
                            error={error}
                            disabled={disabled}
                        />
                        {error && <span className={errorStyle === "medium" ? styles.errorLogin : styles.error}>{errorMessage}</span>}
                    </div>
                )
                //@ts-ignore
                //: <InputField placeholder={placeholder} type={type == "Number" ? "number" : undefined} multiline={multiline ?? false} value={value as string} onChangeHandler={(value) => onChange(value)} />
            }
            {(type === "multiline" || type === "text" || type === "number" || type === "password") &&
                <InputFieldRefactored
                    value={value as string}
                    type={type}
                    onChange={(value) => onChange(value)}
                    onBlurHandler={onBlurHandler}
                    placeholder={placeholder}
                    error={error}
                    errorMessage={errorMessage}
                    errorStyle={errorStyle}
                    autofocus={autofocus}
                    autocomplete={autocomplete}
                    readonly={readonly}
                    step={step}
                    maxLength={maxLength}
                    style={style}
                    newPassword={newPassword}
                    disabled={disabled}
                    register={register}
                />
            }
        </div >
    )
}

export default FormRow