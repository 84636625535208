import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetDefaultTextModulesQuery } from "../../redux/apiSlices/TextModule";
import { Card, CardContent, CardHeader, CardTitle } from "../UI/CardNew";
import DefaultTextModuleRow from "./DefaultTextModuleRow";

export default function DefaultTextModuleOverview() {

    /*     const { data: textModules, isLoading } = useGetTextModulesQuery();*/
    const navigate = useNavigate();
    const { data: defaultModules, isLoading } = useGetDefaultTextModulesQuery();

    return (
        <Card>
            <CardHeader>
                <CardTitle>Standardtexte</CardTitle>
            </CardHeader>
            {isLoading ?
                <div className="flex w-full h-full justify-center items-center"><CircularProgress /></div> :
                <CardContent>
                    <div className="flex flex-row w-full">
                        <div className="w-[40%] font-bold">Name</div>
                        <div className="w-[40%] font-bold">Typ</div>
                    </div>
                    {defaultModules?.map((textModule) => (
                        <DefaultTextModuleRow key={textModule.id} textModule={textModule} />
                    ))}
                </CardContent>
            }
        </Card>
    )
}