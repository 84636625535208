import PageTabsWrapper from "../../Components/Page/PageTabs";
import TextModuleOverview from "../../Components/TextModules/DefaultTextModuleOverview";

export default function SettingsDefaultTextModules() {

    const routes = [
        { path: "/einstellungen/privat", name: "Persönlich" },
        { path: "/einstellungen/unternehmen", name: "Unternehmen" },
        { path: "/einstellungen/standardtexte", name: "Standardtexte" }
    ]

    return (
        <PageTabsWrapper routes={routes}>
                <TextModuleOverview />
        </PageTabsWrapper>
    )
}