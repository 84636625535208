import { DragHandleRounded } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Grid } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import { useStaff } from '../../../Contexts/StaffContext/StaffProvider';
import { DefaultAppointmentCategoryIds, useTasks } from "../../../Contexts/TaskContext/TaskContext";
import { IHoliday } from '../../../Contexts/TimeTrackingContext/TimeTrackingProvider';
import { calculateContrastColor } from '../ColorUtils';
import useDragger from '../useDragger';
import modalStyles from "./HolidayInfoModal.module.css";

interface props {
    holiday?: IHoliday,
    setHoliday?: Dispatch<SetStateAction<IHoliday>>
    setShowHolidayInfoModal?: Dispatch<SetStateAction<boolean>>
}

const HolidayInfoModal = ({ holiday, setHoliday, setShowHolidayInfoModal }: props) => {
    const { staff } = useStaff()
    const { appointmentCategories } = useTasks()

    const [holidayEmployee, setHolidayEmployee] = useState(staff.find((s) => s.id === holiday?.userId))

    const category = appointmentCategories.find((ac) => ac.id == DefaultAppointmentCategoryIds.Holiday)


    useDragger('target', 'AppointmentInfoModal')

    return (
        <div className={modalStyles.modalContainer}>
            <form className={modalStyles.modalForm}>
                <header className={modalStyles.modalHeader} style={{ background: category?.color, color: category?.color ? calculateContrastColor(category?.color?.toString()) : "" }} id='target'>
                    {<DragHandleRounded style={{ color: 'black', marginLeft: '0px', marginRight: 'auto' }} />}
                    <Button onClick={() => {
                        setShowHolidayInfoModal!(false)
                    }}
                        style={{ minWidth: '5%' }} size="small"><CloseRoundedIcon fontSize='small' style={{ color: 'black' }} /></Button>
                </header>
                <div className={modalStyles.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Mitarbeiter:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{holidayEmployee?.person?.completeName ?? holidayEmployee?.username}</Grid>
                        <Grid item xs={12} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}><hr style={{ border: '1px solid whitesmoke' }} /></Grid>
                        {holiday?.requesterNote &&
                            <>
                                <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Beschreibung:</Grid>
                                <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{holiday?.requesterNote}</Grid>
                                <Grid item xs={12} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}><hr style={{ border: '1px solid whitesmoke' }} /></Grid>
                            </>
                        }
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Start:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{dayjs(new Date(holiday?.startDate!)).format('DD.MM.YYYY - HH:mm')}</Grid>

                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Ende:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{dayjs(new Date(holiday?.endDate!)).format('DD.MM.YYYY - HH:mm')}</Grid>
                        <span style={{ marginRight: '0px', marginLeft: 'auto', marginTop: '1em', color: 'grey', fontSize: '0.9em', fontStyle: 'italic' }}>
                            Beantragt am {dayjs(new Date(holiday?.requestedDate!)).format('DD.MM.YYYY - HH:mm')} und akzeptiert am {dayjs(new Date(holiday?.processedDate!)).format('DD.MM.YYYY - HH:mm')}
                        </span>
                    </Grid>
                </div>
            </form>
        </div>

    )
}

export default HolidayInfoModal;