import { Paid, LocalShipping } from '@mui/icons-material';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import { useTasks } from '../../Contexts/TaskContext/TaskContext';
import { useMobileView } from '../../Hooks/useMobileView';
import { Tooltip } from '@mui/material';

interface IDeliveryNoteRow {
    deliveryNote: any
}

function DeliveryNoteRow({ deliveryNote }: IDeliveryNoteRow) {

    const { companies } = useContacts();
    const { deleteDeliveryNote } = useTasks()
    const navigate = useNavigate();

    const isMobile = useMobileView();

    const options = [
        {
            text: "Bearbeiten",
            function: () => { navigate("/aufgaben/lieferschein/erstellen?id=" + deliveryNote.id) }
        },
        {
            text: "Löschen",
            function: () => { deleteDeliveryNote(deliveryNote) }
        }
    ]

    return (

        <>
            {!isMobile &&

                <div className='flex w-[100%] p-4 hover:bg-slate-50'>
                    <div className='w-[32.5%]'>
                        {companies.find(company => company.id === deliveryNote.customerOrganizationId)?.name}
                    </div>

                    <div lang='de' className='w-[15%] break-normal'>
                        {new Date(deliveryNote.date).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                    </div>
                    <div className='w-[5%] flex justify-center'>

                        {deliveryNote.billId !== null ?
                            <Tooltip title="Rechnung erstellt">
                                <Paid style={{ color: "green" }} />
                            </Tooltip>
                            : <Tooltip title="Lieferschein erstellt">
                                <LocalShipping />
                            </Tooltip>
                        }

                    </div>
                    <div className='w-[10%] flex justify-center'>
                        {deliveryNote.number ?? "-"}
                    </div>
                    <div className='w-[32.5%]'>
                        {deliveryNote.title}
                    </div>

                    <div className='w-[5%]'>
                        <MoreOptionsButton options={options}></MoreOptionsButton>
                    </div>

                </div>
            }

            {isMobile &&

                <div className='flex w-[100%] p-2 border-b-2 border-b-slate-200'>
                    <div className='flex flex-col w-[80%]'>

                        <div className='flex flex-row justify-between'>
                            <div className='font-medium'>Kunde</div>
                            <div className='text-center'>
                                {companies.find(company => company.id === deliveryNote.customerOrganizationId)?.name}
                            </div>
                        </div>

                        <div className='flex flex-row justify-between'>
                            <div className='font-medium'>Datum</div>
                            <div lang='de' className='text-center'>
                                {new Date(deliveryNote.date).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" })}
                            </div>
                        </div>

                        <div className='flex flex-row justify-between'>
                            <div className='font-medium'>Status</div>
                            <div className='flex justify-center'>

                                {deliveryNote.billId !== null ?
                                    <Tooltip title="Rechnung erstellt">
                                        <Paid style={{ color: "green" }} />
                                    </Tooltip>
                                    : <Tooltip title="Lieferschein erstellt">
                                        <LocalShipping />
                                    </Tooltip>
                                }

                            </div>
                        </div>

                        <div className='flex flex-row justify-between'>
                            <div className='font-medium'>Nummer</div>
                            <div className='flex justify-center'>
                                {deliveryNote.number ?? "-"}
                            </div>
                        </div>

                        <div className='flex flex-row justify-between'>
                            <div className='font-medium'>Titel</div>
                            <div className='text-center'>
                                {deliveryNote.title}
                            </div>
                        </div>



                    </div>
                    <div className='flex w-[20%] self-center ml-4'>
                        <MoreOptionsButton options={options} />
                    </div>
                </div>
            }
        </>

    )
}

export default DeliveryNoteRow