import React, { useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'

/* Icons */
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import Card from '../Card/Card'
import styles from './RevenueExpenditureCard.module.css'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import BaseButton from '../Buttons/BaseButton/BaseButton'
import Tag from '../Tag/Tag'
import { useBills } from '../../Contexts/BillContext/BillProvider'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const initialData = {
  labels: ['Einnahmen', 'Ausgaben'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19],
      backgroundColor: [
        'rgba(0, 200, 0, 1)',
        'rgba(200, 0, 0, 1)',
      ],
      borderColor: [
        'rgba(0, 200, 0, 1)',
        'rgba(200, 0, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

function RevenueExpenditureCard({ width, height, order }: card) {

  const { bills, accounting } = useBills();

  const [doughnutData, setDoughnutData] = useState(initialData)

  useEffect(() => {
    let tempDataset = doughnutData.datasets[0];
    tempDataset.data = [(accounting?.revenue ?? 0), (accounting?.expenditure ?? 0)]
    setDoughnutData({ ...doughnutData, datasets: [tempDataset] });
  }, [accounting])

  return (
    <Card
      title='Einnahmen/Ausgaben'
      style={{ width, height, order }}
      fullHeightBody={false}
      right={() => <div></div>}
    >
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", }}>
        <Doughnut data={doughnutData} options={{ maintainAspectRatio: false }} height={"300%"} />
      </div>

    </Card>
  )
}

export default RevenueExpenditureCard