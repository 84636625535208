import React, { useEffect } from 'react'
import { IContactData, IPerson } from '../../Contexts/ContactsContext/ContactsProvider'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import { getClassNames } from '../../Hooks/Utilities/Utlitites';
import styles from '../Company/CompanyField.module.css'
import { useModals } from '../../Contexts/DialogContext/DialogProvider';

interface IContactDatafieldProps {
    person: IPerson
    contactData: IContactData[]
}

function ContactDataField(props: IContactDatafieldProps) {

    const { person, contactData } = props;

    const { changeVisibility } = useModals()
    
    const handleEdit = () => {
        changeVisibility!("editCreateContactDynamic", true, { props: { object: person, mode: "create"} })
    }


    const getElement = (type: number, value: string) => {
        switch (type) {
            case 1:
                return (
                    <a href={`tel:${ value }`} className={getClassNames(["flex centered-aligned", styles.clickable])} style={{ textDecoration: "none", color: "var(--text)" }}>
                        <div className='flex centered-aligned' style={{ gap: "8px" }}>
                            <LocalPhoneRoundedIcon fontSize='small' />
                            <span>{value}</span>
                        </div>
                    </a>
                )
            case 2:
                return (
                    <a href={`mailto:${ value }`} className={getClassNames(["flex centered-aligned", styles.clickable])} style={{ textDecoration: "none", color: "var(--text)" }}>
                        <div className='flex centered-aligned' style={{ gap: "8px", width: "100%" }}>
                            <EmailRoundedIcon fontSize='small' />
                            <span style={{ maxWidth: "calc(100%)", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{value}</span>
                        </div>
                    </a>
                )
            case 3:
                let displayURL = value;
                displayURL = displayURL.replace(new RegExp("^https?://"), "");
                return (
                    <a style={{ textDecoration: "none", color: "var(--text)" }} className={getClassNames(["flex centered-aligned", styles.clickable])} target="_blank" href={value}>
                        <div className='flex centered-aligned' style={{ gap: "8px" }}>
                            <PublicRoundedIcon fontSize='small' />
                            <span>{displayURL}</span>
                        </div>
                    </a>
                )
            default:
                return null
        }
    }

    return (
        <div className='flex column' style={{ padding: "10px", gap: "4px" }}>
            {(contactData !== undefined && contactData.length > 0) ? contactData.map((contact) => (
                <div key={contact.id}>{getElement(contact.type, contact.value)}</div>
            ))
                :
                <div className='centered column' style={{ padding: "10px", gap: "10px" }}>
                    <span style={{ color: "var(--text-contrast" }}>Keine Daten zur Person.</span>
                    <button className='cta-button pointer' onClick={() => handleEdit()}>
                        Hinzufügen
                    </button>
                </div>
            }
        </div>
    )
}

export default ContactDataField