import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styles from './BillsCard.module.css'

import { useNavigate } from 'react-router-dom';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import { IProductDTO, useProducts } from '../../Contexts/ProductContext/ProductProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { productFormModes } from '../../Pages/Verwaltung/Produkte';


interface props {
    product: IProductDTO
    setClickedProduct?: Dispatch<SetStateAction<IProductDTO>>
    setFormMode?: Dispatch<SetStateAction<productFormModes>>
}

interface Document {
    id: number,
    title: string,
    description: string,
    fileEnding: string,
    fileId: string,
    base64: string,
    organizationId: number,
}

const ProductRow = ({ product, setClickedProduct, setFormMode }: props) => {
    let navigate = useNavigate();

    const productContext = useProducts();
    const dialogContext = useModals();

    const onPressEdit = () => {
        dialogContext.changeVisibility!("editCreateProduct", true, { props: { object: product, mode: "edit" } });
    }

    const onPressDelete = () => {
        productContext.removeProduct(product);
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const handleProductClicked = () => {
        if(setClickedProduct) {
            setClickedProduct(product);
        }
        if(setFormMode) {
            setFormMode("editProduct");
        }
    }

    return (
        <>
            {product &&
                <div className={'flex column '.concat(styles.container).concat(" itemContainer")} style={{ backgroundColor: "white", cursor: setClickedProduct ? "pointer" : "default" }} onClick={() => handleProductClicked()}>
                    <header className='flex centered-aligned stretched-justify'>
                        <div className='flex centered-aligned' style={{ gap: "6px" }}>
                            <div className={(product.count !== 0) ? styles.accent : styles.outgoing} />
                            <span className='bold-big'>{product.title ?? ""}</span>
                        </div>
                        <MoreOptionsButton options={options}></MoreOptionsButton>
                    </header>
                    <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                        <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span style={{ textOverflow: "ellipsis" }}>{"Menge: " + (product.count === null ? "∞" : product?.count) /* Hier sollte die zur ID gehrenden Firma stehen TODO: ergänzen, wenn der Firmenstate existiert */}</span>
                        </div>
                        
                        <div style={{ height: "12px", width: "1px", backgroundColor: 'black', borderRadius: "100px" }} />
                        <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <span>
                                {"Einkauf: " + product.cost?.toFixed(2).replace(".", ",") + " €"}
                            </span>
                        </div>
                        <div style={{ height: "12px", width: "1px", backgroundColor: 'black', borderRadius: "100px" }} />
                        <div style={{ width: "20%", display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                            <span style={{ textOverflow: "ellipsis" }}>{"Verkauf: " + product.salesPrice?.toFixed(2).replace(".", ",") + " €"}</span>
                        </div>
                    </section>
                </div>
            }
        </>
    )
}

export default ProductRow;