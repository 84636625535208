import React, { useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'

/* Icons */
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import Card from '../Card/Card'
import styles from './RevenueExpenditureCard.module.css'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import BaseButton from '../Buttons/BaseButton/BaseButton'
import Tag from '../Tag/Tag'
import { useBills } from '../../Contexts/BillContext/BillProvider'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Bar, Doughnut } from "react-chartjs-2";
import { Button, CircularProgress } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import useAuthConnection from '../../Hooks/useAuthConnection'

ChartJS.register(ArcElement, Tooltip, Legend);

//Das ist die richtige Data-Intialisierung
export const initialData = {
  labels: ['Einnahmen', 'Ausgaben'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19],
      backgroundColor: [
        'rgba(0, 200, 0, 1)',
        'rgba(200, 0, 0, 1)',
      ],
      borderColor: [
        'rgba(0, 200, 0, 1)',
        'rgba(200, 0, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

function RevenueExpenditureMonthlyCard({ width, height, order }: card) {

  const { accounting } = useBills();

  //Dieser Useeffetct ändert die Balken im Diagramm sobald eine Einnahme/Ausgabe dazukommt.
  useEffect(() => {
    if (accounting) {
      setData(accounting.revenueExpenditure);
    }
  }, [accounting])

  //Kommt vermutlich noch kopiert von d. Mitarbeiterstundenübersicht und kann hier event. gelöscht werden:
  const [data, setData] = useState(initialData);


  return (
    <Card
      style={{ height, width, order }}
      title="Einnahmen/Ausgaben"
    >
      <div style={{ display: "flex", flexDirection: "column", height: "80%", justifyContent: "center", alignItems: "center" }}>
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Bar data={data} />
        </div>
      </div>
    </Card>
  )
}

export default RevenueExpenditureMonthlyCard