import React, { useEffect, useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CreateDepartementCard, { DepartmentFormModes } from '../../Components/DepartementCards/CreateDepartementCard'
import DepartementOverviewCard from '../../Components/DepartementCards/DepartementOverviewCard'
import PersonalCard from '../../Components/PersonalCards/PersonalCard'
import PersonalDataCard from '../../Components/PersonalCards/PersonalDataCard'
import ProductDataCard from '../../Components/ProductCards/ProductDataCard'
import Page from '../../Navigation/Page'
import { IDepartement, defaultDepartment } from '../../Contexts/DepartementContext/DepartementProvider'
import Card from '../../Components/Card/Card'
import { Collapse, Tooltip } from '@mui/material'
import MoreOptionsButton from '../../Components/Buttons/MoreOptionsButton/MoreOptionsButton'
import { Download, ExpandLessRounded, ExpandMoreRounded, PersonOutline, SnoozeOutlined, WorkHistoryOutlined, WorkOffOutlined, WorkOutline } from '@mui/icons-material'
import { useDocuments } from '../../Contexts/DocumentContext/DocumentContext'
import DocumentRow from '../../Components/DocumentRow/DocumentRow'
import Pagination from '../../Components/Pagination/Pagination'
import { formatBytes, getMaxSpaceOfSubscription, totalFileSizeBytes } from '../../utils/helper'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import DocumentHeaderRow from '../../Components/DocumentRow/DocumentHeaderRow'

function Dokumente() {

    const { documents } = useDocuments();

    const [page, setPage] = useState<number>(0);
    const itemsPerPage = 9;

    const spaceUsed = totalFileSizeBytes(documents);

    const { companyData } = useCompanyData();

    return (
        <>
            <CardColumn
                height='100%'
                width='100%'
            >
                <Card
                    title={`Dokumente ${spaceUsed} / ${formatBytes(getMaxSpaceOfSubscription(companyData?.subscription ?? ""))}`}
                >
                    <DocumentHeaderRow />
                    {documents.map((document, index) => {
                        if (index >= (page) * itemsPerPage && index < (page + 1) * itemsPerPage) {
                            return <><DocumentRow document={document} /></>
                        }

                    })}
                    <div style={{ marginTop: "2em" }}>
                        <Pagination items={documents ?? []} itemsPerPage={itemsPerPage} page={page} setPage={setPage} />
                    </div>
                </Card>
            </CardColumn>

        </>
    )
}

export default Dokumente