import { LinearProgress } from '@mui/material'
import { AxiosResponse } from 'axios'
import React, { createContext, useContext, useEffect, useReducer, useState } from 'react'
import uuid from 'react-uuid'
import { authConnection, baseConnection } from '../../Connection/BaseConnection'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useLocalStorage } from '../../Hooks/useLocalStorage'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { journalDTO } from '../../Interfaces/Journal'
import { User } from '../../Interfaces/User'
import { UserContext } from '../../Interfaces/UserContext'
import userReducer from './DocumentReducer'
import { get } from 'https'

export interface IPersonHasDocuments {
  personId: number,
  documents: Array<IDocument>
}

export interface IDocument {
  id?: number,
  title?: string,
  description?: string,
  fileEnding?: string,
  fileId?: string,
  base64?: string,
  organizationId?: string
  sequenceNumber?: number
  fileSize?: number
}

interface DocumentContext {
  documents: Array<any>
  documentsForPerson: Map<number, Array<IDocument>>
  documentsForCompany: Map<number, Array<IDocument>>
  addDocument?: any
  downloadDocument?: any
  uploadChunkedFile?: any
  getAllDocumentsForPerson?: any
  getAllDocumentsForCompany?: any
  addDocumentToPerson?: any
  addDocumentToOrganization?: any
}

//Initalisieren des Kontexts
const DocumentContext = createContext<DocumentContext>({
  documents: [],
  documentsForPerson: new Map(),
  documentsForCompany: new Map()
})


export const downloadFile = (file: IDocument, url: string, onProgress: any) => {
  return authConnection({
    url,
    method: 'GET',
    responseType: 'blob',
    onDownloadProgress: onProgress,
  })
    .then((response) => {
      // Read the filename from the Content-Disposition header
      const customFilename = response.headers['custom-filename'];
      const filename = customFilename ?? file.title ?? "Download";

      // Handle successful download
      // For example, create a download link and simulate a click
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      // Handle error
    });
};

//Wrapper für den Kontext -> Alle Kind-Elemente können auf die Daten in diesem Wrapper zugreifen.
export function DocumentProvider({ children }: { children: JSX.Element }) {

  const { enqueueSnackbar } = useSnackBar()
  const connection = useAuthConnection();

  //Komplexer State für den Kontext -> Good Practice für die Arbeit mit Context
  const [state, dispatch] = useReducer(userReducer, {
    documents: [],
    documentsToPersons: []
  })

  const addDocument = async (file: any, key?: string, id?: string, sequenceNumber?: number) => {
    let formData = new FormData();

    let document: IDocument = { title: file.name }
    const json = JSON.stringify(document);
    const blob = new Blob([json], {
      type: 'application/json'
    });

    formData.append("file", file!)
    formData.append("data", json);

    if (key && id) {
      formData.append(key, JSON.stringify(id!))
    }
    if (sequenceNumber) {
      formData.append("SequenceNumber", sequenceNumber.toString())
    }
    let result = await connection.post("/document/create", formData);

    return result;
  }


  const downloadDocument = async (documentId: number) => {
    let result = await connection.get(`/document/download64/${documentId}`);

    return result;
  }


  const getAllDocumentsList = async () => {
    let result = await connection.get("/document");

    dispatch({
      type: "SET_DOCUMENTS",
      payload: result.data
    })

    return result;
  }

  const getAllDocumentsForPerson = async (personId: number) => {
    let result = await connection.get("/document/personHasDocuments", { params: { personId: personId } });

    let payload = { documents: result.data, personId: personId }
    dispatch({
      type: "SET_DOCUMENTSFORPERSON",
      payload: payload
    })

    return result;
  }

  const getAllDocumentsForCompany = async (organizationId: number) => {
    let result = await connection.get("/document/organizationHasDocuments", { params: { organizationId: organizationId } });

    let payload = { documents: result.data, organizationId: organizationId }
    dispatch({
      type: "SET_DOCUMENTSFORORGANIZATION",
      payload: payload
    })

    return result;
  }

  const addDocumentToPerson = async (personId: number, document: IDocument) => {
    dispatch({
      type: "ADD_DOCUMENTTOPERSON",
      payload: { personId: personId, document: document }
    })
  }

  const addDocumentToOrganization = async (organizationId: number, document: IDocument) => {
    dispatch({
      type: "ADD_DOCUMENTTOORGANIZATION",
      payload: { organizationId: organizationId, document: document }
    })
  }

  useEffect(() => {
    getAllDocumentsList();

    return () => { }
  }, [])

  //Der schließliche Wrapper wird durch den Provider bereitgestellt.
  return (
    <DocumentContext.Provider value={{
      documents: state.documents,
      documentsForPerson: state.documentsForPerson,
      documentsForCompany: state.documentsForCompany,
      addDocument,
      downloadDocument,
      getAllDocumentsForPerson,
      getAllDocumentsForCompany,
      addDocumentToPerson,
      addDocumentToOrganization
      //uploadChunkedFile
    }}>
      {children}
    </DocumentContext.Provider>
  )
}


//Ermöglicht den erleichterten Zugang zu den Daten im Kontext in den Kind-Elementen.
export const useDocuments = () => useContext(DocumentContext)